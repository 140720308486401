import { Button, Grid, Typography } from "@mui/material"
import DialogComponent from "../../../views/prestacaodecontas/Desktop/sections/dialogs-desktop"
import { IconsSource } from "../../../images/Icons"
import { EnumTipo } from "../../../enums/enumPrestacaoDeContas"
import { useState } from "react"

export const CardAddInViagem_desk = ({
    viagem,
    despesas,
    loading,
    pendente,
    handleClickFinalizarViagem,
    hasApprovedAdiantamento,
    incorrectSpen,
    setType
}) => {
    const [avancedDespesa, setAvancedDespesa] = useState(false);
    const [openDeleteDespesa, setOpenDeleteDespesa] = useState(false)
    const [openFinalizarDespesa, setOpenFinalizarDespesa] = useState(false)

    return (
        <Grid container item xs={12} style={{ display: "grid", justifyContent: "center" }}>
            {viagem.status === 1 || viagem.status === 0 || viagem.status === EnumTipo.viagemstatus.pendenteacorrecao ? (
                <Grid style={{ minWidth: "200px", marginTop: "0px", justifyContent: "center", textAlign: "center" }}>
                    {viagem !== null && despesas.length !== 0 && viagem.status !== EnumTipo.viagemstatus.pendenteacorrecao ? (
                        <Button fullWidth size="large" variant="outlined" color="primary" onClick={() => setOpenFinalizarDespesa(true)}>Finalizar despesa</Button>
                    ) : (null)}
                    {(viagem.status === EnumTipo.viagemstatus.pendenteacorrecao && incorrectSpen.length !== 0 && !loading) && (
                        <Typography variant="body2" style={{ color: "#313131" }}>Há despesa(s) pendente a correção, retornado pelo auditor, corrija oque foi pedido para avançar sua viagem.</Typography>
                    )}
                    {viagem.status === EnumTipo.viagemstatus.pendenteacorrecao && incorrectSpen.length === 0 && !loading && despesas.length !== 0 ? (
                        <Button variant="outlined" size="large" color="primary" fullWidth onClick={() => setOpenFinalizarDespesa(true)}>Aplicar Correção</Button>
                    ) : (null)}
                    {(viagem.status === EnumTipo.viagemstatus.pendenteacorrecao && despesas === 0) && (
                        <Button variant="contained" size="large" color="error" onClick={() => setOpenDeleteDespesa(true)}>Deletar despesa</Button>
                    )}
                    <DialogComponent
                        title={"Avançar despesa"}
                        open={openFinalizarDespesa}
                        handleClose={() => setOpenFinalizarDespesa(false)}
                        body={
                            <Grid container item xs={12}>
                                {(pendente.length !== 0) ? (
                                    <Typography>Corrija seus gastos pendentes para finalizar sua despesa</Typography>
                                ) : (
                                    <Typography>Ao avançar sua despesa não poderá retorna-la, apenas ver sua despesa</Typography>
                                )}
                            </Grid>
                        }
                        action={
                            <Grid>
                                {!(pendente.length !== 0) && (
                                    <>
                                        <Button onClick={() => setAvancedDespesa(false)}>Cancelar</Button>
                                        <Button onClick={() => handleClickFinalizarViagem()}>Confimar</Button>
                                    </>
                                )}
                            </Grid>
                        }
                        width={"sm"}
                    />

                    {viagem.status === EnumTipo.viagemstatus.aguardandoinicio || viagem.status === EnumTipo.viagemstatus.emviagem || viagem.status !== EnumTipo.viagemstatus.correcaorealizada && (viagem.status === EnumTipo.viagemstatus.pendenteacorrecao && !hasApprovedAdiantamento(viagem.adiantamento)) ? (
                        <>
                            <Button fullWidth size="large" className="adicionardespesa" variant="contained" color="primary" onClick={() => setType(1)}>Adicionar gasto</Button>
                            <Button
                                className="adicionarhodometro"
                                size="large"
                                fullWidth
                                variant="contained"
                                startIcon={<img alt="Google Maps" src={IconsSource.googleMapIcon} width={20} />}
                                color="secondary"
                                onClick={() => setType(2)}
                            >
                                Hodometro
                            </Button>
                        </>
                    ) : (null)}
                </Grid>
            ) : (null)}
        </Grid>
    )
}