import prestacaoDeContasRepositorio from "../../../repositorios/prestacaodecontas";

import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import { formatarDataPut } from "../../../utils/dates-converters";

dayjs.locale('pt-br');

export const Scripts = {
    getMinhasViagens: async (setLoading, enqueueSnackbar, selectedDate, setData, setPerson) => {
        setLoading(true);
        try {
            let model = {
                data_inicial: dayjs(new Date(new Date().getFullYear(), 0, 1)).toISOString(), // Start of the year in ISO format
                data_final: dayjs(new Date(new Date().getFullYear(), 11, 31)).toISOString(), // End of the year in ISO format
                id_pessoa: 0,
                id_centrocusto: 0,
                minhasviagens: true
            };

            // Override dates if the user selected a date range
            if (selectedDate[0] && selectedDate[1]) {
                model.data_inicial = dayjs(selectedDate[0]).toISOString();
                model.data_final = dayjs(selectedDate[1]).toISOString();
            }

            const response = await prestacaoDeContasRepositorio.getAllViagensUser(model);

            const sortedViagens = response.viagens.sort((a, b) => new Date(b.datadecriacao) - new Date(a.datadecriacao));

            setData(sortedViagens);

            setPerson(response.pessoa);
        } catch (error) {
            enqueueSnackbar('Erro ao obter as suas viagens.', { variant: 'error' });
        } finally {
            setLoading(false);

        }
    },
    confirmCriarNovaViagem: async (setLoading, enqueueSnackbar, novaviagem, setData) => {
        setLoading(true)
        try {
            if (novaviagem.titulo === "" || novaviagem.titulo === null) {
                enqueueSnackbar('Titulo da viagem não pode ser vazio.', { variant: 'warning' });
                return
            }
            if (novaviagem.id_centrocusto === 0) {
                enqueueSnackbar('Centro de custo não pode ser vazio.', { variant: 'warning' });
                return
            }

            if (novaviagem.datainicio === null) {
                enqueueSnackbar('Defina uma data inicial.', { variant: 'warning' });
                return
            }

            if (novaviagem.datafim === null) {
                enqueueSnackbar('Defina uma data final.', { variant: 'warning' });
                return
            }

            novaviagem.adiantamento.forEach(viagem => {
                if (typeof viagem.total === 'string') {
                    let totalString = viagem.total.replace(/\./g, '').replace(',', '.');
                    viagem.total = parseFloat(totalString);
                }
            });

            if (typeof novaviagem.totalinicial === 'string') {
                let totalString = novaviagem.totalinicial.replace(/\./g, '').replace(',', '.');
                novaviagem.totalinicial = parseFloat(totalString);
            }

            await prestacaoDeContasRepositorio.putNovaViagem(novaviagem);

            enqueueSnackbar('Viagem criada com sucesso.', { variant: 'success' });
        } catch (e) { // Correção: incluído o parâmetro de erro no catch
            enqueueSnackbar('Ocorreu um erro ao criar viagem.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    },
    getViagemForID: async (setLoading, enqueueSnackbar, id, setData, gastos) => {
        setLoading(true)
        try {
            const response = await prestacaoDeContasRepositorio.getViagem(id);
            setData(response)
        } catch (error) {
            enqueueSnackbar('Erro ao obter sua viagem.', { variant: 'error' });
        } finally {
            setLoading(false);
        }
    },
    handleFinalizarViagem: async (setLoading, enqueueSnackbar, idviagem) => {
        setLoading(true)
        try {
            await prestacaoDeContasRepositorio.postAvancarViagem(idviagem)
            enqueueSnackbar('Despesa finalizada com sucesso.', { variant: 'success' });
        } catch (e) { // Correção: incluído o parâmetro de erro no catch
            enqueueSnackbar('Ocorreu um erro ao finalizada despesa.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    },
    handleClickEditViagem: async (setLoading, enqueueSnackbar, viagem, titulo, startDate, endDate) => {
        setLoading(true);
        try {
            // Cria um objeto vazio para incluir
            let model = {};
            // Verifica se a data de início foi alterada
            if (startDate) {
                model.datainicio = formatarDataPut(new Date(startDate));
            }

            if (endDate) {
                model.datafim = formatarDataPut(new Date(endDate));
            }

            if (titulo && titulo !== viagem.titulo) {
                model.titulo = titulo;
            }
            // Apenas chama o putEditViagem se houver alguma alteração
            if (Object.keys(model).length > 0) {
                await prestacaoDeContasRepositorio.putEditViagem(viagem.id, model);
                enqueueSnackbar('Viagem editada com sucesso.', { variant: 'success' });
            } else {
                enqueueSnackbar('Nenhuma alteração realizada.', { variant: 'info' });
            }
        } catch (e) {
            enqueueSnackbar('Ocorreu um erro ao editar a viagem.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    },
    handleClickExcluirViagem: async (setLoading, enqueueSnackbar, idviagem) => {
        setLoading(true)
        try {
            await prestacaoDeContasRepositorio.deleteViagem(idviagem)
            enqueueSnackbar('Despesa deletada com sucesso.', { variant: 'success' });
        } catch (e) { // Correção: incluído o parâmetro de erro no catch
            enqueueSnackbar('Ocorreu um erro ao deletar despesa.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    },
    getGastos: async (setLoading, enqueueSnackbar, IdDespesa, setGastos, setExtras) => {
        setLoading(true);
        try {
            const response = await prestacaoDeContasRepositorio.getDespesas(IdDespesa);
            setGastos(response.despesas);
            if (response.extras.length > 0) {
                setExtras(response.extras);
            }
        } catch (error) {
            enqueueSnackbar('Ocorreu um erro ao buscar despesas.', { variant: 'error' });
        } finally {
            setLoading(false);
        }
    },
    handleClickCriarDespesa: async (setLoading, enqueueSnackbar, despesa) => {
        setLoading(true)
        try {
            await prestacaoDeContasRepositorio.postNovaDespesa(despesa);
            enqueueSnackbar('Despesa criada com sucesso.', { variant: 'success' });
        } catch (e) { // Correção: incluído o parâmetro de erro no catch
            enqueueSnackbar('Ocorreu um erro ao criar gasto.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    },
    handleClickEditDespesa: async (setLoading, enqueueSnackbar, despesa) => {
        setLoading(true)
        try {
            await prestacaoDeContasRepositorio.putEditDespesa(despesa)
            enqueueSnackbar('Despesa editada com sucesso.', { variant: 'success' });
        } catch (e) { // Correção: incluído o parâmetro de erro no catch
            enqueueSnackbar('Ocorreu um erro ao editar despesa.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    },
    handleDeleteDespesa: async (setLoading, enqueueSnackbar, iddespesa) => {
        setLoading(true)
        try {
            await prestacaoDeContasRepositorio.deleteDespesa(iddespesa)
            enqueueSnackbar('Despesa excluida com sucesso.', { variant: 'success' });
        } catch (e) { // Correção: incluído o parâmetro de erro no catch
            enqueueSnackbar('Ocorreu um erro ao excluida despesa.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    },
    getMyLimits: async (setLoading, enqueueSnackbar, setTotalChart, setChartData, setCategorias) => {
        setLoading(true);
        try {
            const response = await prestacaoDeContasRepositorio.getAllTiposDeDespesas();

            let totalGeral = 0;

            const chartDataq = {
                series: response
                    .filter(categoria =>
                        categoria.name !== 'Hodometro' &&
                        categoria.name !== 'Outros' &&
                        categoria.name !== 'Pendente' &&
                        categoria.name !== 'SOLICITAÇÃO' &&
                        categoria.name !== 'AEREO' &&
                        categoria.name !== 'HOTEL'
                    )
                    .map(categoria => {
                        const total = categoria.produtos.reduce(
                            (acc, produto) => acc + (produto.limite !== -1 ? produto.limite : 0),
                            0
                        );

                        totalGeral += total;

                        return {
                            label: categoria.name,
                            value: total,
                        };
                    }),
                options: {},
            };

            setTotalChart(totalGeral);
            setChartData(chartDataq);
            setCategorias(response);
        } catch {
            // enqueueSnackbar('Ocorreu um erro ao buscar categorias de despesas.', { variant: 'error' });
        } finally {
            setLoading(false);
        }
    },
    handleClickEditSaldo: async (setLoading, enqueueSnackbar, viagem, saldo) => {
        setLoading(true)
        try {
            let model = {
                idviagem: viagem.id,
                valor_novo: parseFloat(saldo.replace(/\./g, '').replace(',', '.'))
            }

            await prestacaoDeContasRepositorio.PutEditAdiantamento(model);

            enqueueSnackbar("Saldo editado com sucesso", { variant: "success" });
        } catch {
            enqueueSnackbar("Erro ao editar saldo", { variant: "error" });
        } finally {
            setLoading(false)
        }
    }
}