import { AdminPanelSettings, AssignmentReturn, AssignmentReturnRounded, BuildOutlined, BuildRounded, CurrencyExchange, MarkEmailRead, NotificationImportant, QrCode2Outlined, ReportRounded } from "@mui/icons-material"
import { Badge, Box, Button, Card, CardActions, CardContent, Checkbox, Chip, Grid, IconButton, Tooltip, Typography } from "@mui/material"
import { formatarValorComMoeda } from "../../../../enums/enumPrestacaoDeContas"
import { CreationDate, CreationDateConvert, formatarData } from "../../../../views/prestacaodecontas/Desktop/sections"
import { ImageComponent } from "../../../ImageComponent"
import StyledChip from "../../chip"
import { grey } from "../../../../theme/palette"

export const GastoComponent = ({
    view,
    forView,
    indexDespesa,
    despesa,
    grupo,
    handleClickReturn,
    setOpenQrCodeView,
    toggleSelecaoDespesa,
    handleOpenEdit,
    handleClickOpenInvalid
}) => {
    return (
        <>
            {view === "list" ? (
                <>
                    <Grid container item xs={10} style={{ padding: "20px" }}>
                        <Grid container item xs={6}>
                            <Grid style={{ display: "flex", justifyContent: "space-between", textAlign: "justify" }}>
                                <Tooltip title="Categoria da despesa">
                                    <StyledChip
                                        style={{
                                            color: "#535353",
                                            borderRadius: "10px",
                                            height: "30px",
                                            paddingTop: "1px",
                                            fontWeight: "bold"
                                        }}
                                        label={
                                            (forView ? (despesa.despesa_desc.includes("Outros")
                                            ? "Outros"
                                            : despesa.despesa_desc) : (despesa.despesa_desc.includes(" - ")
                                                ? despesa.despesa_desc.split(" - ")[1]
                                                : despesa.despesa_desc))

                                        }
                                    />
                                </Tooltip>
                                {!forView && (
                                    <Box>
                                        {(despesa.mensagem === "" && !despesa.is_invalido) && (
                                            <Tooltip title="Corrigir o valor da despesa">
                                                <Button
                                                    style={{
                                                        borderRadius: "12px",
                                                        marginTop: "-3px",
                                                        marginLeft: "8px"
                                                    }}
                                                    variant="text"
                                                    onClick={() => handleOpenEdit(despesa)} startIcon={<BuildRounded />}
                                                >
                                                    Corrigir
                                                </Button>
                                            </Tooltip>
                                        )}
                                    </Box>
                                )}
                                {(despesa.valor_auditado !== 0 && !forView) && (
                                    <Tooltip title="Valor auditado">
                                        <StyledChip
                                            label={"" + (new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(despesa.valor_auditado))}
                                            color="warning"
                                            style={{
                                                marginLeft: "5px",
                                                color: "white",
                                                fontWeight: "700",
                                                borderRadius: "10px",
                                                height: "30px",
                                            }}
                                        >
                                        </StyledChip>
                                    </Tooltip>
                                )}
                            </Grid>
                        </Grid>
                        <Grid container item xs={6} style={{ display: "flex", justifyContent: "right", textAlign: "right" }}>
                            <Grid style={{ marginLeft: "auto", fontSize: "13px", color: "#919191" }}>
                                {CreationDateConvert(despesa.datadecompra)}
                            </Grid>
                        </Grid>
                        <Grid item xs={8} style={{ textAlign: "left" }}>
                            <Typography style={{ fontWeight: "500", color: "#595959" }}>{despesa.descricao}</Typography>
                        </Grid>
                        <Grid item xs={4} style={{ display: "flex", justifyContent: "right", textAlign: "right" }}>
                            {despesa.qrCode !== null && (
                                <Grid>
                                    <Tooltip title="QrCode do gasto">
                                        <IconButton onClick={() => setOpenQrCodeView(true)}>
                                            <QrCode2Outlined />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            )}

                        </Grid>
                        {forView ? (
                            <>
                                <Grid item xs={8} style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignContent: "flex-start",
                                    alignItems: "center",
                                    flexDirection: "row",
                                    marginTop: "auto"
                                }}>
                                    {despesa.is_invalido ? (
                                        <Tooltip title="Despesa marcada como inválida">
                                            <StyledChip label="Inválido" color="error" />
                                        </Tooltip>
                                    ) : despesa.aprovado ? (
                                        despesa.data_aprovado ? (
                                            <Tooltip title={`Aprovado em ${formatarData(despesa.data_aprovado, "d MMM 'de' yyyy")} por ${despesa.usuario_aprovador}`}>
                                                <StyledChip label="Aprovado" color="primary" />
                                            </Tooltip>
                                        ) : (
                                            <StyledChip label="Aprovado" color="primary" />
                                        )
                                    ) : (
                                        <StyledChip label="Pendente" color="secondary" />
                                    )}

                                    {(despesa.valor_auditado !== 0) && (
                                        <StyledChip
                                            label={"Auditado - " + (new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(despesa.valor_auditado))}
                                            color="warning"
                                            style={{
                                                marginLeft: "5px",
                                                color: "white",
                                                fontWeight: "700",
                                                borderRadius: "10px",
                                                height: "30px",
                                            }}
                                        >
                                        </StyledChip>
                                    )}
                                </Grid>
                                <Grid item xs={4} style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignContent: "flex-end",
                                    alignItems: "center",
                                    flexDirection: "row",
                                    marginTop: "auto"
                                }}>
                                    <Typography style={{ fontWeight: "600", color: "rgba(0, 0, 0, 0.87)" }}>{formatarValorComMoeda(despesa.tipo_moeda, despesa.total)}</Typography>
                                </Grid>
                            </>
                        ) : (
                            <>
                                {despesa.is_invalido ? (
                                    <>
                                        <Chip
                                            label="invalído"
                                            color="error"
                                            style={{
                                                marginLeft: "0px",
                                                color: "white",
                                                fontWeight: "700",
                                                borderRadius: "10px",
                                                height: "30px",
                                                marginTop: "10px"
                                            }}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <Grid item xs={8} style={{
                                            display: "flex",
                                            placeContent: "flex-start",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            marginTop: "auto"
                                        }}>
                                            {despesa.mensagem !== "" ? (
                                                <>
                                                    {despesa.controladoria ? (
                                                        <Button color="warning" style={{ marginLeft: "5px" }} onClick={() => handleClickReturn(despesa)} startIcon={<AdminPanelSettings />}>
                                                            notificado
                                                        </Button>
                                                    ) : (
                                                        <Tooltip title="O viajante já foi notificado sobre a despesa">
                                                            <Button color="warning" style={{ marginLeft: "5px" }} onClick={() => handleClickReturn(despesa)} startIcon={<MarkEmailRead />}>
                                                                Viajante notificado
                                                            </Button>
                                                        </Tooltip>
                                                    )}
                                                </>
                                            ) : (
                                                <Tooltip title="Retornar gasto Controladoria/Colaborador">
                                                    <Button
                                                        color="secondary"
                                                        style={{ marginLeft: "5px" }}
                                                        onClick={() => handleClickReturn(despesa)}
                                                        startIcon={<AssignmentReturnRounded />}
                                                    >
                                                        retornar
                                                    </Button>
                                                </Tooltip>
                                            )}
                                            <Tooltip title="Invalidar despesa">
                                                <Button
                                                    color="secondary"
                                                    style={{ marginLeft: "5px" }}
                                                    onClick={() => handleClickOpenInvalid(despesa)}
                                                    startIcon={<ReportRounded />}
                                                >
                                                    invalidar
                                                </Button>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={4} style={{
                                            display: "flex",
                                            placeContent: "flex-end",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            marginTop: "auto"
                                        }}>
                                            {despesa.mensagem === "" ? (
                                                <>
                                                    <Tooltip title="Aprovar despesa" style={{
                                                        display: "flex",
                                                        placeContent: "flex-end",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        marginTop: "auto"
                                                    }}>
                                                        <Typography style={{ fontWeight: "600", fontSize: "14px" }} color="primary">aprovar</Typography>
                                                        <Checkbox
                                                            defaultChecked
                                                            color="primary"
                                                            checked={despesa.selecionada}
                                                            onChange={() => toggleSelecaoDespesa(grupo.titulo, indexDespesa)}
                                                        />
                                                    </Tooltip>
                                                </>
                                            ) : (null)}
                                            <Typography style={{ fontWeight: "600", color: "rgba(0, 0, 0, 0.87)" }}>{formatarValorComMoeda(despesa.tipo_moeda, despesa.total)}</Typography>
                                        </Grid>
                                    </>
                                )}
                            </>
                        )}
                    </Grid>
                    <Grid container item xs={2} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Badge
                            color="error"
                            badgeContent={
                                <Tooltip title="Fora da política">
                                    <NotificationImportant fontSize="small" style={{ color: "#FFFFFF" }} />
                                </Tooltip>
                            }
                            invisible={despesa.is_fora_politica !== null && despesa.is_fora_politica ? false : true}
                        >
                            <ImageComponent width={100} height={148} src={despesa.comprovante} alt={"Imagem não encontrada"} />
                        </Badge>
                    </Grid>
                    
                </>
            ) : (
                <Card sx={{ width: 275 }} elevation={0} style={{ padding: "0px" }}>
                    <ImageComponent width={"100%"} height={198} src={despesa.comprovante} alt={"Imagem não encontrada"} />
                    <CardContent style={{ gap: "10px", display: "flex", flexDirection: "column" }}>
                        <Grid item xs={12}
                            style={{
                                display: "flex",
                                justifyContent: "left",
                                textAlign: "left",
                                marginLeft: "-6px"
                            }}>
                            {despesa.valor_auditado !== 0 && (
                                <StyledChip
                                    label={"Auditado - " + (new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(despesa.valor_auditado))}
                                    color="warning"
                                    style={{
                                        marginLeft: "5px",
                                        color: "white",
                                        fontWeight: "700",
                                        borderRadius: "10px",
                                        height: "30px",
                                    }}
                                >
                                </StyledChip>
                            )}
                        </Grid>
                        <Grid item xs={12} style={{
                            justifyContent: "space-between",
                            display: "flex",
                            gap: "20px",
                            textAlign: "left"
                        }}>
                            <StyledChip
                                style={{
                                    color: "#535353",
                                    borderRadius: "10px",
                                    height: "30px",
                                    paddingTop: "1px",
                                    fontWeight: "bold"
                                }}
                                label={
                                    despesa.despesa_desc.includes(" - ")
                                        ? despesa.despesa_desc.split(" - ")[1]
                                        : despesa.despesa_desc
                                }
                            />
                            {despesa.qrCode !== null && (
                                <Box>
                                    <IconButton onClick={() => setOpenQrCodeView(true)}>
                                        <QrCode2Outlined />
                                    </IconButton>
                                </Box>
                            )}
                        </Grid>
                        <Typography variant="body2" sx={{ color: 'text.secondary', textAlign: "left" }}>
                            {despesa.descricao}
                        </Typography>
                        <Box style={{ width: "100%", textAlign: "left", display: "flex", justifyContent: "space-between", marginBottom: "-30px" }}>
                            <CreationDate data={despesa.datadecompra} />
                            <Tooltip title="Corrigir o valor da despesa">
                                <Button color="primary" style={{ marginRight: "3px", borderRadius: "20px", marginTop: "-10px" }} onClick={() => handleOpenEdit(despesa)} startIcon={<BuildOutlined />}>
                                    Corrigir
                                </Button>
                            </Tooltip> 
                        </Box>
                        <Grid item xs={12} style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignContent: "flex-start",
                            alignItems: "center",
                            flexDirection: "row",
                            marginTop: "12px"
                        }}>
                            {despesa.is_invalido ? (
                                <>
                                    <Chip
                                        label="invalído"
                                        color="error"
                                        style={{
                                            marginLeft: "0px",
                                            color: "white",
                                            fontWeight: "700",
                                            borderRadius: "10px",
                                            height: "30px",
                                            marginTop: "10px"
                                        }}
                                    />
                                </>
                            ) : (
                                <>
                                    {despesa.mensagem !== "" ? (
                                        <>
                                            {despesa.controladoria ? (
                                                <Button color="warning" style={{ marginLeft: "5px" }} onClick={() => handleClickReturn(despesa)} startIcon={<AdminPanelSettings />}>
                                                    notificado
                                                </Button>
                                            ) : (
                                                <Tooltip title="O viajante já foi notificado sobre a despesa">
                                                    <Button color="warning" style={{ marginLeft: "5px" }} onClick={() => handleClickReturn(despesa)} startIcon={<MarkEmailRead />}>
                                                        Viajante notificado
                                                    </Button>
                                                </Tooltip>
                                            )}
                                        </>
                                    ) : (
                                        <Button color="secondary" style={{ marginLeft: "5px" }} onClick={() => handleClickReturn(despesa)} startIcon={<AssignmentReturn />}>
                                            retornar
                                        </Button>
                                    )}
                                    <Tooltip title="Invalidar despesa">
                                        <Button
                                            color="secondary"
                                            style={{ marginLeft: "5px" }}
                                            onClick={() => handleClickOpenInvalid(despesa)}
                                            startIcon={<ReportRounded />}
                                        >
                                            invalidar
                                        </Button>
                                    </Tooltip>
                                </>
                            )}

                        </Grid>
                    </CardContent>
                    <CardActions style={{ justifyContent: "space-between", marginTop: "-20px" }}>
                        <Grid item xs={12} style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignContent: "flex-start",
                            alignItems: "center",
                            flexDirection: "row",
                            marginTop: "auto"
                        }}>
                            {!despesa.is_invalido && (
                                <>
                                    <Grid item xs={12} style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignContent: "flex-end",
                                        alignItems: "center",
                                        flexDirection: "row",
                                        marginTop: "auto"
                                    }}>
                                        <Typography style={{ fontWeight: "600", padding: "9px", color: "rgba(0, 0, 0, 0.87)" }}>{formatarValorComMoeda(despesa.tipo_moeda, despesa.total)}</Typography>
                                        {despesa.mensagem === "" ? (
                                            <Box style={{
                                                display: "flex",
                                                alignContent: "flex-end",
                                                alignItems: "center",
                                                flexDirection: "row",
                                                marginTop: "auto"
                                            }}>
                                                <Typography style={{ fontWeight: "600", fontSize: "14px" }} color="primary">aprovar</Typography>
                                                <Checkbox
                                                    defaultChecked
                                                    color="primary"
                                                    checked={despesa.selecionada}
                                                    onChange={() => toggleSelecaoDespesa(grupo.titulo, indexDespesa)}
                                                />
                                            </Box>
                                        ) : (null)}
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </CardActions>
                </Card>
            )
            }
        </>
    )
}