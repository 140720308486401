import { ArrowBack, CalendarMonthOutlined } from '@mui/icons-material';
import { Box, Button, Divider, Slide } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { ptBR } from 'date-fns/locale';
import * as React from 'react';
import "./style.css";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme, ismap, fullScreen }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(0),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(0),
    },
    '& .MuiPaper-root': {
        padding: ismap ? '0px' : '0px',
        borderRadius: fullScreen ? "0px" : "0px",
    }
}));

function formatarData(dayjsObject) {
    const dataFormatada = dayjsObject.format('DD MMM YYYY');
    const [dia, mes, ano] = dataFormatada.split(' ');
    const mesCapitalizado = mes.charAt(0).toUpperCase() + mes.slice(1);
    return `${dia} ${mesCapitalizado} ${ano}`;
}

export const DialogMobileEditDate = ({ title, startDate, setStartDate, endDate, setEndDate, body, width, action, open, handleClose, fullScreen = true, ismap = false, isNoTitle = false }) => {

    const handleDateChange = (newValue) => {
        const [start, end] = newValue;
        setStartDate(start);
        setEndDate(end);
    };

    return (
        <>
            <BootstrapDialog
                onClose={handleClose}
                style={fullScreen ? { borderRadius: "0px" } : { borderRadius: "20px" }}
                aria-describedby="alert-dialog-slide-description"
                fullScreen={fullScreen}
                maxWidth={width}
                open={open}
                keepMounted
                TransitionComponent={Transition}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    <Grid container item xs={12}>
                        <Grid item xs={1} style={{ textAlign: "right", justifyContent: "flex-end", alignItems: "center", display: "flex", marginTop: "5px" }}>
                            <IconButton
                                aria-label="close"
                                onClick={handleClose}
                                sx={{
                                    position: 'absolute',
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <ArrowBack />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent style={{ padding: 0 }}>
                    <Grid container item xs={12} sx={{
                        marginBottom: "-38px",
                        padding: "20px"
                    }}>
                        <Grid item xs={6}>
                            <Typography variant="body2" sx={{ fontWeight: 'bold', mb: 0.5 }}>
                                Retirada
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body2" sx={{ fontWeight: 'bold', mb: 0 }}>
                                Devolução
                            </Typography>
                        </Grid>
                    </Grid>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            border: '1px solid #ccc',  // Borda cinza clara
                            borderRadius: "12px",           // Largura máxima (ajuste conforme necessidade)
                            overflow: 'hidden',         // Garante que nada “vaze” do container
                            margin: "20px"
                        }}
                    >
                        <Box
                            sx={{
                                flex: 1,
                                p: 1,
                                textAlign: 'left',
                            }}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
                                <CalendarMonthOutlined fontSize="small" style={{ color: "#727272" }} />
                                {startDate ? <Typography variant="body2">{formatarData(startDate)}</Typography> : <Typography variant="body2">Selecione a data</Typography>}
                            </Box>
                        </Box>

                        <Divider orientation="vertical" flexItem />

                        {/* Seção: Devolução */}
                        <Box
                            sx={{
                                flex: 1,
                                p: 1,
                                textAlign: 'left',
                            }}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
                                <CalendarMonthOutlined fontSize="small" style={{ color: "#727272" }} />
                                {endDate ? <Typography variant="body2">{formatarData(endDate)}</Typography> : <Typography variant="body2">Selecione a data</Typography>}
                            </Box>
                        </Box>
                    </Box>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={ptBR}>
                        <DemoContainer components={['DateRangePicker', 'DateRangeCalendar']}>
                            <DemoItem component="DateRangeCalendar">
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        '.MuiTypography-caption': {
                                            fontSize: '14px', // Aumenta o tamanho das letras dos dias
                                        },
                                        '.MuiPickersCalendarHeader-label': {
                                            fontSize: '1.5rem', // Aumenta o tamanho do cabeçalho do mês
                                            fontWeight: 'bold',
                                        },
                                        '.MuiDateRangeCalendar-root': {
                                            display: 'flex',
                                            flexDirection: 'column', // Altera a direção para coluna
                                            margin: "0px"
                                        },
                                        '.MuiPickersCalendarHeader-root': {
                                            alignSelf: 'flex-start',
                                        },
                                        '.MuiPickersDay-root': {
                                            fontSize: '1.05rem',
                                            color: 'grey', // Aplica cor cinza aos números dos dias não selecionados
                                            '&.Mui-selected.Mui-disabled': {
                                                backgroundColor: '#ff0000',
                                            },
                                            '&.Mui-selected.Mui-selected': {
                                                color: '#FFFFFF',
                                            },
                                        },
                                        '.MuiDateRangeCalendar-container': {
                                            borderRight: "none !important" // Remove a borda direita entre os calendários
                                        },
                                    }}
                                >
                                    <StaticDateRangePicker
                                        displayStaticWrapperAs="desktop"
                                        value={[startDate, endDate]}
                                        onChange={handleDateChange}
                                        calendars={12}
                                        dayOfWeekFormatter={(day) => {
                                            const mapping = {
                                                'Do': 'Dom',
                                                '2ª': 'Seg',
                                                '3ª': 'Ter',
                                                '4ª': 'Qua',
                                                '5ª': 'Qui',
                                                '6ª': 'Sex',
                                                'Sá': 'Sáb',
                                            };
                                            return mapping[day] || day;
                                        }}
                                    />
                                </Box>
                            </DemoItem>
                        </DemoContainer>
                    </LocalizationProvider>
                </DialogContent>


                {action && (
                    <DialogActions>
                        {action}
                    </DialogActions>
                )}
            </BootstrapDialog>
        </>
    )
};
