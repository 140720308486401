import { Alert, Button, FormControl, Grid, InputLabel, MenuItem, Select, Snackbar, TextField, Typography } from "@mui/material"
import { useCallback, useEffect, useState } from "react";
import { Upload } from "../../../../../../upload";
import { EnumTipoVeiculo } from "../../../../../../enums/enumVeiculos";
import { UtaliiDatePickerNormal } from "../../../sections/custom-date-picker-normal";
import prestacaoDeContasRepositorio from "../../../../../../repositorios/prestacaodecontas";
import { useCustomSnackbar } from "../../../../../../components/snackbar2/NewSnackBar";
import { ArrowBackIosNewOutlined } from "@mui/icons-material";
import { processImage } from "../../../../../../components/autalii_expanses/controlimages";
import { EnumTipo } from "../../../../../../enums/enumPrestacaoDeContas";
import ULoaderVoo from "../../../../../../components/uloadervoo";

export const HodometroComponent = ({ idviagem, handleClickCreateHodometro, setType }) => {
    const [activeStep, setActiveStep] = useState(0);
    const [descricao, setDescricao] = useState("")
    const [inicialKm, setInicialKm] = useState(0);
    const [finalKm, setFinalKm] = useState(0);
    const [inicialFoto, setInicialFoto] = useState("");
    const [finalFoto, setFinalFoto] = useState("");
    const [datadecompra, setDatadecompra] = useState(new Date());
    const [tipoveiculo, setTipoveiculo] = useState(1);
    const [tipocombustivel, setTipocombustivel] = useState(1);
    const [tipoporte, setTipoporte] = useState(2)
    const [message, setMessage] = useState("");
    const [log, setLog] = useState(false);
    const [typeLog, setTypeLog] = useState("");
    const [loading, setLoading] = useState(false)
    const [produto, setProduto] = useState({})

    const { enqueueSnackbar } = useCustomSnackbar();

    const getAlltipos = useCallback(async () => {
        setLoading(true);
        try {
            const response = await prestacaoDeContasRepositorio.getAllTiposDeDespesas();

            response.forEach(item => {
                if (item.name === "Hodometro") {
                    item.produtos.forEach(element => {
                        if (element.name === "Manual") {
                            setProduto({
                                iddespesa_tipo: item.iddespesa_tipo,
                                iddespesa_subtipo: element.iddespesa_subtipo
                            });
                        }
                    });
                }
            });
        } catch {
            enqueueSnackbar('Ocorreu um erro ao buscar categorias de despesas.', { variant: 'error' });
        } finally {
            setLoading(false);
        }
    }, [setLoading, setProduto, enqueueSnackbar]); // Dependências


    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleDropHodometroIda = useCallback((acceptedFiles) => {
        const newFile = acceptedFiles[0];
        if (newFile) {
            processImage(Object.assign(newFile, {
                preview: URL.createObjectURL(newFile),
            })).then(base64String => {
                setInicialFoto(base64String); // Aqui você tem a imagem convertida em base64
            }).catch(error => {
                logMessage("Erro ao converter imagem para Base64:" + error, "error");
            });
        }
    }, []);

    const handleDropHodometroVolta = useCallback((acceptedFiles) => {
        const newFile = acceptedFiles[0];
        if (newFile) {
            processImage(Object.assign(newFile, {
                preview: URL.createObjectURL(newFile),
            })).then(base64String => {
                setFinalFoto(base64String); // Aqui você tem a imagem convertida em base64
            }).catch(error => {
                logMessage("Erro ao converter imagem para Base64:" + error, "error");
            });
        }
    }, []);


    const logMessage = (message, type) => {
        setMessage(message);
        setTypeLog(type);
        setLog(true);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return; // Não faz nada se o usuário clicar fora do Snackbar
        }
        setLog(false); // Atualiza o estado para fechar o Snackbar
    };

    const handleClickAdd = () => {
        handleClickCreateHodometro(
            {
                idviagem: idviagem,
                datadecompra: datadecompra,
                descricao: descricao,
                aprovado: false,
                controladoria: false,
                total: 0,
                mensagem: "",
                comprovante: null,
                QrCode: null,
                tipo_moeda: EnumTipo.moedas.BRL.id,
                produtos: {
                    iddespesa_tipo: produto?.iddespesa_tipo,
                    iddespesa_subtipo: produto?.iddespesa_subtipo
                },
                hodometro: {
                    kminicial: inicialKm,
                    kmfinal: finalKm,
                    kmtotal: finalKm - inicialKm,
                    duracao: 0,
                    valor: 0,
                    veiculo: {
                        categoria: tipoveiculo,
                        combustivel: tipocombustivel,
                        porte: tipoporte
                    },
                    comprovanteinicial: inicialFoto,
                    comprovantefinal: finalFoto,
                    is_manual: true
                },
            }
        )
        setType(0);
    }

    useEffect(() => {
        getAlltipos()
    }, [getAlltipos])

    return (
        <>
            <ULoaderVoo isLoading={loading} />
            <Snackbar
                open={log}
                autoHideDuration={2000}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity={typeLog} variant="filled" style={{ fontFamily: "sans-serif", padding: "2px 8px 2px", fontWeight: "600" }} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
            {/* <Container maxWidth={"md"} style={{ textAlign: "center", justifyContent: "center" }}> */}
            <Grid item xs={12} style={{}}>
                <Grid container item xs={12} style={{ justifyContent: "left", display: "flex", flexDirection: "row", alignContent: "flex-start", alignItems: "center" }}>
                    <Grid item xs={6} style={{ marginTop: "-20px" }}>
                        <Button
                            onClick={() => setType(0)}
                            startIcon={<ArrowBackIosNewOutlined />}
                            color="primary"
                        >
                            Voltar
                        </Button>
                    </Grid>
                </Grid>
                {/* Seu conteúdo centralizado aqui */}
                {activeStep === 0 ? (
                    <>
                        <Grid container item xs={12} style={{ padding: "20px" }}>
                            <Grid
                                container
                                item xs={12}
                                style={{ boxShadow: "0px 0px 6px 2px rgba(0,0,0,0.10)", borderRadius: "10px", padding: "20px", marginTop: "0px" }}
                            >
                                <Grid item xs={12} style={{ textAlign: "center" }}>
                                    <Typography variant="h6" fontSize={14}>
                                        Foto do inicial do hodômetro:
                                    </Typography>
                                </Grid>
                                <Upload
                                    file={inicialFoto}
                                    accept="image/jpeg,image/png,image/gif"
                                    style={{ height: "auto" }}
                                    onDrop={handleDropHodometroIda}
                                // validator={(fileData) => {
                                //     if (fileData.size > 1000000) {
                                //         return {
                                //             code: 'file-too-large',
                                //             message: `Essse arquivo é muito grande!! ${fData(1000000)}`,
                                //         };
                                //     }
                                //     return null;
                                // }}
                                />
                                <Grid item xs={12} style={{ textAlign: "center" }}>
                                    <TextField
                                        style={{ textAlign: "right", justifyContent: "right", display: "flex" }}
                                        fullWidth
                                        id="outlined-number"
                                        label="Total do km inicial"
                                        onChange={(e) => setInicialKm(parseInt(e.target.value, 10) || 0)}
                                        type="number"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                item xs={12}
                                style={{ boxShadow: "0px 0px 6px 2px rgba(0,0,0,0.10)", borderRadius: "10px", padding: "20px", margin: "auto", marginTop: "15px" }}
                            >
                                <Grid item xs={12} style={{ textAlign: "center" }}>
                                    <Typography variant="p" fontSize={14} style={{ fontFamily: "sans-serif" }}>
                                        Você pode adicionar o hodômetro inicial agora e o final depois, conforme sua conveniência.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                item xs={12}
                                style={{ boxShadow: "0px 0px 6px 2px rgba(0,0,0,0.10)", borderRadius: "10px", padding: "20px", margin: "auto", marginTop: "15px" }}
                            >
                                <Grid item xs={12} style={{ textAlign: "center" }}>
                                    <Typography variant="h6" fontSize={14}>
                                        Foto final do hodômetro:
                                    </Typography>
                                </Grid>
                                <Upload
                                    file={finalFoto}
                                    accept="image/jpeg,image/png,image/gif"
                                    style={{ height: "auto" }}
                                    onDrop={handleDropHodometroVolta}
                                // validator={(fileData) => {
                                //     if (fileData.size > 1000000) {
                                //         return {
                                //             code: 'file-too-large',
                                //             message: `Essse arquivo é muito grande!! ${fData(1000000)}`,
                                //         };
                                //     }
                                //     return null;
                                // }}
                                />
                                <Grid item xs={12} style={{ textAlign: "center" }}>
                                    <TextField
                                        style={{ textAlign: "right", justifyContent: "right", display: "flex" }}
                                        fullWidth
                                        id="outlined-number"
                                        label="Total do km Final"
                                        onChange={(e) => setFinalKm(parseInt(e.target.value, 10) || 0)}
                                        type="number"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                ) : (
                    <Grid container item xs={12} style={{ padding: "20px" }}>
                        <TextField
                            style={{ marginBottom: "10px" }}
                            id="filled-multiline-flexible"
                            variant="outlined"
                            fullWidth
                            required
                            onChange={(e) => setDescricao(e.target.value)}
                            multiline
                            maxRows={4}
                            label="Justificativa/Descrição"
                            placeholder="Viagem para..."
                        />
                        <Grid item xs={12} style={{ marginBottom: "10px" }}>
                            <FormControl fullWidth>
                                <InputLabel id="veiculo-select-label">Veículo utilizado</InputLabel>
                                <Select
                                    labelId="veiculo-select-label"
                                    id="veiculo-select"
                                    value={tipoveiculo}
                                    onChange={(e) => setTipoveiculo(e.target.value)}
                                    label="Veículo utilizado"
                                >
                                    {Object.entries(EnumTipoVeiculo.categoria).map(([key, value]) => (
                                        <MenuItem key={value} value={value}>
                                            {key.charAt(0).toUpperCase() + key.slice(1)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} style={{ marginBottom: "10px" }}>
                            <FormControl fullWidth>
                                <InputLabel id="veiculo-select-label">Tipo de Combustivel</InputLabel>
                                <Select
                                    labelId="veiculo-select-label"
                                    id="veiculo-select"
                                    value={tipocombustivel}
                                    onChange={(e) => setTipocombustivel(e.target.value)}
                                    label="Tipo de Combustivel"
                                >
                                    {Object.entries(EnumTipoVeiculo.combustivel).map(([key, value]) => (
                                        <MenuItem key={value} value={value}>
                                            {key.charAt(0).toUpperCase() + key.slice(1)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} style={{ marginBottom: "10px" }}>
                            <FormControl fullWidth>
                                <InputLabel id="veiculo-select-label">Porte do veículo</InputLabel>
                                <Select
                                    labelId="veiculo-select-label"
                                    id="veiculo-select"
                                    value={tipoporte}
                                    onChange={(e) => setTipoporte(e.target.value)}
                                    label="Porte do veículo"
                                >
                                    {Object.entries(EnumTipoVeiculo.porte).map(([key, value]) => (
                                        <MenuItem key={value} value={value}>
                                            {key.charAt(0).toUpperCase() + key.slice(1)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} style={{}}>
                            <UtaliiDatePickerNormal
                                value={datadecompra}
                                onChange={setDatadecompra}
                                label="Data do hodometro"
                                fullWidth={true}
                            // Você pode adicionar outras propriedades conforme necessário
                            />
                        </Grid>
                    </Grid>
                )}
                {/* Adicione outros componentes conforme necessário */}
            </Grid>
            <Grid item xs={12} style={{ padding: "20px", display: "flex", alignItems: "center" }}>
                {/* MobileStepper vai aqui */}
                {activeStep === 0 ? (
                    <Button fullWidth variant="contained" color="primary" style={{ marginBottom: "10px", marginRight: "4px" }} onClick={handleNext}>
                        Próximo
                    </Button>
                ) : (
                    <Button fullWidth variant="contained" color="primary" style={{ marginBottom: "10px", marginRight: "4px" }} onClick={() => handleClickAdd()}>
                        Adicionar
                    </Button>
                )}
            </Grid>
            {/* </Container> */}
        </>
    )
}