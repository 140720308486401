import { Button, ButtonGroup, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useLayoutEffect, useState } from "react";
import { EnumTipo } from "../../../../../enums/enumPrestacaoDeContas";
import centroCustoRepositorio from "../../../../../repositorios/centroCusto";
import UtaliiDatePickerInput from "../../../Desktop/sections/custom-date-picker-start_and_end";
import { ControlBalanceInternacional } from "./Internacional";
import { ControlBalanceNacional } from "./Nacional";

export const NovaViagemComponentM = ({ model, setModel, confirmCriarNovaViagem }) => {
    const [centrodecusto, setcentrodecusto] = useState(0);
    const [selectedDate, setSelectedDate] = useState([null, null]);
    const [centrosCusto, setCentrosCusto] = useState([]);
    const [currentTab, setCurrentTab] = useState("Nacional")
    const [statusAd, setStatusAd] = useState(EnumTipo.adiantamento.solicitado)
    const [titulo, setTitulo] = useState("")

    useLayoutEffect(() => {
        (async () => {
            const response = await centroCustoRepositorio.getSearchField();
            setCentrosCusto(response);
        })()
    }, [])

    const handleChangeTituloDaViagem = (e) => {
        setTitulo(e.target.value);
    }

    const handleChangeCentrodecusto = (value) => {
        setcentrodecusto(value);
        setModel(prevModel => ({
            ...prevModel,
            id_centrocusto: value
        }));
    };


    const handleDateChange = (newValue) => {
        setSelectedDate(newValue);
        if (newValue[0] && newValue[1]) {
            setModel(prevModel => ({
                ...prevModel,
                datainicio: newValue[0].toDate(),
                datafim: newValue[1].toDate()
            }));
        }
    };

    const handleChangeTab = (value) => {
        setCurrentTab(value)
        if (value === "Internacional") {
            setModel((prevModel) => ({
                ...prevModel,
                tem_multimonetario: true,
                tipo_moeda: 0,
                totalinicial: 0,
                pedido: true
            }));
        } else {
            setModel((prevModel) => ({
                ...prevModel,
                adiantamento: [{ tipo_moeda: EnumTipo.moedas.BRL.id, total: 0, status: EnumTipo.adiantamento.naosolicitado }],
                tem_multimonetario: false,
                tipo_moeda: 0,
                totalinicial: 0,
                pedido: false
            }));
        }
    }

    const handleClickConfirm = () => {
        let envio = {
            ...model,
            titulo: titulo
        }
        confirmCriarNovaViagem(envio)
    }

    return (
        <>
            <Grid container item xs={12} spacing={1} style={{ marginBottom: "20px" }}>
                <Grid
                    container
                    item xs={12}
                    style={{ boxShadow: "0px 0px 6px 2px rgba(0,0,0,0.10)", borderRadius: "10px", padding: "20px", margin: "auto", marginTop: "15px" }}
                >
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            onChange={(e) => handleChangeTituloDaViagem(e)}
                            label="Título da despesa"
                            placeholder="Despesa para..."
                            style={{ borderRadius: "15px" }}
                            className="titulo"
                        />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: "14px" }}>
                        <FormControl fullWidth className="centrodecusto">
                            <InputLabel id="demo-simple-select-label">Centro de Custo</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={centrodecusto}
                                defaultValue={0}
                                label="Centro de custo"
                            >
                                {centrosCusto?.map((e, index) => (
                                    <MenuItem key={index} value={e.value} onClick={() => handleChangeCentrodecusto(e.value)}>{e.label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid className="data" item xs={12} style={{ marginTop: "7px", fontFamily: "sans-serif" }}>
                        <UtaliiDatePickerInput
                            value={selectedDate}
                            onChange={handleDateChange}
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={12} style={{ marginTop: "0px", padding: "5px" }}>
                    <Grid item xs={12} style={{ marginBottom: "15px", marginTop: "-5px", display: "flex", justifyContent: "center" }}>
                        <ButtonGroup
                            variant="outlined"
                            color="inherit"
                            style={{ justifyContent: "center", borderColor: "#D6D6D6", borderRadius: "10px" }}
                        >
                            <Button
                                size="large"
                                onClick={() => handleChangeTab('Nacional')}
                                style={{
                                    textTransform: "none", // Mantém a capitalização original do texto
                                    fontWeight: currentTab === 'Nacional' ? 600 : 400, // Destaca o botão ativo
                                    borderColor: currentTab === 'Nacional' ? "#1C252E" : "#D6D6D6",
                                    backgroundColor: currentTab === 'Nacional' ? "#1C252E" : "#FFFFFF",
                                    color: currentTab === 'Nacional' ? "#FFFFFF" : "#1C252E",
                                    borderRadius: "8px 0 0 8px" // Aplica borderRadius apenas nos extremos
                                }}
                            >
                                Nacional
                            </Button>
                            <Button
                                size="large"
                                onClick={() => handleChangeTab('Internacional')}
                                style={{
                                    textTransform: "none", // Mantém a capitalização original do texto
                                    fontWeight: currentTab === 'Internacional' ? 600 : 400, // Destaca o botão ativo
                                    borderColor: currentTab === 'Internacional' ? "#1C252E" : "#D6D6D6",
                                    backgroundColor: currentTab === 'Internacional' ? "#1C252E" : "#FFFFFF",
                                    color: currentTab === 'Internacional' ? "#FFFFFF" : "#1C252E",
                                    borderRadius: "0 8px 8px 0" // Aplica borderRadius apenas nos extremos
                                }}
                            >
                                Internacional
                            </Button>
                        </ButtonGroup>
                    </Grid>

                    {currentTab === 'Nacional' && (
                        <ControlBalanceNacional model={model} setModel={setModel} statusAd={statusAd} setStatusAd={setStatusAd} />
                    )}

                    {currentTab === 'Internacional' && (
                        <ControlBalanceInternacional setModel={setModel} />
                    )}
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ padding: "20px" }}>
                <Grid
                    container
                    item
                    xs={12}
                    style={{
                        position: "absolute",
                        bottom: 10,
                        left: 0,
                        height: "80px",
                        padding: 20,
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",   // Opcional: ajuste conforme sua necessidade
                        backgroundColor: "#fff", // Opcional: para garantir fundo visível
                        zIndex: 0,     // Para ficar acima de outros elementos (se necessário)
                    }}
                >
                    <Button size="large" variant="contained" color="primary" fullWidth autoFocus onClick={() => handleClickConfirm()}>
                        Finalizar
                    </Button>
                </Grid>
            </Grid>
        </>
    )
}