import { ArrowForwardIos, Visibility, VisibilityOff, ArrowBack, PriceChange, PriceChangeRounded, SettingsRounded, CurrencyExchange, HelpRounded } from "@mui/icons-material";
import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { formatarParaNomeStatusAdiantamento, formatarMoeda, getCurrencySymbol, EnumTipo } from "../../../enums/enumPrestacaoDeContas";
import { Limits } from "../../../views/prestacaodecontas/Mobile/minhasviagens/Viagem/Limits";
import { useState } from "react";
import DialogComponent from "../../../views/prestacaodecontas/Desktop/sections/dialogs-desktop";

export const CardSaldo_mob = ({
    viagem,
    handleClickEditSaldo
}) => {
    const [myLimits, setMyLimits] = useState(false);
    const [showBalance, setShowBalance] = useState(true);
    const [editSaldo, setEditSaldo] = useState(false);
    const [saldo, setSaldo] = useState(0);

    const handleValorChange = (event) => {
        const valorInserido = event.target.value;

        // Formata o Valor
        const valorFormatado = formatarMoeda(valorInserido);
        // Atualiza o estado com o valor formatado
        setSaldo(valorFormatado);
    };

    return (
        <Grid
            item xs={12}
            style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", borderRadius: "10px", width: "100%", maxWidth: 360 }}
        >
            <DialogComponent
                title={"Editar saldo"}
                open={editSaldo}
                handleClose={() => setEditSaldo(false)}
                body={
                    <Grid container item xs={12} style={{
                        height: "100%",
                    }}>
                        <Grid container item xs={12} style={{
                            display: "flex",
                            justifyContent: "center",
                            height: 10
                        }}>
                            {formatarParaNomeStatusAdiantamento(viagem.adiantamento[0]?.status)}
                            <Box
                                sx={{
                                    padding: '10px 20px',
                                    borderRadius: '5px',
                                    fontFamily: 'Arial, sans-serif',
                                }}
                            >
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: "-14px" }}>
                                    <Typography variant="h6" style={{ fontWeight: "600", color: '#262626', fontSize: "40px", textAlign: "left", display: "flex" }}>
                                        <div style={{ display: "flex" }}>
                                            {showBalance ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(viagem.totalinicial - viagem.total) : '***'}
                                        </div>
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: "2px" }}>
                                    <Typography variant="body2" sx={{ fontWeight: "600", color: '#393939' }}>
                                        Saldo Atual
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid container item xs={12} style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            alignContent: "center"
                        }}>
                            <TextField
                                fullWidth
                                className="totalgasto"
                                id="outlined-number"
                                label="Saldo"
                                variant="standard"
                                value={saldo}
                                onChange={handleValorChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            {getCurrencySymbol(EnumTipo.moedas.BRL.id)}
                                        </InputAdornment>
                                    ),
                                    inputProps: {
                                        inputMode: 'numeric',
                                        style: { fontSize: '28px', fontWeight: 'bold' }
                                    }
                                }}
                            />
                            <Grid item xs={12} style={{ display: "flex", marginTop: "10px", justifyContent: "space-between" }}>
                                <Typography variant="subtitle1" style={{ color: "#242424FF" }}>Total anterior</Typography>
                                <Typography variant="subtitle1" style={{ color: "#242424FF" }}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(viagem.totalinicial)}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                }
                action={
                    < Button fullWidth size="large" variant="contained" color="primary" onClick={() => {
                        handleClickEditSaldo(saldo);
                        setEditSaldo(false);
                    }}> Confimar</Button >
                }
                fullScreen={true}
                width={"sm"}
            />
            <Box
                sx={{
                    padding: '10px 20px',
                    borderRadius: '5px',
                    fontFamily: 'Arial, sans-serif',
                }}
            >
                {viagem.totalinicial === 0 ? (
                    <>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: "-14px" }}>
                            <Typography variant="body2" sx={{ fontWeight: "600", color: '#393939', display: "flex" }}>
                                Total
                            </Typography>
                            <Grid item xs={12} style={{ display: "flex", justifyContent: "right", textAlign: "right", paddingBottom: "-6px" }}>
                                <Button className="limites_mob" onClick={() => setMyLimits(true)} size="large" endIcon={<ArrowForwardIos sx={{ width: "15px" }} />} style={{ color: "#1C252E" }}>
                                    Meus Limites
                                </Button>
                            </Grid>
                        </Box>
                        <Typography variant="h6" style={{ fontWeight: "600", color: '#262626', fontSize: "18px", textAlign: "left" }}>
                            {showBalance ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(viagem.total) : '***'}
                            <IconButton onClick={() => setShowBalance(!showBalance)} size="small" style={{ marginTop: "-3px" }}>
                                {showBalance ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </Typography>
                    </>
                ) : (
                    <>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: "-14px" }}>
                            <Typography variant="h6" style={{ fontWeight: "600", color: '#262626', fontSize: "20px", textAlign: "left", display: "flex" }}>
                                <div style={{ display: "flex" }}>
                                    {showBalance ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(viagem.totalinicial - viagem.total) : '***'}
                                </div>
                                <IconButton onClick={() => setShowBalance(!showBalance)} size="small" style={{ marginTop: "-3px" }}>
                                    {showBalance ? <VisibilityOff /> : <Visibility />}
                                </IconButton>

                            </Typography>
                            <Grid item xs={12} style={{ display: "flex", justifyContent: "right", textAlign: "right", paddingBottom: "-6px" }}>
                                <Button className="limites_mob" onClick={() => setMyLimits(true)} size="large" endIcon={<ArrowForwardIos sx={{ width: "15px" }} />} style={{ color: "#1C252E" }}>
                                    Limites
                                </Button>
                            </Grid>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: "2px" }}>
                            <Typography variant="body2" sx={{ fontWeight: "600", color: '#393939' }}>
                                Saldo Atual
                            </Typography>
                        </Box>
                        <Grid item xs={12} style={{ textAlign: "center", padding: "10px", justifyContent: "space-between" }}>
                            <Typography variant="h6">
                                {formatarParaNomeStatusAdiantamento(viagem.adiantamento[0]?.status)}
                            </Typography>
                            {(viagem.adiantamento[0]?.status === undefined &&
                                viagem.status === EnumTipo.viagemstatus.aguardandoinicio ||
                                viagem.adiantamento[0]?.status === undefined &&
                                viagem.status === EnumTipo.viagemstatus.emviagem
                            ) && (
                                    <Button color="primary" startIcon={<SettingsRounded />} onClick={() => setEditSaldo(true)}>
                                        Editar saldo
                                    </Button>
                                )}
                        </Grid>
                    </>
                )}
            </Box>
            <Dialog
                onClose={() => setMyLimits(false)}
                style={{ borderRadius: "20px" }}
                aria-labelledby="customized-dialog-title"
                fullScreen
                open={myLimits}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    <Grid container item xs={12}>
                        <Grid item xs={1} style={{ textAlign: "right", justifyContent: "flex-end", alignItems: "center", display: "flex" }}>
                            <IconButton
                                aria-label="close"
                                onClick={() => setMyLimits(false)}
                                sx={{
                                    position: 'absolute',
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <ArrowBack />
                            </IconButton>
                        </Grid>
                        <Grid item xs={11} style={{ textAlign: "center", marginLeft: "-10px" }}>
                            <Typography style={{ fontWeight: "600", fontSize: "18px" }}>Seus Limites</Typography>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent style={{ padding: "20px", fontFamily: "sans-serif" }}>
                    <Limits />
                </DialogContent>
            </Dialog>
        </Grid >
    );
};
