import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useStyles } from './styles';
import { CloseOutlined } from '@material-ui/icons';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { HotelTicketDesktop } from './HotelTicketDesktop';
import hotelRepositorio from '../../../repositorios/hotel';
import { HotelTicketMobile } from './HotelTicketMobile';
import HotelChangeCC from './HotelChangeCC';
import { useCustomSnackbar } from '../../../components/snackbar2/NewSnackBar';
import DialogComponent from '../../prestacaodecontas/Desktop/sections/dialogs-desktop';
import { Button, ThemeProvider } from '@mui/material';
import { themepalette } from '../../../theme/palette';

export const HotelTicket = (props) => {
    const classes = useStyles();
    const responsive = props.responsive;
    const open = props.openDialogBookingHotelTicket;
    const [dtoChangeCC, setDtoChangeCC] = useState({
        idpessoa: 0,
        idreserva: 0
    });

    const [openChangeCC, setOpenChangeCC] = useState(false);
    const permissaoDashboard = useSelector((state) => state.permissao.permissaoDashboard);
    const [hotelTicketData, setHotelTicketData] = useState({});

    const { enqueueSnackbar } = useCustomSnackbar();

    const getHotelTicket = useCallback(async () => {
        try {
            // props.setIsLoading(true)
            let responseReserva = await hotelRepositorio.getBookingHotelInLines(props.bookingSelected.idreserva);
            setHotelTicketData(responseReserva);
        } catch (e) {
            enqueueSnackbar('Erro ao obter o hotel.', { variant: 'error' });
        } finally {
            // props.setIsLoading(false)
        }
    }, [setHotelTicketData, props, enqueueSnackbar])

    const handleClickEditHotel = async (model) => {
        try {
            props.setIsLoading(true)
            model.idreserva = props.bookingSelected.idreserva;
            await hotelRepositorio.updateInfoBookingByRoom(model)
            getHotelTicket()
            enqueueSnackbar('Data editada com sucesso.', { variant: 'success' });
        } catch (e) {
            enqueueSnackbar('Erro ao editar as datas.', { variant: 'error' });
        } finally {
            props.setIsLoading(false)
        }
    }

    const openChangeCCButton = (idpessoa) => {
        setDtoChangeCC({
            idpessoa: idpessoa,
            idreserva: props.bookingSelected.idreserva
        })
        setOpenChangeCC(true);
    }

    function formatarTelefone(telefone) {
        const numeroLimpo = telefone.replace(/\D/g, '');
        const temDDD = numeroLimpo.length > 8;
        const regex = temDDD ? /^(\d{2})(\d{4,5})(\d{4})$/ : /^(\d{4,5})(\d{4})$/;
        const telefoneFormatado = numeroLimpo.replace(regex, temDDD ? '($1) $2-$3' : '$1-$2');
        return telefoneFormatado;
    }

    useEffect(() => {
        getHotelTicket();
    }, [getHotelTicket]);

    return (
        <>
            <ThemeProvider theme={themepalette}>
                <DialogComponent
                    open={open}
                    handleClose={() => props.setOpenDialogBookingHotelTicket(false)}
                    fullScreen={responsive}
                    width={'lg'}
                    title={
                        <>
                            Hotel #{Object.keys(hotelTicketData).length !== 0 ? hotelTicketData.reserva.idreservatenant : ''}
                        </>
                    }
                    body={
                        <div style={{ padding: '0 0 60px 0' }}>
                            {Object.keys(hotelTicketData).length !== 0 ? responsive ?
                                (
                                    <HotelTicketMobile {...{
                                        permissaoDashboard,
                                        hotelTicketData,
                                        formatarTelefone,
                                        openChangeCCButton
                                    }} />
                                ) : (
                                    <HotelTicketDesktop {...{
                                        permissaoDashboard,
                                        hotelTicketData,
                                        formatarTelefone,
                                        openChangeCCButton,
                                        handleClickEditHotel
                                    }} />
                                ) : null}
                        </div>
                    }
                    action={
                        <>
                            {(hotelTicketData) && (
                                <>
                                    {(hotelTicketData?.reserva?.status === 9 && permissaoDashboard.permiteautorizarnegar ||
                                        hotelTicketData?.reserva?.status === 14 && permissaoDashboard.permiteaprovar
                                    ) && (
                                        <>
                                            <Button fullWidth={responsive} variant="contained" size="large" color="primary" onClick={() => props.handleClickOpenHistory()}>
                                                Autorizar/Negar
                                            </Button>
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    }
                />
                {/* <CustomizedSnackbars {...{ variant: snackVariant, message: snackMessage, visible: snackState.visible, setSnackbar: setSnackState }} /> */}
                {openChangeCC && (
                    <HotelChangeCC {...{
                        open: openChangeCC,
                        setOpenChangeCC,
                        dtoChangeCC,
                        getHotelTicket
                    }} />
                )}
            </ThemeProvider>
        </>
    )
};
