import React, { createContext, useContext, useState, useCallback } from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { Typography } from '@mui/material';

// Context creation for Snackbar state management
const SnackbarContext = createContext();

// Custom hook to use Snackbar
export const useCustomSnackbar = () => useContext(SnackbarContext);

// Snackbar Provider
export const SnackbarProvider = ({ children }) => {
  const [snackPack, setSnackPack] = useState([]);
  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState(undefined);

  const enqueueSnackbar = useCallback((message, options = {}) => {
    setSnackPack((prev) => [...prev, { message, options }]);
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };

  React.useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackPack.length && messageInfo && open) {
      // Close an active snack if open
      setOpen(false);
    }
  }, [snackPack, messageInfo, open]);

  return (
    <SnackbarContext.Provider value={{ enqueueSnackbar }}>
      {children}
      <Snackbar
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
        TransitionProps={{ onExited: handleExited }}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert variant="filled" onClose={handleClose} severity={messageInfo?.options.variant || 'info'} sx={{ width: '100%', color: "white", fontWeight: "600" }}>
          <Typography variant="h6" style={{ fontWeight: "600", fontSize: "14px" }}>
            {messageInfo?.message}
          </Typography>
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};

// Usage Example Component
function MyApp() {
  const { enqueueSnackbar } = useCustomSnackbar();

  const handleClick = () => {
    enqueueSnackbar('I love snacks.');
  };

  const handleClickVariant = (variant) => () => {
    enqueueSnackbar('This is a success message!', { variant });
  };

  return (
    <React.Fragment>
      <Button onClick={handleClick}>Show snackbar</Button>
      <Button onClick={handleClickVariant('success')}>Show success snackbar</Button>
    </React.Fragment>
  );
}

// Integration component that wraps the app's component tree
export default function IntegrationNotistack({ children }) {
  return (
    <SnackbarProvider>
      {children}
    </SnackbarProvider>
  );
}
