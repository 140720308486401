import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, CardContent, Container, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Menu, MenuItem, Typography } from "@material-ui/core";
import { CreditCardOutlined, EcoOutlined, EditOutlined } from "@material-ui/icons";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";
import { useState } from "react";
// import Cookies from "universal-cookie";
import Enums from "../../../../enums";
import EnumCompany from "../../../../enums/enumFlightCompany";
import { EnumPax } from "../../../../enums/enumPax";
// import logoteste from "../../../../images/Logos/logo_linesturDigital.svg";
// import logoteste from "../../../../images/BackgroundImages/Logo_horizontal__4.png"
import aviaoChegando from "../../../../images/PNG_random/aviao-chegando.png";
// import aviao from "../../../../images/PNG_random/aviao.png";
import ReservaStatusComponent from "../../../../components/LinearProgress/StatusVerifiqued";
import { useStyles } from "./styles";
// import { IconsSource } from "../../../../images/Icons";
import FlightIcon from '@material-ui/icons/Flight';
import { EmailOutlined, MoreVertOutlined, WhatsApp } from "@mui/icons-material";
import { Card } from "@mui/material";

export const BookingTicketMobile = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const permissaoDashboard = props.permissaoDashboard;
  const data = props.bookingTicketData;
  const classes = useStyles();
  // const cookies = new Cookies();
  // const [anchorEl, setAnchorEl] = useState(null);
  // const [isreduce, setIsReduce] = useState(false)

  const tempoDeVoo = (horaida, horachegada) => {
    let diferenca = new Date(horachegada - horaida);
    let h = diferenca.getUTCHours() + "h ";
    let min = diferenca.getUTCMinutes() + "min ";
    return h + " " + min;
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const handleClick = (event) => {     
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  return (
    <>
      <Grid container>
        <Container>
          <div style={{ padding: '8px' }}>
            {/* <Grid container item xs={12} className={classes.gridContainerCustom} style={{ marginBottom: "0px" }}> */}
            <Card
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: "auto",
                borderRadius: "16px",
                overflow: 'hidden',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                backgroundImage: 'linear-gradient(to right, #FFFFFFFF, #ffffff)',
                padding: "10px"
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center', // Alinhar verticalmente no centro
                      justifyContent: 'space-between', // Garantir que ocupem todo o espaço e fiquem um ao lado do outro
                      width: '100%',
                    }}
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography variant="h6" style={{ fontWeight: "600" }}>
                        Agente designado
                      </Typography>
                      <Typography variant="body2" style={{ color: "#454F5B" }}>
                        Zulma A. Torassi
                      </Typography>
                    </Box>
                    <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                      <MoreVertOutlined />
                    </IconButton>
                  </Box>

                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    sx={{ borderRadius: "16px" }}
                  >
                    {/* <Typography variant="body2"><span>E-mail:</span> zulma@linestur.com</Typography>
                </Grid>
                <Grid item={3} className={classes.header2} style={{ marginLeft: "auto" }}>
                  <Typography variant="h6"><b>Contatos</b></Typography>
                  <Typography variant="body2">(48) 3045-7015</Typography>
                  <Typography variant="body2">(48) 99976-0516</Typography> */}
                    <MenuItem onClick={() => {
                      handleClose();
                      window.open('https://wa.me/+554899760516', '_blank'); // Abre o link do WhatsApp em uma nova aba
                    }}>
                      <WhatsApp sx={{ marginRight: 1 }} /> {/* Ícone do WhatsApp */}
                      Whatsapp
                    </MenuItem>
                    <MenuItem onClick={() => {
                      handleClose();
                      window.location.href = 'mailto:zulma@linestur.com'; // Abre o cliente de email com o endereço predefinido
                    }}>
                      <EmailOutlined sx={{ marginRight: 1 }} /> {/* Ícone do Email */}
                      Email
                    </MenuItem>
                  </Menu>
                </CardContent>
              </Box>
            </Card>

            {/* </Grid> */}
            {/* <Grid item xs={6} style={{ textAlign: "center", alignItems: "center", justifyContent: "center", alignContent: "center", }}>
              <CardMedia style={{ width: "150px" }} component="img" image={logoteste} />
            </Grid> */}
            {/* <Grid item xs={6} className={classes.gridCenterAll}>
              <Button onClick={handleClick} className={classes.buttonRed}>Opções</Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
                transformOrigin={{ vertical: 'top', horizontal: 'center', }}
              >
                <MenuItem onClick={() => props.handleClickOpenChangeValuePayment(data.status, data.idreserva)} className={classes.menuCustom}>
                  <CreditCardOutlined />Cartão
                </MenuItem>
                <MenuItem onClick={() => props.handleClickOpenAddAttachments()} className={classes.menuCustom}><InsertDriveFileOutlined />Anexos</MenuItem>
              </Menu>
            </Grid> */}
            {/* <Grid item xs={12} style={{ textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center", alignContent: "center", marginTop: "20px" }}>
              <CardMedia style={{ width: "150px" }} component="img" image={logoteste} />
            </Grid> */}
            {/* <Grid item xs={6} className={classes.gridCenterAll}>
              <Button onClick={handleClick} className={classes.buttonRed}>Opções</Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
                transformOrigin={{ vertical: 'top', horizontal: 'center', }}
              >
                <MenuItem onClick={() => props.handleClickOpenChangeValuePayment(data.status, data.idreserva)} className={classes.menuCustom}>
                  <CreditCardOutlined />Cartão
                </MenuItem>
                <MenuItem onClick={() => props.handleClickOpenAddAttachments()} className={classes.menuCustom}><InsertDriveFileOutlined />Anexos</MenuItem>
              </Menu>
            </Grid> */}
            {/* <hr style={{ width: "100%", backgroundColor: "#CACACA", height: "1px", border: "none" }}></hr> */}
            {/* <Grid item xs={12} style={{
          }}>
            {isreduce ? (
              <ListItem>
                <ListItemAvatar>
                  <Avatar src={IconsSource.zulminhaicon} style={{ marginBottom: "15px" }}>
                  </Avatar>
                </ListItemAvatar>
                <ListItemText secondary="Estou tentando encontrar reduções nessa tarifa a cada segundo." />
              </ListItem>
            ) : (null)}
          </Grid> */}

            <Grid item xs={12} style={{ marginTop: "20px" }}>
              <ReservaStatusComponent
                status={data.status}
                idreserva={data.idreserva}
              />
            </Grid>
            <Grid item container xs={12} style={{ marginBottom: "30px" }}>
              <Grid item xs={12} className={classes.gridCenterAll} style={{ marginTop: "20px" }}>
                <div style={{
                  fontWeight: "600",
                  fontSize: "15px",
                  // backgroundColor: "#d32f2f", // Uma cor vermelha mais escura para indicar uma ação de remover
                  border: "none",
                  cursor: "pointer",
                  padding: "10px 20px",
                  borderRadius: "30px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  transition: "background-color 0.3s, transform 0.3s",
                  display: "flex",
                  alignItems: "center",
                  width: "fit-content",
                  fontFamily: 'HindBold',
                  backgroundColor: Enums.getReservaStatusCor(data.status),
                  color: "#ffffff",
                }}>{Enums.getReservaStatusDescricao(data.status)} </div>
              </Grid>
            </Grid>

            <hr style={{ width: "100%", backgroundColor: "#CACACA", height: "1px", border: "none" }}></hr>

            <Grid item container xs={12} className={classes.gridContainerCustom}>
              <Grid item xs={12} style={{ textAlign: "center", }}>
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <img
                    src={EnumCompany.getLogoCompanhiaAereaEnum(data.aereo.companhiaaerea).url_low}
                    style={{
                      width: "85px",
                    }}
                    alt="Logo da Companhia Aérea"
                  />
                </Grid>
                <Typography style={{ fontSize: "14px", fontWeight: "600" }}>{EnumCompany.getLogoCompanhiaAereaEnum(data.aereo.companhiaaerea).nome} Airlines</Typography>
                <Typography variant="body2" style={{ fontSize: "12px", color: "#454F5B" }}>Localizador - {permissaoDashboard.usuarioLinestur ? data.aereo.localizador : [3, 8, 9, 14].includes(data.status) ? ("Aguardando") : data.aereo.localizador}</Typography>
              </Grid>

              <Grid item xs={12} container style={{ marginTop: "30px" }}>
                <Grid item xs={4} className={classes.gridCenterAllWithDirection}>
                  <Typography variant="body2" style={{ fontWeight: "600" }}>
                    {data.aereo.voos[0].origem}
                  </Typography>
                  <Typography variant="h5"><b>{new Date(data.aereo.voos[0].datapartida).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</b></Typography>
                  <Typography variant="body2" style={{ textAlign: "center", color: "#454F5B" }}>
                    {new Date(data.aereo.voos[0].datapartida).toLocaleDateString('pt-BR', {
                      day: 'numeric',
                      month: 'short',
                      year: 'numeric'
                    }).replace('.', '')}
                  </Typography>
                </Grid>

                <Grid item xs={4} className={classes.gridCenterAllWithDirection}>
                  {data.aereo.voos[0].origem === data.aereo.voos[data.aereo.voos.length - 1].destino ? (
                    <>
                      <Grid item xs={12} style={{
                        textAlign: "center",
                        marginTop: "6px",
                        overflow: "hidden", // Para garantir que o ícone não ultrapasse o contêiner
                        position: "relative", // Para permitir posicionamento absoluto do ícone
                        width: "-webkit-fill-available"
                      }}>
                        <style>
                          {`
                                                                      @keyframes moveRight {
                                                                          0% {
                                                                            transform: translateX(-80%); // Começa fora à esquerda
                                                                          }
                                                                          100% {
                                                                            transform: translateX(80%); // Termina fora à direita
                                                                          }
                                                                        }
                                              }`
                          }
                        </style>
                        <div style={{
                          width: "100%",
                          animation: "moveRight 5s linear infinite", // Ajuste a duração conforme necessário
                        }}>
                          <FlightIcon fontSize="large" style={{ transform: "rotate(90deg)", color: "#c62828", width: "70px" }} />
                        </div>
                      </Grid>

                      <Grid item xs={12} style={{
                        textAlign: "center",
                        marginTop: "6px",
                        overflow: "hidden", // Para garantir que o ícone não ultrapasse o contêiner
                        position: "relative", // Para permitir posicionamento absoluto do ícone,
                        width: "-webkit-fill-available"
                      }}>
                        <style>
                          {`
                                                                        @keyframes moveLeft {
                                                                            0% {
                                                                              transform: translateX(80%); // Começa fora à esquerda
                                                                            }
                                                                            100% {
                                                                              transform: translateX(-80%); // Termina fora à direita
                                                                            }
                                                                          }
                                                                    }`}
                        </style>
                        <div style={{
                          width: "100%",
                          animation: "moveLeft 5s linear infinite", // Ajuste a duração conforme necessário
                        }}>
                          <FlightIcon style={{ transform: "rotate(270deg)", color: "#c62828" }} />
                        </div>
                      </Grid>
                    </>
                  ) : (
                    <Grid item xs={12} style={{
                      textAlign: "center",
                      marginTop: "6px",
                      overflow: "hidden", // Para garantir que o ícone não ultrapasse o contêiner
                      position: "relative", // Para permitir posicionamento absoluto do ícone
                      width: "-webkit-fill-available"
                    }}>
                      <style>
                        {`
                                                                      @keyframes moveRight {
                                                                          0% {
                                                                            transform: translateX(-80%); // Começa fora à esquerda
                                                                          }
                                                                          100% {
                                                                            transform: translateX(80%); // Termina fora à direita
                                                                          }
                                                                        }
                                                                  }`}
                      </style>
                      <div style={{
                        width: "100%",
                        animation: "moveRight 5s linear infinite", // Ajuste a duração conforme necessário
                      }}>
                        <FlightIcon fontSize="large" style={{ transform: "rotate(90deg)", color: "#c62828", width: "70px" }} />
                      </div>
                    </Grid>
                  )}
                </Grid>

                <Grid item xs={4} className={classes.gridCenterAllWithDirection}>
                  {/* <Grid item xs={4} className={classes.gridCenterAllWithDirection}>
                    <Typography variant="h5"><b>{new Date(data.aereo.voos[0].datapartida).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</b></Typography>
                    <Typography variant="body2" style={{ fontWeight: "600" }}>
                      {data.aereo.voos[0].origem}
                    </Typography>
                    <Typography variant="body2" style={{ textAlign: "center", color: "#454F5B" }}>
                      {new Date(data.aereo.voos[0].datapartida).toLocaleDateString('pt-BR', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric'
                      }).replace('.', '')}
                    </Typography>
                  </Grid> */}
                  {data.aereo.voos.map((item, index) => (
                    <>
                      {item.destino === data.aereo.voos[0].origem ?
                        data.aereo.voos.length - 1 ? (
                          <>
                            <Typography variant="body2" style={{ fontWeight: "600" }}>
                              {item.origem}
                            </Typography>
                            <Typography variant="h5"><b>{new Date(item.datapartida).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</b></Typography>
                            <Typography variant="body2" style={{ textAlign: "center", color: "#454F5B" }}>
                              {new Date(item.datapartida).toLocaleDateString('pt-BR', {
                                day: 'numeric',
                                month: 'short',
                                year: 'numeric'
                              }).replace('.', '')}
                            </Typography>
                            {/* <Typography variant="body1">
                              {new Date(item.datapartida).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                            </Typography>
                            <Typography variant="body1">
                              {new Date(item.datapartida).toLocaleDateString()}
                            </Typography> */}

                            {/* <Typography variant="body2" style={{ fontWeight: "600" }}>
                              {data.aereo.voos[0].origem}
                            </Typography>
                            <Typography variant="body2" style={{ textAlign: "center", color: "#454F5B" }}>
                              {new Date(data.aereo.voos[0].datapartida).toLocaleDateString('pt-BR', {
                                day: 'numeric',
                                month: 'short',
                                year: 'numeric'
                              }).replace('.', '')}
                            </Typography> */}
                          </>
                        ) : null
                        : null}
                      {item.destino !== data.aereo.voos[0].origem ?
                        data.aereo.voos.length - 1 === index ? (
                          <>
                            <Typography variant="body2" style={{ fontWeight: "600" }}>
                              {item.origem}
                            </Typography>
                            <Typography variant="h5"><b>{new Date(item.datachegada).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</b></Typography>
                            <Typography variant="body2" style={{ textAlign: "center", color: "#454F5B" }}>
                              {new Date(item.datachegada).toLocaleDateString('pt-BR', {
                                day: 'numeric',
                                month: 'short',
                                year: 'numeric'
                              }).replace('.', '')}
                            </Typography>
                          </>
                        ) : null
                        : null}
                    </>
                  ))}
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={12} className={classes.gridContainerCustom} style={{ padding: "10px" }}>
              <List>
                <ListItem onClick={() => props.handleClickOpenChangeValuePayment(data.status, data.idreserva)}>
                  <ListItemAvatar>
                    <Avatar>
                      <CreditCardOutlined />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Cartão" secondary="Utilizado para emitir" />
                </ListItem>
              </List>
            </Grid>
            <hr style={{ width: "100%", backgroundColor: "#CACACA", height: "1px", border: "none" }}></hr>
            <Grid item container xs={12} className={classes.gridContainerCustom}>
              <Grid item xs={12} style={{ textAlign: "left" }}>
                <Typography variant="body1" className={classes.typographyGrey}><b>Solicitante</b></Typography>
                <Typography variant="h6" style={{ fontSize: "17px" }}>
                  {data.usuario.usuario}
                  {data.status === 7 && props.permissaoDashboard.usuarioLinestur ? (
                    <>
                      {props.showIconWppByValue(data.usuario.idpessoa, 1, true)}
                    </>
                  ) : null}
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ textAlign: "left" }}>
                <Typography variant="body1" className={classes.typographyGrey}><b>Criação</b></Typography>
                <Typography variant="h6" style={{ fontSize: "17px" }}>{new Date(data.datacriacao).toLocaleDateString('pt-BR', { weekday: 'long', day: 'numeric', month: 'short', year: 'numeric' })} </Typography>
              </Grid>
            </Grid>
          </div>
        </Container>
        <Grid container item xs={12} className={classes.gridContainerRed}>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <img alt="Logo da campanhia não encontrada" src={data.aereo.companhiaaerea === undefined ? null : EnumCompany.getLogoCompanhiaAereaEnum(data.aereo.companhiaaerea).url_high} width={"auto"} style={{ height: "8rem" }} />
          </Grid>
          <Grid item xs={12} className={classes.gridCenterAll}>
            <Typography variant="h6" className={classes.typographyWhite}>DETALHES</Typography>
          </Grid>
          <Grid item xs={12} >
            <Accordion className={classes.accordionRed}>
              <AccordionSummary expandIcon={<ExpandMoreOutlinedIcon className={classes.colorIcon} />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography variant="body1" className={classes.typographyWhite}>PASSAGEIROS</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionValues}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {data.aereo.passageiros.map((item) => (
                    <Accordion className={classes.accordionRed} style={{ width: '100%' }} >
                      <AccordionSummary expandIcon={<ExpandMoreOutlinedIcon className={classes.colorIcon} />} aria-controls="panel1a-content" id="panel1a-header">
                        <Typography variant='body2'>{`${item.passageiro.nome} ${item.passageiro.sobrenome}`}</Typography>
                      </AccordionSummary>
                      <AccordionDetails className={classes.accordionDetailsItem}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography variant="body2">Nome</Typography>
                            <Typography variant="body1">{item.passageiro.nome}</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="body2">Sobrenome</Typography>
                            <Typography variant="body1">{item.passageiro.sobrenome}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="body2">Nascimento</Typography>
                            <Typography variant="body1">{new Date(item.passageiro.datanascimento).toLocaleDateString()}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="body2">Gênero</Typography>
                            <Typography variant="body1">{EnumPax.getGenero[item.passageiro.sexo]}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="body2">Tipo</Typography>
                            <Typography variant="body1">{EnumPax.getFaixaEtaria[item.faixaetaria]}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            {data.status === 7 && permissaoDashboard.usuarioLinestur ? props.showIconWppByValue(item.idpassageiro, item.bilheteenviado, false) : null}
                          </Grid>
                          <Grid item xs={12} style={{ textAlign: 'center' }}>
                            <Typography variant="body1">Emissão CO2 (kg)</Typography>
                            <Typography variant="h6">{item.carbono} <EcoOutlined style={{ color: '#43a047' }} /></Typography>
                          </Grid>
                          <Grid item xs={12} style={{ textAlign: "center" }}>
                            <Typography variant="body1" noWrap>
                              Centro de Custo
                              {permissaoDashboard.usuarioLinestur ? (<IconButton onClick={() => props.handleClickOpenChangeValueCC(item)} ><EditOutlined className={classes.colorIcon} /></IconButton>) : null}
                            </Typography>
                            <Typography variant="h6" noWrap>
                              {`${item.centroCusto.nome} - ${item.centroCusto.codigo}`}
                            </Typography>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion className={classes.accordionRed}>
              <AccordionSummary expandIcon={<ExpandMoreOutlinedIcon className={classes.colorIcon} />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography variant="body1" >VOOS</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionValues}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {data.aereo.voos.map((item) => (
                    <Accordion className={classes.accordionRed}>
                      <AccordionSummary expandIcon={<ExpandMoreOutlinedIcon className={classes.colorIcon} />} aria-controls="panel1a-content" id="panel1a-header">
                        <Typography variant={'body2'}>{`${item.origemdescricao} ⇀ ${item.destinodescricao}`}</Typography>
                      </AccordionSummary>
                      <AccordionDetails className={classes.accordionDetailsItem}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} className={classes.gridCenterAll}>
                            <img alt="Logo da campanhia não encontrada" src={item.iata === undefined ? null : EnumCompany.getLogoCompanhiaAereaEnum(item.iata).url_low} style={{ margin: "5px", width: "120px", }} />
                          </Grid>
                          <Grid item xs={4} className={classes.gridTextCenter}>
                            <Typography variant="body2">{new Date(item.datapartida).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</Typography>
                            {/* <Typography variant="h1">{`${new Date(item.datapartida).toLocaleString().slice(11, new Date(item.datapartida).toLocaleString().length - 3)}`}</Typography> */}
                            <Typography variant="h6">{item.origem}</Typography>
                            <Typography variant="body1">{item.origemdescricao}</Typography>
                            <Typography variant="body1">{new Date(item.datapartida).toLocaleDateString()}</Typography>
                            {/* <Typography variant="h6">{`${new Date(item.datapartida).toLocaleString().slice(0, new Date(item.datapartida).toLocaleString().length - 10)}`}</Typography> */}
                          </Grid>
                          <Grid item xs={4} style={{ textAlign: "center" }}>
                            <img alt="" src={aviaoChegando} style={{ width: "50px", marginTop: "0px" }} />
                            <Typography variant="subtitle2">{tempoDeVoo(new Date(item.datapartida), new Date(item.datachegada))}</Typography>
                          </Grid>
                          <Grid item xs={4} className={classes.gridTextCenter}>
                            <Typography variant="body1">{new Date(item.datachegada).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</Typography>
                            {/* <Typography variant="h1">{`${new Date(item.datachegada).toLocaleString().slice(11, new Date(item.datachegada).toLocaleString().length - 3)}`}</Typography> */}
                            <Typography variant="h6">{item.destino}</Typography>
                            <Typography variant="body1">{item.destinodescricao}</Typography>
                            <Typography variant="body1">{new Date(item.datachegada).toLocaleDateString()}</Typography>
                            {/* <Typography variant="body1">{`${new Date(item.datachegada).toLocaleString().slice(0, new Date(item.datachegada).toLocaleString().length - 10)}`}</Typography> */}
                          </Grid>
                          <Grid item xs={12} container className={classes.gridTextCenter}>
                            <Grid item xs={4} >
                              <Typography variant="body1">Voo</Typography>
                              <Typography variant="h6">{item.numero}</Typography>
                            </Grid>
                            <Grid item xs={4} >
                              <Typography variant="body1">CI.</Typography>
                              <Typography variant="h6">{item.classe}</Typography>
                            </Grid>
                            <Grid item xs={4} >
                              <Typography variant="body1">Esc.</Typography>
                              <Typography variant="h6">{item.escala}</Typography>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} className={classes.gridTextCenter}>
                            <Typography variant="body1">Info</Typography>
                            <Typography variant="h6">
                              {item.incluibagagem ? `Com Bagagem(${item.tipobagagem === null ?
                                "Sem nome" : item.tipobagagem})` : `Sem Bagagem(${item.tipobagagem === null ? "Sem nome" : item.tipobagagem})`}
                            </Typography>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion className={classes.accordionRed}>
              <AccordionSummary expandIcon={<ExpandMoreOutlinedIcon className={classes.colorIcon} />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography variant="body1" >VALORES</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionValues}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {data.aereo.passageiros.map((item) => (
                    <Accordion className={classes.accordionRed}>
                      <AccordionSummary expandIcon={<ExpandMoreOutlinedIcon className={classes.colorIcon} />} aria-controls="panel1a-content" id="panel1a-header">
                        <Typography variant="body2">{item.passageiro.sobrenome + "/" + item.passageiro.nome}</Typography>
                      </AccordionSummary>
                      <AccordionDetails className={classes.accordionDetailsItem}>
                        <Grid container spacing={2} >
                          <Grid item xs={6} className={classes.gridTax}>
                            <Typography variant="body2">Tarifas</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="body1">
                              {`R$ ${item.total.toFormatDefault()}`}
                              {permissaoDashboard.usuarioLinestur && data.status === 7 ? (
                                <IconButton onClick={() => props.handleClickOpenChangeValuePax(item, "tarifa")} >
                                  <EditOutlined className={classes.colorIcon} />
                                </IconButton>
                              ) : null}
                            </Typography>
                          </Grid>
                          <Grid item xs={6} className={classes.gridTax}>
                            <Typography variant="body2">Extras</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="body1">
                              {`R$ ${item.taxes.toFormatDefault()}`}
                              {permissaoDashboard.usuarioLinestur && data.status === 7 ? (
                                <IconButton onClick={() => props.handleClickOpenChangeValuePax(item, "extras")} >
                                  <EditOutlined className={classes.colorIcon} />
                                </IconButton>
                              ) : null}
                            </Typography>
                          </Grid>
                          <Grid item xs={6} className={classes.gridTax}>
                            <Typography variant="body2">Total</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="body1">{`R$ ${item.total_geral.toFormatDefault()}`}</Typography>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </div>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} container className={classes.resumValues}>
            <Grid item xs={4}>
              <Typography variant="body2">Tarifas</Typography>
              <Typography variant="body1">{"R$" + data.aereo.total?.toFormatDefault() + " "}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2">Extras</Typography>
              <Typography variant="body1">{"R$" + data.aereo.taxes?.toFormatDefault() + " "}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2">Total</Typography>
              <Typography variant="body1">{"R$" + data.aereo.total_geral?.toFormatDefault() + " "}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.gridContainerInfoCustom}>
            <Typography>
              <b>Informações para Embarque</b>
            </Typography>
            <Typography>
              Apresente-se em nosso checkin com 2 horas de antecedência em
              voos nacionais e com 3 horas de antecedência em voos
              internacionais.
            </Typography>
            <Typography> Levar documento original: </Typography>
            <Typography><li><b>Carteira de Identidade</b> para vôos nacionais.</li></Typography>
            <Typography><li><b>Passaporte</b> e os vistos necessários para entrada no pais de destino para voos internacionais.</li></Typography>
            <Typography>
              Informações sobre validade de PASSAPORTE, VACINAS e VISTOS que possam ser necessários para sua viagem devem ser consultados com as respectivas embaixadas ou despachantes de vistos.
            </Typography>
            <Typography>
              Verifique essa necessidade para todos os países envolvidos na viagem, mesmo aqueles onde há apenas uma escala.
            </Typography>
            <Typography>
              Lembre-se que alguns países exigem que o passaporte tenha uma validade mínima de 6 meses para embarcar.
            </Typography>
          </Grid>
        </Grid>
      </Grid >
    </>
  );
};
