import { AppBar, Button, Dialog, DialogActions, DialogContent, Grid, IconButton, Slide, Toolbar, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { Close, SaveOutlined, SearchOutlined } from '@material-ui/icons';
import React, { useCallback, useEffect, useState } from 'react';
import { CustomizedSnackbars } from '../../../../components/snackbar';
import ULoaderVoo from '../../../../components/uloadervoo';
import USearchField from '../../../../components/usearchfield';
import aereoBilhete from '../../../../repositorios/aereoBilhete/index';
import { useStyles } from './styles';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ChangeValuePayment = (props) => {
    const open = props.openChangeValuePayment;
    const idReserva = props.itemSelectedChangeValuePayment.idReserva;
    const statusBilheteView = props.itemSelectedChangeValuePayment.statusBilheteView;
    const classes = useStyles();
    const theme = useTheme();
    const [selecionado, setSelecionado] = useState(null);
    const [selecionadoOpcoes, setSelecionadoOpcoes] = useState(null);
    const [itensLista, setItensLista] = useState(null);
    const [itensListaOpcoes, setItensListaOpcoes] = useState(null);
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const [snackState, setSnackState] = useState({ visible: false });
    const [snackVariant, setSnackVariant] = useState('success');
    const [snackMessage, setSnackMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showOpcoes, setShowOpcoes] = useState(false);

    const handleCloseDialog = () => {
        setSelecionado(null);
        props.setOpenChangeValuePayment(false);
    }

    const loadData = useCallback(async () => {
        setIsLoading(true);
        try {
            let response = await aereoBilhete.getCreditCards();
            setItensLista(response.data.itens)
            let response2 = await aereoBilhete.getReserveCard(idReserva);
            setSelecionado(response2.data.cardSelecionado)
            if (response2.data.parcelSelecionado.value >= 0) {
                setShowOpcoes(true);
                setSelecionadoOpcoes(response2.data.parcelSelecionado);
            }
        } catch (e) {
            setSnackVariant('error');
            setSnackMessage('Erro ao carregar cartões.');
            setSnackState({ visible: true });
        } finally {
            setIsLoading(false);
        }
    }, [idReserva])

    const handleClickSearchParcelas = async () => {
        let dto = null;
        setIsLoading(true);
        try {
            dto = {
                idreserva: idReserva,
                idcartao: selecionado.value,
            }
            let response = await aereoBilhete.postSelectedCreditCard(dto);
            setItensListaOpcoes(response.data.itens)
        } catch (e) {
            setSnackVariant('error');
            setSnackMessage('Erro ao carregar opções de parcelas.');
            setSnackState({ visible: true });
        } finally {
            setIsLoading(false)
        }
        setShowOpcoes(true);
    }

    const handleClickSave = async () => {
        setIsLoading(true);
        let dto = null;

        if (selecionado === null) {
            setSnackVariant('error');
            setSnackMessage('Opções de cartão não pode ser vazio.');
            setSnackState({ visible: true });
            setIsLoading(false);
            return;
        }
        if (selecionadoOpcoes === null) {
            setSnackVariant('error');
            setSnackMessage('Opções de parcelamento não pode ser vazio.');
            setSnackState({ visible: true });
            setIsLoading(false);
            return;
        }
        try {
            dto = {
                idcartao: selecionado?.value,
                idreserva: idReserva,
                descParcela: selecionadoOpcoes?.label,
                nParcelas: selecionadoOpcoes?.value,
                idparcela: selecionadoOpcoes?.idparcela,
            }
            await aereoBilhete.putSaveReserveCard(dto);
            setSnackVariant('success');
            setSnackMessage('Opção salva com sucesso.');
            setSnackState({ visible: true });
        } catch (e) {
            setSnackVariant('error');
            setSnackMessage('Erro ao salvar');
            setSnackState({ visible: true });
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        setSelecionadoOpcoes(null)
    }, [selecionado])

    useEffect(() => {
        setItensLista([]);
        setItensListaOpcoes([]);
        (async () => { await loadData() })()
    }, [])

    return (
        <>
            <Dialog
                open={open}
                onClose={handleCloseDialog}
                maxWidth={"sm"}
                fullWidth
                fullScreen={responsive}
                TransitionComponent={Transition}
            >
                <ULoaderVoo isLoading={isLoading} />
                <CustomizedSnackbars {...{ variant: snackVariant, message: snackMessage, visible: snackState.visible, setSnackbar: setSnackState }} />
                <AppBar position="relative" style={{ background: 'none' }}>
                    <Toolbar className={classes.appbar} >
                        <Typography >Método de pagamento</Typography>
                        <IconButton onClick={handleCloseDialog}><Close className={classes.iconButton} /></IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent style={responsive ? { paddingLeft: '5px', paddingRight: '5px' } : null}>
                    <Grid container className={classes.gridContainerCenter}>
                        <Grid item xs={10}>
                            <USearchField disabled={statusBilheteView} itens={itensLista} value={selecionado} setValue={setSelecionado} label="Opções de pagamento" />
                        </Grid>
                        {selecionado?.value >= 0 && statusBilheteView === false ? (
                            <Grid item xs={2}>
                                <IconButton onClick={() => handleClickSearchParcelas()}><SearchOutlined /></IconButton>
                            </Grid>
                        ) : (null)}
                        {showOpcoes && selecionado?.value >= 0 ? (
                            <Grid item xs={12}>
                                <USearchField disabled={statusBilheteView} itens={itensListaOpcoes} value={selecionadoOpcoes} setValue={setSelecionadoOpcoes} label="Opções de parcelamento" />
                            </Grid>
                        ) : null}
                    </Grid>
                </DialogContent>
                {statusBilheteView === false ? (
                    <DialogActions>
                        <Button onClick={() => handleClickSave()} className={classes.buttonSave} startIcon={<SaveOutlined />} disabled={isLoading}>Salvar</Button>
                    </DialogActions>
                ) : (null)}
            </Dialog>
        </>
    )
};

export default ChangeValuePayment;
