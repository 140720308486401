import { Button, Grid, TextField, Typography } from "@mui/material"
import DialogComponent from "../../../../views/prestacaodecontas/Desktop/sections/dialogs-desktop"
import StyledChip from "../../chip"
import { LockOpenRounded, LockPersonRounded } from "@mui/icons-material"
import { CreationDateConvert } from "../../../../views/prestacaodecontas/Desktop/sections"

export const DialogInvalidDespesa = ({
    despesa,
    openInvalidar,
    setOpenInvalidar,
    setJustifyInvalid,
    handleClickInvalidViagem
}) => {
    return (
        <>
            {despesa && (
                <DialogComponent
                    title={
                        <>
                            {despesa && (
                                <>
                                    <Typography variant="body1" style={{ color: "#181818" }}>Despesa #{despesa?.iddespesa}</Typography>
                                    <Typography variant="body2" style={{ color: "#181818", color: "#7e7e7e" }}>{CreationDateConvert(despesa?.datadecompra)}</Typography>
                                </>
                            )}
                        </>
                    }
                    open={openInvalidar}
                    handleClose={() => setOpenInvalidar(false)}
                    body={
                        <Grid container item xs={12}>
                            {despesa && (
                                <>
                                    <Grid style={{ padding: "10px", borderRadius: "12px", boxShadow: "0px 0px 8px 1px rgba(0,0,0,0.13)", }} container item xs={12}>
                                        <Grid item xs={2}>
                                            <img style={{ width: "60px", borderRadius: "10px" }} alt="Img despesa" src={despesa.comprovante}></img>
                                        </Grid>
                                        <Grid container item xs={10}>
                                            <Typography style={{ marginLeft: "10px" }} variant="body1">{despesa.descricao}</Typography>
                                            <StyledChip
                                                style={{
                                                    color: "#535353",
                                                    borderRadius: "10px",
                                                    height: "30px",
                                                    paddingTop: "1px",
                                                    fontWeight: "bold",
                                                    marginLeft: "10px"
                                                }}
                                                label={(despesa.despesa_desc)}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} style={{ marginTop: "15px" }}>
                                        <TextField
                                            id="outlined-multiline-static"
                                            label="Justificativa"
                                            fullWidth
                                            multiline
                                            rows={4}
                                            onChange={(e) => setJustifyInvalid(e.target.value)}
                                        />
                                    </Grid>
                                    <Typography variant="body2" style={{ color: "#5c5c5c", marginTop: "15px" }}>
                                        {despesa.is_invalido ? (
                                            <>
                                                <LockOpenRounded color="primary" style={{ fontSize: "16px", marginRight: "6px", marginTop: "4px" }} />
                                                Ao validar a despesa será contablizada no total
                                            </>
                                        ) : (
                                            <>
                                                <LockPersonRounded color="error" style={{ fontSize: "16px", marginRight: "6px", marginTop: "4px" }} />
                                                Ao invalidar a despesa não será contablizada no total
                                            </>
                                        )}
                                    </Typography>
                                </>
                            )}
                        </Grid>
                    }
                    action={
                        <Grid container item xs={12} style={{ display: "flex", textAlign: "right", justifyContent: "right" }}>
                            <Button color={despesa.is_invalido ? "primary" : "error"} onClick={() => handleClickInvalidViagem(!despesa.is_invalido)}>Confirmar</Button>
                        </Grid>
                    }
                    width={"xs"}
                />
            )}
        </>
    )
}