import { Box, Grid, List, ListItem, Typography } from "@mui/material"
import DunutChart from "../../../../../../assets/charts/donutchart"
import { useCustomSnackbar } from "../../../../../../components/snackbar2/NewSnackBar"
import { useEffect, useState } from "react"
import { Scripts } from "../../../../Scripts"

export const Limits = () => {
    const [categorias, setCategorias] = useState([])
    const [chartData, setChartData] = useState({
        series: [
        ],
        options: {
        },
    })
    const [totalchart, setTotalChart] = useState(0)
    const [loading, setLoading] = useState(false);

    const chartColors = ['#FF4560', '#00E396', '#008FFB', '#775DD0'];

    const { enqueueSnackbar } = useCustomSnackbar();

    useEffect(() => {
        Scripts.getMyLimits(setLoading, enqueueSnackbar, setTotalChart, setChartData, setCategorias);
    },[])

    return (
        <Grid container item xs={12}>
            {totalchart >= 0 && (
                <Grid item xs={12}>
                    <DunutChart
                        title="Por despesa"
                        subheader="O quanto você pode gastar por categoria"
                        totalcenter={totalchart}
                        chart={chartData}
                        colors={chartColors}
                    />
                </Grid>
            )}

            <Grid item xs={12} style={{ marginBottom: "16px" }}>
                <Typography variant="body2" style={{ color: "#3D3D3D" }}>
                    Cada categoria de despesa contém o limite máximo de gasto. Caso você exceda esse limite,
                    a aprovação dependerá do auditor e do setor financeiro.
                </Typography>
            </Grid>

            <Grid item xs={12}>
                {categorias?.filter((item) =>
                    item.name !== 'Hodometro' &&
                    item.name !== 'Outros' &&
                    item.name !== 'Pendente' &&
                    item.name !== 'SOLICITAÇÃO' &&
                    item.name !== 'AEREO' &&
                    item.name !== 'HOTEL'
                ).map((item) => (
                    <>
                        <Typography variant="h6">{item.name}</Typography>
                        <Box sx={{ padding: '10px', borderRadius: '8px' }}>
                            <List>
                                {item.produtos.map((sub) => (
                                    <ListItem sx={{ display: 'flex', justifyContent: 'space-between' }} key={sub.name}>
                                        <Box>
                                            <Typography variant="body1">
                                                {sub.name}
                                            </Typography>
                                            <Typography variant="body2" sx={{ color: '#4F4F4F', fontWeight: "600" }}>
                                                Por despesa
                                            </Typography>
                                        </Box>
                                        <Typography variant="body1">
                                            {sub.limite > 0 ? (
                                                <>
                                                    {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(sub.limite)}
                                                </>
                                            ) : (
                                                "Sem Limites"
                                            )}
                                        </Typography>
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                    </>
                ))}
            </Grid>
        </Grid>
    )
}