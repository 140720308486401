import { Fullscreen, FullscreenExit, ViewList, ViewModule } from "@mui/icons-material";
import { Box, Button, ButtonGroup, Checkbox, Container, Grid, TextField, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { CardDespesaAprove } from "../../../../../components/autalii_expanses/card_viewdespesa";
import { DialogEditDespesaAudit } from "../../../../../components/autalii_expanses/dialogs/dialog_EditdespesaAudit";
import { DialogInvalidDespesa } from "../../../../../components/autalii_expanses/dialogs/dialog_InvalidDespesa";
import { ViewHodometerExpansesComponent } from "../../../../../components/autalii_expanses/viewhodometer_expanses";
import { ViewQrCodeProducts } from "../../../../../components/autalii_expanses/viewqrcode";
import { DeskCardPersonExpanses } from "../../../../../components/card_person_expanses";
import { useCustomSnackbar } from "../../../../../components/snackbar2/NewSnackBar";
import ULoaderVoo from "../../../../../components/uloadervoo";
import { formatarMoeda } from "../../../../../enums/enumPrestacaoDeContas";
import { excluirExtra_Mock } from "../../../../../mock/prestacaodecontas";
import { getTipoDespesaForNumber } from "../../../../../mock/tiposdespesas";
import prestacaoDeContasRepositorio from "../../../../../repositorios/prestacaodecontas";
import { CreationDate, IniciadoEm } from "../../sections";
import DialogComponent from "../../sections/dialogs-desktop";
import { ExtraAddComponent } from "../Extras";
import { useStyles } from "./style";

export const AprovarDespesasComponent = ({ viagem, setViagem, isExpanded, setIsExpanded, setOpen, getViagensReload }) => {
    const classes = useStyles();
    const [despesasAgrupadas, setDespesasAgrupadas] = useState(null);
    const [totalSelecionadas, setTotalSelecionadas] = useState(0);
    const [openReturnDespesa, setOpenReturnDespesa] = useState(null)
    const [descricao, setDescricao] = useState("");
    const [despesa, setDespesa] = useState(null);
    const [despesas, setDespesas] = useState([]);
    const [openInfoHodometro, setOpenInfoHodometro] = useState(false);
    const [openAuditar, setOpenAuditar] = useState(false);
    const [currentTab, setCurrentTab] = useState(1);

    const [totalGasto, setTotalgasto] = useState((despesa?.total || 0).toLocaleString('pt-BR', { minimumFractionDigits: 2 }));
    const [totalAuditar, setTotalAuditar] = useState("0,00");

    const [openDeleteExtra, setOpenDeleteExtra] = useState(false);
    const [loading, setLoading] = useState(false);
    const [categorias, setCategorias] = useState([])
    const [openQrCodeView, setOpenQrCodeView] = useState(false)
    const [marcarTodas, setMarcarTodas] = useState(false);
    const [view, setView] = useState("list");
    const [conciliacao, setConciliacao] = useState([]);
    const [openInvalidar, setOpenInvalidar] = useState(false);
    const [justifyInvalid, setJustifyInvalid] = useState("");

    const { enqueueSnackbar } = useCustomSnackbar();

    const getAlltipos = useCallback(async () => {
        setLoading(true);
        try {
            const response = await prestacaoDeContasRepositorio.getAllTiposDeDespesas();
            setCategorias(response);
        } catch {
            enqueueSnackbar('Ocorreu um erro ao buscar categorias de despesas.', { variant: 'error' });
        } finally {
            setLoading(false);
        }
    }, [setLoading, setCategorias, enqueueSnackbar]); // Dependências

    const getDespesas = useCallback(async () => {
        setLoading(true);
        try {
            const response = await prestacaoDeContasRepositorio.getDespesas(viagem.id);
            setDespesas(response.despesas);
            setConciliacao(response.extras)
            await getAlltipos();  // Aguarda a execução de getAlltipos
        } catch (error) {
            enqueueSnackbar('Ocorreu um erro ao buscar despesas.', { variant: 'error' });
        } finally {
            setLoading(false);
        }
    }, [setLoading, viagem.id, setDespesas, enqueueSnackbar, getAlltipos]);

    const [count, setCount] = useState(0)

    const reload = () => {
        setTotalSelecionadas(0)
        agruparDespesasPorTipo();
        setDescricao("")
    }

    const agruparDespesasPorTipo = useCallback(() => {
        const agrupadas = {};

        categorias?.forEach((element) => {
            despesas?.forEach((despesa) => {
                if (!despesa.aprovado && despesa.produtos.iddespesa_tipo === element.iddespesa_tipo) {
                    const tipoTitulo = element.name;
                    if (!agrupadas[tipoTitulo]) {
                        agrupadas[tipoTitulo] = [];
                    }
                    agrupadas[tipoTitulo].push({ ...despesa, selecionada: false });
                }
            });
        });

        setDespesasAgrupadas(Object.entries(agrupadas).map(([titulo, despesas]) => ({ titulo, despesas })));
    }, [categorias, despesas, setDespesasAgrupadas]);

    const toggleSelecaoDespesa = (tipoTitulo, indexDespesa) => {
        let contador = 0; // Inicializa contador para atualização do total de selecionadas

        setDespesasAgrupadas(despesasAgrupadas.map(grupo => {
            if (grupo.titulo === tipoTitulo) {
                return {
                    ...grupo,
                    despesas: grupo.despesas.map((despesa, index) => {
                        if (index === indexDespesa) {

                            const despesaAtualizada = { ...despesa, selecionada: !despesa.selecionada };
                            // Se a despesa for selecionada, incrementa, senão decrementa
                            contador += despesaAtualizada.selecionada ? 1 : -1;
                            return despesaAtualizada;
                        }
                        // Conta as já selecionadas
                        if (despesa.selecionada) contador++;
                        return despesa;
                    }),
                };
            }
            // Conta as selecionadas em outros grupos
            grupo.despesas.forEach(despesa => {
                if (despesa.selecionada) contador++;
            });
            return grupo;
        }));

        setTotalSelecionadas(contador);
    };

    const toggleSelecaoTodasDespesas = () => {
        let contador = 0; // Inicializa contador para atualização do total de selecionadas

        // Atualiza o estado das despesas agrupadas
        setDespesasAgrupadas(despesasAgrupadas.map(grupo => {
            return {
                ...grupo,
                despesas: grupo.despesas.map(despesa => {
                    // Seleciona ou desmarca todas de acordo com o estado de marcarTodas
                    const selecionada = !marcarTodas;
                    contador += selecionada ? 1 : 0; // Incrementa contador apenas se for selecionada
                    return { ...despesa, selecionada };
                }),
            };
        }));

        // Atualiza o contador de despesas selecionadas
        setTotalSelecionadas(contador);

        // Atualiza o estado de marcarTodas para alternar o comportamento do Checkbox
        setMarcarTodas(!marcarTodas);
    };

    const handleClickAprovar = async () => {
        setLoading(true)
        try {
            let list = []
            despesasAgrupadas.forEach(element => {
                element.despesas.forEach(item => {
                    if (item.selecionada) {
                        list.push(item.iddespesa)
                    }
                })
            });

            await prestacaoDeContasRepositorio.postAprovarDespesas(viagem.id, list)
            getDespesas()
            getViagensReload()
            enqueueSnackbar('Aprovada com sucesso.', { variant: 'success' });
        } catch {
            enqueueSnackbar('Ocorreu um erro ao tentar aprovar despesa(s).', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    const handleChangeTab = useCallback((newValue) => {
        setCurrentTab(newValue);
    }, []);

    const handleClickAuditar = async () => {
        setLoading(true)
        try {
            if (totalGasto && parseFloat(totalGasto.replace(/\./g, '').replace(',', '.')) !== "0,00") {
                let model = {
                    idviagem: viagem.id,
                    iddespesa: despesa.iddespesa,
                    valor_editado: parseFloat(totalGasto.replace(/\./g, '').replace(',', '.')),
                    justificativa: "",
                }
                if (despesa.total !== model.valor_editado) {
                    await prestacaoDeContasRepositorio.putCorrigirDespesa(model);
                    enqueueSnackbar('Valor editado com sucesso.', { variant: 'success' });
                }
            }
            if (totalAuditar !== "0,00") {
                let totalStringAud = parseFloat(totalAuditar.replace(/\./g, '').replace(',', '.'));
                await prestacaoDeContasRepositorio.putAuditarValor(viagem.id, despesa.iddespesa, totalStringAud);
                enqueueSnackbar('Valor auditado com sucesso.', { variant: 'success' });
            }
            getDespesas()
            getViagensReload()
            setOpenAuditar(false)
        } catch {
            enqueueSnackbar('Ocorreu um erro auditar a despesa.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    const handleClickOpenHodometro = (despesa) => {
        setDespesa(despesa)
        setOpenInfoHodometro(true)
    }

    const handleClickReturn = (despesa) => {
        setDespesa(despesa)
        setOpenReturnDespesa(true)
    }

    const handleOpenEdit = (value) => {
        setDespesa(value)
        setTotalgasto((value.total).toLocaleString('pt-BR', { minimumFractionDigits: 2 }));
        setTotalAuditar("0,00");
        setOpenAuditar(true);
    }

    const handleClickOpenQrCode = (despesa) => {
        setDespesa(despesa)
        setOpenQrCodeView(true)
    }

    const handleClickOpenInvalid = (despesa) => {
        setDespesa(despesa)
        setOpenInvalidar(true)
    }

    const handleClickExcluirExtra = () => {
        excluirExtra_Mock(viagem.id, despesa.id)
        setOpenDeleteExtra(false)
        setCurrentTab(1)
        enqueueSnackbar('Removido com sucesso.', { variant: 'success' });
        reload()
    }

    const handleValorChange = (event) => {
        const valorInserido = event.target.value;
        // Formata o valor
        const valorFormatado = formatarMoeda(valorInserido);
        // Atualiza o estado com o valor formatado
        setTotalgasto(valorFormatado);
    };

    const handleValorChangeAuditar = (event) => {
        const valorInserido = event.target.value;
        // Formata o valor
        const valorFormatado = formatarMoeda(valorInserido);
        // Atualiza o estado com o valor formatado
        setTotalAuditar(valorFormatado);
    };

    const handleClickInvalidViagem = async (ivalid = true) => {
        try {
            // Validação para a justificativa
            if (justifyInvalid.trim().length < 5) {
                enqueueSnackbar('A justificativa deve ter pelo menos 5 caracteres.', { variant: 'warning' });
                return;
            }

            let model = {
                idviagem: viagem.id,
                iddespesa: despesa.iddespesa,
                justificativa: justifyInvalid, // Utilize a justificativa informada pelo usuário
                is_invalido: ivalid
            }

            let list = [despesa.iddespesa]
            await prestacaoDeContasRepositorio.putCorrigirDespesa(model);
            await prestacaoDeContasRepositorio.postAprovarDespesas(viagem.id, list)
            setOpenInvalidar(false)
            getDespesas()
            getViagensReload()
            enqueueSnackbar('Valor editado com sucesso.', { variant: 'success' });
        } catch {
            enqueueSnackbar('Ocorreu um erro ao auditar a despesa.', { variant: 'error' });
        } finally {
            setLoading(false);
        }
    }

    const handleClickAprovarViagem = async () => {
        setLoading(true)
        try {
            await prestacaoDeContasRepositorio.postAvancarViagem(viagem.id)
            getDespesas()
            getViagensReload()
            setOpen(false)
            enqueueSnackbar('Avançada para o financeiro com sucesso.', { variant: 'success' });
        } catch {
            enqueueSnackbar('Ocorreu um erro ao tentar avançar a viagem.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    const handleClickRetornarDespesaComMensagem = async () => {
        setLoading(true)
        try {
            despesa.mensagem = descricao
            await prestacaoDeContasRepositorio.putEnviarParaPessoa(viagem.id, [despesa])
            getDespesas()
            getViagensReload()
            setOpenReturnDespesa(false)
            enqueueSnackbar('Enviado para pessoa com sucesso.', { variant: 'success' });
        } catch {
            enqueueSnackbar('Ocorreu um erro ao enviar despesa para pessoa.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    const handleClickControladoriaDespesa = async () => {
        setLoading(true)
        try {
            await prestacaoDeContasRepositorio.putEnviarControladoria(viagem.id, despesa.iddespesa, descricao)
            getDespesas()
            getViagensReload()
            setOpenReturnDespesa(false)
            enqueueSnackbar('Enviado para controladoria com sucesso.', { variant: 'success' });
        } catch {
            enqueueSnackbar('Ocorreu um erro enviar para controladoria.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    const getHistorico = async () => {
        setLoading(true)
        try {
            // await prestacaoDeContasRepositorio.getHistoricoDespesas(viagem.id)
        } catch {
            enqueueSnackbar('Ocorreu um erro ao buscar histórico de despesas.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        let c = 0;
        viagem?.extras?.forEach(extra => {
            if (!extra.add) {
                c += 1;
            }
        })
        setCount(c)
    }, [viagem])

    useEffect(() => {
        getDespesas()
    }, [])

    useEffect(() => {
        agruparDespesasPorTipo()
    }, [despesas, categorias, agruparDespesasPorTipo]);

    return (
        <>
            <ULoaderVoo isLoading={loading} />

            <DialogComponent
                title={
                    <>
                        Informações do QrCode
                    </>
                }
                open={openQrCodeView}
                handleClose={() => setOpenQrCodeView(false)}
                body={
                    <ViewQrCodeProducts QrCode={despesa?.qrCode} />
                }
                action={
                    <></>
                }
                width={"xs"}
            />
            {despesa?.hodometro != null ? (
                <DialogComponent
                    title={
                        <>
                            Informações do Hodômetro
                        </>
                    }
                    open={openInfoHodometro}
                    handleClose={() => setOpenInfoHodometro(false)}
                    body={
                        <ViewHodometerExpansesComponent despesa={despesa} />
                    }
                    action={
                        <></>
                    }
                    width={"sm"}
                />
            ) : (null)}
            <DialogEditDespesaAudit
                despesa={despesa}
                openAuditar={openAuditar}
                setOpenAuditar={setOpenAuditar}
                totalGasto={totalGasto}
                totalAuditar={totalAuditar}
                handleValorChange={handleValorChange}
                handleValorChangeAuditar={handleValorChangeAuditar}
                handleClickAuditar={handleClickAuditar}
            />
            <DialogComponent
                title={
                    <>
                        Mensagem
                    </>
                }
                open={openReturnDespesa}
                handleClose={() => setOpenReturnDespesa(false)}
                body={
                    <Grid container item xs={12}>
                        <Typography style={{ marginTop: "15px", marginBottom: "15px" }}>Envie a descrição do erro dessa despesa para a controladoria ou de volta para a pessoa</Typography>
                        <TextField
                            id="filled-multiline-flexible"
                            variant="outlined"
                            fullWidth
                            required
                            defaultValue={despesa?.mensagem}
                            onChange={(e) => setDescricao(e.target.value)}
                            disabled={despesa?.mensagem !== "" ? true : false}
                            multiline
                            maxRows={4}
                            label="Justificativa/Descrição"
                            placeholder="Tem algo de errado com..."
                        />
                    </Grid>
                }
                action={
                    <Grid container item xs={12} style={{ display: "flex", textAlign: "right", justifyContent: "right" }}>
                        {despesa?.mensagem !== "" ? (
                            <>
                            </>
                            // <Button color="error" onClick={() => handleClickCancelarEnvioDeMensagem(despesa?.id)}>Cancelar</Button>
                        ) : (
                            <>
                                <Button color="warning" disabled={descricao !== "" ? false : true} onClick={() => handleClickControladoriaDespesa(despesa?.id)}>Controladoria</Button>
                                <Button disabled={descricao !== "" ? false : true} onClick={() => handleClickRetornarDespesaComMensagem(despesa?.id)}>Pessoa</Button>
                            </>
                        )}
                    </Grid>
                }
                width={"xs"}
            />

            <DialogInvalidDespesa
                despesa={despesa}
                openInvalidar={openInvalidar}
                setOpenInvalidar={setOpenInvalidar}
                handleClickInvalidViagem={handleClickInvalidViagem}
                setJustifyInvalid={setJustifyInvalid}
            />

            <Grid container item xs={12} style={{ width: "100%" }}>
                <Grid item xs={12} container style={{ textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Grid item xs={12} container style={{ marginTop: "0px", maxWidth: 645 }}>
                        <Grid item xs={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                            <IniciadoEm datainicio={viagem.datainicio} />
                        </Grid>
                        <Grid item xs={6} style={{ display: "flex", justifyContent: "flex-end", }}>
                            <Grid style={{ marginLeft: "auto" }}>
                                <CreationDate data={viagem.datadecriacao} />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: "center", display: "flex", justifyContent: "center" }}>
                            <DeskCardPersonExpanses viagem={viagem} despesas={despesas} />
                        </Grid>
                    </Grid>
                    {conciliacao.length > 0 ? (
                        <Grid item xs={12} style={{ justifyContent: "center", alignContent: "center", textAlign: "center", marginBottom: "0px" }}>
                            <ButtonGroup variant="outlined" color="inherit" style={{ justifyContent: "center", borderColor: "#D6D6D6" }}>
                                {[{ value: 1, label: "Despesas" }, { value: 2, label: "Conciliação" }].map((tab) => (
                                    <Button
                                        key={tab.value}
                                        onClick={() => handleChangeTab(tab.value)}
                                        style={{
                                            textTransform: "none", // Mantém a capitalização original do texto
                                            fontWeight: currentTab === tab.value ? 600 : 400, // Destaca o botão ativo
                                            borderColor: "#D6D6D6"
                                        }}
                                    >
                                        <Typography variant="h6" style={{ fontSize: "15px", fontWeight: currentTab === tab.value ? 600 : 400 }}>
                                            {tab.label}
                                        </Typography>
                                    </Button>
                                ))}
                            </ButtonGroup>
                        </Grid>
                    ) : (null)}
                    <Grid item xs={12} style={{ padding: "20px", gap: "20px", display: "flex", justifyContent: "right", padding: "0px", marginBottom: "-30px", marginTop: "10px" }}>
                        <Button
                            onClick={() => setIsExpanded(!isExpanded)}
                            sx={{
                                backgroundColor: isExpanded ? "#1C252E" : "#FFFFFF",
                                color: isExpanded ? "#FFFFFF" : "#1C252E",
                                borderRadius: "8px",
                                transition: "transform 0.2s ease-in-out",
                                "&:hover": {
                                    backgroundColor: isExpanded ? "#181E24" : "#F5F5F5",
                                    transform: "scale(1.05)",
                                },
                            }}
                        >
                            {isExpanded ? (
                                <>
                                    <Fullscreen sx={{ mr: 1, color: "inherit" }} />
                                    Modo Expandido
                                </>
                            ) : (
                                <>
                                    <FullscreenExit sx={{ mr: 1, color: "inherit" }} />
                                    Modo Contraído
                                </>
                            )}
                        </Button>
                        <ButtonGroup
                            aria-label="Layout selection"
                            sx={{
                            }}
                        >
                            {/* Botão: List */}
                            <Button
                                onClick={() => setView("list")}
                                sx={{
                                    backgroundColor: view === "list" ? "#1C252E" : "#FFFFFF",
                                    color: view === "list" ? "#FFFFFF" : "#1C252E",
                                    borderColor: "#1C252E",
                                    borderRadius: "8px 0 0 8px", // arredonda só o lado esquerdo
                                    // se quiser remover o efeito de hover default
                                    "&:hover": {
                                        backgroundColor: view === "list" ? "#1C252E" : "#F5F5F5",
                                    },
                                }}
                            >
                                <ViewList style={{ color: "inherit" }} />
                            </Button>

                            {/* Botão: Module */}
                            <Button
                                onClick={() => setView("module")}
                                sx={{
                                    backgroundColor: view === "module" ? "#1C252E" : "#FFFFFF",
                                    color: view === "module" ? "#FFFFFF" : "#1C252E",
                                    borderColor: "#1C252E",
                                    borderRadius: "0 8px 8px 0", // arredonda só o lado direito
                                    "&:hover": {
                                        backgroundColor: view === "module" ? "#1C252E" : "#F5F5F5",
                                    },
                                }}
                            >
                                <ViewModule style={{ color: "inherit" }} />
                            </Button>
                        </ButtonGroup>
                    </Grid>

                    {currentTab === 1 && (
                        <Container style={{ marginTop: "20px", padding: "0px", marginBottom: "70px" }}>
                            {despesasAgrupadas?.length > 0 ? (
                                <Grid item xs={12} container style={{ FlexDirection: "column" }}>
                                    {despesasAgrupadas.map((grupo, indexGrupo) => (
                                        <Grid container item xs={12} key={indexGrupo} style={{ gap: "20px", display: "flex", justifyContent: "center", marginTop: "20px" }}>
                                            <Box style={{ width: "100%" }}>
                                                <Typography variant="subtitle1">{grupo.titulo}</Typography>
                                            </Box>
                                            <Box
                                                gap={2}
                                                display="grid"
                                                gridTemplateColumns={
                                                    (isExpanded) ? {
                                                        xs: 'repeat(1, 1fr)',
                                                        sm: 'repeat(1, 1fr)',
                                                        md: view === 'list' ? 'repeat(2, 1fr)' : 'repeat(4, 1fr)',
                                                    } : {
                                                        xs: 'repeat(1, 1fr)',
                                                        sm: 'repeat(2, 1fr)',
                                                        md: view === 'list' ? 'repeat(1, 1fr)' : 'repeat(2, 1fr)',
                                                    }}
                                                style={view === "list" ? { gap: 20 } : { gap: 10 }}
                                            >
                                                {grupo.despesas.map((item, indexDespesa) => (
                                                    <>
                                                        <CardDespesaAprove
                                                            despesa={item}
                                                            view={view}
                                                            indexDespesa={indexDespesa}
                                                            grupo={grupo}
                                                            setTotalgasto={setTotalgasto}
                                                            handleOpenEdit={handleOpenEdit}
                                                            handleClickOpenInvalid={handleClickOpenInvalid}
                                                            handleClickOpenQrCode={handleClickOpenQrCode}
                                                            toggleSelecaoDespesa={toggleSelecaoDespesa}
                                                            handleClickAuditar={handleClickAuditar}
                                                            handleClickOpenHodometro={handleClickOpenHodometro}
                                                            handleClickReturn={handleClickReturn}
                                                            handleClickControladoriaDespesa={handleClickControladoriaDespesa}
                                                            handleClickRetornarDespesaComMensagem={handleClickRetornarDespesaComMensagem}
                                                        />
                                                    </>
                                                ))}
                                            </Box>

                                        </Grid>
                                    ))}
                                </Grid>
                            ) : (
                                <>

                                </>
                            )}
                        </Container>
                    )}
                </Grid>
                {currentTab === 2 && (
                    <div style={{ marginBottom: "40px" }}>
                        <Grid item xs={12} marginBottom={5}>
                            <ExtraAddComponent viagem={viagem} />
                        </Grid>
                        <DialogComponent
                            title={
                                <>
                                    Excluir extra?
                                </>
                            }
                            open={openDeleteExtra}
                            handleClose={() => setOpenDeleteExtra(false)}
                            body={
                                <Grid container item xs={12} width={280}>
                                    <Typography>Deseja realmente excluir o extra?</Typography>
                                </Grid>
                            }
                            action={
                                <Grid>
                                    <Button onClick={() => handleClickExcluirExtra()}>Confirmar</Button>
                                </Grid>
                            }
                            width={"sm"}
                        />
                        <Grid container item xs={12}
                            gap={2}
                            display="grid"
                            gridTemplateColumns={
                                (isExpanded) ? {
                                    xs: 'repeat(1, 1fr)',
                                    sm: 'repeat(1, 1fr)',
                                    md: view === 'list' ? 'repeat(2, 1fr)' : 'repeat(4, 1fr)',
                                } : {
                                    xs: 'repeat(1, 1fr)',
                                    sm: 'repeat(2, 1fr)',
                                    md: view === 'list' ? 'repeat(1, 1fr)' : 'repeat(2, 1fr)',
                                }}
                            style={view === "list" ? { gap: 20, marginBottom: "40px" } : { gap: 10, marginBottom: "40px" }}
                        >
                            {conciliacao?.map((value) => (
                                <>
                                    <Grid
                                        item xs={12}
                                        container
                                        style={{
                                            marginTop: "5px",
                                            padding: "20px",
                                            transition: 'background-color 0.5s ease', // Suaviza a transição do efeito de escurecimento
                                            cursor: 'default', // Cursor padrão quando não está em hover
                                            backgroundColor: "#FFFFFF",
                                            textAlign: 'center',
                                            borderRadius: "10px",
                                            boxShadow: "0px 0px 8px 1px rgba(0,0,0,0.13)",
                                        }}
                                    >
                                        <Grid container item xs={12}>
                                            <Grid container item xs={6} style={{ display: "flex", flexDirection: "column", textAlign: "justify", justifyContent: "center" }}>
                                                <Typography style={{ fontWeight: "600" }}>{value.hodometro ? ("hodômetro") : ("Gasto")}</Typography>
                                            </Grid>
                                            <Grid item xs={6} style={{ display: "flex", textAlign: "right", justifyContent: "right" }}>
                                                {/* <IconButton onClick={() => { setOpenDeleteExtra(true); setDespesa(value); }}>
                                                    <DeleteOutline />
                                                </IconButton> */}
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} style={{ textAlign: "left", marginTop: "8px" }}>
                                            <Typography style={{ fontWeight: "500", color: "#595959" }}>{value.descricao}</Typography>
                                        </Grid>
                                        <Grid item xs={12} style={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            alignContent: "flex-start",
                                            alignItems: "flex-start",
                                            flexDirection: "row"
                                        }}>
                                            {getTipoDespesaForNumber(value.tipo, value.produtos.value)}
                                        </Grid>
                                        <Grid item xs={9} style={{ display: "flex", flexDirection: "column", textAlign: "left", justifyContent: "left" }}>
                                            <CreationDate data={value.datacriacao} />
                                        </Grid>
                                        <Grid item xs={3} style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignContent: "flex-end",
                                            alignItems: "flex-end",
                                            flexDirection: "row"
                                        }}>
                                            <Typography style={{ fontWeight: "600", color: "rgba(0, 0, 0, 0.87)" }}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value.total)}</Typography>
                                        </Grid>
                                    </Grid>
                                </>
                            ))}
                        </Grid>
                    </div>
                )}
                {despesasAgrupadas?.length > 0 ? (
                    <Grid
                        container
                        item
                        xs={12}
                        style={{
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                            height: "80px",
                            padding: 20,
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",   // Opcional: ajuste conforme sua necessidade
                            backgroundColor: "#fff", // Opcional: para garantir fundo visível
                            zIndex: 0,     // Para ficar acima de outros elementos (se necessário)
                        }}
                    >
                        <Box
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                                flexDirection: "row",
                                marginTop: "10px"
                            }}
                        >
                            <Typography style={{ fontWeight: 600, fontSize: "14px" }} color="primary">
                                Selecionar Todos
                            </Typography>
                            <Checkbox
                                color="primary"
                                checked={marcarTodas}
                                onChange={toggleSelecaoTodasDespesas}
                            />
                        </Box>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={totalSelecionadas > 0 ? false : true}
                            onClick={() => handleClickAprovar()}
                        >
                            Aprovar
                        </Button>
                    </Grid>

                ) : (
                    <>
                        {!loading && despesasAgrupadas !== null && despesasAgrupadas?.length === 0 && (
                            <Grid container item xs={12} style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px" }}>
                                <Button variant="contained" color="primary" fullWidth onClick={() => handleClickAprovarViagem()}>Avançar para o financeiro</Button>
                            </Grid>
                        )}
                    </>
                )}
            </Grid >
        </>
    )
}