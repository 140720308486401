import { makeStyles } from '@material-ui/core/styles';
// import imagemDesk from "../../images/BackgroundImages/login_com_titulo_1920x1080.svg"
// import imagemDesk from "../../images/BackgroundImages/login_1927x1080.png"
// import Fundo from '../../images/fundoTeste.svg';
export const useStyles = makeStyles(theme => ({
    root: {
        width: '100vw',
        height: '100vh',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    'svg': {
        width: '100px',
        height: '100px'
    },
    textfield: {
        width: '90%',
        background: 'linear-gradient',
        color: 'white'
    },
    box: {
        width: 400,
        height: 400,
        background: 'rgba(0,0,0,0.40)',
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: "15px",
        justifyContent: 'center',
        '@media (max-width:480px)': {
            width: '90%',
        },
    },
    button: {
        marginTop: theme.spacing(2),
        width: '90%',
        height: 45,
        color: '#FFFFFF',
        background: 'transparent',
        border: 'solid 1px #FFFFFF',
        '&:hover': {
            border: 'solid 1px #FFFFFF',
        },
        '&.MuiButton-root:hover.Mui-disabled': {
            border: 'solid 1px #FFFFFF',

        }
    },
    btn: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%'
    },
    progress: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(14),
        position: 'absolute',
        zIndex: 100,
        color: '#ffffff',
        width: '20px',
        height: '20px',

    }
}));
