import React, { useState } from 'react';
import { Box, Typography, Paper, Avatar, Stack, Grid } from '@mui/material';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { CsvIcon, FileIcon, ImageIcon, PdfIcon } from '../../images/Icons/archives';

const formatBytes = (bytes) => {
    if (bytes === 0) return '0 B';
    const k = 1024;
    const sizes = ['B', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
};

const getFileIcon = (ext) => {
    switch (ext) {
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'gif':
            return <img src={ImageIcon} alt="Imagem" width={40} height={40} />;
        case 'pdf':
            return <img src={PdfIcon} alt="PDF" width={40} height={40} />;
        case 'csv':
            return <img src={CsvIcon} alt="CSV" width={40} height={40} />;
        default:
            return <img src={FileIcon} alt="Arquivo" width={40} height={40} />;
    }
};

const FilePreview = ({ file }) => {
    const ext = file.name.split('.').pop().toLowerCase();
    const fileSize = formatBytes(file.size);
    const uploadDate = new Date().toLocaleString('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    });

    return (
        <Grid
            style={{
                display: 'flex',
                alignItems: 'center',
                padding: "10px",
                gap: "20px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: "16px",
            }}
        >
            <Avatar
                sx={{
                    bgcolor: '#34c7591a',
                    color: '#34c759',
                    width: 56,
                    height: 56,
                }}
            >
                {getFileIcon(ext)}
            </Avatar>

            <Box sx={{ display: 'flex', flexDirection: "column", alignItems: 'left', gap: 1, overflow: 'hidden' }}>
                <Typography
                    fontWeight={600}
                    noWrap
                    sx={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 200 }}
                >
                    {file.name}
                </Typography>

                <Typography variant="body2" color="text.secondary" noWrap>
                    {fileSize} · {uploadDate}
                </Typography>
            </Box>
        </Grid>
    );
};

export default FilePreview;
