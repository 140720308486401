import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ShareLocationIcon from '@mui/icons-material/ShareLocation';
import { Button, Container, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DialogGoogleMaps } from "../../../../../../components/autalii_expanses/dialogs/dialog_MobileMaps";
import LocationComponent from "./CheckForGoogle";
import { GoogleMapsMComponent } from "./GoogleMaps";
import { ManualMComponent } from "./Manual";

export const HodometroComponentM = ({ viagem, setType, handleClickCreateHodometro }) => {
  const [manual, setManual] = useState(0)
  const [acessLoc, setAcessLoc] = useState(false)

  useEffect(() => {
    setAcessLoc(acessLoc)
  }, [acessLoc])

  return (
    <>
      {manual === 0 ? (
        <>
          <Grid container item xs={12} style={{ justifyContent: "left", display: "flex", flexDirection: "row", alignContent: "flex-start", alignItems: "center", paddingLeft: "15px" }}>
            <Grid item xs={6} style={{ marginTop: "-20px" }}>
              <Button
                size="large"
                onClick={() => setType(0)}
                startIcon={<ArrowBackIosNewIcon />}
                color="primary"
              >
                Voltar
              </Button>
            </Grid>
          </Grid>
          <Container maxWidth={"sm"} style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}>
            <p style={{ color: "#637381", fontFamily: "sans-serif", fontSize: "14px", textAlign: "center" }}>Hodômetro pode ser realizado de forma manual ou utilizar GPS</p>
            <Grid item xs={12} style={{ height: "70vh", width: 350 }}>
              <Button size="large" onClick={() => setManual(1)} startIcon={<AssignmentIcon />} variant="contained" color="secondary" fullWidth>Adicionar Manualmente</Button>
              <Button size="large" onClick={() => setManual(2)} startIcon={<ShareLocationIcon />} variant="contained" color="primary" fullWidth>Adicionar com GPS</Button>
            </Grid>
          </Container>
        </>
      ) : (null)}
      {manual === 1 ? (
        <>
          <Grid container item xs={12} style={{ justifyContent: "left", display: "flex", flexDirection: "row", alignContent: "flex-start", alignItems: "center", paddingLeft: "15px" }}>
            <Grid item xs={6} style={{ marginTop: "-20px" }}>
              <Button
                size="large"
                onClick={() => setType(0)}
                startIcon={<ArrowBackIosNewIcon />}
                color="info"
              >
                Voltar
              </Button>
            </Grid>
          </Grid>
          <Grid container item xs={12} style={{ justifyContent: "center", display: "flex", flexDirection: "row", alignContent: "center", alignItems: "center" }}>
            <ManualMComponent idviagem={viagem.id} setType={setType} handleClickCreateHodometro={handleClickCreateHodometro} />
          </Grid>
        </>
      ) : (
        null
      )}
      {manual === 2 ? (
        <>
          {acessLoc ? (
            <DialogGoogleMaps
              body={
                <GoogleMapsMComponent idviagem={viagem.id} setType={setType} handleClickCreateHodometro={handleClickCreateHodometro} />
              }
              width={"md"}
              open={true}
              handleClose={() => setType(0)}
            />
          ) : (
            <>
              <Grid container item xs={12} style={{ justifyContent: "left", display: "flex", flexDirection: "row", alignContent: "flex-start", alignItems: "center", paddingLeft: "15px" }}>
                <Grid item xs={6} style={{ marginTop: "-20px" }}>
                  <Button
                    size="large"
                    onClick={() => setType(0)}
                    startIcon={<ArrowBackIosNewIcon />}
                    color="primary"
                  >
                    Voltar
                  </Button>
                </Grid>
              </Grid>
              <LocationComponent onLocationChange={setAcessLoc} setType={setType} />
            </>
          )}
        </>
      ) : (
        null
      )}

    </>
  );
}

