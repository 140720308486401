import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { useState } from "react";
import { formatarParaMoedaString, formatarParaNomeStatusAdiantamento, formatarTotalDespesasInternacional, formatarValorComMoeda } from "../../../enums/enumPrestacaoDeContas";
import TransferComponent from "../card_transfer";

export const CardSaldo_desk = ({
    viagem
}) => {
    const [showBalance, setShowBalance] = useState(true);
    const despesas = [];

    const handleToggleBalance = () => {
        setShowBalance(!showBalance);
    };

    return (
        <div style={{ width: "-webkit-fill-available" }} className="total">
            {!viagem.tem_multimonetario ? (
                <>
                    {viagem.totalinicial === 0 ? (
                        <>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: "-14px" }}>
                                <Typography variant="body2" sx={{ fontWeight: "600", color: '#393939', display: "flex" }}>
                                    Total
                                </Typography>
                            </Box>
                            <Typography variant="h6" style={{ fontWeight: "600", color: '#262626', fontSize: "18px", textAlign: "left" }}>
                                {showBalance ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(viagem.total) : '***'}
                                <IconButton onClick={handleToggleBalance} size="small" style={{ marginTop: "-3px" }}>
                                    {showBalance ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </Typography>
                        </>
                    ) : (
                        <Box style={{ width: "100%", display: "flex" }}>
                            <Box style={{ width: "-webkit-fill-available", paddingLeft: "10px" }}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: "-14px" }}>
                                    <Typography variant="h6" style={{ fontWeight: "600", color: '#262626', fontSize: "20px", textAlign: "left", display: "flex" }}>
                                        <div style={{ display: "flex" }}>
                                            {showBalance ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(viagem.totalinicial - viagem.total) : '***'}
                                        </div>
                                        <IconButton onClick={handleToggleBalance} size="small" style={{ marginTop: "-3px" }}>
                                            {showBalance ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: "2px" }}>
                                    <Typography variant="body2" sx={{ fontWeight: "600", color: '#393939' }}>
                                        Saldo Atual
                                    </Typography>
                                </Box>
                            </Box>
                            <Box style={{ width: "100%", textAlign: "end" }}>
                                <Typography variant="h6">
                                    {formatarParaNomeStatusAdiantamento(viagem.adiantamento[0]?.status)}
                                </Typography>
                            </Box>
                        </Box>
                    )}
                </>
            ) : (
                <>
                    {viagem.adiantamento.length === 1 ? (
                        <Box style={{ width: "100%", display: "flex" }}>
                            <Box style={{ width: "-webkit-fill-available", paddingLeft: "10px" }}>
                                <Typography variant="caption" color="textSecondary">{formatarParaMoedaString(viagem.adiantamento[0].tipo_moeda, viagem.adiantamento[0].total)}</Typography>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: "-14px" }}>
                                    <Typography variant="h6" style={{ fontWeight: "600", color: '#262626', fontSize: "20px", textAlign: "left", display: "flex" }}>
                                        <div style={{ display: "flex" }}>
                                            {showBalance ? formatarValorComMoeda(viagem.adiantamento[0].tipo_moeda, viagem.adiantamento[0].total - formatarTotalDespesasInternacional(viagem.adiantamento[0].tipo_moeda, despesas)) : '***'}
                                        </div>
                                        <IconButton onClick={handleToggleBalance} size="small" style={{ marginTop: "-3px" }}>
                                            {showBalance ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: "2px" }}>
                                    <Typography variant="body2" sx={{ fontWeight: "600", color: '#393939' }}>
                                        Saldo Atual
                                    </Typography>
                                </Box>
                            </Box>
                            <Box style={{ width: "100%", textAlign: "end" }}>
                                <Typography variant="h6">
                                    {formatarParaNomeStatusAdiantamento(viagem.adiantamento[0]?.status)}
                                </Typography>
                            </Box>
                        </Box>
                    ) : (
                        <Grid item xs={12} sx={{
                            position: 'fixed', left: 0, top: 0, zIndex: -1,
                            height: "-webkit-fill-available",
                            flexDirection: "row",
                            paddingLeft: "2%",
                            width: "inherit",
                            display: "flex",
                            alignItems: "flex-end",
                            justifyContent: "flex-end",
                            padding: "20px"
                        }}>
                            <Grid item xs={12} style={{ backgroundColor: "#FFFFFF", borderRadius: "15px", maxWidth: "350px", minWidth: "330px", padding: "20px" }}>
                                {viagem.adiantamento?.map((item) => (
                                    <Grid item xs={12} style={{ marginTop: "10px" }}>
                                        <TransferComponent viagem={viagem} despesas={despesas} totalex={item.total} totalbrl={item.total_brl} moeda={item.tipo_moeda} status={item.status} />
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    )}
                </>
            )}
        </div>
    );
};
