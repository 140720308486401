import { Card, Divider, Stack } from "@mui/material"
import Scrollbar from "../../scrollbar"
import InvoiceAnalytic from "../../../views/prestacaodecontas/Desktop/sections/custom-totals"
import { Paid, PendingActions, PointOfSale, ReceiptLong } from "@mui/icons-material"
import { memo } from "react"

export const ResumeAllDespesas = memo(({ data }) => {
    const getInvoiceLength = (status, fp = false) => {
        if (status.includes(0) && !fp) {  // Considerando '0' como o valor default para todos os status
            return data.length;
        } else if (fp) {
            return data.filter((item) => item.necessita_atencao).length;
        } else {
            return data.filter((item) => status.includes(item.status)).length;
        }
    }

    const getTotalAmount = (statuses) => {
        if (statuses.includes(0)) {  // Considerando '0' como o valor default para todos os status
            return data.reduce((acc, item) => acc + item.total, 0);
        }
        return data
            .filter(item => statuses.includes(item.status))
            .reduce((acc, item) => acc + item.total, 0);
    };

    return (
        <>
            <Card
                sx={{
                    mb: { xs: 3, md: 5 },
                }}
            >
                <Scrollbar>
                    <Stack
                        direction="row"
                        divider={<Divider orientation="vertical" flexItem sx={{ borderStyle: 'dashed' }} />}
                        sx={{ py: 2 }}
                    >
                        <InvoiceAnalytic
                            title="Total"
                            total={getInvoiceLength([0])}
                            percent={100}
                            price={getTotalAmount([0])}
                            icon={<ReceiptLong color="secondary" sx={{ position: 'absolute' }} />}
                            color="secondary"
                        />

                        <InvoiceAnalytic
                            title="Finalizado"
                            total={getInvoiceLength([5])}
                            percent={100}
                            price={getTotalAmount([5])}
                            icon={<Paid color="success" sx={{ position: 'absolute' }} />}
                            color="success"
                        />

                        <InvoiceAnalytic
                            title="Financeiro"
                            total={getInvoiceLength([4])}
                            percent={100}
                            price={getTotalAmount([4])}
                            icon={<Paid color="primary" sx={{ position: 'absolute' }} />}
                            color="primary"
                        />

                        <InvoiceAnalytic
                            title="Controladoria"
                            total={getInvoiceLength([3])}
                            percent={100}
                            price={getTotalAmount([3])}
                            icon={<PointOfSale color="warning" sx={{ position: 'absolute' }} />}
                            color="warning"
                        />

                        <InvoiceAnalytic
                            title="Pendente"
                            total={getInvoiceLength([2, 8, 9, 10])}
                            percent={100}
                            price={getTotalAmount([2, 8, 9, 10])}
                            icon={<PendingActions color="info" sx={{ position: 'absolute' }} />}
                            color="info"
                        />
                    </Stack>
                </Scrollbar>
            </Card>
        </>
    )
})