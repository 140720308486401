import { Button, Grid, IconButton, Skeleton, TextField, Typography } from "@mui/material"
import { EnumTipo, hasApprovedAdiantamento } from "../../../../../../enums/enumPrestacaoDeContas"
import DialogComponent from "../../../../Desktop/sections/dialogs-desktop"
import { useEffect, useState } from "react";

import dayjs from "dayjs";
import { Delete, SaveOutlined } from "@mui/icons-material";
import { CardButtonEditDate_mob } from "../../../../../../components/autalii_expanses/card_editDate";

export const DespesaBar = ({
    viagem,
    temDespesa,
    handleClickEditViagem,
    handleClickExcluirViagem
}) => {
    const [openDeleteViagem, setOpenDeleteViagem] = useState(false);
    const [startDate, setStartDate] = useState(dayjs(viagem?.datainicio || new Date()).locale('pt-br'));
    const [endDate, setEndDate] = useState(dayjs(viagem?.datafim || new Date()).locale('pt-br'));
    const [titulo, setTitulo] = useState("");
    const [loading, setLoading] = useState(false);
    const [showDelet, setShowDelet] = useState(false);
    const [alterTitle, setAlterTitle] = useState(false);
    // Função auxiliar para verificar se o botão de excluir deve ser mostrado
    const shouldShowDeleteButton = async () => {
        setLoading(true)
        try {
            const noDespesaOrAdiantamento = !temDespesa && !hasApprovedAdiantamento(viagem?.adiantamento);
            const isNotStartedOrInTravel = viagem?.status === 0 || viagem?.status === EnumTipo.viagemstatus.emviagem;
            setShowDelet(noDespesaOrAdiantamento || (isNotStartedOrInTravel && viagem?.adiantamento?.length === 0))
        } catch {
        } finally {
            setLoading(false)
        }
    };

    const onClickDelete = (id) => {
        setOpenDeleteViagem(false)
        handleClickExcluirViagem(id)
    }

    const confirmEditDates = () => {
        setAlterTitle(false)
        handleClickEditViagem(titulo, startDate, endDate);
    }

    const handleChangenameViagem = (e) => {
        setTitulo(e.target.value)
        setAlterTitle(true)
    }

    useEffect(() => {
        shouldShowDeleteButton()
    }, [shouldShowDeleteButton, viagem])

    return (
        <>
            {loading ? (
                <>
                    <Skeleton variant="rectangular" width="90%" height={40} style={{ borderRadius: "16px" }} />
                </>
            ) : (
                <>
                    <DialogComponent
                        title={viagem?.titulo}
                        open={openDeleteViagem}
                        handleClose={() => setOpenDeleteViagem(false)}
                        body={
                            <Grid container item xs={12}>
                                <Typography>
                                    {temDespesa ? "Delete todas as despesas da sua viagem antes!" : "Deseja realmente deletar essa viagem?"}
                                </Typography>
                            </Grid>
                        }
                        action={
                            <>
                                {!temDespesa &&
                                    (
                                        <>
                                            <Button size="large" onClick={() => setOpenDeleteViagem(false)}>Cancelar</Button>
                                            <Button size="large" onClick={() => onClickDelete(viagem.id)}>Confirmar</Button>
                                        </>
                                    )
                                }
                            </>
                        }
                        width={"sm"}
                    />
                    {viagem.status === EnumTipo.viagemstatus.aguardandoinicio || viagem.status === EnumTipo.viagemstatus.emviagem ? (
                        <Grid container item xs={12}>
                            <Grid item xs={7}>
                                <TextField
                                    size="large"
                                    variant="standard"
                                    fullWidth
                                    defaultValue={viagem.titulo}
                                    onChange={(e) => handleChangenameViagem(e)}
                                    style={{ borderRadius: "15px" }}
                                    className="titulo_mob"
                                />
                            </Grid>
                            <Grid item xs={5} style={{ display: "flex", justifyContent: "center" }}>
                                {showDelet && (
                                    <IconButton
                                        className="excluir_mob"
                                        size="large"
                                        variant="contained"
                                        color="error"
                                        onClick={() => setOpenDeleteViagem(true)}
                                    >
                                        <Delete size="large" />
                                    </IconButton>
                                )}
                                <CardButtonEditDate_mob actionButton={confirmEditDates} startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />
                            </Grid>
                            {alterTitle && (
                                <Grid item xs={12}>
                                    <Button
                                        size="large"
                                        variant="outlined"
                                        fullWidth
                                        startIcon={<SaveOutlined />}
                                        onClick={confirmEditDates}
                                    >
                                        Salvar
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                    ) : (
                        <>
                            <Typography style={{ fontWeight: "600" }}>{viagem.titulo}</Typography>
                        </>
                    )}
                </>
            )
            }
        </>
    )
}