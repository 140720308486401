import { Grid, Typography } from "@mui/material"
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import AccountCircleIcon from '@mui/icons-material/AccountCircle'; 
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

export const IniciadoEm = ({ datainicio, type }) => {
    const hoje = new Date();
    const inicio = new Date(datainicio);
    const diferencaEmMilisegundos = inicio - hoje; 
    const diferencaEmDias = diferencaEmMilisegundos / (1000 * 3600 * 24);
    const diferencaEmSemanas = diferencaEmDias / 7;
    const diferencaEmMeses = diferencaEmDias / 30; // Aproximação simples

    let mensagem;

    if (diferencaEmMilisegundos < 0) {
        // Já começou
        mensagem = "Criado há " +
            (Math.abs(diferencaEmMeses) >= 1 ? Math.floor(Math.abs(diferencaEmMeses)) + " mês(es)" :
                Math.abs(diferencaEmSemanas) >= 1 ? Math.floor(Math.abs(diferencaEmSemanas)) + " semana(s)" :
                    Math.floor(Math.abs(diferencaEmDias)) + " dia(s)");
    } else {
        // Ainda não começou
        mensagem = "Criado em " +
            (diferencaEmMeses >= 1 ? Math.floor(diferencaEmMeses) + " mês(es)" :
                diferencaEmSemanas >= 1 ? Math.floor(diferencaEmSemanas) + " semana(s)" :
                    Math.ceil(diferencaEmDias) + " dia(s)");
    }

    return (  
        <Grid item xs={12} style={{ display: "flex", fontSize: "7px" }}>
            <AccessTimeFilledIcon style={{ color: "#919EAB", marginRight: "6px", width: "20px" }} />
            <Typography style={{ color: "#919EAB", fontSize: "15px" }}>{mensagem}</Typography>
        </Grid>
    );
};

export const CriadoPor = (tipo) => {
    return (
        <Grid item xs={12} style={{ display: "flex", fontSize: "7px", justifyContent: "flex-start", alignItems: "flex-end" }}>
            <AccountCircleIcon style={{ color: "#919EAB", marginRight: "6px", width: "20px" }} />
            <Typography style={{ color: "#919EAB", fontSize: "15px" }}>Usuario</Typography>
        </Grid>
    )
}

export const CreationDate = ({ data }) => {
    const date = new Date(data);
    const formattedDate = date.toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
    }).replace('', '');

    // Remover ' AM' ou ' PM' do resultado final, se estiver presente
    const finalDate = formattedDate.replace(/ AM| PM/gi, '');

    return (
        <Grid item xs={12} style={{ fontSize: "7px" }}>
            <Typography style={{ color: "#919EAB", fontSize: "15px" }}>
                {finalDate}
            </Typography>
        </Grid>
    );
}

export const CreationDateConvert = (data, hour = false) => {
    const date = new Date(data);
  
    const formattedDate = date.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    });
  
    // Data sem AM/PM
    const finalDate = formattedDate.replace(/ AM| PM/gi, '');
  
    // Quando hour for false, adiciona hora no formato HH:mm
    if (hour) {
      const formattedTime = date.toLocaleTimeString('pt-BR', {
        hour: '2-digit',
        minute: '2-digit',
      });
      return `${finalDate} ${formattedTime}`;
    }
  
    return finalDate;
  }

export const formatarData = (data, formato) => {
    return format(new Date(data), formato, { locale: ptBR });
};
