import { EditCalendarOutlined } from "@mui/icons-material"
import { Button, Grid, IconButton } from "@mui/material"
import { DialogMobileEditDate } from "../dialogs/dialog_MobileEditDate"
import { useState } from "react";

export const CardButtonEditDate_mob = ({ startDate, setStartDate, endDate, setEndDate, actionButton }) => {
    const [openEditDate, setOpenEditDate] = useState(false);

    return (
        <>
            <DialogMobileEditDate
                startDate={startDate} 
                setStartDate={setStartDate} 
                endDate={endDate} 
                setEndDate={setEndDate}
                title={"Editar datas"}
                open={openEditDate}
                handleClose={() => setOpenEditDate(false)}
                action={
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: "20px" }}>
                        {actionButton && (
                            <Button fullWidth variant="contained" color="primary" onClick={() => { actionButton(); setOpenEditDate(false); } } size="large">
                                Confimar
                            </Button>
                        )}
                    </div>
                }
            />
            <IconButton
                className="editar_data_mob"
                size="large"
                variant="contained"
                color="primary"
                onClick={() => setOpenEditDate(true)} // Substitua handleEditDates pela função que abre a edição de datas
            >
                <EditCalendarOutlined size="large" />
            </IconButton>
        </>
    )
}