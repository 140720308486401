import { ArchiveRounded, CurrencyExchangeRounded, EditRounded, MoveDownOutlined, MoveToInbox, MoveToInboxRounded, SettingsRounded, Visibility, VisibilityOff } from '@mui/icons-material';
import { Avatar, Box, Button, Divider, FormControl, FormControlLabel, Grid, IconButton, Input, InputAdornment, InputLabel, MenuItem, Select, Stack, Switch, TextField, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { EnumTipo, formatarMoeda, formatarParaMoeda, formatarParaMoedaForFlag, formatarParaMoedaString, formatarParaMoedaStringComFlag, formatarParaNomeStatusAdiantamento, formatarValorComMoeda, getCurrencySymbol } from '../../../enums/enumPrestacaoDeContas';
import { grey } from '../../../theme/palette';
import DialogComponent from '../../../views/prestacaodecontas/Desktop/sections/dialogs-desktop';
import { DialogWithReturn } from '../dialogs/dialog_MobileReturn';
import Flag from 'react-world-flags';
import FileDropZone from '../../FileDropZone';
import FilePreview from '../../FileDropZone';
import { useCustomSnackbar } from '../../snackbar2/NewSnackBar';
import { useCustomLoading } from '../../uloadervoo/uloader';
import prestacaoDeContasRepositorio from '../../../repositorios/prestacaodecontas';
import { processImage } from '../controlimages';

const TransferComponent = ({ viagem, android = false, reload, despesas, getViagem, idadiantamento, totalex = 0.00, totalbrl = 0.00, moeda = EnumTipo.moedas.USD.id, status }) => {
    const [showBalance, setShowBalance] = useState(true);
    const [total, setTotal] = useState(0);
    const [editSaldo, setEditSaldo] = useState(0);
    const [novaMoeda, setNovaMoeda] = useState(false)
    const [saldo, setSaldo] = useState(0);
    const [saldoedit, setSaldoedit] = useState(0)
    const [file, setFile] = useState(null);
    const [saldotrademoeda, setSaldoTrademoeda] = useState(0)
    const [selectMoedaComprada, setSelectedMoedaComprada] = useState(1);
    const [infoComprovante, setInfoComprovante] = useState("")

    const { enqueueSnackbar } = useCustomSnackbar();
    const { setIsLoading } = useCustomLoading();

    const handleValorChange = (event) => {
        const valorInserido = event.target.value;

        // Formata o Valor
        const valorFormatado = formatarMoeda(valorInserido);
        // Atualiza o estado com o valor formatado
        let totalString = valorFormatado.replace(/\./g, '').replace(',', '.');
        // Converte a string para um número float
        let newtotal = parseFloat(totalString);
        setSaldoedit(newtotal)
        setSaldo(valorFormatado);
    };

    const handleValorChangeTrade = (event) => {
        const valorInserido = event.target.value;

        // Formata o Valor
        const valorFormatado = formatarMoeda(valorInserido);
        // Atualiza o estado com o valor formatado
        let totalString = valorFormatado.replace(/\./g, '').replace(',', '.');
        // Converte a string para um número float
        setSaldoTrademoeda(valorFormatado)
    };

    const handleMoedaChange = (event) => {
        setSelectedMoedaComprada(event.target.value)
    };

    const handleToggleBalance = () => {
        setShowBalance(!showBalance);
    };

    const handleClickEditSaldoInternational = async () => {
        setIsLoading(true)
        try {
            if (saldo === "0,00" || saldo === 0) {
                enqueueSnackbar('O saldo não pode ser Zero (0).', { variant: 'warning' });
                return
            }
            let totalString = saldo.replace(/\./g, '').replace(',', '.');
            // Converte a string para um número float
            let newtotal = parseFloat(totalString);

            let model = {
                idadiantamento: idadiantamento,
                valor_novo: newtotal
            }

            await prestacaoDeContasRepositorio.editSaldoInternacional(model);
            enqueueSnackbar('Saldo editado com sucesso!.', { variant: 'success' });
            setEditSaldo(false)
            reload()
        } catch {
            enqueueSnackbar('Erro ao editar saldo.', { variant: 'error' });
        } finally {
            setIsLoading(false)
        }
    }

    const handleClickChangeMoeda = async () => {
        setIsLoading(true)
        try {
            if (saldotrademoeda === "0,00" || saldotrademoeda === 0) {
                enqueueSnackbar('Adicione um valor a moeda trocada.', { variant: 'warning' });
                return
            }

            let totalString = saldotrademoeda.replace(/\./g, '').replace(',', '.');
            // Converte a string para um número float
            let newtotal = parseFloat(totalString);

            if (file === null) {
                enqueueSnackbar('O comprovante da compra é obrigatório.', { variant: 'warning' });
                return
            }

            let model = {
                tipo_original: moeda,
                tipo_convertida: selectMoedaComprada,
                total_original: totalex,
                total_convertida: newtotal,
                comprovante: file
            }

            await prestacaoDeContasRepositorio.trocarMoeda(viagem.id, model)
            enqueueSnackbar('Moeda adicionada com sucesso!.', { variant: 'success' });
            setNovaMoeda(false)
            reload()
        } catch {
            enqueueSnackbar('Erro ao adicionar moeda.', { variant: 'error' });
        } finally {
            setIsLoading(false)
        }
    }

    const handleClickComprovante = useCallback((event) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            const newFile = files[0];
            setInfoComprovante(newFile);
            processImage(Object.assign(newFile, {
                preview: URL.createObjectURL(newFile),
            })).then(base64String => {
                setFile(base64String); // Aqui você tem a imagem convertida em base64
            }).catch(error => {
                console.error("Erro ao converter imagem para Base64:", error);
            });
        }
    }, []);

    const GetGastoTotal = () => {
        let total2 = 0;
        despesas.forEach(despesa => {
            if (despesa.tipo_moeda === moeda) {
                total2 += despesa.total;
            }
        });
        setTotal(total2);
    }

    useEffect(() => {
        setSaldoedit(totalex)
        GetGastoTotal()
    }, [despesas])

    return (
        <Box sx={{ maxWidth: 400, p: 3, borderRadius: "10px", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", backgroundColor: 'white' }}>
            <DialogWithReturn
                title={
                    <Typography variant='body2' style={{ color: "#5c5c5c" }}>
                        {formatarParaMoedaString(moeda, totalbrl)}
                    </Typography>
                }
                open={editSaldo}
                handleClose={() => setEditSaldo(false)}
                body={
                    <Grid container item xs={12} style={{
                        height: "100%",
                    }}>
                        <Grid container item xs={12} style={{
                            display: "flex",
                            justifyContent: "center",
                            height: 10
                        }}>
                            {formatarParaNomeStatusAdiantamento(status)}
                            <Box
                                sx={{
                                    padding: '10px 20px',
                                    borderRadius: '5px',
                                    fontFamily: 'Arial, sans-serif',
                                }}
                            >
                                <Box style={{ marginBottom: "-10px" }}>
                                    <Typography variant="caption" color="textSecondary">{formatarParaMoedaString(moeda, totalbrl)}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: "-14px" }}>
                                    <Typography variant="h6" style={{
                                        fontWeight: "600", color: '#262626', fontSize: "40px", textAlign: "left", display: "flex"
                                    }}>
                                        <div style={{ display: "flex" }}>
                                            {showBalance ? formatarValorComMoeda(moeda, saldoedit) : '***'}
                                        </div>
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: "2px" }}>
                                    <Typography variant="body2" sx={{ fontWeight: "600", color: '#393939' }}>
                                        Saldo Atual
                                    </Typography>
                                </Box>
                                <Stack direction="row" spacing={1} alignItems="center" style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                                    <Typography variant="body1" sx={{
                                        textDecoration: 'line-through', color: 'gray', fontSize: "14px" // Ajuste na espessura da linha
                                    }}>
                                        {formatarValorComMoeda(moeda, totalex)}
                                    </Typography>
                                    <Typography variant="body1" sx={{
                                        fontWeight: 'bold', color: saldoedit - total < 0 ? 'red' : '#31A552', fontSize: "16px"
                                    }}>
                                        {saldoedit - total > 0 && "+"}{formatarValorComMoeda(moeda, saldoedit - total)}
                                    </Typography>
                                </Stack>
                            </Box>
                        </Grid>
                        <Grid container item xs={12} style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            alignContent: "center"
                        }}>
                            <TextField
                                fullWidth
                                className="totalgasto"
                                id="outlined-number"
                                label="Saldo"
                                variant="standard"
                                value={saldo}
                                onChange={handleValorChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            {getCurrencySymbol(moeda)}
                                        </InputAdornment>
                                    ),
                                    inputProps: {
                                        inputMode: 'numeric',
                                        style: { fontSize: '28px', fontWeight: 'bold' }
                                    }
                                }}
                            />
                            <Grid item xs={12} style={{ display: "flex", marginTop: "10px", justifyContent: "space-between" }}>
                                <Typography variant="subtitle1" style={{ color: "#242424FF", fontWeight: 'bold' }}>Total anterior</Typography>
                                <Typography variant="subtitle1" style={{ color: "#242424FF", fontWeight: 'bold' }}>
                                    {formatarValorComMoeda(moeda, totalex)}
                                </Typography>
                            </Grid>
                        </Grid>

                    </Grid>
                }
                action={
                    <>
                        <Button onClick={handleClickEditSaldoInternational} fullWidth variant='outlined' color="primary" size='large'>Atualizar</Button>
                    </>
                }
                fullScreen={true}
                width={"sm"}
            />
            <DialogWithReturn
                title={
                    <Typography variant='body2' style={{ color: "#5c5c5c" }}>
                        {formatarParaMoedaString(moeda, totalbrl)}
                    </Typography>
                }
                open={novaMoeda}
                handleClose={() => setNovaMoeda(false)}
                body={
                    <Grid container item xs={12} style={{
                        height: "100%",
                    }}>
                        <Grid item xs={12}>
                            <Grid container item xs={12} style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "80%",
                                alignContent: "center"
                            }}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography variant="h6" sx={{
                                        fontWeight: 'bold', color: saldoedit - total < 0 ? 'red' : '#31A552', fontSize: "34px"
                                    }}>
                                        {saldoedit - total > 0 && ""}{formatarValorComMoeda(moeda, totalex)}
                                    </Typography>
                                </Box>
                                <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
                                    <CurrencyExchangeRounded style={{ color: saldoedit - total < 0 ? 'red' : '#31A552' }} />
                                </Grid>
                                <Grid container item xs={12} style={{ gap: "10px" }}>
                                    {saldoedit - total < 0 ? (
                                        <>
                                            <Typography variant='body1' style={{ color: "#8f8f8f", textAlign: "center", width: "100%", marginTop: "10px" }}>
                                                Você não possui saldo o suficiente.
                                            </Typography>
                                        </>
                                    ) : (
                                        <>
                                            <FormControl fullWidth variant="outlined" style={{ minWidth: 120, marginTop: "10px" }}>
                                                <InputLabel>Moeda comprada</InputLabel>
                                                <Select
                                                    label="Moeda comprada"
                                                    value={selectMoedaComprada}
                                                    onChange={(event) => handleMoedaChange(event)}
                                                >
                                                    {Object.keys(EnumTipo.moedas).map((key) => {
                                                        const item = EnumTipo.moedas[key];
                                                        return (
                                                            <MenuItem key={item.id} value={item.id}>
                                                                <Flag code={item.flagCode} width="20" style={{ marginRight: '10px' }} /> {item.nome}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </FormControl>
                                            <TextField
                                                fullWidth
                                                className="totalgasto"
                                                id="outlined-number"
                                                label="Saldo"
                                                // variant="standard"
                                                value={saldotrademoeda}
                                                onChange={handleValorChangeTrade}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            {getCurrencySymbol(selectMoedaComprada)}
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            <Grid item xs={12} style={{ padding: 0 }}>
                                                {infoComprovante && <FilePreview file={infoComprovante} />}

                                                <Button
                                                    variant="outlined"
                                                    fullWidth
                                                    size="large"
                                                    startIcon={file ? <ArchiveRounded /> : <MoveToInboxRounded />}
                                                    sx={{ mb: 2 }}
                                                    component="label"
                                                >
                                                    {file ? "Editar comprovante" : "Adicionar Comprovante"}
                                                    <input
                                                        id="file-upload"
                                                        type="file"
                                                        hidden
                                                        onChange={handleClickComprovante}
                                                    />
                                                </Button>

                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                }
                action={
                    <>
                        <Button onClick={() => handleClickChangeMoeda()} style={{ backgroundColor: "#1b1b1b" }} size='large' fullWidth variant='contained'>Transferir</Button>
                    </>
                }
                fullScreen={true}
                width={"sm"}
            />
            <Grid container spacing={1} style={{ marginTop: "-16px" }}>
                {status === EnumTipo.adiantamento.aprovado ? (
                    <>
                        <Grid item xs={12} style={{ display: "flex", textAlign: "left", flexDirection: "column" }}>
                            <Typography variant="caption" color="textSecondary">{formatarParaMoedaString(moeda, totalex)}</Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: "-14px" }}>
                                <Typography variant="h6" style={{ fontWeight: "600", color: '#262626', fontSize: "20px", textAlign: "left", display: "flex" }}>
                                    <div style={{ display: "flex" }}>
                                        {showBalance ? formatarValorComMoeda(moeda, totalex - total) : '***'}
                                    </div>
                                    <IconButton onClick={handleToggleBalance} size="small" style={{ marginTop: "-3px" }}>
                                        {showBalance ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: "2px" }}>
                                <Typography variant="body2" sx={{ fontWeight: "600", color: '#393939' }}>
                                    Saldo Atual
                                </Typography>
                            </Box>
                        </Grid>
                    </>
                ) : (
                    <>
                        <Grid item xs={12}>
                            <Box display="flex" justifyContent="space-between" style={{ justifyContent: "left" }}>
                                <Typography variant="caption" color="textSecondary">
                                    {formatarParaNomeStatusAdiantamento(status)}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box style={{ width: "-webkit-fill-available", paddingLeft: "0px", textAlign: "left" }}>
                                <Typography variant="caption" color="textSecondary">{formatarParaMoedaString(moeda, totalbrl)}</Typography>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: "-14px" }}>
                                    <Typography variant="h6" style={{ fontWeight: "600", color: '#262626', fontSize: "20px", textAlign: "left", display: "flex" }}>
                                        <div style={{ display: "flex" }}>
                                            {showBalance ? formatarValorComMoeda(moeda, totalex - total) : '***'}
                                        </div>
                                        <IconButton onClick={handleToggleBalance} size="small" style={{ marginTop: "-3px" }}>
                                            {showBalance ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: "6px" }}>
                                    <Typography variant="body2" sx={{ fontWeight: "600", color: '#393939' }}>
                                        Saldo Atual
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        {(
                            status === EnumTipo.adiantamento.naosolicitado && android
                            ||
                            status === EnumTipo.adiantamento.aprovado && android
                        ) && (
                                <Grid item xs={12} style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "20px", marginTop: "-16px", marginBottom: "-10px" }}>
                                    <Button color="primary" size='large' onClick={() => setEditSaldo(true)} style={{ color: "#FFFF", backgroundColor: "#212B36", borderRadius: "16px" }} startIcon={<EditRounded />} variant='text' fullWidth>Saldo</Button>
                                    <Button color="primary" size='large' onClick={() => setNovaMoeda(true)} style={{ color: "#212B36" }} startIcon={<CurrencyExchangeRounded />} variant='text' fullWidth>Trocar</Button>
                                </Grid>
                            )}
                    </>
                )}
            </Grid>
        </Box >
    );
};

export default TransferComponent;