import { Menu, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React from 'react';
import { RowDespesa } from './row';

export const TableDespesas = ({ despesa, despesas, handleClickEdit, handleClickInvalid, setDespesa, setTotalGastoa, setTotalAuditar }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event, item) => {
        setAnchorEl(event.currentTarget);
        setDespesa(item);
        setTotalGastoa((item.total).toLocaleString('pt-BR', { minimumFractionDigits: 2 }));
        setTotalAuditar("0,00");
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <TableContainer elevation={0} sx={{ borderRadius: "10px", border: "1px solid #f2f2f2" }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Data</TableCell>
                        <TableCell>Metódo</TableCell>
                        <TableCell align="right">Total</TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {despesas?.map((transaction, index) => (
                        <RowDespesa
                            index={index}
                            open={open}
                            transaction={transaction}
                            handleClick={handleClick}
                        />
                    ))}
                    <Menu
                        id="demo-positioned-menu"
                        aria-labelledby="demo-positioned-button"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <MenuItem onClick={() => {
                            handleClose();
                            handleClickEdit();
                        }}>Editar</MenuItem>
                        <MenuItem onClick={() => {
                            handleClose();
                            handleClickInvalid();
                        }}>{!despesa?.is_invalido ? "Invalidar" : "Revalidar"}</MenuItem>
                    </Menu>
                </TableBody>
            </Table>
        </TableContainer>
    )
}