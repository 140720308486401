import React, { createContext, useContext, useState, useCallback } from 'react';
import { Backdrop, Typography } from '@mui/material';
import { useStyles } from './styles';
import Image from '../../images/travel1.gif';

// Context creation for Loading state management
const LoadingContext = createContext();

// Custom hook to use Loading
export const useCustomLoading = () => useContext(LoadingContext);

// Loading Provider
export const LoadingProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("")
  const classes = useStyles();

  // Function to toggle loading state
  const loadingBackdrop = useCallback((loading) => {
    setIsLoading(loading);
  }, []);

  return (
    <LoadingContext.Provider value={{ isLoading, setIsLoading, loadingBackdrop, setMessage }}>
      {isLoading && (
        <Backdrop className={classes.Backdrop} open={isLoading}>
          <div style={{ textAlign: 'center' }}>
            <img src={Image} style={{ width: 350, height: 350 }} alt="Loading" />
            <Typography variant="h5" style={{ marginTop: "-100px", fontWeight: "600" }}>{message}</Typography>
          </div>
        </Backdrop>
      )}
      {children}
    </LoadingContext.Provider>
  );
};

// Integration component that wraps the app's component tree
export default function IntegrationBackdrop({ children }) {
  return (
    <LoadingProvider>
      {children}
    </LoadingProvider>
  );
}
