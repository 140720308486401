import { Avatar, Box, Chip, Grid, Typography } from "@mui/material";
import React, { memo } from "react";
import { GastoIn } from "./Gasto";
import { Hodometer } from "./Hodometer";
import { useStyles } from "./styles";
import { Warning } from "./Warning";
import { HodometerD } from "./Hodometer/index_desk";
import { GastoInD } from "./Gasto/index_desk";
import { WarningD } from "./Warning/index_desk";

export const CardDespesa = memo(({ Gasto, setGasto, onEditHodometro, onEditGasto, Viagemadiantamento, Viagemstatus, fullScreen, onClick, onDelete, onEdit, onQrCode }) => {
  const classes = useStyles();

  return fullScreen ? (
    <Grid
      item xs={12}
      className={classes.card}
      style={{ marginTop: "20px", padding: "20px" }}
      container
    >
      {Gasto.hodometro ? (
        <>
          <Hodometer
            Gasto={Gasto}
            setGasto={setGasto}
            Viagemstatus={Viagemstatus}
            handleClickEdit={onEditHodometro}
            handleClickDelete={onDelete}
          />
        </>
      ) : (
        <>
          {Gasto.mensagem ? (
            <>
              <Warning
                Gasto={Gasto}
                setGasto={setGasto}
                Viagemstatus={Viagemstatus}
                handleClickEdit={onEditGasto}
                handleClickDelete={onDelete}
              />
            </>
          ) : (
            <>
              <GastoIn
                Gasto={Gasto}
                setGasto={setGasto}
                Viagemstatus={Viagemstatus}
                handleClickEdit={onEditGasto}
                handleClickDelete={onDelete}
                onQrCode={onQrCode}
              />
            </>
          )}
        </>
      )}
    </Grid>
  ) : (
    <>
      <Grid
        item xs={12}
        className={classes.card}
        style={{ marginTop: "0px" }}
        container
      >
        {Gasto.hodometro ? (
          <>
            <HodometerD
              Gasto={Gasto}
              setGasto={setGasto}
              Viagemstatus={Viagemstatus}
              handleClickEdit={onEditHodometro}
              handleClickDelete={onDelete}
            />
          </>
        ) : (
          <>
            {Gasto.mensagem ? (
              <WarningD
                Gasto={Gasto}
                setGasto={setGasto}
                Viagemstatus={Viagemstatus}
                handleClickEdit={onEditGasto}
                handleClickDelete={onDelete}
              />
            ) : (
              <GastoInD
                Gasto={Gasto}
                setGasto={setGasto}
                Viagemstatus={Viagemstatus}
                handleClickEdit={onEditGasto}
                handleClickDelete={onDelete}
                onQrCode={onQrCode}
              />
            )}
          </>
        )}
      </Grid>
    </>
  )
});
