import React from 'react';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { Button } from '@material-ui/core';
import { fDate } from '../../../../../utils/format-time';

const ExportToExcelItinerarioRealizado = ({ data }) => {
    const exportToExcel = async () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Relatório de Voos');

        // Adiciona cabeçalhos
        worksheet.columns = [
            { header: 'ID', key: 'id', width: 10 },
            { header: 'Passageiro', key: 'passageiro', width: 30 },
            { header: 'Centro de Custo', key: 'centro_custo', width: 30 },
            { header: 'Total p/Pax', key: 'total_pax', width: 15 },
            { header: 'CIA', key: 'cia', width: 10 },
            { header: 'Trecho', key: 'trecho', width: 20 },
            { header: 'Data', key: 'data', width: 15 },
            { header: 'Horário Ida', key: 'hora_ida', width: 15 },
            { header: 'Horário Chegada', key: 'hora_chegada', width: 15 },
            { header: 'Localizador', key: 'localizador', width: 15 },
            { header: 'Total p/LOC', key: 'total_loc', width: 15 },
        ];

        let currentRow = 2;

        data.forEach((reserva) => {
            const totalTrechos = reserva.infoTrecho.length;
            const totalPassageiros = reserva.passageiro.length;
            const totalRows = totalTrechos * totalPassageiros;

            reserva.passageiro.forEach((passageiro, passageiroIndex) => {
                reserva.infoTrecho.forEach((trecho, trechoIndex) => {
                    worksheet.addRow({
                        id: reserva.idreserva,
                        passageiro: passageiro.nome,
                        centro_custo: `${passageiro.cc_codigo} - ${passageiro.cc_nome}`,
                        total_pax: `R$${passageiro.total.toFixed(2).replace('.', ',')}`,
                        cia: reserva.cia,
                        trecho: trecho.trecho,
                        data: fDate(new Date(trecho.data)),
                        hora_ida: trecho.horaIda,
                        hora_chegada: trecho.horaChegada,
                        localizador: reserva.localizador,
                        total_loc: `R$${reserva.total.toFixed(2).replace('.', ',')}`,
                    });
                    currentRow++;
                });
            });

            // Mescla células conforme a necessidade
            const startRow = currentRow - totalRows;
            worksheet.mergeCells(`A${startRow}:A${currentRow - 1}`);
            worksheet.mergeCells(`I${startRow}:I${currentRow - 1}`);
            worksheet.mergeCells(`J${startRow}:J${currentRow - 1}`);

            reserva.passageiro.forEach((_, passageiroIndex) => {
                const passageiroStartRow = startRow + passageiroIndex * totalTrechos;
                worksheet.mergeCells(`B${passageiroStartRow}:B${passageiroStartRow + totalTrechos - 1}`);
                worksheet.mergeCells(`C${passageiroStartRow}:C${passageiroStartRow + totalTrechos - 1}`);
                worksheet.mergeCells(`D${passageiroStartRow}:D${passageiroStartRow + totalTrechos - 1}`);
            });

            const ciaStartRow = startRow;
            worksheet.mergeCells(`E${ciaStartRow}:E${ciaStartRow + totalRows - 1}`);
        });

        // Gera o arquivo Excel
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/octet-stream' });
        saveAs(blob, 'Relatorio_de_Voos.xlsx');
    };

    return (
        <div>
            <Button onClick={exportToExcel} style={{
                background: '#c62828',
                color: '#fff',
                '&:hover': {
                    background: '#b91119'
                }
            }}>Exportar </Button>
        </div>
    );
};

export default ExportToExcelItinerarioRealizado;
