import { Avatar, Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, InputAdornment, InputBase, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material"
import DialogComponent from "../../../views/prestacaodecontas/Desktop/sections/dialogs-desktop"
import { IconsSource } from "../../../images/Icons"
import { EnumTipo, formatarMoeda, formatarParaMoedaForFlag, formatarParaMoedaString, formatarValorComMoeda, getCurrencySymbol, hasApprovedAdiantamento } from "../../../enums/enumPrestacaoDeContas"
import { useState } from "react"
import { LocalAirportRounded, PublicRounded } from "@mui/icons-material"
import { DialogWithReturn } from "../dialogs/dialog_MobileReturn"
import Flag from "react-world-flags"
import prestacaoDeContasRepositorio from "../../../repositorios/prestacaodecontas"
import { useCustomSnackbar } from "../../snackbar2/NewSnackBar"
import { useCustomLoading } from "../../uloadervoo/uloader"

export const CardAddInViagem_mob = ({
    viagem,
    despesas,
    openFinalizarDespesa,
    setOpenFinalizarDespesa,
    loading,
    pendente,
    incorrectSpen,
    handleClickFinalizarViagem,
    hasApprovedAdiantamento,
    setOpenGastoAdd,
    setOpenHodometroAdd,
    reload
}) => {
    const [avancedDespesa, setAvancedDespesa] = useState(false);
    const [openNewMoeda, setOpenNewMoeda] = useState(false);
    const [novamoeda, setNovamoeda] = useState("0,00")
    const [idnovamoeda, setIdnovamoeda] = useState(2)

    const { enqueueSnackbar } = useCustomSnackbar();
    const { setIsLoading } = useCustomLoading();

    const handleClickAdicionarMoeda = async () => {
        setIsLoading(true)
        try {
            let totalString = novamoeda.replace(/\./g, '').replace(',', '.');
            // Converte a string para um número float
            let newtotal = parseFloat(totalString);
            let model = {
                idviagem: viagem.id,
                valor_novo: newtotal,
                tipo_moeda: idnovamoeda
            }
            await prestacaoDeContasRepositorio.adicionarNovaMoeda(model)
            enqueueSnackbar('Moeda adicionada com sucesso.', { variant: 'success' });
            setOpenNewMoeda(false)
            reload()
        } catch {
            enqueueSnackbar('Erro ao adicioanr moeda', { variant: 'error' });
        } finally {
            setIsLoading(false)
        }
    }

    const handleMoedaChange = (event) => {
        setIdnovamoeda(event.target.value)
    };

    const handleValorChange = (event) => {
        const valorInserido = event.target.value;

        // Formata o Valor
        const valorFormatado = formatarMoeda(valorInserido);
        // Atualiza o estado com o valor formatado
        let totalString = valorFormatado.replace(/\./g, '').replace(',', '.');
        // Converte a string para um número float
        let newtotal = parseFloat(totalString);
        setNovamoeda(valorFormatado)
    };

    return (
        <>
            <Grid
                item xs={12}
                style={{ boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)", borderRadius: "10px", padding: "20px", width: "100%", maxWidth: 360 }}
            >
                <Grid style={{ minWidth: "200px", justifyContent: "center", textAlign: "center" }}>
                    {viagem !== null && despesas.length !== 0 && viagem.status !== EnumTipo.viagemstatus.pendenteacorrecao ? (
                        <Button variant="outlined" size="large" color="primary" disabled={pendente === 0} fullWidth onClick={() => setAvancedDespesa(true)}>Finalizar despesa</Button>
                    ) : (null)}
                    {viagem.status === EnumTipo.viagemstatus.pendenteacorrecao && incorrectSpen.length !== 0 && !loading && (
                        <Typography variant="body2" style={{ color: "#313131" }}>Há despesa(s) pendente a correção, retornado pelo auditor, corrija oque foi pedido para avançar sua viagem.</Typography>
                    )}
                    {viagem.status === EnumTipo.viagemstatus.pendenteacorrecao && incorrectSpen.length === 0 && !loading && despesas.length !== 0 ? (
                        <Button variant="outlined" size="large" color="primary" fullWidth onClick={() => setAvancedDespesa(true)}>Aplicar Correção</Button>
                    ) : (null)}

                    <DialogComponent
                        title={viagem?.titulo}
                        open={avancedDespesa}
                        handleClose={() => setAvancedDespesa(false)}
                        body={
                            <Grid container item xs={12}>
                                {(pendente.length !== 0) ? (
                                    <Typography>Corrija seus gastos pendentes para finalizar sua despesa</Typography>
                                ) : (
                                    <Typography>Ao avançar sua despesa não poderá retorna-la, apenas ver sua despesa</Typography>
                                )}
                            </Grid>
                        }
                        action={
                            <Grid>
                                {!(pendente.length !== 0) && (
                                    <>
                                        <Button onClick={() => setAvancedDespesa(false)}>Cancelar</Button>
                                        <Button onClick={() => handleClickFinalizarViagem()}>Confimar</Button>
                                    </>
                                )}
                            </Grid>
                        }
                        width={"sm"}
                    />
                    <DialogWithReturn
                        title={
                            <div style={{ height: "20px" }}></div>
                        }
                        open={openNewMoeda}
                        handleClose={() => setOpenNewMoeda(false)}
                        body={
                            <Grid container item xs={12}>
                                <Grid item xs={12} container spacing={2} style={{ marginBottom: '0px', marginTop: "0px" }}>
                                    <Grid item xs={12}>
                                        <Box textAlign="left" my={2}>
                                            <Typography variant="h5" fontWeight="bold" gutterBottom>
                                                Escolha a sua nova moeda
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                Ao adicionar uma moeda não terá como informar ao financeiro, apenas quando você finalizar a viagem.
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth variant="outlined" style={{ minWidth: 120 }}>
                                            <InputLabel>Moeda</InputLabel>
                                            <Select
                                                value={idnovamoeda}
                                                onChange={(event) => handleMoedaChange(event)}
                                                label="Moeda"
                                            >
                                                {Object.keys(EnumTipo.moedas).map((key) => {
                                                    const item = EnumTipo.moedas[key];
                                                    return (
                                                        <MenuItem key={item.id} value={item.id}>
                                                            <Flag code={item.flagCode} width="20" style={{ marginRight: '10px' }} /> {item.nome}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} style={{ borderRadius: "16px", padding: "30px 60px", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}>
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            alignItems="center"
                                            width="100%"
                                            mt={2}
                                        >
                                            <Typography variant="caption" color="textSecondary">
                                                {formatarParaMoedaString(idnovamoeda)}
                                            </Typography>
                                            <InputBase
                                                value={getCurrencySymbol(idnovamoeda) + novamoeda}
                                                onChange={handleValorChange}
                                                inputProps={{
                                                    style: {
                                                        textAlign: 'center',
                                                        fontSize: '32px',
                                                        fontWeight: 'bold',
                                                        letterSpacing: '1px',
                                                    },
                                                    readOnly: false,
                                                }}
                                                sx={{
                                                    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                                                    width: 'fit-content',
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                        action={
                            <Grid item xs={12}>
                                <Button onClick={() => handleClickAdicionarMoeda()} fullWidth size="large" color="primary" variant="contained">Adicionar</Button>
                            </Grid>
                        }
                        fullScreen={true}
                        width={"sm"}
                    />
                    {viagem.status === EnumTipo.viagemstatus.aguardandoinicio || viagem.status === EnumTipo.viagemstatus.emviagem || (viagem.status === EnumTipo.viagemstatus.pendenteacorrecao && !hasApprovedAdiantamento(viagem.adiantamento)) ? (
                        <>
                            <Button size="large" className="adicionardespesa_mob" fullWidth variant="contained" color="primary" onClick={() => setOpenGastoAdd(true)}>Adicionar gasto</Button>
                            <Button size="large" className="adicionarhodometro_mob" fullWidth startIcon={<img alt='Icon' src={IconsSource.googleMapIcon} width={20}></img>} variant="contained" color="secondary" onClick={() => setOpenHodometroAdd(true)}>Hodômetro</Button>
                            {viagem.tem_multimonetario && (
                                <Button onClick={() => setOpenNewMoeda(true)} startIcon={<PublicRounded />} style={{ color: "#ffffff", backgroundColor: "#212B36" }} variant="contained" size="large" color="primary" fullWidth>
                                    Nova moeda
                                </Button>
                            )}
                        </>
                    ) : (null)}
                </Grid>
            </Grid>
        </>
    )
}