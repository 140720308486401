import { TimelineOppositeContent } from '@mui/lab';
import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { Avatar, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import * as React from 'react';
import { formatarParaNomeStatusViagemString } from '../../../../enums/enumPrestacaoDeContas';
import prestacaoDeContasRepositorio from '../../../../repositorios/prestacaodecontas';
import { stringAvatar } from '../../../../theme/palette';
import { CreationDateConvert } from '../../../../views/prestacaodecontas/Desktop/sections';
import { useCustomSnackbar } from '../../../snackbar2/NewSnackBar';
import { useCustomLoading } from '../../../uloadervoo/uloader';

export default function DespesasHistory({ despesa }) {
    const [history, setHistory] = React.useState();
    const { enqueueSnackbar } = useCustomSnackbar();
    const { setIsLoading } = useCustomLoading();

    const getColor = (text = "") => {
        const normalized = text.toLowerCase();

        if (normalized.includes("despesa invalidada")) return "error";
        if (normalized.includes("despesa valida") || normalized.includes("despesa revalidada")) return "primary";
        if (normalized.includes("valor alterado")) return "info";

        return "default"; // ou outra cor padrão de sua escolha
    };

    const getHistorico = async () => {
        setIsLoading(true);
        try {
            const response = await prestacaoDeContasRepositorio.getHistorico(despesa.iddespesa);
            setHistory(response);
        } catch {
            enqueueSnackbar('Erro ao buscar histórico.', { variant: 'error' });
        } finally {
            setIsLoading(false);
        }
    };

    React.useEffect(() => {
        getHistorico();
    }, []); // roda apenas uma vez, quando for montado

    return (
        <Timeline position="alternate" style={{ padding: "0px" }}>
            {history?.map((item, idx) => (
                <TimelineItem style={{ padding: "0px" }}>
                    <TimelineOppositeContent
                        sx={{ m: 'auto 0' }}
                        align="right"
                        variant="body2"
                        color="text.secondary"
                    >
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar
                                    {...stringAvatar(item.usuario.pessoa.nome + " " + item.usuario.pessoa.sobrenome)}
                                    alt={item.usuario.imagem}
                                    src={item.usuario.imagem}
                                    style={{ margin: '0 auto', width: '30px', height: '30px' }}
                                />
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography variant='body2' style={{
                                        color: "#4e4e4e",
                                        whiteSpace: 'nowrap', // Impede quebra de linha
                                        overflow: 'hidden', // Esconde o texto excedente
                                        textOverflow: 'ellipsis', // Adiciona "..." se necessário
                                        maxWidth: '120px',
                                    }}>
                                        {
                                            item.usuario.pessoa.nome + " " + item.usuario.pessoa.sobrenome
                                        }
                                    </Typography>
                                }
                                secondary={CreationDateConvert(item.data_alteracao, true)}
                            />
                        </ListItem>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDot color={getColor(item.descricao_alteracao)}>
                        </TimelineDot>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: '12px', px: 2, m: 'auto 0', display: "flex", flexDirection: "column" }}>
                        <Typography variant='body2' style={{
                            color: "#4e4e4e", marginBottom: "0px"
                        }}>
                            {item.descricao_alteracao === "" ? "Sem descrição" : item.descricao_alteracao}
                        </Typography>
                        <Typography variant='body2' style={{
                            color: "#4e4e4e", marginBottom: "0px"
                        }}>em {formatarParaNomeStatusViagemString(item.status_despesa_na_alteracao)}
                        </Typography>
                        {/* {formatarParaNomeStatusViagem(item.status_despesa_na_alteracao)} */}
                    </TimelineContent>
                </TimelineItem>
            ))}
        </Timeline>
    );
}
