import { IosShareOutlined } from '@mui/icons-material';
import { Chip, IconButton, MenuItem, Tooltip } from '@mui/material';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { alpha } from '@mui/material/styles';
import { isAfter } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import ExportExcel from '../../../../../components/UExportExcel';
import { ResumeAllDespesas } from '../../../../../components/autalii_expanses/card_resumeall_despesas';
import { CustomTabs } from '../../../../../components/autalii_expanses/customTabs';
import Scrollbar from '../../../../../components/scrollbar';
import { TableEmptyRows, TableHeadCustom, TableNoData, TablePaginationCustom, TableSelectedAction, emptyRows, useTable } from '../../../../../components/table';
import OrderTableFiltersResult from '../../sections/forTables/order-table-filters-result';
import OrderTableToolbar from '../../sections/forTables/order-table-toolbar';
import "./style.css";
import { ExcelLoadTableDespesas } from '../../../../../components/autalii_expanses/excel';
import { OrderTableRow } from './row/order-table-row';

const TABLE_HEAD = [
    { id: 'idviagemtenant', label: 'ID', width: 60 },
    { id: 'pessoa', label: 'Descrição', width: 280 },
    { id: 'datadecriação', label: 'Data de criação', width: 250 },
    // { id: 'datadeinicio', label: 'Data de Inicio', width: 150 },
    { id: 'total', label: 'Total', width: 60, align: 'center', width: 100 },
    { id: 'totalinicial', label: 'Total inicial', width: 40, align: 'center' },
    { id: 'status', label: 'Status', width: 100, align: 'center' },
];

const defaultFilters = {
    name: '',
    startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
    statusFilter: 'todos',
    statusArr: [0],
    filterAlter: { nescessita_de_atencao: [{ id: 0 }] },
    centrodecusto: 0,
    idpessoa: 0
}

// ----------------------------------------------------------------------

export default function OrderListView({ permissions, data, handleClickEditDespesa, handleClickFilter, getViagensReload }) {
    const table = useTable({ defaultOrderBy: 'orderNumber', defaultDense: "small" });
    const order = 'asc';
    const orderBy = '';
    const [dataTable, setDataTable] = useState(data)
    const [filters, setFilters] = useState(defaultFilters);
    const [colunasExcel, setColunasExcel] = useState([]);
    const [dataExcel, setDataExcel] = useState([]);
    const dateError = isAfter(filters.startDate, filters.endDate);
    const dataFiltered = dataTable;

    function sortData(array, comparator) {
        const stabilizedArray = array.map((el, index) => [el, index]);
        stabilizedArray.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedArray.map((el) => el[0]);
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function descendingComparator(a, b, orderBy) {
        if (typeof a[orderBy] === 'number' && typeof b[orderBy] === 'number') {
            return a[orderBy] < b[orderBy] ? -1 : a[orderBy] > b[orderBy] ? 1 : 0;
        }
        if (typeof a[orderBy] === 'string' && typeof b[orderBy] === 'string') {
            return a[orderBy].localeCompare(b[orderBy]);
        }
        return 0;
    }

    const sortedData = useMemo(() => {
        return sortData(dataTable, getComparator(order, orderBy));
    }, [dataTable, order, orderBy]);

    const STATUS_OPTIONS = [
        { value: 'todos', label: 'Todos', status: [0] },
        { value: 'finalizado', label: 'Finalizado', status: [5] },
        { value: 'financeiro', label: 'Financeiro', status: [4] },
        { value: 'controladoria', label: 'Controladoria', status: [3] },
        { value: 'emviagem', label: 'Em Aberto', status: [1] },
        { value: 'pendente', label: 'Pendente', status: [2, 8, 9, 10] },
    ];

    const denseHeight = table.dense ? 56 : 56 + 20;

    const canReset =
        !!filters.name || filters.statusFilter !== 'todos';

    const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

    const handleFilters = useCallback(
        (name, value) => {
            table.onResetPage();
            setFilters((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        },
        [table]
    );

    useEffect(() => {
        ExcelLoadTableDespesas(data, table, setDataExcel, setColunasExcel)
    }, [table.selected, data]);  // Adiciona "data" como dependência, pois você está usando-a no efeito

    const handleResetFilters = useCallback(() => {
        handleStatusOption('todos', [0])
        setFilters(defaultFilters);
    }, []);

    const handleDeleteRow = useCallback(
        (id) => {
            const deleteRow = dataTable.filter((row) => row.id !== id);
            setDataTable(deleteRow);
        },
        [
            table,
            dataTable]
    );

    const handleStatusOption = (value, status, fp) => {
        if (status[0] == 0) {
            setFilters((prevFilters) => ({ ...prevFilters, statusFilter: 'todos', statusArr: [0] }));
            return
        } else if (fp && status[0] == 0) {
            setFilters((prevFilters) => ({ ...prevFilters, statusFilter: 'todos', statusArr: [0], filterAlter: { nescessita_de_atencao: [{ id: 0 }] } }));
            return
        } else {
            setFilters((prevFilters) => ({ ...prevFilters, statusFilter: value, statusArr: status }));
            return
        }
    }

    const applyFilters = () => {
        let filteredData = data;

        if (filters.name) {
            filteredData = filteredData.filter(item =>
                item.titulo.toLowerCase().includes(filters.name.toLowerCase())
            );
        }

        if (filters.statusFilter && filters.statusFilter !== 'todos') {
            filteredData = filteredData.filter(item => filters.statusArr.includes(item.status));
        }

        setDataTable(filteredData);
    };

    const handleViewRow = useCallback(
    );

    const getInvoiceLength = (status, fp = false) => {
        if (status.includes(0) && !fp) {  // Considerando '0' como o valor default para todos os status
            return data.length;
        } else if (fp) {
            return data.filter((item) => item.necessita_atencao).length;
        } else {
            return data.filter((item) => status.includes(item.status)).length;
        }
    }

    const handleClickSearch = () => {
        handleClickFilter(filters)
    }

    useEffect(() => {
        setDataTable(data)
        table.dense = true;
        applyFilters()
    }, [data])

    useEffect(() => {
        applyFilters()
    }, [filters])

    return (
        <>
            <Container maxWidth={'lg'}>
                <ResumeAllDespesas data={data} />
                <Card style={{ borderRadius: "10px", marginBottom: "60px" }}>
                    <CustomTabs
                        value={filters.statusFilter}
                        sx={{
                            px: 2.5,
                            boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
                        }}
                        color="secondary"
                    >
                        {STATUS_OPTIONS.map((tab) => (
                            <Tab
                                key={tab.value}
                                iconPosition="end"
                                value={tab.value}
                                label={tab.label}
                                variant="subtitle1"
                                style={{ fontFamily: "unset " }}
                                onClick={() => handleStatusOption(tab.value, tab.status)}
                                icon={
                                    <Chip
                                        size="small"
                                        variant={
                                            ((tab.status.includes(STATUS_OPTIONS[0].value) || tab.value === filters.status) && 'filled') || 'soft'
                                        }
                                        sx={{
                                            backgroundColor:
                                                (tab.value === 'todos' && '#212B36') ||
                                                (tab.value === 'finalizado' && 'primary.light') ||
                                                (tab.value === 'financeiro' && 'primary.dark') ||
                                                (tab.value === 'pendente' && 'secondary.main') ||
                                                (tab.value === 'emviagem' && 'primary.main') ||
                                                (tab.value === 'controladoria' && '#fbc02d') ||
                                                (tab.value === 'cancelado' && '#c62828'),
                                            color: tab.value === 'todos' || tab.value === 'cancelado' ? '#FFFFFF' : '#FFFFFF',
                                            borderRadius: '5px'
                                        }}
                                        label={
                                            tab.value === 'todos'
                                                ? data?.length
                                                : getInvoiceLength(tab.status)
                                        }
                                    />
                                }
                            />
                        ))}
                    </CustomTabs>

                    <OrderTableToolbar
                        filters={filters}
                        setFilters={setFilters}
                        data={dataTable}
                        onFilters={handleFilters}
                        handleClickSearch={handleClickSearch}
                        dateError={dateError}
                    />

                    {canReset && (
                        <OrderTableFiltersResult
                            filters={filters}
                            onFilters={handleFilters}
                            handleStatusOption={handleStatusOption}
                            onResetFilters={handleResetFilters}
                            results={dataFiltered.length}
                            sx={{ p: 2.5, pt: 0 }}
                        />
                    )}

                    <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
                        <TableSelectedAction
                            dense={table.dense}
                            numSelected={table.selected.length}
                            rowCount={dataFiltered.length}
                            onSelectAllRows={(checked) =>
                                table.onSelectAllRows(
                                    checked,
                                    dataFiltered.map((row) => row.id)
                                )
                            }
                            action={
                                <>
                                    <Tooltip title="Exportar Para Excel">
                                        {/* <IconButton color="primary" onClick={() => ExportToExcel()}>
                                            <IosShareOutlined />
                                        </IconButton> */}
                                        <ExportExcel
                                            name="Divergência"
                                            columns={colunasExcel}
                                            data={dataExcel}
                                            element={
                                                <MenuItem>
                                                    <IconButton color="primary">
                                                        <IosShareOutlined />
                                                    </IconButton>
                                                </MenuItem>
                                            }
                                        />
                                    </Tooltip>
                                </>
                            }
                        />
                        <Scrollbar>
                            <Table size={table.dense ? 'small' : 'medium'}>
                                <TableHeadCustom
                                    order={table.order}
                                    orderBy={table.orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={dataTable.length}
                                    numSelected={table.selected.length}
                                    onSort={table.onSort}
                                    onSelectAllRows={(checked) =>
                                        table.onSelectAllRows(
                                            checked,
                                            dataFiltered.map((row) => row.id)
                                        )
                                    }
                                />

                                <TableBody>
                                    {sortedData ? (
                                        <>
                                            {
                                                sortedData?.slice(
                                                    table.page * table.rowsPerPage,
                                                    table.page * table.rowsPerPage + table.rowsPerPage
                                                ).map((row) => (
                                                    <>
                                                        <OrderTableRow
                                                            key={row.id}
                                                            permissions={permissions}
                                                            row={row}
                                                            data={data}
                                                            selected={table.selected.includes(row.id)}
                                                            onSelectRow={() => table.onSelectRow(row.id)}
                                                            onDeleteRow={() => handleDeleteRow(row.id)}
                                                            onViewRow={() => handleViewRow(row.id)}
                                                            getViagensReload={getViagensReload}
                                                            handleClickEditDespesa={handleClickEditDespesa}
                                                        />
                                                    </>
                                                ))}
                                            <TableEmptyRows
                                                height={denseHeight}
                                                emptyRows={emptyRows(table.page, table.rowsPerPage, dataFiltered.length)}
                                            />
                                        </>
                                    ) : (
                                        null
                                    )}
                                    <TableNoData notFound={notFound} />
                                </TableBody>
                            </Table>
                        </Scrollbar>
                    </TableContainer>

                    <TablePaginationCustom
                        count={dataTable.length}
                        page={table.page}
                        rowsPerPage={table.rowsPerPage}
                        onPageChange={table.onChangePage}
                        onRowsPerPageChange={table.onChangeRowsPerPage}
                        //
                        dense={table.dense}
                        onChangeDense={table.onChangeDense}
                    />
                </Card>
            </Container>
        </>
    );
}