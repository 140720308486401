import { forEach } from "jszip";
import React from "react";
import Chart from "react-apexcharts";
import { EnumTipo, formatarValorComMoeda } from "../../../enums/enumPrestacaoDeContas";

const FormaterExpanses = (expanses) => {
    let seriesd = [];

    expanses.forEach((item) => {
        let form = {
            x: new Date(item.datadecompra).getTime(),
            y: item.total,
            totalbrl: formatarValorComMoeda(EnumTipo.moedas.BRL.id, item.total_brl),
            totalmoeda: formatarValorComMoeda(item.tipo_moeda, item.total),
        };
        seriesd.push(form);
    });

    // Ordena pelo timestamp (data)
    return seriesd.sort((a, b) => a.x - b.x);
};



function Test({ expanses }) {
    const seriesd = FormaterExpanses(expanses);


    // Dados simulados com propriedades extras:
    const seriesData = [
        {
            x: new Date("2022-01-16").getTime(), // data como timestamp
            y: 100,            // valor principal (BRL)
            totalbrl: 100,     // valor em BRL
            totalmoeda: 120,   // valor na moeda original
        },
        {
            x: new Date("2022-01-01").getTime(),
            y: 150,
            totalbrl: 150,
            totalmoeda: 180,
        },
        {
            x: new Date("2022-03-01").getTime(),
            y: 200,
            totalbrl: 200,
            totalmoeda: 240,
        },
    ];


    const options = {
        yaxis: {
            labels: {
                style: {
                    fontSize: "12px",
                    fontFamily: "Roboto Google",
                },
            },
        },
        // Exibe a legenda e utiliza as cores definidas
        legend: {
            show: true,
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            width: 5,
            curve: "smooth",
            lineCap: "round",
        },
        chart: {
            id: "myChart",
            toolbar: { show: false },
            zoom: { enabled: false },
            foreColor: "#333",
        },
        xaxis: {
            type: "datetime", // usa o valor de x para exibir a data
            labels: {
                style: {
                    fontSize: "14px",
                    fontFamily: "Roboto Google",
                },
            },
        },
        tooltip: {
            // Tooltip customizado para acessar as propriedades extras
            custom: ({ seriesIndex, dataPointIndex, w }) => {
                const dataPoint = w.config.series[seriesIndex].data[dataPointIndex];
                // Converte a data para um formato legível
                const dateLabel = new Date(dataPoint.x).toLocaleDateString();
                return `
          <div style="
            padding: 8px;
            background-color: #ffffff;
            border-radius: 8px;
            color: #333;
            font-size: 14px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            font-family: 'Roboto Google', sans-serif;
          ">
            <strong>${dateLabel}</strong><br/>
            Valor principal (BRL): ${dataPoint.totalbrl}<br/>
            Valor na moeda original: ${dataPoint.totalmoeda}
          </div>
        `;
            },
        },
        stroke: {
            width: 3,
            curve: "smooth",
        },
    };
    
    const series = [
        {
            name: "Teste",
            data: seriesd,
        },
    ];

    return (
        <div style={{ maxWidth: 600, margin: "0 auto" }}>
            <h2>Exemplo com Array de Objetos e Tooltip Customizado</h2>
            <Chart options={options} series={series} type="line" width="100%" height="300" />
        </div>
    );
}

export default Test;
