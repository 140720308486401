import { AddCardOutlined, Info, IosShareOutlined, MoreVertOutlined } from '@mui/icons-material';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import { Avatar, Checkbox, Chip, ListItemText, Menu, MenuItem, TableCell, TableRow, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Tabs from '@mui/material/Tabs';
import Tooltip from '@mui/material/Tooltip';
import { alpha, styled } from '@mui/material/styles';
import { isAfter } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { NewExportExcel } from '../../../../../components/UExportExcel/newExport';
import Scrollbar from '../../../../../components/scrollbar';
import { TableEmptyRows, TableNoData, TablePaginationCustom, TableSelectedAction, emptyRows, useTable } from '../../../../../components/table';
import { EnumTipo, formatarParaMoedaString, formatarParaNomeStatusAdiantamentoBool, formatarParaNomeStatusAdiantamentoList, formatarParaNomeStatusAdiantamentoListI, formatarParaNomeStatusAdiantamentoString, formatarParaNomeStatusViagem, formatarValorComMoeda } from '../../../../../enums/enumPrestacaoDeContas';
import { useBoolean } from '../../../../../hooks/use-boolean';
import { stringAvatar } from '../../../../../theme/palette';
import { DetalhesViagemComponent } from '../../../../prestacaodecontas/Desktop/auditor/detalhesViagem';
import { formatarData } from '../../../../prestacaodecontas/Desktop/sections';
import DialogComponent from '../../../../prestacaodecontas/Desktop/sections/dialogs-desktop';
import OrderTableFiltersResult from '../../../../prestacaodecontas/Desktop/sections/forTables/order-table-filters-result';
import OrderTableToolbar from '../../../../prestacaodecontas/Desktop/sections/forTables/order-table-toolbar';
import { HistoricoDePagamentosComponent } from '../../Historico';
import { PagamentoComponent } from '../../Pagamento';
import TableHeadCustomFinance from './table-head-custom';

const TABLE_HEAD = [
    { id: 'pessoa', label: 'Descrição', width: "auto" },
    { id: 'total', label: 'Total', width: "auto", align: 'center' },
    { id: 'totalinicial', label: 'Total inicial', width: "auto", align: 'center' },
    { id: 'status', label: 'Status', width: "auto", align: 'center' },
];

const CustomTabs = styled(Tabs)({
    '& .MuiTabs-indicator': {
        backgroundColor: "#212B36", // Cor do indicador personalizada
    },
    '& .MuiTab-root.Mui-selected': {
        color: "#212B36", // Cor do indicador personalizada
    },
    '& .MuiTab-root': {
        minHeight: "0px !important"
    }
});

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

const STATUS_OPTIONS = [
    { value: 'todos', label: 'Todos', status: [0] },
    { value: 'finalizado', label: 'Finalizado', status: [5] },
    { value: 'financeiro', label: 'Financeiro', status: [4] },
    { value: 'emviagem', label: 'Em Aberto', status: [1] },
    { value: 'pendente', label: 'Pendente', status: [4, 0] },
];

const defaultFilters = {
    name: '',
    startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
    statusFilter: 'todos',
    statusArr: [0],
    centrodecusto: 0,
    idpessoa: 0
};
// ----------------------------------------------------------------------

export default function OrderListView({ data, handleClickFilter, getAllViagens, getViagensReload, filtredvalues }) {
    // const classes = useStyles();
    const table = useTable({ defaultOrderBy: 'orderNumber' });
    const [dataTable, setDataTable] = useState(data);
    const [filters, setFilters] = useState(defaultFilters);
    const [anchorEl, setAnchorEl] = useState(null);
    const [viagem, setViagem] = useState(null);
    const [openDetails, setOpenDetails] = useState(false);
    const [openPag, setOpenPag] = useState(false);
    const [openHist, setOpenHist] = useState(false);
    const open = Boolean(anchorEl);
    const dateError = isAfter(filters.startDate, filters.endDate);
    const dataFiltered = dataTable;

    const denseHeight = table.dense ? 56 : 56 + 20;

    const canReset =
        filters.statusFilter != 'todos';

    const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

    const handleFilters = useCallback(
        (name, value) => {
            table.onResetPage();
            setFilters((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        },
        [table]
    );

    const applyFilters = () => {
        let filteredData = data;

        if (filters.name) {
            filteredData = filteredData.filter(item =>
                item.titulo.toLowerCase().includes(filters.name.toLowerCase())
            );
        }

        if (filters.statusFilter && filters.statusFilter !== 'todos') {
            filteredData = filteredData.filter(item => filters.statusArr.includes(item.status));
        }

        setDataTable(filteredData);
    };

    const handleClick = (event, row) => {
        setViagem(row)
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickDetails = (row) => {
        setOpenDetails(true)
        handleClose(false)
    }

    const handleClickPagamento = (row) => {
        setOpenPag(true)
        handleClose(false)
    }

    const handleClickHistory = (row) => {
        setOpenHist(true)
        handleClose(false)
    }

    const handleResetFilters = useCallback(() => {
        handleStatusOption(0);
        setFilters(defaultFilters);
    }, []);

    const handleDeleteRow = useCallback(
        (id) => {
            const deleteRow = dataTable.filter((row) => row.id !== id);
            setDataTable(deleteRow);
        },
        [dataTable]
    );

    const handleStatusOption = (value, status) => {
        if (status[0] == 0) {
            setFilters((prevFilters) => ({ ...prevFilters, statusFilter: 'todos', statusArr: [0] }));
            return
        } else {
            setFilters((prevFilters) => ({ ...prevFilters, statusFilter: value, statusArr: status }));
            return
        }
    }

    const handleFilterStatus = useCallback(
        (event, newValue) => {
            handleFilters('status', newValue);
        },
        [handleFilters]
    );

    const getInvoiceLength = (status) => {
        if (status == 0) {  // Considerando '0' como o valor default para todos os status
            return data.length;
        }
        return data?.filter((item) => status.includes(item.status)).length;
    }

    const handleClickSearch = () => {
        handleClickFilter(filters)
    }

    useEffect(() => {
        setDataTable(data)
        applyFilters()
    }, [data])

    const ExportToExcel = async () => {
        const newColumns = [
            { title: "ID", width: 5 },  // Largura em caracteres
            { title: "Titulo", width: 30 },
            { title: "Centro de Custo", width: 20 },
            { title: "Nome Completo", width: 25 },
            { title: "Email", width: 30 },
            { title: "Data de criação viagem", width: 20 },
            { title: "Data inicial da viagem", width: 15 },
            { title: "Data final da viagem", width: 15 },
            { title: "Quantidade de Despesas", width: 20 },
            { title: "Política", width: 25 },
            { title: "Total Inicial", width: 12 },
            { title: "Total gasto", width: 12 },
            { title: "Total Conciliação", width: 25 },
            { title: "Adiantamentos", width: 35 },  // Coluna única para adiantamentos
            { title: "Status Adiantamento", width: 25 },  // Coluna única para adiantamentos
        ];

        // Função para formatar os valores de adiantamentos
        const formatarAdiantamento = (adiantamento) => {
            const valorMoedaOriginal = formatarParaMoedaString(adiantamento.tipo_moeda, adiantamento.total);
            const valorConvertido = adiantamento.tipo_moeda === EnumTipo.moedas.BRL.id
                ? formatarValorComMoeda(EnumTipo.moedas.BRL.id, adiantamento.total) // Sem conversão se já for BRL
                : `${formatarParaMoedaString(EnumTipo.moedas.BRL.id, adiantamento.total_brl)} - ${formatarValorComMoeda(EnumTipo.moedas.BRL.id, adiantamento.total_brl)}`;

            return [
                "", // Deixar as colunas que você não deseja preencher vazias
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                `${valorMoedaOriginal} - ${formatarValorComMoeda(adiantamento.tipo_moeda, adiantamento.total)} - ${valorConvertido}`,
                `${formatarParaNomeStatusAdiantamentoString(adiantamento.status)}`
            ];
        };

        // Filtrando os itens selecionados e mapeando para o formato necessário
        const datas = data
            .filter((item) => table.selected.includes(item.id))  // Filtra apenas os itens selecionados
            .flatMap((item) => {
                let uniq = item.adiantamento.length === 1 ? true : false;

                // Linha principal com os dados da viagem, incluindo o primeiro adiantamento
                const primeiroAdiantamento = item.adiantamento[0] || { tipo_moeda: EnumTipo.moedas.BRL.id, total: item.total }; // Caso não tenha adiantamento, usa o valor total da viagem
                const valorMoedaOriginal = formatarParaMoedaString(primeiroAdiantamento.tipo_moeda, primeiroAdiantamento.total);
                const valorConvertido = primeiroAdiantamento.tipo_moeda === EnumTipo.moedas.BRL.id
                    ? formatarValorComMoeda(EnumTipo.moedas.BRL.id, primeiroAdiantamento.total) // Sem conversão se já for BRL
                    : `${formatarParaMoedaString(EnumTipo.moedas.BRL.id, primeiroAdiantamento.total_brl)} - ${formatarValorComMoeda(EnumTipo.moedas.BRL.id, primeiroAdiantamento.total_brl)}`;

                const linhaPrincipal = [
                    item.idviagemtenant,
                    item.titulo,
                    item.centrocusto,
                    item.pessoa.nome,
                    item.pessoa.email,
                    formatarData(item.datadecriacao, "d MMM 'de' yyyy 'às' HH:mm"), // Formato com horário
                    formatarData(item.datainicio, "d MMM 'de' yyyy"), // Sem horário
                    formatarData(item.datafim, "d MMM 'de' yyyy"), // Sem horário
                    item.qtd_despesas,
                    item.necessita_atencao ? "Fora da política" : "Dentro da política",
                    formatarValorComMoeda(EnumTipo.moedas.BRL.id, item.totalinicial),

                    // Valor do primeiro adiantamento ou valor total da viagem
                    item.adiantamento.length === 1
                        ? formatarValorComMoeda(item.adiantamento[0].tipo_moeda, item.total)
                        : (item.adiantamento.length === 0
                            ? formatarValorComMoeda(EnumTipo.moedas.BRL.id, item.total)
                            : "Multi Moedas"  // Junta múltiplos valores separados por vírgula
                        ),
                    formatarValorComMoeda(EnumTipo.moedas.BRL.id, item.totalextras),

                    // Nacional ou Internacional baseado no tipo de moeda do primeiro adiantamento
                    `${valorMoedaOriginal} - ${formatarValorComMoeda(primeiroAdiantamento.tipo_moeda, primeiroAdiantamento.total)} - ${valorConvertido}`,
                    `${formatarParaNomeStatusAdiantamentoString(primeiroAdiantamento.status)}`
                ];

                // Linhas de adiantamento (começando a partir do segundo, se houver mais de um)
                const linhasAdiantamentos = item.adiantamento.length > 1
                    ? item.adiantamento.slice(1).map(formatarAdiantamento)  // Mapeia o restante dos adiantamentos, começando do segundo
                    : []; // Não há adiantamentos extras

                return [linhaPrincipal, ...linhasAdiantamentos]; // Adiciona a linha principal seguida das linhas de adiantamentos extras
            });


        // Atualizando as colunas e dados para exportação no Excel
        NewExportExcel({
            name: "Prestação de contas - Viagens",
            columns: newColumns,
            data: datas
        });
    }

    useEffect(() => {
        applyFilters()
    }, [filters])

    // useEffect(() => {
    //     // Definindo as colunas do Excel com larguras específicas
    //     const newColumns = [
    //         { title: "ID", width: 5 },  // Largura em caracteres
    //         { title: "Titulo", width: 30 },
    //         { title: "Centro de Custo", width: 20 },
    //         { title: "Nome Completo", width: 25 },
    //         { title: "Email", width: 30 },
    //         { title: "Data de criação viagem", width: 20 },
    //         { title: "Data inicial da viagem", width: 15 },
    //         { title: "Data final da viagem", width: 15 },
    //         { title: "Quantidade de Despesas", width: 20 },
    //         { title: "Política", width: 25 },
    //         { title: "Total Inicial", width: 12 },
    //         { title: "Total gasto", width: 12 },
    //         { title: "Total Conciliação", width: 25 },
    //         { title: "Adiantamentos", width: 25 },  // Coluna única para adiantamentos
    //     ];

    //     // Função para formatar os valores de adiantamentos
    //     const formatarAdiantamento = (adiantamento) => {
    //         const valorMoedaOriginal = formatarParaMoedaString(adiantamento.tipo_moeda, adiantamento.total);
    //         const valorConvertido = adiantamento.tipo_moeda === EnumTipo.moedas.BRL.id
    //             ? formatarValorComMoeda(EnumTipo.moedas.BRL.id, adiantamento.total) // Sem conversão se já for BRL
    //             : `${formatarParaMoedaString(EnumTipo.moedas.BRL.id, adiantamento.total_brl)} - ${formatarValorComMoeda(EnumTipo.moedas.BRL.id, adiantamento.total_brl)}`;

    //         return [
    //             "", // Deixar a primeira coluna (ID) vazia
    //             "", // Deixar a coluna de Título vazia
    //             "", // Deixar a coluna de Centro de Custo vazia
    //             "", // Deixar a coluna de Nome Completo vazia
    //             "", // Deixar a coluna de Email vazia
    //             "", // Deixar a coluna de Data de criação vazia
    //             "", // Deixar a coluna de Data inicial vazia
    //             "", // Deixar a coluna de Data final vazia
    //             "", // Deixar a coluna de Quantidade de Despesas vazia
    //             "", // Deixar a coluna de Política vazia
    //             "", // Deixar a coluna de Total Inicial vazia
    //             "", // Deixar a coluna de Total Gasto vazia
    //             "", // Deixar a coluna de Total Conciliação vazia
    //             `${valorMoedaOriginal} - ${formatarValorComMoeda(adiantamento.tipo_moeda, adiantamento.total)} - ${valorConvertido}`
    //         ];
    //     };


    //     const formatarAdiantamentoTotais = (adiantamento) => {
    //         return `${formatarValorComMoeda(adiantamento.tipo_moeda, adiantamento.total)})`;
    //     };

    //     // Filtrando os itens selecionados e mapeando para o formato necessário
    //     const datas = data
    //         .filter((item) => table.selected.includes(item.id))  // Filtra apenas os itens selecionados
    //         .flatMap((item) => {
    //             // Linha principal com os dados da viagem
    //             const linhaPrincipal = [
    //                 item.idviagemtenant,
    //                 item.titulo,
    //                 item.centrocusto,
    //                 item.pessoa.nome,
    //                 item.pessoa.email,
    //                 formatarData(item.datadecriacao, "d MMM 'de' yyyy 'às' HH:mm"), // Formato com horário
    //                 formatarData(item.datainicio, "d MMM 'de' yyyy"), // Sem horário
    //                 formatarData(item.datafim, "d MMM 'de' yyyy"), // Sem horário
    //                 item.qtd_despesas,
    //                 item.necessita_atencao ? "Fora da política" : "Dentro da política",
    //                 formatarValorComMoeda(EnumTipo.moedas.BRL.id, item.totalinicial),

    //                 // Lógica para tratar múltiplos adiantamentos
    //                 item.adiantamento.length === 1
    //                     ? formatarValorComMoeda(item.adiantamento[0].tipo_moeda, item.adiantamento[0].total)
    //                     : (item.adiantamento.length === 0
    //                         ? formatarValorComMoeda(EnumTipo.moedas.BRL.id, item.total)
    //                         : item.adiantamento.map(ad => formatarAdiantamentoTotais(ad)).join(", ")  // Junta múltiplos valores separados por vírgula
    //                     ),

    //                 formatarValorComMoeda(EnumTipo.moedas.BRL.id, item.totalextras),

    //                 // Nacional ou Internacional baseado no tipo de moeda
    //                 item.adiantamento.length === 1 && item.adiantamento[0].tipo_moeda === EnumTipo.moedas.BRL.id
    //                     ? "Nacional"
    //                     : "Internacional"
    //             ];

    //             // Linhas de adiantamento
    //             const linhasAdiantamentos = item.adiantamento
    //                 ? item.adiantamento.map(formatarAdiantamento)
    //                 : [["Não solicitado"]]; // Caso não haja adiantamentos, adicionar uma linha dizendo "Não solicitado"

    //             return [linhaPrincipal, ...linhasAdiantamentos]; // Adiciona a linha principal seguida das linhas de adiantamentos
    //         });


    //     // Atualizando as colunas e dados para exportação no Excel
    //     setColunasExcel(newColumns);
    //     setDataExcel(datas);
    // }, [table.selected, data]);

    return (
        <Container maxWidth="xl">
            {viagem != null && (
                <>
                    <DialogComponent
                        title={
                            <>
                                #{viagem.id} - Pagar viagem
                            </>
                        }
                        open={openPag}
                        handleClose={() => setOpenPag(false)}
                        body={<PagamentoComponent viagem={viagem} setOpen={setOpenPag} getAllViagens={getAllViagens} />}
                        action={null}
                        width={"lg"}
                    />

                    <DialogComponent
                        title={
                            <>
                                {viagem?.titulo}  {viagem ? formatarParaNomeStatusViagem(viagem.status) : null}
                            </>
                        }
                        open={openDetails}
                        handleClose={() => setOpenDetails(false)}
                        body={<DetalhesViagemComponent viagem={viagem} />}
                        action={null}
                        width={"sm"}
                    />

                    <DialogComponent
                        title={
                            <>
                                {viagem?.titulo}  {viagem ? formatarParaNomeStatusViagem(viagem.status) : null}
                            </>
                        }
                        open={openHist}
                        handleClose={() => setOpenHist(false)}
                        body={<HistoricoDePagamentosComponent viagem={viagem} />}
                        action={null}
                        width={"sm"}
                    />
                </>
            )}

            <Card style={{ borderRadius: "10px", marginBottom: "20px" }}>
                <CustomTabs
                    value={filters.status}
                    onChange={handleFilterStatus}
                    aria-label="secondary tabs example"
                    size="small"
                >
                    {STATUS_OPTIONS.map((tab) => (
                        <Tab
                            key={tab.value}
                            iconPosition="end"
                            value={tab.value}
                            label={tab.label}
                            variant="subtitle1"
                            style={{ fontFamily: "unset " }}
                            onClick={() => handleStatusOption(tab.value, tab.status)}
                            icon={
                                <Chip
                                    size="small"
                                    variant={
                                        ((tab.status.includes(STATUS_OPTIONS[0].value) || tab.value === filters.status) && 'filled') || 'soft'
                                    }
                                    sx={{
                                        backgroundColor:
                                            (tab.value === 'todos' && '#212B36') ||
                                            (tab.value === 'finalizado' && 'primary.light') ||
                                            (tab.value === 'financeiro' && 'primary.dark') ||
                                            (tab.value === 'pendente' && 'secondary.main') ||
                                            (tab.value === 'emviagem' && 'primary.main') ||
                                            // (tab.value === 'controladoria' && '#fbc02d') 
                                            // ||
                                            (tab.value === 'cancelado' && '#c62828'),
                                        color: tab.value === 'todos' || tab.value === 'cancelado' ? '#FFFFFF' : '#FFFFFF',
                                        borderRadius: '5px'
                                    }}
                                    label={
                                        tab.value === 'todos'
                                            ? data?.length
                                            : getInvoiceLength(tab.status)
                                    }
                                />
                            }
                        />

                    ))}
                </CustomTabs>
                <OrderTableToolbar
                    filters={filters}
                    setFilters={setFilters}
                    data={dataTable}
                    onFilters={handleFilters}
                    handleClickSearch={handleClickSearch}
                    dateError={dateError}
                />
                {canReset && (
                    <OrderTableFiltersResult
                        filters={filters}
                        onFilters={handleFilters}
                        handleStatusOption={handleStatusOption}
                        onResetFilters={handleResetFilters}
                        results={dataFiltered.length}
                        sx={{ p: 2.5, pt: 0 }}
                    />
                )}
                <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
                    <TableSelectedAction
                        dense={table.dense}
                        numSelected={table.selected.length}
                        rowCount={dataFiltered.length}
                        onSelectAllRows={(checked) =>
                            table.onSelectAllRows(
                                checked,
                                dataFiltered.map((row) => row.id)
                            )
                        }
                        action={
                            <Tooltip title="Exportar Para Excel">
                                <IconButton color="primary" onClick={() => ExportToExcel()}>
                                    <IosShareOutlined />
                                </IconButton>
                            </Tooltip>
                        }
                    />
                    <Scrollbar>
                        <Table size="small" sx={{ minWidth: 660 }}>
                            <TableHeadCustomFinance
                                order={table.order}
                                orderBy={table.orderBy}
                                headLabel={TABLE_HEAD}
                                rowCount={dataTable.length}
                                numSelected={table.selected.length}
                                onSort={table.onSort}
                                onSelectAllRows={(checked) =>
                                    table.onSelectAllRows(
                                        checked,
                                        dataFiltered.map((row) => row.id)
                                    )
                                }
                            />
                            <TableBody>
                                <StyledMenu
                                    id="demo-customized-menu"
                                    MenuListProps={{
                                        'aria-labelledby': 'demo-customized-button',
                                    }}
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={(e) => handleClose(e)}
                                >
                                    <MenuItem onClick={() => handleClickDetails(viagem)} disableRipple>
                                        <Info />
                                        Detalhes
                                    </MenuItem>
                                    {
                                        (
                                            viagem?.status == EnumTipo.viagemstatus.financeiro &&
                                            (formatarParaNomeStatusAdiantamentoBool(viagem.adiantamento) === "pendente" || viagem.adiantamento.status !== 4) ||
                                            viagem?.status == EnumTipo.viagemstatus.aguardandoinicio &&
                                            (formatarParaNomeStatusAdiantamentoBool(viagem.adiantamento) === "pendente" || viagem.adiantamento.status !== 4) ||
                                            viagem?.status == EnumTipo.viagemstatus.emviagem ||
                                            (viagem?.datainicio === "0001-01-01T00:00:00")  
                                        )
                                        && (
                                            <MenuItem onClick={() => handleClickPagamento(viagem)} disableRipple>
                                                <AddCardOutlined />
                                                Pagar Viagem
                                            </MenuItem>
                                        )}
                                    <MenuItem onClick={() => handleClickHistory(viagem)} disableRipple>
                                        <HistoryToggleOffIcon />
                                        Histórico De Pagamentos
                                    </MenuItem>
                                </StyledMenu>
                                {dataTable ? (
                                    <>
                                        {dataTable
                                            ?.slice(
                                                table.page * table.rowsPerPage,
                                                table.page * table.rowsPerPage + table.rowsPerPage
                                            )
                                            .map((row) => (
                                                <TableRow hover selected={table.selected.includes(row.id)} width="auto">
                                                    <TableCell padding="checkbox">
                                                        <Checkbox checked={table.selected.includes(row.id)} onClick={() => table.onSelectRow(row.id)} />
                                                    </TableCell>

                                                    <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <Avatar {...stringAvatar(row.pessoa.nome)} src={row.pessoa.img} style={{ width: 48, height: 48 }} />

                                                        <ListItemText
                                                            primary={row.titulo}
                                                            secondary={row.pessoa.nome}
                                                            primaryTypographyProps={{ fontSize: "12px" }}
                                                            secondaryTypographyProps={{
                                                                fontSize: "11px",
                                                                color: 'text.disabled',
                                                            }}
                                                        />

                                                    </TableCell>

                                                    <TableCell align="center">
                                                        <Typography style={{ fontSize: "12px" }}>
                                                            {row.adiantamento.length === 1 ? (
                                                                <>
                                                                    {formatarValorComMoeda(row.adiantamento[0].tipo_moeda, row.total)}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {row.adiantamento.length === 0 ? (
                                                                        <>
                                                                            {formatarValorComMoeda(EnumTipo.moedas.BRL.id, row.total)}
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <Tooltip title={
                                                                                <>
                                                                                    Essa viagem está utilizando multimoedas
                                                                                </>
                                                                            }>
                                                                                <Typography variant="subtitle2">
                                                                                    Multimoeda
                                                                                </Typography>
                                                                            </Tooltip>
                                                                        </>
                                                                    )}
                                                                </>
                                                            )}
                                                        </Typography>
                                                    </TableCell>

                                                    <TableCell align="center" >
                                                        {row.tem_multimonetario ? (
                                                            <>
                                                                {formatarParaNomeStatusAdiantamentoListI(row.adiantamento)}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Tooltip title={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.totalinicial)}>
                                                                    {formatarParaNomeStatusAdiantamentoList(row.adiantamento)}
                                                                </Tooltip>
                                                            </>
                                                        )}
                                                    </TableCell>

                                                    <TableCell align="center">
                                                        <Typography
                                                            variant="soft"
                                                        >
                                                            {row.status != null ? (
                                                                <>
                                                                    {formatarParaNomeStatusViagem(row.status)}
                                                                </>
                                                            ) : (<>
                                                            </>)}
                                                        </Typography>
                                                    </TableCell>

                                                    <TableCell align="right" sx={{ px: 1 }}>
                                                        <IconButton
                                                            id="demo-customized-button"
                                                            aria-controls={open ? 'demo-customized-menu' : undefined}
                                                            aria-haspopup="true"
                                                            aria-expanded={open ? 'true' : undefined}
                                                            variant="contained"
                                                            disableElevation
                                                            onClick={(e) => handleClick(e, row)}
                                                        >
                                                            <MoreVertOutlined />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        <TableEmptyRows
                                            height={denseHeight}
                                            emptyRows={emptyRows(table.page, table.rowsPerPage, dataFiltered.length)}
                                        />
                                    </>
                                ) : null}
                                <TableNoData notFound={notFound} />
                            </TableBody>
                        </Table>
                    </Scrollbar>
                </TableContainer>
                <TablePaginationCustom
                    count={dataFiltered.length}
                    page={table.page}
                    rowsPerPage={table.rowsPerPage}
                    onPageChange={table.onChangePage}
                    onRowsPerPageChange={table.onChangeRowsPerPage}
                    dense={table.dense}
                    onChangeDense={table.onChangeDense}
                />
            </Card>
        </Container>
    );
}

// ----------------------------------------------------------------------

function applyFilter({ inputData, comparator, filters, dateError }) {
    const { status, name, startDate, endDate } = filters;

    const stabilizedThis = inputData?.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });

    inputData = stabilizedThis.map((el) => el[0]);

    if (name) {
        inputData = inputData?.filter(
            (order) =>
                order.orderNumber.toLowerCase().indexOf(name.toLowerCase()) !== -1 ||
                order.customer.name.toLowerCase().indexOf(name.toLowerCase()) !== -1 ||
                order.customer.email.toLowerCase().indexOf(name.toLowerCase()) !== -1
        );
    }

    if (status !== 'all') {
        inputData = inputData?.filter((order) => order.status === status);
    }

    if (!dateError) {
        if (startDate && endDate) {
            //   inputData = inputData.filter((order) => isBetween(order.createdAt, startDate, endDate));
        }
    }

    return inputData;
}
