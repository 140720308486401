import { Button, Chip, Dialog, DialogContent, DialogTitle, Grid, IconButton, Snackbar, TextField, Typography, useMediaQuery, useTheme, withStyles } from '@material-ui/core';
import { Close, FreeBreakfast, PolicyOutlined } from '@material-ui/icons';
import { Alert, Autocomplete, Skeleton } from '@material-ui/lab';
import SingleBedIcon from '@material-ui/icons/SingleBed';
// import VerifiedIcon from '@mui/icons-material/Verified';
// import CloseIcon from '@mui/icons-material/Close';
// import CoffeeIcon from '@mui/icons-material/Coffee';
import React, { useEffect, useState } from 'react';
import StarsIcon from '@material-ui/icons/Stars';
import { Checkbox } from '@mui/material';

const ULinesTextField = withStyles({
    root: {
        '& .MuiInputBase-root': {
            color: 'black',
        },
        '& label': {
            color: 'inherited',
        },
        '& label.Mui-focused': {
            color: '#b91119',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#b91119',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'silver',
            },
            '&:hover fieldset': {
                borderColor: 'silver',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#b91119',
            },
        }
    }
})(TextField)

const RoomBooking = (props) => {
    const [openSelectTarifa, setOpenSelectTarifa] = useState(false);
    const [onclickreserva, setOnclickReserva] = useState(false)
    const [roomPlans, setRoomsPlan] = useState([]);
    const [cotacao, setCotacao] = useState([]);
    const [cotacaoSelections, setCotacaoSelections] = useState(Array(props.numberOfRooms).fill(false));
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const [message, setMessage] = useState("");
    const [log, setLog] = useState(false);
    const [typeLog, setTypeLog] = useState("");
    const [short, setShort] = useState(props.orderTarifas)

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return; // Não faz nada se o usuário clicar fora do Snackbar
        }
        setLog(false); // Atualiza o estado para fechar o Snackbar
    };

    const handlePlanChange = (index, planIndex) => {
        const plan = roomPlans[planIndex];

        if (!plan) {
            console.error('Plano selecionado não encontrado.');
            return;
        }

        const newSelections = [...props.roomSelections];

        newSelections[index] = {
            ...newSelections[index],
            selectedPlan: planIndex,
            selectedRate: '',
            name: plan.name,
            type: plan.type,
            id: plan.id,
            irrevocable: plan.irrevocable,
            description: plan.description
        };
        props.setRoomSelections(newSelections);
    };

    const handleRateChange = (index, rateIndex) => {
        const currentSelection = props.roomSelections[index];
        const selectedPlan = roomPlans[currentSelection.selectedPlan];

        if (!selectedPlan || !selectedPlan.rates[rateIndex]) {
            console.error('Tarifa selecionada não encontrada.');
            return;
        }

        const selectedRate = selectedPlan.rates[rateIndex];

        const newSelections = [...props.roomSelections];
        newSelections[index] = {
            ...newSelections[index],
            selectedRate: rateIndex,
            plano: selectedRate
        };

        props.setRoomSelections(newSelections);
        setOnclickReserva(true)
    };

    const handleCotarChange = (index, rateIndex) => {
        // Acesso ao plano e taxa selecionados
        const currentSelection = props.roomSelections[index];
        const selectedPlan = roomPlans[currentSelection.selectedPlan];
        const selectedRate = selectedPlan.rates[rateIndex];

        // Alternar o estado checked
        selectedRate.checked = !selectedRate.checked;

        // Adiciona as informações do hotel somente quando necessário
        if (selectedRate.checked) {
            let detalhes = [];
            let comodidades = [];

            props.hotel.amenities.forEach(comod => {
                comodidades.push(comod.name)
            });

            if (selectedRate.cafedamanha) {
                detalhes.push("Café da manhã")
            } else {
                detalhes.push("Sem café da manhã")
            }
            if (selectedRate.nonRefundable) {
                detalhes.push("Não reembolsável")
            } else {
                detalhes.push("Reembolsável")
            }
            // Adicionando detalhes do hotel à taxa selecionada
            const rateWithHotelInfo = {
                ...selectedRate,
                hotelname: props.hotel.name,
                hoteldescription: props.hotel.description,
                hotelendereco: props.hotel.location.address,
                hotelcomodidades: comodidades,
                hoteldetalhes: detalhes,
                hotelcheckin: props.checkin,
                hotelcheckout: props.checkout,
                hotelStars: parseInt(props.hotel.award.rating, 10),
                hotelthumb: props.hotel.thumbImage,
            };

            // Adiciona à lista se checked é verdadeiro
            props.setListCotacao(prevList => [...prevList, { index, rateIndex, ...rateWithHotelInfo }]);
            logMessage("Adicionado a lista de cotação", "success")
        } else {
            // Remove da lista se checked é falso
            props.setListCotacao(prevList => prevList.filter(rate => rate.id !== selectedRate.id));
        }
    }

    function sortRoomPlans(roomPlans, order = 1) {
        const sortedRoomPlans = [...roomPlans];
        const compareDescending = (a, b) => {
            if (a.rates[0].price < b.rates[0].price) return 1;
            if (a.rates[0].price > b.rates[0].price) return -1;
            return 0;
        };
        const compareAscending = (a, b) => compareDescending(b, a);
        const compareFunction = order == 1 ? compareAscending : compareDescending;
        setRoomsPlan(sortedRoomPlans.sort(compareFunction));
    }

    // const handleCloseOrder = () => {
    //     if (props.orderTarifas == 1) {
    //         // Ordena por preço mais baixo primeiro
    //         const sortedPlansDescending = sortRoomPlans(roomsPlan, 1);
    //     } else if (props.orderTarifas == 2) {
    //         // Ordena por preço mais alto primeiro
    //         const sortedPlansDescending = sortRoomPlans(roomsPlan, 1);
    //     }
    // }

    useEffect(() => {
        sortRoomPlans(roomPlans, 1);
    }, [props.hotelplan])

    useEffect(() => {
        // setShort(props.orderTarifas)
        sortRoomPlans(roomPlans, props.orderTarifas);
    }, [props.orderTarifas])

    useEffect(() => {
        if (onclickreserva) {
            props.handleChangeRoom();
        }
    }, [props.roomSelections]);

    function verificaCafe(array) {
        const positivos = [/com café/, /café da manhã/, /breakfast/];
        const negativo = /sem café/;

        return array.some(item => {
            const desc = item.description.toLowerCase();
            const plan = item.mealplandescription.toLowerCase();

            // Se qualquer campo tiver “sem café”, ignoramos este item
            if (negativo.test(desc) || negativo.test(plan)) return false;

            // Se algum termo positivo aparecer em qualquer campo → true
            return positivos.some(rx => rx.test(desc) || rx.test(plan));
        });
    }


    function verificaReembolsos(array) {
        // Verifica se algum item do array contém as frases desejadas na descrição
        const reembolsavel = array.some(item =>
            !item.nonRefundable
        );

        return reembolsavel;
    }

    const logMessage = (message, type) => {
        setMessage(message);
        setTypeLog(type);
        setLog(true);
    };

    useEffect(() => {
        if (typeof props.setRoomSelections === 'function') {
            props.setRoomSelections(Array(props.numberOfRooms).fill({ selectedPlan: '', selectedRate: '' }));
        }

        let updatedRoomPlans = []; // Cria um novo array para armazenar os planos atualizados

        props.hotelplan?.forEach(item => {
            let rates2 = [];

            item.rates.forEach(element => {
                let array = {
                    description: element.fareTypeDescription,
                    mealPlanId: element.mealPlanId,
                    id: element.accommodationIndex,
                    price: element.totalPriceWithTaxFeeBRL,
                    productToken: element.productToken,
                    priceperday: element.avgPricePerDayBRL,
                    pricetax: element.totalTaxFeeAmountBRL,
                    mealplandescription: element.mealPlanDescription,
                    cafedamanha: verificaCafe([{ description: element.fareTypeDescription, mealplandescription: element.mealPlanDescription }]),
                    nonRefundable: element.nonRefundable,
                    superFare: element.superFare,
                    checked: false,
                    is_Fora_Politica: element.is_Fora_Politica,
                    Tem_politica: element.has_Politica,
                    Valor_politica: element.valor_Politica,
                    total_moeda: element.totalPriceWithTaxFee,
                    tipo_moeda: element.originalCurrency,
                    total: element.totalPriceWithTaxFeeBRL,
                    cotacao_moeda: element.cotacao_moeda
                };
                rates2.push(array);
            });


            let value = {
                name: item.roomTypeDescription,
                type: item.bedType,
                rates: rates2,
                id: item.id,
                irrevocable: item.irrevocable,
                description: item.description,
                cafedamanha: verificaCafe(rates2),
                reembolsavel: verificaReembolsos(rates2)
            };

            updatedRoomPlans.push(value); // Adiciona o objeto 'value' ao array 'updatedRoomPlans'
        });

        sortRoomPlans(updatedRoomPlans, 1)

        // setRoomsPlan(updatedRoomPlans); // Atualiza o estado 'roomPlans' com o novo array
    }, [props.hotelplan, props.setRoomSelections]);

    return (
        <Grid container item xs={12}>
            <Snackbar
                open={log}
                autoHideDuration={2000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity={typeLog}
                    variant="filled"
                    sx={{ width: "100%" }}
                >
                    {message}
                </Alert>
            </Snackbar>
            {roomPlans.length !== 0 ? (
                <>
                    {props.roomSelections?.map((selection, index) => (
                        <>
                            {responsive ? (
                                <Grid container item xs={12} key={index} style={{ marginTop: "15px" }}>
                                    {roomPlans?.map((plan, planIndex) => (
                                        <Grid
                                            container
                                            item xs={12}
                                            style={{
                                                padding: "18px 18px 60px 18px",
                                                width: '100%',
                                                flexShrink: 0,
                                                fill: '#FFF',
                                                filter: 'drop-shadow(0px 0px 0.8999999761581421px rgba(0, 0, 0, 0.25))',
                                                borderRadius: '10px',
                                                backgroundColor: "#FFFFFF",
                                                boxShadow: "0px 0px 2px 2px rgba(0, 0, 0, 0.1)",
                                                borderBottom: 'none',
                                                marginBottom: "20px"
                                            }}
                                        >
                                            <Grid item xs={12} style={{ display: "flex", flexDirection: "column", paddingRight: "10px" }}>
                                                <Typography style={{ fontSize: "20px", fontWeight: "600" }}>1x {plan.name}</Typography>
                                                <Typography style={{ fontSize: "14px", fontWeight: "500" }}>{props.adutnumber} adulto(s) {props.criancasnumber > 0 ? (", " + props.criancasnumber + " criança(s)") : null}</Typography>
                                                <Typography style={{ fontSize: "15px", fontWeight: "500", marginTop: "10px" }}>{plan.description}</Typography>
                                            </Grid>
                                            <Grid container item xs={12} style={{ height: "100%", boxShadow: "0px 0px 1px 1px rgba(0, 0, 0, 0.2)", borderRadius: "10px", padding: "20px", display: "flex", alignItems: "center", maxHeight: "150px", marginTop: "10px" }}>
                                                <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
                                                    {plan.cafedamanha ? (
                                                        <Typography style={{ display: "flex", alignItems: "center" }}>
                                                            <FreeBreakfast style={{ marginRight: "7px" }} /> café da manhã
                                                        </Typography>
                                                    ) :
                                                        <Typography>
                                                            Sem café da manhã
                                                        </Typography>
                                                    }
                                                </Grid>
                                                <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
                                                    <Typography style={{ display: "flex", alignItems: "center" }}>
                                                        <SingleBedIcon style={{ marginRight: "7px" }} />
                                                        {plan.type}
                                                    </Typography>
                                                </Grid>
                                                <Grid style={{ display: "flex", alignItems: "center" }}>
                                                    {plan.reembolsavel ? (
                                                        <Typography style={{ display: "flex", alignItems: "center" }}>
                                                            <StarsIcon style={{ marginRight: "7px" }} />
                                                            Tarifa reembolsável
                                                        </Typography>
                                                    ) : (
                                                        <Typography style={{ fontWeight: "600", fontFamily: "sans-serif" }}>
                                                            Não reembolsável
                                                        </Typography>
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container item xs={12} style={{ marginTop: "10px" }}>
                                                <Grid container item xs={12} style={{ height: "100%", boxShadow: "0px 0px 1px 1px rgba(0, 0, 0, 0.2)", borderRadius: "10px", padding: "20px", display: "flex", alignItems: "center" }}>
                                                    <Grid item xs={12}>
                                                        <Typography style={{ fontSize: "19px" }}>{plan.rates[0].priceperday.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} <span style={{ fontSize: "14px" }}>/ Diárias</span></Typography>
                                                        <Typography style={{ fontSize: "12px" }}>Total {plan.rates[0].price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} style={{ textAlign: "center" }}>
                                                        <Button onClick={() => { handlePlanChange(index, planIndex); setOpenSelectTarifa(true); window.scrollBy(0, 300); }} variant='contained' fullWidth style={{ borderRadius: "12px", backgroundColor: "#00a152", color: "white" }}>
                                                            Escolher
                                                        </Button>
                                                    </Grid>
                                                    <hr style={{ width: "100%", backgroundColor: "#CACACA", height: "1px", border: "none" }}></hr>
                                                    <Grid item xs={12} style={{ textAlign: "center" }}>
                                                        <Typography>
                                                            Taxas inclusas
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container item xs={12} style={{ textAlign: "right", justifyContent: "right", display: "flex" }}>
                                                {selection.selectedPlan !== '' && selection.selectedPlan == planIndex && (
                                                    <>
                                                        <Grid item xs={12} onClick={() => props.setRoomSelections([{
                                                            selectedPlan: "",
                                                            selectedRate: ""
                                                        }])}>
                                                            <IconButton>
                                                                <Close />
                                                            </IconButton>
                                                        </Grid>
                                                        {roomPlans[selection.selectedPlan]?.rates.map((rate, rateIndex) => (
                                                            <Grid item xs={12} style={{ marginTop: "0px" }}>
                                                                <Grid
                                                                    container
                                                                    item xs={12}
                                                                    style={{
                                                                        padding: "18px",
                                                                        width: '100%',
                                                                        flexShrink: 0,
                                                                        fill: '#FFF',
                                                                        top: "40%",
                                                                        filter: 'drop-shadow(0px 0px 0.8999999761581421px rgba(0, 0, 0, 0.25))',
                                                                        borderRadius: '10px',
                                                                        backgroundColor: "#FFFFFF",
                                                                        boxShadow: "0px 0px 1px 1px rgba(0, 0, 0, 0.1)",
                                                                        borderBottom: 'none',
                                                                        marginBottom: "10px",
                                                                        marginTop: "10px",
                                                                    }}
                                                                >
                                                                    <Grid container item xs={12} style={{ textAlign: "left" }}>
                                                                        <Grid item xs={8}>
                                                                            <Typography style={{ fontSize: "19px" }}>{rate.priceperday.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} <span style={{ fontSize: "14px" }}>/ Diárias</span></Typography>
                                                                            <Typography style={{ fontSize: "12px", fontWeight: "600" }}>
                                                                                Total {rate.price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                                                                {rate.superFare ? (
                                                                                    <a style={{ backgroundColor: "#DF3333", marginLeft: "5px", color: "white", padding: "4px 10px 4px 10px", borderRadius: "6px" }}>
                                                                                        Oferta!
                                                                                    </a>
                                                                                ) : null
                                                                                }
                                                                            </Typography>
                                                                            <Typography style={{ fontSize: "12px" }}>{rate.description}</Typography>
                                                                        </Grid>
                                                                        <Grid item xs={4} style={{ textAlign: "right", display: "flex", alignItems: "flex-start", justifyContent: "right" }}>
                                                                            <Typography component="div" style={{ alignItems: "center", marginTop: "1vh" }}>
                                                                                Cotar
                                                                            </Typography>
                                                                            <Checkbox
                                                                                label="Cotação"
                                                                                checked={rate.checked}
                                                                                onChange={() => handleCotarChange(index, rateIndex)}
                                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                                                style={{
                                                                                    color: "#c62828", // Cor quando não está selecionado
                                                                                    '&$checked': { // Cor quando está selecionado
                                                                                        color: "#c62828",
                                                                                    },
                                                                                    '&:hover': { // Cor quando passa o mouse por cima
                                                                                        backgroundColor: 'rgba(198, 40, 40, 0.04)',
                                                                                    },
                                                                                    '&$disabled': { // Cor quando está desabilitado
                                                                                        color: "#c62828",
                                                                                    }
                                                                                }}
                                                                            />

                                                                        </Grid>
                                                                        <Grid item xs={12} style={{ textAlign: "center", justifyContent: "center", display: "flex", padding: "15px 45px 0px 35px" }}>
                                                                            {rate.cafedamanha ? (
                                                                                <Typography style={{ fontSize: "14px", fontWeight: "600", alignItems: "center", display: "flex" }}>
                                                                                    {/* <CoffeeIcon style={{ marginRight: "7px" }} /> */}
                                                                                    café da manhã
                                                                                </Typography>
                                                                            ) : (
                                                                                <Typography style={{ fontSize: "14px", fontWeight: "600" }}>
                                                                                    Sem café da manhã
                                                                                </Typography>
                                                                            )}
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid item xs={12} style={{ textAlign: "center", justifyContent: "center", display: "flex" }}>
                                                                        {!rate.nonRefundable ? (
                                                                            <>
                                                                                <Typography style={{ fontSize: "14px", fontWeight: "600", alignItems: "center", display: "flex" }}>
                                                                                    <StarsIcon style={{ marginRight: "7px" }} />
                                                                                    Tarifa reembolsável
                                                                                </Typography>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <Typography style={{ fontSize: "14px", fontWeight: "600" }}>
                                                                                    Não reembolsável
                                                                                </Typography>
                                                                            </>
                                                                        )}
                                                                    </Grid>
                                                                    {rate.is_Fora_Politica && (
                                                                        <Grid item xs={12} style={{ justifyContent: "center", display: "flex", alignItems: "center" }}>
                                                                            <Chip
                                                                                size='small'
                                                                                icon={<PolicyOutlined style={{ color: "#fafafa" }} />}
                                                                                label={
                                                                                    <Typography variant='body2' style={{ color: "#fafafa" }}>
                                                                                        {"Fora da Política de " + rate.Valor_politica.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                                                                    </Typography>
                                                                                }
                                                                                style={{ backgroundColor: "#c62828" }}
                                                                            />
                                                                        </Grid>
                                                                    )}
                                                                    <Grid item xs={12} style={{ textAlign: "right", display: "flex", alignItems: "center" }}>
                                                                        <Button
                                                                            key={rateIndex}
                                                                            onClick={() => handleRateChange(index, rateIndex)}
                                                                            fullWidth
                                                                            style={{ margin: '5px', backgroundColor: "#d32f2f", color: "white" }}
                                                                        >
                                                                            Reservar
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        ))}
                                                    </>)}
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            ) : (

                                <Grid container item xs={12} key={index} style={{ marginTop: "15px" }}>
                                    {roomPlans?.map((plan, planIndex) => (
                                        <Grid container item xs={12} key={planIndex}>
                                            <Grid
                                                container
                                                item xs={12}
                                                style={{
                                                    padding: "18px",
                                                    width: '100%',
                                                    height: 'auto',
                                                    flexShrink: 0,
                                                    fill: '#FFF',
                                                    top: "40%",
                                                    filter: 'drop-shadow(0px 0px 0.8999999761581421px rgba(0, 0, 0, 0.25))',
                                                    borderRadius: '10px',
                                                    backgroundColor: "#FFFFFF",
                                                    boxShadow: "0px 0px 2px 2px rgba(0, 0, 0, 0.1)",
                                                    borderBottom: 'none',
                                                    marginBottom: "20px",
                                                    minHeight: "180px",
                                                }}
                                            >
                                                <Grid
                                                    container
                                                    item xs={12}
                                                    style={{
                                                        minHeight: "180px",
                                                    }}
                                                >
                                                    <Grid item xs={5} style={{ display: "flex", flexDirection: "column", paddingRight: "10px" }}>
                                                        <Typography style={{ fontSize: "20px", fontWeight: "600" }}>1x {plan.name}</Typography>
                                                        <Typography style={{ fontSize: "14px", fontWeight: "500" }}>{props.adutnumber} adulto(s){props.criancasnumber > 0 ? (", " + props.criancasnumber + " criança(s)") : null}</Typography>
                                                        <p>{plan.description}</p>
                                                    </Grid>

                                                    <Grid container item xs={3} style={{ height: "100%", boxShadow: "0px 0px 1px 1px rgba(0, 0, 0, 0.2)", borderRadius: "10px", padding: "20px", display: "flex", alignItems: "center", maxHeight: "180px" }}>
                                                        <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
                                                            {plan.cafedamanha ? (
                                                                <>
                                                                    <FreeBreakfast style={{ marginRight: "7px" }} /> café da manhã
                                                                </>
                                                            ) : <>
                                                                Sem café da manhã
                                                            </>}
                                                        </Grid>
                                                        <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
                                                            {/* <SingleBedIcon style={{ marginRight: "7px" }} />  */}
                                                            {plan.type}
                                                        </Grid>
                                                        <Grid style={{ display: "flex", alignItems: "center" }}>
                                                            {plan.reembolsavel ? (
                                                                <>
                                                                    <StarsIcon style={{ marginRight: "7px" }} />
                                                                    Tarifa reembolsável
                                                                </>
                                                            ) : (
                                                                <>
                                                                    Não reembolsável
                                                                </>
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container item xs={4} style={{ paddingLeft: "29px" }}>
                                                        <Grid container item xs={12} style={{ height: "100%", boxShadow: "0px 0px 1px 1px rgba(0, 0, 0, 0.2)", borderRadius: "10px", padding: "20px", display: "flex", alignItems: "center", maxHeight: "180px" }}>
                                                            <Grid item xs={6}>
                                                                <Typography style={{ fontSize: "19px" }}>{plan.rates[0].priceperday.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} <span style={{ fontSize: "14px" }}>/ Diárias</span></Typography>
                                                                <Typography style={{ fontSize: "12px" }}>Total {plan.rates[0].price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Typography>
                                                            </Grid>
                                                            <Grid item xs={6} style={{ textAlign: "center" }}>
                                                                <Button onClick={() => { handlePlanChange(index, planIndex); setOpenSelectTarifa(true) }} variant='contained' fullWidth style={{ borderRadius: "12px", backgroundColor: "#00a152", color: "white" }}>
                                                                    Escolher
                                                                </Button>
                                                            </Grid>
                                                            <hr style={{ width: "100%", backgroundColor: "#CACACA", height: "1px", border: "none" }}></hr>
                                                            <Grid item xs={12} style={{ textAlign: "center" }}>
                                                                Taxas inclusas
                                                            </Grid>
                                                            {/* <Grid item xs={12} style={{ textAlign: "center" }}>
                                                                <Checkbox size="small" />
                                                                Cotação
                                                            </Grid> */}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid container item xs={12} style={{ textAlign: "right", justifyContent: "right", display: "flex" }}>
                                                    {selection.selectedPlan !== '' && selection.selectedPlan == planIndex && (
                                                        <>
                                                            <Grid item xs={12} onClick={() => props.setRoomSelections([{
                                                                selectedPlan: "",
                                                                selectedRate: ""
                                                            }])}>
                                                                <IconButton>
                                                                    <Close />
                                                                </IconButton>
                                                            </Grid>
                                                            {roomPlans[selection.selectedPlan]?.rates.map((rate, rateIndex) => (
                                                                <Grid item xs={4} style={{ padding: "20px", marginTop: "-20px" }}>
                                                                    <Grid
                                                                        container
                                                                        item xs={12}
                                                                        style={{
                                                                            padding: "18px",
                                                                            width: '100%',
                                                                            height: 'auto',
                                                                            flexShrink: 0,
                                                                            fill: '#FFF',
                                                                            top: "40%",
                                                                            filter: 'drop-shadow(0px 0px 0.8999999761581421px rgba(0, 0, 0, 0.25))',
                                                                            borderRadius: '10px',
                                                                            backgroundColor: "#FFFFFF",
                                                                            boxShadow: "0px 0px 1px 1px rgba(0, 0, 0, 0.1)",
                                                                            borderBottom: 'none',
                                                                            marginBottom: "20px",
                                                                            minHeight: "110px",
                                                                            marginTop: "20px",
                                                                        }}
                                                                    >
                                                                        <Grid container item xs={12} style={{ textAlign: "left" }}>
                                                                            <Grid item xs={8}>
                                                                                <Typography style={{ fontSize: "19px" }}>{rate.priceperday.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} <span style={{ fontSize: "14px" }}>/ Diárias</span></Typography>
                                                                                <Typography style={{ fontSize: "12px", fontWeight: "600" }}>
                                                                                    Total {rate.price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                                                                    {rate.superFare ? (
                                                                                        <a style={{ backgroundColor: "#DF3333", marginLeft: "5px", color: "white", padding: "4px 10px 4px 10px", borderRadius: "6px" }}>
                                                                                            Oferta!
                                                                                        </a>
                                                                                    ) : null
                                                                                    }
                                                                                </Typography>
                                                                                <Typography style={{ fontSize: "12px" }}>{rate.description}</Typography>
                                                                            </Grid>
                                                                            <Grid item xs={4} style={{ textAlign: "right", display: "flex", alignItems: "flex-start", justifyContent: "right" }}>
                                                                                <Typography component="div" style={{ alignItems: "center", marginTop: "1vh" }}>
                                                                                    Cotar
                                                                                </Typography>
                                                                                <Checkbox
                                                                                    label="Cotação"
                                                                                    checked={rate.checked}
                                                                                    onChange={() => handleCotarChange(index, rateIndex)}
                                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                                    style={{
                                                                                        color: "#c62828", // Cor quando não está selecionado
                                                                                        '&$checked': { // Cor quando está selecionado
                                                                                            color: "#c62828",
                                                                                        },
                                                                                        '&:hover': { // Cor quando passa o mouse por cima
                                                                                            backgroundColor: 'rgba(198, 40, 40, 0.04)',
                                                                                        },
                                                                                        '&$disabled': { // Cor quando está desabilitado
                                                                                            color: "#c62828",
                                                                                        }
                                                                                    }}
                                                                                />

                                                                            </Grid>
                                                                            <Grid item xs={12} style={{ textAlign: "center", justifyContent: "center", display: "flex", padding: "15px 45px 0px 35px" }}>
                                                                                {rate.cafedamanha ? (
                                                                                    <Typography style={{ fontSize: "14px", fontWeight: "600", alignItems: "center", display: "flex" }}>
                                                                                        {/* <CoffeeIcon style={{ marginRight: "7px" }} /> */}
                                                                                        café da manhã
                                                                                    </Typography>
                                                                                ) : (
                                                                                    <Typography style={{ fontSize: "14px", fontWeight: "600" }}>
                                                                                        Sem café da manhã
                                                                                    </Typography>
                                                                                )}
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid item xs={12} style={{ textAlign: "center", justifyContent: "center", display: "flex" }}>
                                                                            {!rate.nonRefundable ? (
                                                                                <>
                                                                                    <Typography style={{ fontSize: "14px", fontWeight: "600", alignItems: "center", display: "flex" }}>
                                                                                        {/* <VerifiedIcon style={{ marginRight: "7px" }} />  */}
                                                                                        Tarifa reembolsável
                                                                                    </Typography>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    Não reembolsável
                                                                                </>
                                                                            )}
                                                                        </Grid>
                                                                        {rate.is_Fora_Politica && (
                                                                            <Grid item xs={12} style={{ justifyContent: "center", display: "flex", alignItems: "center" }}>
                                                                                <Chip
                                                                                    size='small'
                                                                                    icon={<PolicyOutlined style={{ color: "#fafafa" }} />}
                                                                                    label={
                                                                                        <Typography variant='body2' style={{ color: "#fafafa" }}>
                                                                                            {"Fora da Política de " + rate.Valor_politica.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                                                                        </Typography>
                                                                                    }
                                                                                    style={{ backgroundColor: "#c62828" }}
                                                                                />
                                                                            </Grid>
                                                                        )}
                                                                        <Grid item xs={12} style={{ textAlign: "right", display: "flex", alignItems: "center" }}>
                                                                            <Button
                                                                                key={rateIndex}
                                                                                onClick={() => handleRateChange(index, rateIndex)}
                                                                                fullWidth
                                                                                style={{ margin: '5px', backgroundColor: "#d32f2f", color: "white" }}
                                                                            >
                                                                                Reservar
                                                                            </Button>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            ))}
                                                        </>)}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            )}
                        </>
                    ))}
                </>
            ) : (
                <>
                    <Grid container item xs={12} style={{ marginTop: "15px" }}>
                        <Grid container item xs={12}>
                            <Skeleton variant="rect" style={{
                                padding: "18px",
                                width: '100%',
                                height: 'auto',
                                flexShrink: 0,
                                fill: '#FFF',
                                top: "40%",
                                filter: 'drop-shadow(0px 0px 0.8999999761581421px rgba(0, 0, 0, 0.25))',
                                borderRadius: '10px',
                                borderBottom: 'none',
                                marginBottom: "20px",
                                minHeight: "180px",
                            }} />
                        </Grid>

                    </Grid>
                </>
            )}

        </Grid>
    );
};

export default RoomBooking;