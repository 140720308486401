import {
  Button,
  Card,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useTheme, withStyles } from "@material-ui/core/styles";
import { AccountCircleOutlined, LockOutlined } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { Autenticacao } from "../../Auth";
import Snackbar from "../../components/snackbar";
// import Logo from '../../images/BackgroundImages/Logo_horizontal__4.png';
import Logo from "../../images/BackgroundImages/logotipo_lines360_2025.png";
import { useStyles } from "./styles";

const StyledTextField = withStyles({
  root: {
    "& .MuiInputBase-root": {
      color: "#FFFFFF",
    },
    "& label": {
      color: "#FFFFFF",
      fontWeight: "bold",
      fontFamily: "sans-serif",
    },
    "& label.Mui-focused": {
      color: "#FFFFFF",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#FFFFFF",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#FFFFFF",
      },
      "&:hover fieldset": {
        borderColor: "#FFFFFF",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#FFFFFF",
      },
    },
  },
})(TextField);

export const Login = (props) => {

  const [values, setValues] = useState({
    usuario: "",
    senha: "",
  });
  const [snackbar, setSnackbar] = useState({
    visible: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const sceneUrl = 'https://prod.spline.design/lemIDEpo3jyIGpTS/scene.splinecode';
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => setIsLoaded(true), 2000); // Simula um carregamento leve
  }, []);

  const logar = async () => {
    setIsLoading(true);
    const autenticado = await Autenticacao(
      values.usuario.toLowerCase(),
      values.senha
    );
    setTimeout(() => {
      if (!autenticado) {
        setSnackbar({
          visible: true,
        });
        setIsLoading(false);
        return;
      }
      setIsLoading(false);
      props.history.push("/dashboard");
    }, 1000);
  };

  const onKeyPress = async (e) => {
    if (e.key === "Enter") {
      await logar();
    }
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <div className={classes.root} style={{ position: "relative", width: "100vw", height: "100vh" }}>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
        loading="lazy"
      >
        <spline-viewer url={sceneUrl} camera-controls="false"></spline-viewer>
      </div>

      <Snackbar
        {...{
          variant: "warning",
          message: "Usuário e/ou senha incorreto(s)!",
          visible: snackbar.visible,
          setSnackbar: setSnackbar,
        }}
      />

      <Card className={classes.box} style={{ position: "fixed" }}>
        <img
          alt="logo"
          src={Logo}
          width={200}
          style={{ marginBottom: theme.spacing(4) }}
        />
        <StyledTextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircleOutlined />
              </InputAdornment>
            ),
          }}
          className={classes.textfield}
          name="usuario"
          onKeyPress={(e) => onKeyPress(e)}
          label="Usuário ou E-mail"
          margin="dense"
          variant="outlined"
          value={values.usuario || ""}
          onChange={(e) => {
            handleChange(e);
            setSnackbar({ visible: false });
          }}
        />
        <StyledTextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockOutlined />
              </InputAdornment>
            ),
          }}
          className={classes.textfield}
          name="senha"
          onKeyPress={(e) => onKeyPress(e)}
          type="password"
          label="Senha"
          margin="dense"
          variant="outlined"
          value={values.senha || ""}
          onChange={(e) => {
            handleChange(e);
            setSnackbar({ visible: false });
          }}
        />
        <div className={classes.btn}>
          <Button onClick={(e) => logar()} disabled={isLoading} className={classes.button}>
            Entrar
          </Button>
          {isLoading ? <CircularProgress size="small" className={classes.progress} /> : null}
        </div>
      </Card>
    </div>
  );
};
export default Login;
