import { useCallback, useEffect, useState } from "react";
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { Upload } from "../../../../../../../upload";
import { EnumTipoVeiculo } from "../../../../../../../enums/enumVeiculos";
import { UtaliiDatePickerNormal } from "../../../../../Desktop/sections/custom-date-picker-normal";
import { fData } from "../../../../../../../upload/utils/utils";
import { useCustomSnackbar } from "../../../../../../../components/snackbar2/NewSnackBar";
import prestacaoDeContasRepositorio from "../../../../../../../repositorios/prestacaodecontas";
import { processImage } from "../../../../../../../components/autalii_expanses/controlimages";
import { EnumTipo } from "../../../../../../../enums/enumPrestacaoDeContas";
import ULoaderVoo from "../../../../../../../components/uloadervoo";

export const EditHodometerManual = ({ viagemid, hodometro, editHodometro, setType }) => {
    const [descricao, setDescricao] = useState(hodometro.descricao);
    const [inicialFoto, setInicialFoto] = useState(hodometro.hodometro.comprovanteinicial);
    const [finalFoto, setFinalFoto] = useState(hodometro.hodometro.comprovantefinal);
    const [inicialKm, setInicialKm] = useState(hodometro.hodometro.kminicial);
    const [finalKm, setFinalKm] = useState(hodometro.hodometro.kmfinal);
    const [datadecompra, setDatadecompra] = useState(new Date());
    const [tipoveiculo, setTipoveiculo] = useState(hodometro.hodometro.categoria);
    const [tipocombustivel, setTipocombustivel] = useState(hodometro.hodometro.combustivel);
    const [tipoporte, setTipoporte] = useState(hodometro.hodometro.porte)
    const [loading, setLoading] = useState(false)
    const [produto, setProduto] = useState({});

    const { enqueueSnackbar } = useCustomSnackbar();

    const getAlltipos = useCallback(async () => {
        setLoading(true);
        try {
            const response = await prestacaoDeContasRepositorio.getAllTiposDeDespesas();
    
            response.forEach(item => {
                if (item.name === "Hodometro") {
                    setProduto({
                        iddespesa_tipo: item.iddespesa_tipo,
                        iddespesa_subtipo: item.produtos[0].iddespesa_subtipo
                    });
                }
            });
        } catch {
            enqueueSnackbar('Ocorreu um erro ao buscar categorias de despesas.', { variant: 'error' });
        } finally {
            setLoading(false);
        }
    }, [setLoading, setProduto, enqueueSnackbar]);

    const handleClickEditManual = () => {
        const hodometroData = {
            kminicial: inicialKm,
            kmfinal: finalKm,
            kmtotal: finalKm - inicialKm,
            duracao: 0,
            valor: 0,
            veiculo: {
                categoria: tipoveiculo,
                combustivel: tipocombustivel,
                porte: tipoporte
            }
        };

        if (inicialFoto) {
            hodometroData.comprovanteinicial = inicialFoto;
        }

        if (finalFoto) {
            hodometroData.comprovantefinal = finalFoto;
        }

        const editData = {
            idviagem: viagemid,
            iddespesa: hodometro.iddespesa,
            datadecompra: datadecompra,
            descricao: descricao,
            aprovado: false,
            controladoria: false,
            hodometro: hodometroData,
            produtos: {
                iddespesa_tipo: produto?.iddespesa_tipo,
                iddespesa_subtipo: produto?.iddespesa_subtipo
            },
            total: 0,
            mensagem: "",
            comprovante: null,
            QrCode: null,
            tipo_moeda: EnumTipo.moedas.BRL.id,
        };

        editHodometro(editData);
        setType(0)
    }

    const handleDropHodometroIda = useCallback((acceptedFiles) => {
        const newFile = acceptedFiles[0];
        if (newFile) {
            processImage(Object.assign(newFile, {
                preview: URL.createObjectURL(newFile),
            })).then(base64String => {
                setInicialFoto(base64String); // Aqui você tem a imagem convertida em base64
            }).catch(error => {
                // logMessage("Erro ao converter imagem para Base64:" + error, "error");
            });
        }
    }, []);

    const handleDropHodometroVolta = useCallback((acceptedFiles) => {
        const newFile = acceptedFiles[0];
        if (newFile) {
            processImage(Object.assign(newFile, {
                preview: URL.createObjectURL(newFile),
            })).then(base64String => {
                setFinalFoto(base64String); // Aqui você tem a imagem convertida em base64
            }).catch(error => {
                // logMessage("Erro ao converter imagem para Base64:" + error, "error");
            });
        }
    }, []);

    useEffect(() => {
        getAlltipos()
    }, [getAlltipos])

    return (
        <>
            <ULoaderVoo isLoading={loading} />
            <Grid
                container
                item xs={12}
            >
                <Grid container item xs={12} spacing={2} style={{ padding: "20px" }}>
                    <Grid container spacing={2}>
                        <Grid
                            container
                            item xs={12}
                            style={{ boxShadow: "0px 0px 6px 2px rgba(0,0,0,0.10)", borderRadius: "10px", padding: "20px", margin: "auto", marginTop: "15px" }}
                        >
                            <Grid item xs={12} style={{ textAlign: "center" }}>
                                <Typography variant="h6" fontSize={14}>
                                    Foto do inicial do hodômetro:
                                </Typography>
                            </Grid>
                            <Upload
                                file={inicialFoto}
                                accept="image/jpeg,image/png,image/gif"
                                style={{ height: "auto" }}
                                onDrop={handleDropHodometroIda}
                                validator={(fileData) => {
                                    if (fileData.size > 1000000) {
                                        return {
                                            code: 'file-too-large',
                                            message: `Essse arquivo é muito grande!! ${fData(1000000)}`,
                                        };
                                    }
                                    return null;
                                }}
                            />
                            <Grid item xs={12} style={{ textAlign: "center" }}>
                                <TextField
                                    style={{ textAlign: "right", justifyContent: "right", display: "flex" }}
                                    fullWidth
                                    id="outlined-number"
                                    label="Total do km inicial"
                                    defaultValue={inicialKm}
                                    onChange={(e) => setInicialKm(parseInt(e.target.value, 10) || 0)}
                                    type="number"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            item xs={12}
                            style={{ boxShadow: "0px 0px 6px 2px rgba(0,0,0,0.10)", borderRadius: "10px", padding: "20px", margin: "auto", marginTop: "15px" }}
                        >
                            <Grid item xs={12} style={{ textAlign: "center" }}>
                                <Typography variant="h6" fontSize={14}>
                                    Foto final do hodômetro:
                                </Typography>
                            </Grid>
                            <Upload
                                file={finalFoto}
                                accept="image/jpeg,image/png,image/gif"
                                style={{ height: "auto" }}
                                onDrop={handleDropHodometroVolta}
                                validator={(fileData) => {
                                    if (fileData.size > 1000000) {
                                        return {
                                            code: 'file-too-large',
                                            message: `Essse arquivo é muito grande!! ${fData(1000000)}`,
                                        };
                                    }
                                    return null;
                                }}
                            />
                            <Grid item xs={12} style={{ textAlign: "center" }}>
                                <TextField
                                    style={{ textAlign: "right", justifyContent: "right", display: "flex" }}
                                    fullWidth
                                    id="outlined-number"
                                    label="Total do km Final"
                                    defaultValue={finalKm}
                                    onChange={(e) => setFinalKm(parseInt(e.target.value, 10) || 0)}
                                    type="number"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item xs={12} style={{ padding: "10px" }}>
                    {/* <Typography variant="h6" gutterBottom>
                                    Registro de Hodômetro Manual
                                </Typography> */}
                    <Grid item xs={12} style={{ marginTop: "0px" }}>
                        <TextField
                            style={{ marginBottom: "10px" }}
                            id="filled-multiline-flexible"
                            variant="outlined"
                            fullWidth
                            required
                            value={descricao}
                            onChange={(e) => setDescricao(e.target.value)}
                            multiline
                            maxRows={4}
                            label="Justificativa/Descrição"
                            placeholder="Viagem para..."
                        />
                    </Grid>
                    <Grid item xs={12} style={{ marginBottom: "10px" }}>
                        <FormControl fullWidth>
                            <InputLabel id="veiculo-select-label">Veículo utilizado</InputLabel>
                            <Select
                                labelId="veiculo-select-label"
                                id="veiculo-select"
                                value={tipoveiculo}
                                onChange={(e) => setTipoveiculo(e.target.value)}
                                label="Veículo utilizado"
                            >
                                {Object.entries(EnumTipoVeiculo.categoria).map(([key, value]) => (
                                    <MenuItem key={value} value={value}>
                                        {key.charAt(0).toUpperCase() + key.slice(1)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} style={{ marginBottom: "10px" }}>
                        <FormControl fullWidth>
                            <InputLabel id="veiculo-select-label">Tipo de Combustivel</InputLabel>
                            <Select
                                labelId="veiculo-select-label"
                                id="veiculo-select"
                                value={tipocombustivel}
                                onChange={(e) => setTipocombustivel(e.target.value)}
                                label="Tipo de Combustivel"
                            >
                                {Object.entries(EnumTipoVeiculo.combustivel).map(([key, value]) => (
                                    <MenuItem key={value} value={value}>
                                        {key.charAt(0).toUpperCase() + key.slice(1)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} style={{ marginBottom: "10px" }}>
                        <FormControl fullWidth>
                            <InputLabel id="veiculo-select-label">Porte do veículo</InputLabel>
                            <Select
                                labelId="veiculo-select-label"
                                id="veiculo-select"
                                value={tipoporte}
                                onChange={(e) => setTipoporte(e.target.value)}
                                label="Porte do veículo"
                            >
                                {Object.entries(EnumTipoVeiculo.porte).map(([key, value]) => (
                                    <MenuItem key={value} value={value}>
                                        {key.charAt(0).toUpperCase() + key.slice(1)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} style={{}}>
                        <UtaliiDatePickerNormal
                            value={datadecompra}
                            onChange={setDatadecompra}
                            label="Data do hodometro"
                            fullWidth={true}
                        // Você pode adicionar outras propriedades conforme necessário
                        />
                    </Grid>
                    <Grid item xs={12} style={{ display: "flex", justifyContent: "right" }}>
                        <Button variant="contained" color="primary" size="large" fullWidth autoFocus onClick={() => handleClickEditManual()}>
                            Editar despesa
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}