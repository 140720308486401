import { Avatar, Button, Grid, Paper, Typography, useTheme } from "@mui/material"
import { stringAvatar } from "../../../theme/palette"
import DialogComponent from "../../../views/prestacaodecontas/Desktop/sections/dialogs-desktop"
import { NovaViagemComponentTutorial } from "../../../views/prestacaodecontas/Desktop/minhasviagens/NovaViagem/indexTutorial"
import { ConfirmButtonNovaViagem, NovaViagemComponent } from "../../../views/prestacaodecontas/Desktop/minhasviagens/NovaViagem"
import Tour from "reactour"
import { deskHelpNovaDespesa } from "../../tutorial/createdespesa"
import { useState } from "react"
import { EnumTipo } from "../../../enums/enumPrestacaoDeContas"

export const CardInitialUser_Desk = ({ pessoa, criarNovaviagem, novaviagem, setNovaviagem, setCriarNovaViagem, confirmCriarNovaViagem }) => {
    const theme = useTheme();
    const [openTutorial, setOpenTutorial] = useState(false);

    const novaviagemtour = {
        titulo: "CGH -> POA",
        id_centrocusto: 1,
        adiantamento: [{ tipo_moeda: EnumTipo.moedas.BRL.id, total: 0, status: EnumTipo.adiantamento.naosolicitado }],
        totalinicial: 100,
        tem_multimonetario: false,
        pedido: true
    };

    const handleClickInitialTour = () => {
        setOpenTutorial(true)
        setNovaviagem(novaviagemtour)
    }

    return (
        <Paper
            sx={{
                py: 2,
                textAlign: 'center',
                boxShadow: theme.customShadows,
                position: "sticky",
                top: 80,
                borderRadius: "10px"
            }}
        >
            <Grid container item xs={12} style={{ padding: "20px" }}>
                <Grid item xs={12} style={{ textAlign: "center", justifyContent: "center", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    {pessoa != null && (
                        <Avatar
                            size={5}
                            src={pessoa?.img}
                            {...stringAvatar(pessoa?.nome)}
                            style={{ width: 56, height: 56, marginRight: "-16px" }}
                        />
                    )}
                    <Typography style={{ fontWeight: "600", marginTop: "8px" }}>{pessoa?.nome}</Typography>
                    <Typography style={{ color: "#919EAB" }}>{pessoa?.centrocusto}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Button className="element1" variant="contained" size="large" color="primary" fullWidth onClick={() => setCriarNovaViagem(true)}>
                        Abrir despesa
                    </Button>
                    <DialogComponent
                        title={"Nova despesa"}
                        open={criarNovaviagem}
                        handleClose={() => setCriarNovaViagem(false)}
                        body={
                            <>
                                {openTutorial ? (
                                    <NovaViagemComponentTutorial model={novaviagemtour} />
                                ) : (
                                    <NovaViagemComponent model={novaviagem} setModel={setNovaviagem} openTutorial={openTutorial} />
                                )}
                            </>}
                        action={<ConfirmButtonNovaViagem setOpen={setCriarNovaViagem} confirmFunction={confirmCriarNovaViagem} />}
                        width={"sm"}
                    />
                    {/* <div style={{ justifyContent: "right", display: "flex", width: "100%" }}>
                                        <HelpComponent />
                                    </div> */}
                    <div style={{ justifyContent: "right", display: "flex", width: "100%" }}>
                        <Tour startTour={deskHelpNovaDespesa} setOpenTutorial={setOpenTutorial} setOpen={setCriarNovaViagem} handleClickInitialTour={handleClickInitialTour} />
                    </div>
                </Grid>
            </Grid>
        </Paper>
    )
}