import { ExpandMoreRounded, HistoryToggleOff, KeyboardArrowDownRounded, KeyboardArrowUpRounded, MoreVert } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, IconButton, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import React, { useState } from 'react';
import StyledChip from '../../../../../components/autalii_expanses/chip';
import { formatarValorComMoeda } from '../../../../../enums/enumPrestacaoDeContas';
import { formatarData } from '../../../../prestacaodecontas/Desktop/sections';
import DespesasHistory from '../../../../../components/autalii_expanses/card_viewdespesa/Timeline';

export const RowDespesa = ({ index, open, transaction, handleClick }) => {
    const [expanded, setExpanded] = useState(false);
  
    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  
    return (
      <>
        <TableRow key={index}>
          <TableCell>
            <Typography variant="body2" fontWeight="bold">{transaction.iddespesa}</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body2" fontWeight="bold">
              {capitalizeFirstLetter(format(new Date(transaction.datacriacao), "EEE'.' d MMM 'de' yyyy", { locale: ptBR }))}
            </Typography>
            <Typography variant="caption" color="textSecondary">{transaction.despesa_desc}</Typography>
          </TableCell>
          <TableCell>
            <Box display="flex" alignItems="center">
              {transaction.is_invalido ? (
                <Tooltip title="Despesa marcada como inválida"><StyledChip label="Inválido" color="error" /></Tooltip>
              ) : transaction.aprovado ? (
                transaction.data_aprovado ? (
                  <Tooltip title={`Aprovado em ${formatarData(transaction.data_aprovado, "d MMM 'de' yyyy")} por ${transaction.usuario_aprovador}`}>
                    <StyledChip label="Aprovado" color="primary" />
                  </Tooltip>
                ) : (
                  <StyledChip label="Aprovado" color="primary" />
                )
              ) : (
                <StyledChip label="Pendente" color="secondary" />
              )}
            </Box>
          </TableCell>
          <TableCell align="right">
            {transaction.hodometro
              ? <Typography variant="body2" fontWeight="bold">{transaction.hodometro.kmtotal}km</Typography>
              : <Typography variant="body2" fontWeight="bold">{formatarValorComMoeda(transaction.tipo_moeda, transaction.total)}</Typography>
            }
          </TableCell>
          {!transaction.hodometro && (
            <TableCell align="right">
              <IconButton
                size="small"
                aria-controls={open ? 'demo-positioned-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={(event) => handleClick(event, transaction)}
              >
                <MoreVert />
              </IconButton>
            </TableCell>
          )}
        </TableRow>
  
        {transaction.has_historico && (
          <TableRow>
            <TableCell colSpan={5} sx={{ p: 0 }}>
              <Accordion
                elevation={0}
                expanded={expanded}
                onChange={(_, isExpanded) => setExpanded(isExpanded)}
                sx={{ width: "100%", boxShadow: "0px 0px 2px 1px rgba(0,0,0,0.13)", borderRadius: 0 }}
              >
                <AccordionSummary expandIcon={<ExpandMoreRounded />}>
                  <HistoryToggleOff sx={{ mr: 2 }} />
                  <Typography>Histórico de alteração</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ p: 0 }}>
                  {expanded && <DespesasHistory despesa={transaction} />}
                </AccordionDetails>
              </Accordion>
            </TableCell>
          </TableRow>
        )}
      </>
    );
  }
  