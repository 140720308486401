import { Assignment } from "@mui/icons-material";
import { Box, Button, Grid, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { PendenteD } from "../../../../../../components/autalii_expanses/card_despesa/Pendente/index-desk";
import { DialogWithReturn } from "../../../../../../components/autalii_expanses/dialogs/dialog_MobileReturn";
import { useCustomSnackbar } from "../../../../../../components/snackbar2/NewSnackBar";
import prestacaoDeContasRepositorio from "../../../../../../repositorios/prestacaodecontas";
import { EditarDespesaComponent } from "../EditarDespesa";

export const PendentesList = ({ isExpanded, pendentes, viagem, setGasto, handleClickEditGasto, setType }) => {
    const [selectedPendentes, setSelectedPendentes] = useState([]); // IDs das despesas selecionadas
    const [openCorrigirDialog, setOpenCorrigirDialog] = useState(false);
    const [categorias, setCategorias] = useState([]);
    const [activeStep, setActiveStep] = useState(0);
    const { enqueueSnackbar } = useCustomSnackbar();

    const getAlltipos = useCallback(async () => {
        try {
            const response = await prestacaoDeContasRepositorio.getAllTiposDeDespesas();
            setCategorias(response);
        } catch {
            enqueueSnackbar('Ocorreu um erro ao buscar categorias de despesas.', { variant: 'error' });
        }
    }, [setCategorias, enqueueSnackbar]);

    useEffect(() => {
        getAlltipos();
    }, []);

    // Filtra as despesas selecionadas para correção
    const expensesToCorrect = pendentes.filter((despesa) =>
        selectedPendentes.includes(despesa.iddespesa)
    );

    // Wrapper para simular a correção "passo a passo"
    const handleEditGastoWrapper = (despesa) => {
        // Envia os valores atualizados da despesa
        handleClickEditGasto(despesa);
        enqueueSnackbar(`Despesa ${despesa.iddespesa} corrigida!`, { variant: "success" });

        // Obtém o índice da despesa no array 'pendentes'
        const index = pendentes.findIndex((item) => item.iddespesa === despesa.iddespesa);

        // Avança para o próximo passo se houver mais despesas a corrigir
        if (activeStep < expensesToCorrect.length - 1) {
            setActiveStep(index);
        } else {
            // Se todas foram corrigidas, fecha o diálogo e reseta o fluxo
            setOpenCorrigirDialog(false);
            setSelectedPendentes([]);
            setActiveStep(0);
        }
    };


    return (
        <>
            <Box
                gap={2}
                display="grid"
                gridTemplateColumns={
                    (isExpanded) ? {
                        xs: 'repeat(1, 1fr)',
                        sm: 'repeat(1, 1fr)',
                        md: 'repeat(2, 1fr)',
                    } : {
                        xs: 'repeat(1, 1fr)',
                        sm: 'repeat(2, 1fr)',
                        md: 'repeat(1, 1fr)',
                    }}
                style={{ gap: 10, marginBottom: "20px" }}
            >
                {pendentes.map((value) => (
                    <PendenteD
                        key={value.iddespesa}
                        Gasto={value}
                        Viagemstatus={viagem.status}
                        setGasto={setGasto}
                        // Controle de seleção:
                        selected={selectedPendentes.includes(value.iddespesa)}
                        onSelect={(id) => {
                            if (selectedPendentes.includes(id)) {
                                setSelectedPendentes(selectedPendentes.filter((item) => item !== id));
                            } else {
                                setSelectedPendentes([...selectedPendentes, id]);
                            }
                        }}
                    />
                ))}
            </Box>
            {/* Diálogo de correção coletiva com Stepper padrão do Material UI */}
            <DialogWithReturn
                title={<Typography variant="h6">Corrigir Categorias Pendentes</Typography>}
                open={openCorrigirDialog}
                handleClose={() => setOpenCorrigirDialog(false)}
                fullScreen={false}
                ismap={false}
                body={
                    <Grid container style={{ padding: "0px", display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center" }}>
                        {expensesToCorrect.length > 0 && (
                            <>
                                <Stepper alternativeLabel activeStep={activeStep} style={{ marginBottom: "0px" }}>
                                    {expensesToCorrect.map((_, index) => (
                                        <Step key={index}>
                                            <StepLabel></StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                                {expensesToCorrect[activeStep] && (
                                    <EditarDespesaComponent
                                        key={expensesToCorrect[activeStep].iddespesa}
                                        viagem={viagem}
                                        despesa={expensesToCorrect[activeStep]}
                                        setType={setType}
                                        corrigir={true}
                                        // O wrapper é chamado ao confirmar a edição da despesa atual
                                        handleEdit={handleEditGastoWrapper}
                                    />
                                )}
                            </>
                        )}
                        {expensesToCorrect.length === 0 && (
                            <Typography>Nenhuma despesa selecionada para correção.</Typography>
                        )}
                    </Grid>
                }
                action={null}
            />
            {selectedPendentes.length > 0 && (
                <Grid
                    item
                    xs={12}
                    style={{
                        zIndex: 1002,
                        position: "absolute",
                        bottom: "-20px", // 20px do fundo da tela
                        left: 0,
                        right: 0,
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "20px",
                        padding: "10px",
                        backgroundColor: "#FFFFFF",
                    }}
                >
                    <Grid style={{ display: "flex", width: "100%", textAlign: "right", justifyContent: "right", alignContent: "right" }}>
                        <Button
                            startIcon={<Assignment />}
                            color="warning"
                            variant="contained"
                            style={{ color: "#FFFFFF" }}
                            size="large"
                            onClick={() => {
                                if (selectedPendentes.length === 0) {
                                    enqueueSnackbar("Nenhuma despesa selecionada", { variant: "warning" });
                                } else {
                                    setActiveStep(0);
                                    setOpenCorrigirDialog(true);
                                }
                            }}
                        >
                            Corrigir selecionadas
                        </Button>
                    </Grid>
                </Grid>
            )}
        </>
    );
};
