import { Box, Grid, List, ListItem, Paper, TablePagination, Typography } from '@material-ui/core';
import { AirlineSeatReclineExtraOutlined, CalendarTodayOutlined, ClosedCaptionOutlined, FlightLandOutlined, FlightTakeoffOutlined, MonetizationOnOutlined } from '@material-ui/icons';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import EnumCompany from '../../../../../enums/enumFlightCompany';
import { format, parse, parseISO } from 'date-fns';
import { fDate } from '../../../../../utils/format-time';

function ItinerariosList(props) {
    const variadosReduxTipoPesquisa = useSelector(state => state.variados.aereosSearch);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [itensPerPage, setItensPergPage] = useState(10);
    const pages = Math.ceil(props.data.length);
    const s = page * itensPerPage;
    const r = itensPerPage + s;
    const todaEmissao = props.data.slice(s, r);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setItensPergPage(event.target.value);
    };

    return (
        <>
            <TablePagination
                style={{ minWidth: 'max-content' }}
                labelRowsPerPage=''
                component="div"
                count={pages}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPageOptions={[10, 25, 50]}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <List>
                {todaEmissao.map(item => (
                    <ListItem divider>
                        <div>
                            <Paper elevation={2} style={{ padding: '5px' }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Typography variant='h5'> <b>{item.localizador}</b></Typography>
                                    </Grid>
                                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Typography> <MonetizationOnOutlined fontSize="small" />{item.total.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</Typography>
                                    </Grid>
                                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Typography variant='h6'><b>Passageiro(s)</b></Typography>
                                    </Grid>
                                    {item.passageiro.map(pax => (
                                        <>
                                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Typography style={{ display: 'flex' }}><AirlineSeatReclineExtraOutlined fontSize="small" /><b>{pax.nome}</b></Typography>
                                                <Typography style={{ display: 'flex' }}><ClosedCaptionOutlined fontSize="small" />{pax.cc_codigo} - {pax.cc_nome} </Typography>
                                                <Typography style={{ display: 'flex' }}><MonetizationOnOutlined fontSize="small" />{pax.total.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</Typography>
                                            </Grid>
                                        </>
                                    ))}
                                    <Grid item xs={12} style={{ display: 'flex', height: '90', justifyContent: 'center', alignItems: 'center' }}>
                                        {variadosReduxTipoPesquisa.tipoPesquisaAct === 'nacional' ? (
                                            <img width={80} height="80" alt="Logo" src={EnumCompany.getLogoCompanhiaAereaEnum(item.cia).logo} />
                                        ) : (
                                            <img width={80} height="80" alt="Logo" src={EnumCompany.getLogoCompanhiaAereaEnum(item.cia).url_low} />
                                        )}
                                    </Grid>
                                    {item.infoTrecho.map(trecho => (
                                        <>
                                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Typography><b>{trecho.trecho}</b></Typography>
                                            </Grid>
                                            <Grid item xs={5} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Box mr={0.5}>
                                                    <CalendarTodayOutlined fontSize="small" />
                                                </Box>
                                                <Typography>{fDate(trecho.data)}</Typography>
                                            </Grid>
                                            <Grid item xs={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Box mr={0.5}>
                                                    <FlightTakeoffOutlined fontSize="small" />
                                                </Box>
                                                <Typography>{format(parse(trecho.horaIda, 'HH:mm:ss', new Date()), 'HH:mm')}</Typography>
                                            </Grid>
                                            <Grid item xs={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Box mr={0.5}>
                                                    <FlightLandOutlined fontSize="small" />
                                                </Box>
                                                <Typography>{format(parse(trecho.horaChegada, 'HH:mm:ss', new Date()), 'HH:mm')}</Typography>
                                            </Grid>
                                        </>
                                    ))}















                                    {/* <Grid item xs={12} style={{ display: 'flex' }}>
                                    <Box mr={0.5}>
                                        <AirlineSeatReclineExtraOutlined fontSize="small" />
                                    </Box>
                                    <Typography>{item.passageiro.nome}</Typography>
                                </Grid>
                                <Grid item xs={6} style={{ display: 'flex' }}>
                                    <Box mr={0.5}>
                                        <CalendarTodayOutlined fontSize="small" />
                                    </Box>
                                    <Typography>{item.datacriacao}</Typography>
                                </Grid>
                                <Grid item xs={6} style={{ display: 'flex' }}>
                                    <ConfirmationNumberOutlined fontSize="small" />
                                    <Typography>{item.localizador}</Typography>
                                </Grid>
                                <Grid item xs={6} style={{ display: 'flex' }}>
                                    <Box mr={0.5}>
                                        <FlightTakeoffOutlined fontSize="small" />
                                    </Box>
                                    <Typography>{item.horaida}</Typography>
                                </Grid>
                                <Grid item xs={6} style={{ display: 'flex' }}>
                                    <Box mr={0.5}>
                                        <FlightLandOutlined fontSize="small" />
                                    </Box>
                                    <Typography>{item.horachegada}</Typography>
                                </Grid>
                                <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Typography>{item.trecho}</Typography>
                                </Grid>
                                <Grid item xs={6} style={{ display: 'flex', height: '90' }}>
                                    {variadosReduxTipoPesquisa.tipoPesquisaAct === 'nacional' ? (
                                        <img width={80} height="80" src={EnumCompany.getLogoCompanhiaAereaEnum(item.cia).logo} />
                                    ) : (
                                        <img width={80} height="80" src={EnumCompany.getLogoCompanhiaAereaEnum(item.cia).url_low} />
                                    )}
                                </Grid> */}
                                </Grid>
                            </Paper>
                        </div>
                    </ListItem>
                ))}
            </List>
        </>
    );
}

export default ItinerariosList;
