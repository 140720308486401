import { Badge, Button, ButtonGroup, Container, Grid, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { CardAddInViagem_mob } from "../../../../../../components/autalii_expanses/card_addinviagem";
import { CardDespesa } from "../../../../../../components/autalii_expanses/card_despesa";
import { CardSaldo_mob } from "../../../../../../components/autalii_expanses/card_saldo/index_mob";
import TransferComponent from "../../../../../../components/autalii_expanses/card_transfer";
import { ViewQrCodeProducts } from "../../../../../../components/autalii_expanses/viewqrcode";
import { startTourViagemCriada_Mobile } from "../../../../../../components/tutorial";
import { TourComponent } from "../../../../../../components/tutorial/index2";
import { EnumTipo, formatarParaNomeStatusViagem } from "../../../../../../enums/enumPrestacaoDeContas";
import { IconsSourceSvg } from "../../../../../../images/Icons";
import DialogComponent from "../../../../Desktop/sections/dialogs-desktop";
import { ExtrasDespesasComponentM } from "../Extras";
import { BoltOutlined } from "@mui/icons-material";
import { PendentesList } from "../CorrigirDespesa";
import prestacaoDeContasRepositorio from "../../../../../../repositorios/prestacaodecontas";
import { useCustomSnackbar } from "../../../../../../components/snackbar2/NewSnackBar";
import { useCustomLoading } from "../../../../../../components/uloadervoo/uloader";
import TransferMoedaComponent from "../../../../../../components/autalii_expanses/card_transfer_moeda";

export const DespesaList = ({
    setType,
    viagem,
    gastos,
    gasto,
    setGasto,
    extras,
    loading,
    reload,
    handleClickFinalizarViagem,
    hasApprovedAdiantamento,
    handleClickDeleteGasto,
    handleClickEditGasto,
    handleClickEditSaldo
}) => {
    const [openDeleteDespesa, setOpenDeleteDespesa] = useState(false)
    const [openQrCodeView, setOpenQrCodeView] = useState(false);
    const [currentTab, setCurrentTab] = useState(1);
    const [pendente, setPendente] = useState([]);
    const [incorrectSpen, setIncorrectSpen] = useState([])

    const { enqueueSnackbar } = useCustomSnackbar();
    const { setIsLoading } = useCustomLoading();

    const aggregatedMoedaArray = Object.values(
        viagem.moedatroca.reduce((acc, item) => {
            // Define a chave de agrupamento; aqui utilizamos "tipo_convertida"
            const key = item.tipo_convertida;
            if (!acc[key]) {
                acc[key] = {
                    tipo_original: item.tipo_original,
                    tipo_convertida: item.tipo_convertida,
                    cotacao: item.cotacao,
                    total_original: 0,
                    total_convertida: 0,
                    localizador_comprovante: [],
                    url_comprovante: [],
                    // Se houver outros campos que queira manter, você pode adicioná-los aqui:
                    status: item.status,
                    idpagamento: item.idpagamento,
                };
            }

            // Soma os totais
            acc[key].total_original += item.total_original;
            acc[key].total_convertida += item.total_convertida;

            // Agrupa as strings em arrays (evita duplicatas, se desejado)
            if (item.localizador_comprovante && !acc[key].localizador_comprovante.includes(item.localizador_comprovante)) {
                acc[key].localizador_comprovante.push(item.localizador_comprovante);
            }
            if (item.url_comprovante && !acc[key].url_comprovante.includes(item.url_comprovante)) {
                acc[key].url_comprovante.push(item.url_comprovante);
            }
            return acc;
        }, {})
    );

    // Supondo que 'extras' e 'pendente' sejam arrays disponíveis no seu componente
    const tabsData = [
        { value: 1, label: "Despesas" },
        extras && extras.length > 0 ? { value: 2, label: "Conciliação" } : null,
        pendente && pendente.length > 0 ? { value: 3, label: "Pendente" } : null
    ].filter(tab => tab !== null);


    const handleChangeTab = useCallback((newValue) => {
        setCurrentTab(newValue);
    }, []);

    const handleClickOpenQrCode = (despesa) => {
        setGasto(despesa)
        setOpenQrCodeView(true)
    }

    const verifyPendente = () => {
        let verif = []
        setCurrentTab(1)
        gastos.forEach(element => {
            if (element.produtos.iddespesa_tipo === 1) {
                verif.push(element)
                setCurrentTab(3)
            }
        });
        setPendente(verif)
    }

    const verifyIncorrect = () => {
        let verif = []
        gastos.forEach(element => {
            if (element.mensagem !== "") {
                verif.push(element)
            }
        });
        setIncorrectSpen(verif)
    }

    useEffect(() => {
        verifyPendente()
        verifyIncorrect()
    }, [extras, gastos])

    return (
        <Container maxWidth={"lg"} style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center"
        }}>
            <DialogComponent
                title={
                    <>
                        Informações do QrCode
                    </>
                }
                open={openQrCodeView}
                handleClose={() => setOpenQrCodeView(false)}
                body={
                    <ViewQrCodeProducts QrCode={gasto?.qrCode} />
                }
                action={
                    <>
                    </>
                }
                fullScreen={true}
            />
            {(viagem.status === EnumTipo.viagemstatus.emviagem || viagem.status === EnumTipo.viagemstatus.aguardandoinicio || viagem.status === EnumTipo.viagemstatus.pendenteacorrecao) && (
                <CardAddInViagem_mob
                    viagem={viagem}
                    despesas={gastos}
                    pendente={pendente}
                    incorrectSpen={incorrectSpen}
                    loading={loading}
                    handleClickFinalizarViagem={handleClickFinalizarViagem}
                    hasApprovedAdiantamento={hasApprovedAdiantamento}
                    setOpenGastoAdd={() => setType(1)}
                    setOpenHodometroAdd={() => setType(2)}
                    reload={reload}
                />
            )}
            <Grid container item xs={12} className="total_mob" style={{ display: "flex", justifyContent: "center" }}>
                {(viagem.status !== 1 && viagem.status !== 0) && (
                    <Grid item xs={12} style={{ marginTop: "8px", fontFamily: "sans-serif", textAlign: "center" }}>
                        {formatarParaNomeStatusViagem(viagem.status)}
                    </Grid>
                )}
                <Grid container item xs={12} style={{ textAlign: "center", alignItems: "center", justifyContent: "center", display: "flex", maxWidth: 360, padding: "0px", marginTop: "10px" }}>
                    {!viagem.tem_multimonetario ? (
                        <CardSaldo_mob
                            viagem={viagem}
                            handleClickEditSaldo={handleClickEditSaldo}
                        />
                    ) : (
                        <Grid container item xs={12} style={{ borderRadius: "10px", width: "100%", maxWidth: 360 }}>
                            {[...viagem.adiantamento]
                                .sort((a, b) => (b.pedido === true ? 1 : 0) - (a.pedido === true ? 1 : 0)) // true vai para o topo
                                .map((item) => (
                                    <Grid key={item.idpagamento} item xs={12} style={{ marginTop: "10px" }}>
                                        <TransferComponent
                                            android={true}
                                            viagem={viagem}
                                            despesas={gastos}
                                            totalex={item.total}
                                            totalbrl={item.total_brl}
                                            moeda={item.tipo_moeda}
                                            status={item.status}
                                            idadiantamento={item.idpagamento}
                                            reload={reload}
                                        />
                                    </Grid>
                                ))}
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        {aggregatedMoedaArray && (
                            <Grid item xs={12} style={{ marginTop: "10px" }}>
                                {aggregatedMoedaArray?.map((item) => (
                                    <TransferMoedaComponent
                                        adiantamento={item}
                                        viagem={viagem}
                                        despesas={gastos}
                                        totalex={item.total_convertida}
                                        totalusado={item.total_original}
                                        moedausada={item.tipo_original}
                                        totalbrl={item.total_brl}
                                        moeda={item.tipo_convertida}
                                        status={item.status}
                                        idadiantamento={item.idpagamento}
                                        reload={reload}
                                    />
                                ))}
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ justifyContent: "center", alignContent: "center", textAlign: "center", marginBottom: "0px" }}>
                {!(tabsData.length === 1) && (
                    <ButtonGroup variant="outlined" color="inherit" style={{ justifyContent: "center", borderColor: "#D6D6D6" }}>
                        {tabsData.map((tab) => (
                            <Badge
                                invisible={!(tab.value === 3)}
                                key={tab.value} // É importante passar uma key única para cada elemento
                                badgeContent={pendente.length}
                                sx={{
                                    "& .MuiBadge-badge": {
                                        backgroundColor: "#ffa600",
                                        color: "white",
                                        marginBottom: "-20px",
                                        marginTop: "10px"
                                    }
                                }}
                            >
                                <Button
                                    onClick={() => handleChangeTab(tab.value)}
                                    style={{
                                        textTransform: "none", // Mantém a capitalização original do texto
                                        fontWeight: currentTab === tab.value ? 600 : 400, // Destaca o botão ativo
                                        borderColor: "#D6D6D6"
                                    }}
                                >
                                    <Typography
                                        variant="h6"
                                        style={{ fontSize: "15px", fontWeight: currentTab === tab.value ? 600 : 400 }}
                                    >
                                        {tab.label}
                                    </Typography>
                                </Button>
                            </Badge>
                        ))}
                    </ButtonGroup>
                )}
            </Grid>
            {currentTab === 1 && viagem !== null && (
                <>
                    <DialogComponent
                        title="Deletar gasto"
                        open={openDeleteDespesa}
                        handleClose={() => setOpenDeleteDespesa(false)}
                        body={
                            <Grid container item xs={12}>
                                <Typography>Deseja realmente deletar essa gasto?</Typography>
                            </Grid>
                        }
                        action={
                            <Grid>
                                <Button size="large" onClick={() => setOpenDeleteDespesa(false)}>Cancelar</Button>
                                <Button size="large" onClick={() => { handleClickDeleteGasto(); setOpenDeleteDespesa(false); }}>Confimar</Button>
                            </Grid>
                        }
                        width={"sm"}
                    />
                    {viagem !== null && gastos.length !== 0 ? (
                        <Grid
                            item xs={12}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                                marginBottom: "20px",
                                padding: "0px"
                            }}
                        >
                            {gastos
                                .filter((value) => value.produtos.iddespesa_tipo !== 1)
                                .map((value) => (
                                    <CardDespesa
                                        Gasto={value}
                                        Viagemadiantamento={viagem.adiantamento}
                                        Viagemstatus={viagem.status}
                                        setGasto={setGasto}
                                        onClick={() => {
                                            setGasto(value);
                                            setType(value.hodometro !== null ? 4 : 3);
                                        }}
                                        onDelete={() => {
                                            setGasto(value);
                                            setOpenDeleteDespesa(true);
                                        }}
                                        onEditGasto={() => {
                                            setGasto(value);
                                            setType(3);
                                        }}
                                        onEditHodometro={() => {
                                            setGasto(value);
                                            setType(4);
                                        }}
                                        onQrCode={() => handleClickOpenQrCode(value)}
                                        fullScreen={true}
                                    />
                                ))
                            }

                        </Grid>
                    ) : (
                        <Grid item xs={12} style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginBottom: "20px" }}>
                            <img alt="Imagem de pastas" src={IconsSourceSvg.pastas}></img>
                            <Typography>Nenhuma despesa encontrada</Typography>
                            <TourComponent startTour={startTourViagemCriada_Mobile} />
                        </Grid>
                    )}
                </>
            )}
            {currentTab === 2 && (
                <>
                    <ExtrasDespesasComponentM viagem={viagem} extras={extras} />
                </>
            )}
            {currentTab === 3 && (
                <PendentesList viagem={viagem} pendentes={pendente} setGasto={setGasto} setType={setType} handleClickEditGasto={handleClickEditGasto} />
            )}
        </Container>
    )
}