import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";

const StyledChip = styled(Chip)(({ theme }) => ({
    borderRadius: "16px",
    paddingTop: "1px",
    fontWeight: "bold",
}));

export default StyledChip;
