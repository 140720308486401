import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import { Info, MoreVertOutlined, NotificationImportant } from '@mui/icons-material';
import { fTime } from '../../../../../../utils/format-time';
import { Badge, Button, Tooltip, Typography, alpha, styled } from '@mui/material';
import { formatarParaNomeStatusAdiantamentoList, formatarParaNomeStatusAdiantamentoListI, formatarParaNomeStatusViagem } from '../../../../../../enums/enumPrestacaoDeContas';
import DialogComponent from '../../../sections/dialogs-desktop';
import { DetalhesViagemComponent } from '../../detalhesViagem';
import Menu from '@mui/material/Menu';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { memo, useState } from 'react';
import { AprovarDespesasComponent } from '../../aprovarDespesas';
import { ControladoriaDespesasComponent } from '../../controladoriaDespesas';
import { stringAvatar } from '../../../../../../theme/palette';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { HtmlTooltip } from '../../../../../../components/tooltip';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export const OrderTableRow = memo(({ permissions, row, data, index, selected, onSelectRow, onDeleteRow, handleClickEditDespesa, getViagensReload }) => {
  const [openDetails, setOpenDetails] = useState(false);
  const [openApprove, setOpenApprove] = useState(null);
  const [openControladoria, setOpenControladoria] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [viagem, setViagem] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const breakString = (str, maxLength) => {
    // Condição de parada: se a string restante for menor ou igual ao comprimento máximo
    if (str.length <= maxLength) return str;

    // Define o ponto onde quer dividir a string
    const breakpoint = str.lastIndexOf('+', maxLength);

    // Se encontrar o '+' antes do limite, quebra, senão quebra no maxLength
    const breakAt = breakpoint !== -1 ? breakpoint : maxLength;

    // Se a string restante for pequena, parar a recursão
    if (breakAt === 0) return str;

    // Retorna a parte da string dividida com o hífen e continua processando o restante
    return str.slice(0, breakAt) + '- ' + breakString(str.slice(breakAt).trim(), maxLength);
  };



  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickDetails = () => {
    setViagem(row);
    setOpenDetails(true);
    handleClose(false);
  };

  const handleClickApprove = () => {
    setViagem(row);
    setOpenApprove(true);
    handleClose(false);
  };

  const handleClickControladoria = () => {
    setViagem(row);
    setOpenControladoria(true);
    handleClose(false);
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const { status } = row;

  const renderPrimary = (
    <TableRow selected={selected} sx={{ maxWidth: 300 }}>

      <TableCell padding="checkbox" sx={{ maxWidth: 10 }}>
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell>

      <TableCell sx={{ maxWidth: 10 }}>
        {row.idviagemtenant}
      </TableCell>
 
      <TableCell sx={{ display: 'flex', alignItems: 'center', maxWidth: 260 }}>
        <Avatar {...stringAvatar(row.pessoa.nome)} src={row.pessoa.img} style={{ width: 48, height: 48 }} />

        <HtmlTooltip
          title={
            <div
              style={{
                padding: '8px',
                backgroundColor: '#ffffff',
                borderRadius: '8px',
                color: '#333',
                fontSize: '14px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                fontFamily: "'Roboto Google', sans-serif",
              }}
            >
              <span>{row.titulo}</span>
              <span>{row.pessoa.nome}</span>
            </div>
          }
        >
          <ListItemText
            primary={breakString(row.titulo, 10)} // Limita os caracteres do título exibido
            secondary={row.pessoa.nome}
            primaryTypographyProps={{
              typography: 'body2',
              sx: {
                whiteSpace: 'nowrap', // Impede quebra de linha
                overflow: 'hidden', // Esconde o texto excedente
                textOverflow: 'ellipsis', // Adiciona "..." se necessário
                maxWidth: '120px',
              },
            }}
            secondaryTypographyProps={{
              mt: 0.5,
              display: 'flex',
              component: 'span',
              alignItems: 'center',
              typography: 'caption',
              color: 'text.disabled',
              maxWidth: '120px',
              sx: {
                whiteSpace: 'nowrap', // Impede quebra de linha
                overflow: 'hidden', // Esconde excesso de texto
                textOverflow: 'ellipsis', // Adiciona "..."
              },
            }}
          />
        </HtmlTooltip>


      </TableCell>



      <TableCell>
        <ListItemText
          primary={capitalizeFirstLetter(format(new Date(row.datadecriacao), "EEE'.' d MMM 'de' yyyy", { locale: ptBR }))}
          secondary={fTime(row.datadecriacao)}
          primaryTypographyProps={{ typography: 'body2', noWrap: true }}
          secondaryTypographyProps={{
            mt: 0.5,
            component: 'span',
            typography: 'caption',
          }}
        />
      </TableCell>

      <TableCell align="center">
        {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.total)}
      </TableCell>

      <TableCell align="center">
        {row.tem_multimonetario ? (
          <>
            {formatarParaNomeStatusAdiantamentoListI(row.adiantamento)}
          </>
        ) : (
          <>
            <Tooltip title={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.totalinicial)}>
              {formatarParaNomeStatusAdiantamentoList(row.adiantamento, row.totalinicial)}
            </Tooltip>
          </>
        )}
      </TableCell>

      <TableCell align="center">
        <Typography
          variant="soft"
          color={
            (status === 'completed' && 'success') ||
            (status === 'pending' && 'warning') ||
            (status === 'cancelled' && 'error') ||
            'default'
          }
        >
          {row.status != null ? (
            <>
              {formatarParaNomeStatusViagem(row.status)}
            </>
          ) : (<>
          </>)}
        </Typography>
      </TableCell>

      <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={() => handleClickDetails()} disableRipple>
            <Info />
            Detalhes
          </MenuItem>
          {row.status === 3 && permissions.permissao_controladoria && (
            <MenuItem onClick={() => handleClickControladoria()} disableRipple>
              <AdminPanelSettingsIcon />
              Controladoria
            </MenuItem>
          )}
          {(row.status === 8 || row.status === 9 || row.status === 10 || row.status === 2) && permissions.permissao_contabilidade && (
            <MenuItem onClick={() => handleClickApprove()} disableRipple>
              <AssignmentTurnedInIcon />
              Revisar Despesas
            </MenuItem>
          )}
        </StyledMenu>

        <Badge
          color="error"
          badgeContent={
            <Tooltip title="Fora da política">
              <NotificationImportant fontSize="small" style={{ color: "#FFFFFF" }} />
            </Tooltip>
          }
          invisible={row.necessita_atencao != null && row.necessita_atencao ? false : true}
        >
          <IconButton
            id="demo-customized-button"
            aria-controls={open ? 'demo-customized-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            variant="contained"
            disableElevation
            onClick={handleClick}
          >
            <MoreVertOutlined />
          </IconButton>
        </Badge>

        {viagem != null && (
          <>
            <DialogComponent
              title={
                <>
                  {viagem?.titulo} {viagem ? formatarParaNomeStatusViagem(viagem.status) : null}
                </>
              }
              open={openDetails}
              handleClose={() => setOpenDetails(false)}
              body={<DetalhesViagemComponent viagem={viagem} />}
              action={null}
              width="sm"
            />

            <DialogComponent
              title={
                <div style={{ paddingLeft: "10px", paddingTop: "20px", marginTop: "-20px", marginBottom: "-15px" }}>
                  {viagem?.titulo} {viagem ? formatarParaNomeStatusViagem(viagem.status) : null}
                </div>
              }
              open={openApprove}
              handleClose={() => setOpenApprove(false)}
              body={
                <div style={{ padding: "20px", marginTop: "-10px" }}>
                  <AprovarDespesasComponent
                    viagem={viagem}
                    isExpanded={isExpanded}
                    setIsExpanded={setIsExpanded}
                    setViagem={setViagem}
                    setOpen={setOpenApprove}
                    getViagensReload={getViagensReload}
                  />
                </div>
              }
              ismap={true}
              action={null}
              fullWidth={true}
              width={isExpanded ? "lg" : "sm"}
            />

            <DialogComponent
              title={
                <>
                  {viagem?.titulo} {viagem ? formatarParaNomeStatusViagem(viagem.status) : null}
                </>
              }
              open={openControladoria}
              handleClose={() => setOpenControladoria(false)}
              body={<ControladoriaDespesasComponent viagem={viagem} setViagem={setViagem} setOpen={setOpenApprove} getViagensReload={getViagensReload} handleClickEditDespesa={handleClickEditDespesa} />}
              action={null}
              width="md"
            />
          </>
        )}

      </TableCell>
    </TableRow>
  );

  return (
    <>
      {renderPrimary}
    </>
  );
})

OrderTableRow.propTypes = {
  row: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  selected: PropTypes.bool,
  onDeleteRow: PropTypes.func,
  onSelectRow: PropTypes.func,
};
