import { CircularProgress, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useCustomSnackbar } from "../../../../../components/snackbar2/NewSnackBar";
import { hasApprovedAdiantamento } from "../../../../../enums/enumPrestacaoDeContas";
import DialogComponent from "../../../Desktop/sections/dialogs-desktop";
import { Scripts } from '../../../Scripts';
import { AdicionarDespesaComponentM } from "./AdicionarDespesa";
import { DespesaBar } from "./DespesaBar";
import { DespesaList } from './DespesaList';
import { EditarDespesaComponentM } from "./EditarDespesa";
import { HodometroComponentM } from "./Hodometro";
import { ViewHodometerMComponent } from "./HodometroInfo";

export const DespesaComponent = ({
    IdDespesa,
    openViagem = false,
    setOpenViagem,
    handleClickExcluirViagem
}) => {
    //Type 0
    const [type, setType] = useState(0);
    const [viagem, setViagem] = useState(null);
    const [gastos, setGastos] = useState([]);

    const [gasto, setGasto] = useState(null)
    const [extras, setExtras] = useState([]);
    const [loading, setLoading] = useState(false);

    const { enqueueSnackbar } = useCustomSnackbar();

    const handleClickFinalizarViagem = async () => {
        await Scripts.handleFinalizarViagem(setLoading, enqueueSnackbar, viagem.id)
        await Scripts.getViagemForID(setLoading, enqueueSnackbar, IdDespesa, setViagem)
    }

    const handleClickCreateGasto = async (despesa) => {
        await Scripts.handleClickCriarDespesa(setLoading, enqueueSnackbar, despesa)
        await Scripts.getViagemForID(setLoading, enqueueSnackbar, IdDespesa, setViagem)
        await Scripts.getGastos(setLoading, enqueueSnackbar, IdDespesa, setGastos, setExtras)
    }

    const handleClickEditGasto = async (despesa) => {
        await Scripts.handleClickEditDespesa(setLoading, enqueueSnackbar, despesa)
        await Scripts.getViagemForID(setLoading, enqueueSnackbar, IdDespesa, setViagem)
        await Scripts.getGastos(setLoading, enqueueSnackbar, IdDespesa, setGastos, setExtras)
    }

    const handleClickDeleteGasto = async () => {
        await Scripts.handleDeleteDespesa(setLoading, enqueueSnackbar, gasto.iddespesa)
        await Scripts.getViagemForID(setLoading, enqueueSnackbar, IdDespesa, setViagem)
        await Scripts.getGastos(setLoading, enqueueSnackbar, IdDespesa, setGastos, setExtras)
    }

    const handleClickEditViagem = async (titulo = "", startDate = null, endDate = null) => {
        await Scripts.handleClickEditViagem(setLoading, enqueueSnackbar, viagem, titulo, startDate, endDate);
        await Scripts.getViagemForID(setLoading, enqueueSnackbar, IdDespesa, setViagem)
    };

    const handleClickEditSaldo = async (saldo) => {
        await Scripts.handleClickEditSaldo(setLoading, enqueueSnackbar, viagem, saldo);
        await Scripts.getViagemForID(setLoading, enqueueSnackbar, IdDespesa, setViagem)
    }

    const reload = async () => {
        setViagem(null)
        Scripts.getGastos(setLoading, enqueueSnackbar, IdDespesa, setGastos, setExtras)
        Scripts.getViagemForID(setLoading, enqueueSnackbar, IdDespesa, setViagem)
    }

    useEffect(() => {
        if (IdDespesa) {
            setViagem(null)
            Scripts.getGastos(setLoading, enqueueSnackbar, IdDespesa, setGastos, setExtras)
            Scripts.getViagemForID(setLoading, enqueueSnackbar, IdDespesa, setViagem)
        }
    }, [IdDespesa])

    return (
        <Grid>
            <DialogComponent
                title={
                    <>
                        {viagem && (
                            <DespesaBar
                                viagem={viagem}
                                handleClickEditViagem={handleClickEditViagem}
                                handleClickExcluirViagem={handleClickExcluirViagem}
                                temDespesa={gastos.length > 0}
                            />
                        )}
                    </>
                }
                open={openViagem}
                handleClose={() => setOpenViagem(false)}
                fullScreen={true}
                ismap={true}
                body={
                    <>
                        {!viagem ? (
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
                                <CircularProgress color="success" style={{ zIndex: 1200 }} />
                            </div>
                        ) : (
                            <>
                                {(type === 0 && viagem !== null) && (
                                    <DespesaList
                                        viagem={viagem}
                                        loading={loading}
                                        gastos={gastos}
                                        extras={extras}
                                        gasto={gasto}
                                        setType={setType}
                                        setViagem={setViagem}
                                        setExtras={setExtras}
                                        setGastos={setGastos}
                                        setLoading={setLoading}
                                        setGasto={setGasto}
                                        handleClickFinalizarViagem={handleClickFinalizarViagem}
                                        hasApprovedAdiantamento={hasApprovedAdiantamento}
                                        handleClickDeleteGasto={handleClickDeleteGasto}
                                        handleClickEditGasto={handleClickEditGasto}
                                        handleClickEditSaldo={handleClickEditSaldo}
                                        reload={reload}
                                    />
                                )}
                                {
                                    type === 1 && (
                                        <>
                                            <AdicionarDespesaComponentM viagem={viagem} handleCreate={handleClickCreateGasto} setType={setType} />
                                        </>
                                    )
                                }
                                {
                                    type === 3 && (
                                        <>
                                            <EditarDespesaComponentM viagem={viagem} despesa={gasto} handleEdit={handleClickEditGasto} setType={setType} />
                                        </>
                                    )
                                }
                                {
                                    type === 2 && (
                                        <HodometroComponentM viagem={viagem} handleClickCreateHodometro={handleClickCreateGasto} setType={setType} />
                                    )
                                }
                                {
                                    type === 4 && (
                                        <ViewHodometerMComponent viagem={viagem} hodometro={gasto} setType={setType} editHodometro={handleClickEditGasto} typehodometro={0} />
                                    )
                                }
                            </>
                        )}
                    </>
                }
                width={"sm"}
            />
        </Grid>
    )
}