import { KeyboardArrowRightRounded, Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { EnumTipo, formatarMoeda, formatarParaMoedaString, formatarValorComMoeda } from '../../../enums/enumPrestacaoDeContas';
import prestacaoDeContasRepositorio from '../../../repositorios/prestacaodecontas';
import { useCustomSnackbar } from '../../snackbar2/NewSnackBar';
import { useCustomLoading } from '../../uloadervoo/uloader';
import { DialogWithReturn } from '../dialogs/dialog_MobileReturn';

const TransferMoedaComponent = ({ adiantamento, viagem, reload, despesas, totalusado, moedausada, getViagem, idadiantamento, totalex = 0.00, totalbrl = 0.00, moeda = EnumTipo.moedas.USD.id, status }) => {
    const [showBalance, setShowBalance] = useState(true);
    const [total, setTotal] = useState(0);
    const [editSaldo, setEditSaldo] = useState(0);
    const [novaMoeda, setNovaMoeda] = useState(false)
    const [saldo, setSaldo] = useState(0);
    const [saldoedit, setSaldoedit] = useState(0);

    const [openInfo, setOpenInfo] = useState(false)

    const handleToggleBalance = () => {
        setShowBalance(!showBalance);
    };

    const GetGastoTotal = () => {
        let total2 = 0;
        despesas.forEach(despesa => {
            if (despesa.tipo_moeda === moeda) {
                total2 += despesa.total;
            }
        });
        setTotal(total2);
    }

    useEffect(() => {
        setSaldoedit(totalex)
        GetGastoTotal()
    }, [despesas])

    return (
        <Box sx={{ maxWidth: 400, p: 3, borderRadius: "10px", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", backgroundColor: 'white' }}>
            <DialogWithReturn
                title={
                    <Typography variant='body2' style={{ color: "#5c5c5c" }}>
                    </Typography>
                }
                open={editSaldo}
                handleClose={() => setEditSaldo(false)}
                body={
                    <Grid container item xs={12} style={{
                        height: "100%",
                    }}>

                    </Grid>
                }
                action={
                    <>
                    </>
                }
                fullScreen={true}
                width={"sm"}
            />
            <Grid container spacing={1} style={{ marginTop: "-16px" }}>
                {status === EnumTipo.adiantamento.aprovado ? (
                    <>
                        <Grid item xs={12}>
                            <Typography variant="caption" color="textSecondary">{formatarParaMoedaString(moeda, totalex)}</Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: "-14px" }}>
                                <Typography variant="h6" style={{ fontWeight: "600", color: '#262626', fontSize: "20px", textAlign: "left", display: "flex" }}>
                                    <div style={{ display: "flex" }}>
                                        {showBalance ? formatarValorComMoeda(moeda, totalex - total) : '***'}
                                    </div>
                                    <IconButton onClick={handleToggleBalance} size="small" style={{ marginTop: "-3px" }}>
                                        {showBalance ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: "2px" }}>
                                <Typography variant="body2" sx={{ fontWeight: "600", color: '#393939' }}>
                                    Saldo Atual
                                </Typography>
                            </Box>
                        </Grid>
                    </>
                ) : (
                    <>
                        <Grid item xs={6}>
                            <Box style={{ width: "-webkit-fill-available", paddingLeft: "0px", textAlign: "left" }}>
                                <Typography variant="caption" color="textSecondary">{formatarParaMoedaString(moeda, totalbrl)}</Typography>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: "-14px" }}>
                                    <Typography variant="h6" style={{ fontWeight: "600", color: '#262626', fontSize: "20px", textAlign: "left", display: "flex" }}>
                                        <div style={{ display: "flex" }}>
                                            {showBalance ? formatarValorComMoeda(moeda, totalex - total) : '***'}
                                        </div>
                                        <IconButton onClick={handleToggleBalance} size="small" style={{ marginTop: "-3px" }}>
                                            {showBalance ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: "6px" }}>
                                    <Typography variant="body2" sx={{ fontWeight: "600", color: '#393939' }}>
                                        Saldo Atual
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={6} container style={{ textAlign: "right", justifyContent: "flex-end", alignItems: "flex-end" }}>
                            <Grid item xs={12}>
                                <Typography variant='body2' style={{ color: "#1a9b00", fontWeight: "600",  }}>{formatarValorComMoeda(moedausada, totalusado)}</Typography>
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
        </Box >
    );
};

export default TransferMoedaComponent;
