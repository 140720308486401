import { Avatar, Button, Card, CardActions, CardContent, Grid, Typography } from "@mui/material"
import DialogComponent from "../../../views/prestacaodecontas/Desktop/sections/dialogs-desktop"
import { TourComponent } from "../../tutorial/index2"
import { startTourNovaViagem_Mobile } from "../../tutorial"
import { NovaViagemComponentM } from "../../../views/prestacaodecontas/Mobile/minhasviagens/NovaViagem"
import { stringAvatar } from "../../../theme/palette"

export const CardInitialUser_Mob = ({ pessoa, criarNovaviagem, novaviagem, setNovaviagem, setCriarNovaViagem, confirmCriarNovaViagem }) => {
    return (
        <Card style={{ width: 'auto', textAlign: 'center', padding: '16px', borderRadius: '8px' }}>
            <CardContent>
                {pessoa?.nome !== "" && pessoa?.nome !== null && (
                    <Avatar
                        {...stringAvatar(pessoa?.nome)}
                        alt={pessoa?.nome}
                        src={pessoa?.img}
                        style={{ margin: '0 auto', width: '80px', height: '80px' }}
                    />
                )}
                <Typography variant="h6" style={{ marginTop: '8px', fontWeight: 'bold' }}>
                    {pessoa?.nome}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    {pessoa?.centrocusto}
                </Typography>
            </CardContent>
            <CardActions style={{ justifyContent: 'center' }}>
                <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => setCriarNovaViagem(true)}
                    id="#demo__1">
                    Abrir despesa
                </Button>
                <DialogComponent
                    title={
                        <Grid container item xs={12}>
                            <Grid container item xs={6}>
                                <Typography style={{ fontWeight: "600" }}>Nova despesa</Typography>
                            </Grid>
                            <Grid container item xs={6} style={{ display: "flex", justifyContent: "center", alignItens: "center", height: "0px", marginTop: "-14px" }}>
                                <TourComponent startTour={startTourNovaViagem_Mobile} />
                            </Grid>
                        </Grid>}
                    open={criarNovaviagem}
                    handleClose={() => setCriarNovaViagem(false)}
                    fullScreen={true}
                    body={<NovaViagemComponentM model={novaviagem} setModel={setNovaviagem} confirmCriarNovaViagem={confirmCriarNovaViagem} />}
                    action={<></>}
                    width={"sm"}
                />
            </CardActions>
        </Card>
    )
}