import { DeleteOutlineOutlined, EditRounded, MoreVertOutlined, QrCode2Outlined } from "@mui/icons-material"
import { Button, Chip, Grid, IconButton, MenuItem, Typography } from "@mui/material"
import { useState } from "react"
import { EnumTipo, formatarValorComMoeda } from "../../../../enums/enumPrestacaoDeContas"
import { CreationDate } from "../../../../views/prestacaodecontas/Desktop/sections"
import { StyledMenu } from "../../../MenuStyled/style"

export const HodometerD = ({
    Gasto, setGasto, Viagemstatus, handleClickEdit, handleClickDelete, handleClickDespesa, onQrCode
}) => {
    return (
        <Grid container item xs={12} style={{ padding: "20px" }}>
            <Grid container item xs={7} style={{ display: "flex", flexDirection: "column", textAlign: "justify" }}>
                <Typography style={{ fontWeight: "600" }}>Hodômetro</Typography>
            </Grid>
            <Grid item xs={5} style={{ display: "flex", justifyContent: "right", textAlign: "right" }}>
                <Grid style={{ marginLeft: "auto" }}>
                    <CreationDate data={Gasto.datadecompra} />
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ textAlign: "left" }}>
                <Typography style={{ fontWeight: "500", color: "#595959" }}>{Gasto.descricao}</Typography>
            </Grid>
            <Grid item xs={6} style={{
                display: "flex",
                justifyContent: "flex-start",
                alignContent: "flex-start",
                alignItems: "flex-start",
                flexDirection: "row",
                marginTop: "auto"
            }}>
                <Typography style={{ fontSize: "small" }}>
                    {Gasto.despesa_desc}
                </Typography>
            </Grid>
            <Grid item xs={4} style={{
                display: "flex",
                justifyContent: "flex-end",
                alignContent: "flex-end",
                alignItems: "flex-end",
                flexDirection: "row",
                marginTop: "auto"
            }}>
                {(Viagemstatus === EnumTipo.viagemstatus.aguardandoinicio ||
                    Viagemstatus === EnumTipo.viagemstatus.emviagem ||
                    Viagemstatus === EnumTipo.viagemstatus.pendenteacorrecao) && (
                        <>
                            <IconButton onClick={() => { setGasto(Gasto); handleClickDelete() }}>
                                <DeleteOutlineOutlined />
                            </IconButton>
                            <IconButton onClick={handleClickEdit}>
                                <EditRounded />
                            </IconButton>
                        </>
                    )
                }
            </Grid>
            <Grid item xs={2} style={{
                display: "flex",
                justifyContent: "flex-end",
                alignContent: "flex-end",
                alignItems: "flex-end",
                flexDirection: "row",
            }}>
                <Grid style={{ marginRight: "10px" }}>
                    {Gasto.aprovado ? (
                        <Chip label="Aprovado" color="primary" />
                    ) : (
                        <>
                            {Viagemstatus === EnumTipo.viagemstatus.controladoria && !Gasto.controladoria ? (
                                <Chip label="Pendente" color="secondary" />
                            ) : (
                                null
                            )}
                        </>
                    )}
                </Grid>
                <Typography style={{ fontWeight: "600", color: "rgba(0, 0, 0, 0.87)" }}>
                    {Gasto.hodometro.kmtotal} Km
                </Typography>
            </Grid>
        </Grid>
    )
}