import { Container } from "@material-ui/core";
import { SearchOutlined } from "@mui/icons-material";
import { Grid, InputAdornment, Pagination, Skeleton, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { CardInitialUser_Mob } from "../../../../components/autalii_expanses/card_initialuser";
import { CardMyViagem_mob } from "../../../../components/autalii_expanses/card_viagem/index_mob";
import { FilterComponent_mob } from "../../../../components/autalii_expanses/FiltersViagens";
import usePagination from "../../../../components/pagination";
import { useCustomSnackbar } from "../../../../components/snackbar2/NewSnackBar";
import ULoaderVooMessage from "../../../../components/uloadervoo/loader";
import { EnumTipo } from "../../../../enums/enumPrestacaoDeContas";
import { IconsSourceSvg } from "../../../../images/Icons";
import prestacaoDeContasRepositorio from "../../../../repositorios/prestacaodecontas";
import { Scripts } from "../../Scripts";
import { DespesaComponent } from "./Viagem";

export const MinhasViagensM = () => {
    const [minhasviagens, setMinhasViagens] = useState([]);
    const [criarNovaviagem, setCriarNovaViagem] = useState(false);
    const [openViagem, setOpenViagem] = useState(false);
    const [idDespesa, setIdDespesa] = useState(null);
    const [novaviagem, setNovaviagem] = useState({
        titulo: "",
        id_centrocusto: 0,
        adiantamento: [{ tipo_moeda: EnumTipo.moedas.BRL.id, total: "100,00", status: EnumTipo.adiantamento.solicitado }],
        totalinicial: "100,00",
        tem_multimonetario: false,
        pedido: true,
        datainicio: null,
        datafim: null,
    });
    const [pessoa, setPessoa] = useState({})
    const [loading, setLoading] = useState(false)
    const [filterViagens, setFilterViagens] = useState([]);
    const [openFilters, setOpenFilters] = useState(false)
    const [selectedDate, setSelectedDate] = useState([null, null]);
    const initialStatuses = Object.keys(EnumTipo.viagemstatus).reduce((acc, key) => {
        acc[key] = false;  // default status is inactive
        return acc;
    }, {});
    const [statuses, setStatuses] = useState(initialStatuses);

    const hasActiveStatus = Object.values(statuses).some((value) => value);

    const itemsPerPage = 6;

    // Usar o hook de paginação
    const { currentItems, page, totalPages, setPage } = usePagination(filterViagens, itemsPerPage);

    // Função para mudar de página
    const handleChangePage = (event, value) => {
        setPage(value);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const { enqueueSnackbar } = useCustomSnackbar();

    const handlesearchname = (e) => {
        const searchTerm = e.target.value.toLowerCase();

        const filteredViagens = minhasviagens.filter(viagem =>
            viagem.titulo.toLowerCase().includes(searchTerm)
        );

        setPage(1)

        setFilterViagens(filteredViagens);
    }


    const applyFilters = () => {
        Scripts.getMinhasViagens(setLoading, enqueueSnackbar, selectedDate, setMinhasViagens, setPessoa)
        setOpenFilters(false)
    }

    const confirmCriarNovaViagem = async (model) => {
        await Scripts.confirmCriarNovaViagem(setLoading, enqueueSnackbar, model);
        await Scripts.getMinhasViagens(setLoading, enqueueSnackbar, selectedDate, setFilterViagens, setPessoa)
        setCriarNovaViagem(false);
    }

    const handleClickDespesa = (iddespesa) => {
        setLoading(true)
        setIdDespesa(iddespesa)
        setOpenViagem(true)
        setLoading(false)
    }

    const handleClickExcluirViagem = async (idviagem) => {
        setLoading(true)
        try {
            await prestacaoDeContasRepositorio.deleteViagem(idviagem)
            Scripts.getMinhasViagens(setLoading, enqueueSnackbar, selectedDate, setMinhasViagens, setPessoa)
            setIdDespesa(null)
            enqueueSnackbar('Despesa deletada com sucesso.', { variant: 'success' });
        } catch (e) { // Correção: incluído o parâmetro de erro no catch
            enqueueSnackbar('Ocorreu um erro ao deletar despesa.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (!openViagem) {
            Scripts.getMinhasViagens(setLoading, enqueueSnackbar, selectedDate, setFilterViagens, setPessoa)
        }
    }, [openViagem])

    return (
        <>
            <ULoaderVooMessage isLoading={loading} />
            <Container maxWidth={"sm"} item xs={12} container style={{ marginBottom: "70px" }}>
                <Grid item xs={12}>
                    <CardInitialUser_Mob pessoa={pessoa} criarNovaviagem={criarNovaviagem} novaviagem={novaviagem} setNovaviagem={setNovaviagem} setCriarNovaViagem={setCriarNovaViagem} confirmCriarNovaViagem={confirmCriarNovaViagem} />
                </Grid>
                <Grid container item xs={12}>
                    <Grid container item xs={10} style={{ marginTop: "20px" }}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            label="Pesquisar por título"
                            onChange={(e) => handlesearchname(e)}
                            placeholder="Pesquisar por título..."
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchOutlined />
                                    </InputAdornment>
                                ),
                            }}
                            style={{
                                borderRadius: "15px",
                                fontStyle: "inherit",
                                fontFamily: "sans-serif",
                            }}
                        />
                    </Grid>
                    <Grid item xs={2} style={{ display: 'flex', alignItems: "flex-end", justifyContent: "center" }}>
                        <FilterComponent_mob
                            openFilters={openFilters}
                            hasActiveStatus={hasActiveStatus}
                            setOpenFilters={setOpenFilters}
                            selectedDate={selectedDate}
                            setSelectedDate={setSelectedDate}
                            statuses={statuses}
                            setStatuses={setStatuses}
                            applyFilters={applyFilters}
                        />
                    </Grid>
                </Grid>

                <Grid container item xs={12}>
                    {currentItems?.map((item, index) => (
                        <>
                            <CardMyViagem_mob item={item} handleClickDespesa={handleClickDespesa} />
                        </>
                    ))}

                    {(currentItems.length === 0 && !loading) && (
                        <Grid item xs={12} style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginBottom: "20px" }}>
                            <img alt="Pastas imagem" src={IconsSourceSvg.pastas}></img>
                            <Typography variant={"body1"} style={{ color: "#818181", marginTop: "10px" }}>Nenhuma despesa encontrada</Typography>
                        </Grid>
                    )}

                    {(loading) && (
                        <Grid item xs={12} style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginTop: "20px" }}>
                            <Skeleton variant="rectangular" height={170} style={{ borderRadius: "16px", width: "100%" }} />
                        </Grid>
                    )}

                    <Grid item xs={12} marginBottom={8} marginTop={2} style={{ display: "flex", justifyContent: "center" }}>
                        {totalPages > 1 && (
                            <Pagination
                                color="primary"
                                count={totalPages}
                                page={page}
                                onChange={handleChangePage}
                                siblingCount={0}
                                shape="rounded"
                            />
                        )}
                    </Grid>
                    {idDespesa && (
                        <DespesaComponent
                            IdDespesa={idDespesa}
                            openViagem={openViagem}
                            setOpenViagem={setOpenViagem}
                            handleClickExcluirViagem={handleClickExcluirViagem}
                        />
                    )}
                </Grid>
            </Container>
        </>
    )
}