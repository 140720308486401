import React, { useCallback, useEffect, useMemo, useState } from "react";
import SearchIcon from '@mui/icons-material/Search';
import { Container, Grid, IconButton, InputAdornment, Pagination, TextField } from "@mui/material";
import { useCustomSnackbar } from "../../../../components/snackbar2/NewSnackBar";
import { EnumTipo } from "../../../../enums/enumPrestacaoDeContas";
import prestacaoDeContasRepositorio from "../../../../repositorios/prestacaodecontas";
import 'shepherd.js/dist/css/shepherd.css';
import { CardInitialUser_Desk } from "../../../../components/autalii_expanses/card_initialuser/index_desk";
import { CardMyViagem_desk } from "../../../../components/autalii_expanses/card_viagem/index_desk";
import { useCustomLoading } from "../../../../components/uloadervoo/uloader";
import { Scripts } from "../../Scripts";
import { DespesaComponent } from "./Viagem";

export const MinhasViagens = () => {
  const [minhasviagens, setMinhasViagens] = useState([]);
  const [pessoa, setPessoa] = useState(null);
  const [criarNovaviagem, setCriarNovaViagem] = useState(false);
  const [openViagem, setOpenViagem] = useState(false);
  const [selectedDate, setSelectedDate] = useState([null, null]);
  const [filterViagens, setFilterViagens] = useState([]);
  const [novaviagem, setNovaviagem] = useState({
    titulo: "",
    id_centrocusto: 0,
    adiantamento: [{
      tipo_moeda: EnumTipo.moedas.BRL.id,
      total: 0,
      status: EnumTipo.adiantamento.naosolicitado
    }],
    totalinicial: 0,
    tem_multimonetario: false,
    pedido: false,
    datainicio: null,
    datafim: null,
  });
  const [idDespesa, setIdDespesa] = useState(null);
  const [page, setPage] = useState(1);
  const itemsPerPage = 5;

  // Memoriza os itens atuais para evitar recalcular a cada render
  const currentItems = useMemo(() => {
    const startIndex = (page - 1) * itemsPerPage;
    return filterViagens.slice(startIndex, startIndex + itemsPerPage);
  }, [filterViagens, page]);

  const { enqueueSnackbar } = useCustomSnackbar();
  const { setIsLoading } = useCustomLoading();

  // Busca os dados apenas quando houver alteração real
  const getMinhasViagens = useCallback(async () => {
    const controller = new AbortController();

    await Scripts.getMinhasViagens(
      setIsLoading,
      enqueueSnackbar,
      selectedDate,
      (viagens) => {
        setFilterViagens(viagens);
        setMinhasViagens(viagens);
      },
      setPessoa
    );
    
    return () => {
      // Ao desmontar, cancela a requisição
      controller.abort();
    };
  }, []);

  // Evita recriar a função a cada render
  const handleSearchName = useCallback((e) => {
    const searchTerm = e.target.value.toLowerCase();
    const filtered = minhasviagens.filter(viagem =>
      viagem.titulo.toLowerCase().includes(searchTerm)
    );
    setPage(1);
    setFilterViagens(filtered);
  }, [minhasviagens]);

  const confirmCriarNovaViagem = async () => {
    await Scripts.confirmCriarNovaViagem(setIsLoading, enqueueSnackbar, novaviagem);
    await getMinhasViagens();
    setCriarNovaViagem(false);
  };

  const handleClickExcluirViagem = useCallback(async (idviagem) => {
    setIsLoading(true);
    try {
      await prestacaoDeContasRepositorio.deleteViagem(idviagem);
      setOpenViagem(false);
      await getMinhasViagens();
      enqueueSnackbar('Viagem deletada com sucesso.', { variant: 'success' });
    } catch (error) {
      if (error.response && error.response.status === 409) {
        enqueueSnackbar('Não foi possível excluir a despesa devido a um conflito.', { variant: 'warning' });
      } else {
        enqueueSnackbar('Ocorreu um erro ao excluir a despesa.', { variant: 'error' });
      }
    } finally {
      setIsLoading(false);
    }
  }, [setIsLoading, getMinhasViagens, enqueueSnackbar]);

  const handleClickDespesa = useCallback((iddespesa) => {
    setIdDespesa(iddespesa);
    setOpenViagem(true);
  }, []);

  useEffect(() => {
    if (!openViagem) {
      getMinhasViagens();
    }
  }, [openViagem]);

  return (
    <>
      <Container maxWidth="lg">
        <Grid container item xs={12}>
          <Grid item xs={4}>
            <CardInitialUser_Desk
              pessoa={pessoa}
              criarNovaviagem={criarNovaviagem}
              novaviagem={novaviagem}
              setNovaviagem={setNovaviagem}
              setCriarNovaViagem={setCriarNovaViagem}
              confirmCriarNovaViagem={confirmCriarNovaViagem}
            />
          </Grid>
          <Grid container item xs={8} style={{ padding: "20px", marginTop: "-20px" }}>
            <Grid container item xs={12} alignItems="center" spacing={2}>
              <Grid item xs={4} style={{ display: "flex", alignItems: "center" }}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Pesquisar por título"
                  onChange={handleSearchName}
                  placeholder="Pesquisar por título..."
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                  style={{ borderRadius: "15px" }}
                />
              </Grid>
              <Grid item xs={8} style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", marginTop: "-7px" }}>
                <IconButton variant="contained" color="primary" sx={{ height: "45px" }} onClick={getMinhasViagens}>
                  <SearchIcon />
                </IconButton>
              </Grid>
            </Grid>

            <Grid container item xs={12} style={{ marginTop: "10px" }}>
              <Grid item xs={12} style={{ gap: "5px", display: "flex", flexWrap: "wrap" }}>
                {currentItems.map((value) => (
                  <CardMyViagem_desk key={value.id} despesa={value} onClick={handleClickDespesa} />
                ))}
              </Grid>
              <Grid item xs={12} marginBottom={8} marginTop={2} style={{ display: "flex", justifyContent: "center" }}>
                <Pagination
                  color="primary"
                  count={Math.ceil(filterViagens.length / itemsPerPage)}
                  page={page}
                  onChange={(event, value) => {
                    setPage(value);
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                  }}
                  siblingCount={0}
                  shape="rounded"
                />
              </Grid>
            </Grid>
          </Grid>
          {idDespesa && (
            <DespesaComponent
              IdDespesa={idDespesa}
              openViagem={openViagem}
              setOpenViagem={setOpenViagem}
              handleClickExcluirViagem={handleClickExcluirViagem}
            />
          )}
        </Grid>
      </Container>
    </>
  );
};
