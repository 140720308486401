import { DeleteOutlineOutlined, EditRounded, MoreVertOutlined, QrCode2Outlined } from "@mui/icons-material"
import { Button, Checkbox, Chip, Grid, IconButton, MenuItem, Typography } from "@mui/material"
import { useState } from "react"
import { EnumTipo, formatarValorComMoeda } from "../../../../enums/enumPrestacaoDeContas"
import { CreationDate } from "../../../../views/prestacaodecontas/Desktop/sections"
import { StyledMenu } from "../../../MenuStyled/style"
import { ImageComponent } from "../../../ImageComponent"
import { useStyles } from "../styles"
import StyledChip from "../../chip"

export const PendenteD = ({
    Gasto,
    setGasto,
    Viagemstatus,
    onQrCode,
    selected, // booleano para indicar se está selecionado
    onSelect, // função para alternar seleção, por exemplo, onSelect(Gasto.iddespesa)
}) => {
    

    return (
        <Grid container item xs={12} style={{
            padding: "0px",
            border: selected ? "2px solid #ffa600" : "none",
            transition: 'background-color 0.5s ease', // Suaviza a transição do efeito de escurecimento
            cursor: 'default', // Cursor padrão quando não está em hover
            backgroundColor: "#FFFFFF",
            textAlign: 'center',
            borderRadius: "10px",
            boxShadow: "0px 0px 8px 1px rgba(0,0,0,0.13)",
        }}>
            <Grid container item xs={10} style={{ padding: "20px" }}>
                {/* <Grid container item xs={12} style={{ display: "flex", alignItems: "left", justifyContent: "left" }}>
                    <Checkbox
                        checked={selected}
                        onChange={() => onSelect(Gasto.iddespesa)}
                    />
                </Grid> */}
                <Grid container item xs={7} style={{ display: "flex", flexDirection: "column", textAlign: "justify" }}>
                    <Typography style={{ fontWeight: "600" }}>
                        {Gasto.hodometro ? "Hodômetro" : "Gasto"}
                    </Typography>
                </Grid>
                <Grid item xs={5} style={{ display: "flex", justifyContent: "right", textAlign: "right" }}>
                    <CreationDate data={Gasto.datacriacao} />
                </Grid>
                <Grid item xs={12} style={{ textAlign: "left" }}>
                    <Typography style={{ fontWeight: "500", color: "#595959" }}>
                        {Gasto.descricao}
                    </Typography>
                </Grid>
                <Grid item xs={6} style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignContent: "flex-start",
                    alignItems: "flex-start",
                    flexDirection: "row",
                    marginTop: "auto"
                }}>
                    <Typography style={{ fontSize: "small" }}>
                        {Gasto.despesa_desc === "Pendente - Pendente" ? (
                            <StyledChip label="Pendente" style={{ backgroundColor: "#fdaa20", color: "white" }} />
                        ) : Gasto.despesa_desc}
                    </Typography>
                </Grid>
                <Grid item xs={3} style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end"
                }}>

                </Grid>
                <Grid item xs={3} style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignContent: "flex-end",
                    alignItems: "flex-end",
                    flexDirection: "row",
                }}>
                    <Grid style={{ marginRight: "10px" }}>
                        {Gasto.aprovado ? (
                            <StyledChip label="Aprovado" color="primary" />
                        ) : (
                            (Viagemstatus === EnumTipo.viagemstatus.controladoria && !Gasto.controladoria ||
                                Viagemstatus === EnumTipo.viagemstatus.pendenteacorrecao ||
                                Viagemstatus === EnumTipo.viagemstatus.correcaorealizada) ? (
                                <StyledChip label="Pendente" color="secondary" />
                            ) : null
                        )}
                    </Grid>
                    <Typography style={{ fontWeight: "600", color: "rgba(0, 0, 0, 0.87)" }}>
                        {formatarValorComMoeda(Gasto.tipo_moeda, Gasto.total)}
                    </Typography>
                </Grid>
            </Grid>
            <Grid
                container
                item
                xs={2}
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative" // Torna o container referência para o posicionamento absoluto
                }}
            >
                <ImageComponent
                    width={"100%"}
                    height={148}
                    src={Gasto.comprovante}
                    alt={"Imagem não encontrada"}
                />
                <Checkbox
                    checked={selected}
                    onChange={() => onSelect(Gasto.iddespesa)}
                    style={{
                        color: "white",
                        position: "absolute",
                        top: 8,
                        right: 8,
                        zIndex: 1,
                    }}
                />
            </Grid>
        </Grid>
    );
};