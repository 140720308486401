import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import {
    Button,
    Grid,
    LinearProgress,
    Typography
} from "@mui/material";
import React from "react";
import DialogComponent from '../../../../../../Desktop/sections/dialogs-desktop';

export const CardWorking = ({ 
    start,
    loading, 
    finalized, 
    totalDistance,
    minutes,
    totalGanho,
    handleClickFinalized,
    setStart,
    openCancelarTrajeto,
    setOpenCancelarTrajeto,
    clearPolylines,
    handleClickCreate
}) => {
    return (
        <Grid item xs={12} style={{ position: 'absolute', bottom: 0, width: "-webkit-fill-available", background: 'rgba(0, 0, 0, 0)', padding: "20px" }}>
            {loading && (
                <LinearProgress color="success" style={{ width: '100%' }} />
            )}
            {finalized ? (
                <Grid container item xs={12} justifyContent="center" style={{ padding: '20px', backgroundColor: "#FFFFFF", borderRadius: "10px" }}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <DirectionsCarIcon fontSize="large" />
                        </Grid>
                        <Grid item xs>
                            <Typography variant="caption" display="block" gutterBottom>
                                DISTÂNCIA
                            </Typography>
                            <Typography variant="h6">
                                {totalDistance.toFixed(1)} km
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography variant="caption" display="block" gutterBottom>
                                DURAÇÃO
                            </Typography>
                            <Typography variant="h6">
                                {minutes} min
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="caption" display="block" gutterBottom>
                                TOTAL EM REAIS
                            </Typography>
                            <Typography variant="h6">
                                {totalGanho.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                size="large"
                                variant="contained"
                                color="primary"
                                fullWidth
                                disabled={loading}
                                onClick={() => handleClickCreate()}
                            >
                                Adicionar Trajeto
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            ) : (
                <Grid container item xs={12} justifyContent="center" style={{ padding: '20px', backgroundColor: "#FFFFFF", borderRadius: "10px", zIndex: 2 }}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <DirectionsCarIcon fontSize="large" />
                        </Grid>
                        <Grid item xs>
                            <Typography variant="caption" display="block" gutterBottom>
                                DISTÂNCIA
                            </Typography>
                            <Typography variant="h6">
                                {totalDistance} km
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography variant="caption" display="block" gutterBottom>
                                DURAÇÃO
                            </Typography>
                            <Typography variant="h6">
                                {minutes} min
                            </Typography>
                        </Grid>
                    </Grid>
                    {start ? (
                        <Button
                            size="large"
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => handleClickFinalized()}
                        >
                            Finalizar Trajeto
                        </Button>
                    ) : (
                        <Button
                            size="large"
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => setStart(true)}
                        >
                            Iniciar Trajeto
                        </Button>
                    )}
                    {start ? (
                        <>
                            <DialogComponent
                                title={"Cancelar trajeto"}
                                open={openCancelarTrajeto}
                                handleClose={() => setOpenCancelarTrajeto(false)}
                                body={
                                    <Grid container item xs={12}>
                                        <Typography>Ao cancelar seu trajeto, não poderá ser visto, será apagado, deseja realmente cancelar seu trajeto?</Typography>
                                    </Grid>
                                }
                                action={
                                    <Grid>
                                        <Button size="large" onClick={() => setOpenCancelarTrajeto(false)}>Cancelar</Button>
                                        <Button size="large" onClick={() => clearPolylines()}>Confimar</Button>
                                    </Grid>
                                }
                                width={"sm"}
                            />
                            <Button
                                size="large"
                                variant="contained"
                                color="info"
                                fullWidth
                                onClick={() => setOpenCancelarTrajeto(true)}
                            >
                                Cancelar
                            </Button>
                        </>
                    ) : (
                        null
                    )}
                </Grid>
            )}
        </Grid>
    )
}