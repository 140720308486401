import { Badge, Box, Button, ButtonGroup, Container, Grid, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { CardAddInViagem_desk } from "../../../../../../components/autalii_expanses/card_addinviagem/index_desk";
import { CardDespesa } from "../../../../../../components/autalii_expanses/card_despesa";
import Test from "../../../../../../components/autalii_expanses/charts/text";
import { ViewQrCodeProducts } from "../../../../../../components/autalii_expanses/viewqrcode";
import { startTourViagemCriada_Mobile } from "../../../../../../components/tutorial";
import { TourComponent } from "../../../../../../components/tutorial/index2";
import { EnumTipo, formatarParaNomeStatusViagem } from "../../../../../../enums/enumPrestacaoDeContas";
import { IconsSourceSvg } from "../../../../../../images/Icons";
import DialogComponent from "../../../../Desktop/sections/dialogs-desktop";
import { PendentesList } from "../CorrigirDespesa";
import { ExtrasDespesasComponent } from "../Extras";

export const DespesaList = ({
    view,
    setView,
    isExpanded,
    setIsExpanded,
    setType,
    viagem,
    gastos,
    gasto,
    setGasto,
    extras,
    loading,
    handleClickFinalizarViagem,
    hasApprovedAdiantamento,
    handleClickDeleteGasto,
    handleClickEditGasto
}) => {
    const [openDeleteDespesa, setOpenDeleteDespesa] = useState(false)
    const [openQrCodeView, setOpenQrCodeView] = useState(false);
    const [currentTab, setCurrentTab] = useState(1);
    const [pendente, setPendente] = useState([]);
    const [incorrectSpen, setIncorrectSpen] = useState([])

    // Supondo que 'extras' e 'pendente' sejam arrays disponíveis no seu componente
    const tabsData = [
        { value: 1, label: "Despesas" },
        extras && extras.length > 0 ? { value: 2, label: "Conciliação" } : null,
        pendente && pendente.length > 0 ? { value: 3, label: "Pendente" } : null
    ].filter(tab => tab !== null);


    const handleChangeTab = useCallback((newValue) => {
        setCurrentTab(newValue);
    }, []);

    const handleClickOpenQrCode = (despesa) => {
        setGasto(despesa)
        setOpenQrCodeView(true)
    }

    const verifyPendente = () => {
        let verif = []
        setCurrentTab(1)
        gastos.forEach(element => {
            if (element.produtos.iddespesa_tipo === 1) {
                verif.push(element)
                setCurrentTab(3)
            }
        });
        setPendente(verif)
    }

    const verifyIncorrect = () => {
        let verif = []
        gastos.forEach(element => {
            if (element.mensagem !== "") {
                verif.push(element)
            }
        });
        setIncorrectSpen(verif)
    }

    useEffect(() => {
        verifyPendente()
        verifyIncorrect()
    }, [extras, gastos])

    return (
        <Container style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "20px"
        }}>
            <DialogComponent
                title={
                    <>
                        Informações do QrCode
                    </>
                }
                open={openQrCodeView}
                handleClose={() => setOpenQrCodeView(false)}
                body={
                    <ViewQrCodeProducts QrCode={gasto?.qrCode} />
                }
                action={
                    <>
                    </>
                }
                fullScreen={false}
            />
            <Grid container item xs={12}>
                <Grid item xs={!isExpanded ? 12 : 6}>
                    {(viagem.status === EnumTipo.viagemstatus.emviagem || viagem.status === EnumTipo.viagemstatus.aguardandoinicio || viagem.status === EnumTipo.viagemstatus.pendenteacorrecao) && (
                        <CardAddInViagem_desk
                            viagem={viagem}
                            despesas={gastos}
                            pendente={pendente}
                            incorrectSpen={incorrectSpen}
                            loading={loading}
                            handleClickFinalizarViagem={handleClickFinalizarViagem}
                            hasApprovedAdiantamento={hasApprovedAdiantamento}
                            setType={setType}
                        />
                    )}
                </Grid>
                {isExpanded && (
                    <Grid item xs={6}>
                        <Test expanses={gastos} />
                    </Grid>
                )}
            </Grid>

            {(viagem.status !== 1 && viagem.status !== 0) && (
                <Grid item xs={12} style={{ marginTop: "8px", fontFamily: "sans-serif", textAlign: "center" }}>
                    {formatarParaNomeStatusViagem(viagem.status)}
                </Grid>
            )}
            <Grid item xs={12} style={{ justifyContent: "center", marginTop: "-10px", alignContent: "center", textAlign: "center", marginBottom: "-30px" }}>
                {!(tabsData.length === 1) && (
                    <ButtonGroup variant="outlined" color="inherit" style={{ justifyContent: "center", borderColor: "#D6D6D6" }}>
                        {tabsData.map((tab) => (
                            <Badge
                                invisible={!(tab.value === 3)}
                                key={tab.value} // É importante passar uma key única para cada elemento
                                badgeContent={pendente.length}
                                sx={{
                                    "& .MuiBadge-badge": {
                                        backgroundColor: "#ffa600",
                                        color: "white",
                                        marginBottom: "-20px",
                                        marginTop: "10px"
                                    }
                                }}
                            >
                                <Button
                                    onClick={() => handleChangeTab(tab.value)}
                                    style={{
                                        textTransform: "none", // Mantém a capitalização original do texto
                                        fontWeight: currentTab === tab.value ? 600 : 400, // Destaca o botão ativo
                                        borderColor: "#D6D6D6"
                                    }}
                                >
                                    <Typography
                                        variant="h6"
                                        style={{ fontSize: "15px", fontWeight: currentTab === tab.value ? 600 : 400 }}
                                    >
                                        {tab.label}
                                    </Typography>
                                </Button>
                            </Badge>
                        ))}
                    </ButtonGroup>
                )}
            </Grid>
            <Grid
                item xs={12}
                style={{
                    gap: "20px",
                    display: "flex",
                    justifyContent: "right",
                    marginBottom: "0px",
                    marginTop: "10px",
                    width: "100%"
                }}>
            </Grid>
            {currentTab === 1 && viagem !== null && (
                <>
                    <DialogComponent
                        title="Deletar gasto"
                        open={openDeleteDespesa}
                        handleClose={() => setOpenDeleteDespesa(false)}
                        body={
                            <Grid container item xs={12}>
                                <Typography>Deseja realmente deletar essa gasto?</Typography>
                            </Grid>
                        }
                        action={
                            <Grid>
                                <Button size="large" onClick={() => setOpenDeleteDespesa(false)}>Cancelar</Button>
                                <Button size="large" onClick={() => { handleClickDeleteGasto(); setOpenDeleteDespesa(false); }}>Confimar</Button>
                            </Grid>
                        }
                        width={"sm"}
                    />
                    {viagem !== null && gastos.length !== 0 ? (
                        <Box
                            gap={2}
                            display="grid"
                            gridTemplateColumns={
                                (isExpanded) ? {
                                    xs: 'repeat(1, 1fr)',
                                    sm: 'repeat(1, 1fr)',
                                    md: 'repeat(2, 1fr)',
                                } : {
                                    xs: 'repeat(1, 1fr)',
                                    sm: 'repeat(2, 1fr)',
                                    md: 'repeat(1, 1fr)',
                                }}
                            style={{ gap: 10 }}
                        >
                            {(incorrectSpen.length > 0 && viagem.status === EnumTipo.viagemstatus.pendenteacorrecao) ?
                                (
                                    <>
                                        {incorrectSpen?.map((value) => (
                                            <CardDespesa
                                                Gasto={value}
                                                Viagemadiantamento={viagem.adiantamento}
                                                Viagemstatus={viagem.status}
                                                setGasto={setGasto}
                                                onClick={() => { setGasto(value); setType(gasto.hodometro !== null ? 4 : 3); }}
                                                onDelete={() => { setGasto(value); setOpenDeleteDespesa(true); }}
                                                onEditGasto={() => { setGasto(value); setType(3); }}
                                                onEditHodometro={() => { setGasto(value); setType(4); }}
                                                onQrCode={() => handleClickOpenQrCode(value)}
                                                fullScreen={false}
                                            />
                                        ))}
                                    </>
                                )
                                :
                                (
                                    <>
                                        {gastos.filter((value) => value.produtos.iddespesa_tipo !== 1).map((value) => (
                                            <CardDespesa
                                                Gasto={value}
                                                Viagemadiantamento={viagem.adiantamento}
                                                Viagemstatus={viagem.status}
                                                setGasto={setGasto}
                                                onClick={() => { setGasto(value); setType(gasto.hodometro !== null ? 4 : 3); }}
                                                onDelete={() => { setGasto(value); setOpenDeleteDespesa(true); }}
                                                onEditGasto={() => { setGasto(value); setType(3); }}
                                                onEditHodometro={() => { setGasto(value); setType(4); }}
                                                onQrCode={() => handleClickOpenQrCode(value)}
                                                fullScreen={false}
                                            />
                                        ))}
                                    </>
                                )
                            }
                        </Box>
                    ) : (
                        <Grid item xs={12} style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginBottom: "20px" }}>
                            <img alt="Imagem de pastas" src={IconsSourceSvg.pastas}></img>
                            <Typography>Nenhuma despesa encontrada</Typography>
                            <TourComponent startTour={startTourViagemCriada_Mobile} />
                        </Grid>
                    )}
                </>
            )}
            {currentTab === 2 && (
                <>
                    <ExtrasDespesasComponent viagem={viagem} extras={extras} />
                </>
            )}
            {currentTab === 3 && (
                <PendentesList isExpanded={isExpanded} viagem={viagem} pendentes={pendente} setGasto={setGasto} setType={setType} handleClickEditGasto={handleClickEditGasto} />
            )}
        </Container>
    )
}