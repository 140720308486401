import { EditOutlined } from "@mui/icons-material";
import { Button, FormControl, FormControlLabel, FormLabel, Grid, InputAdornment, Radio, RadioGroup, Slider, TextField, Typography } from "@mui/material";
import { EnumTipo, formatarMoeda, getCurrencySymbol } from "../../../../../../enums/enumPrestacaoDeContas";
import { useState } from "react";

export const ControlBalanceNacional = ({ model, setModel, statusAd, setStatusAd }) => {
    const valorInicial = "sem"; // ou "livre" ou outro valor
    const [gerenciamento, setGerenciamento] = useState(
        valorInicial === "sem" || valorInicial === "livre" ? "sem" : "com"
    );

    const [custo, setCusto] = useState(model.totalinicial);

    const handleValorChange = (event) => {
        let value = formatarMoeda(event.target.value);
        setCusto(value);
        setModel((prevModel) => ({
            ...prevModel,
            tem_multimonetario: false,
            adiantamento: [{ tipo_moeda: EnumTipo.moedas.BRL.id, total: value, status: statusAd }],
            totalinicial: value,
            pedido: gerenciamento == "sem" || gerenciamento === "livre" ? false : model.pedido // Atualiza o modelo com os valores atuais
        }));
    };

    const handleChange = (event) => {
        if (event.target.value === "com") {
            setModel((prevModel) => ({
                ...prevModel,
                tem_multimonetario: false,
                adiantamento: [{ tipo_moeda: EnumTipo.moedas.BRL.id, total: custo, status: EnumTipo.adiantamento.solicitado }],
                totalinicial: custo,
                pedido: true
            }));
            setStatusAd(EnumTipo.adiantamento.solicitado)
        }
        if (event.target.value === "sem") {
            setModel((prevModel) => ({
                ...prevModel,
                tem_multimonetario: false,
                adiantamento: [{ tipo_moeda: EnumTipo.moedas.BRL.id, total: custo, status: EnumTipo.adiantamento.naosolicitado }],
                totalinicial: custo,
                pedido: false
            }));
            setStatusAd(EnumTipo.adiantamento.naosolicitado)
        }
        if (event.target.value === "livre") {
            setModel((prevModel) => ({
                ...prevModel,
                tem_multimonetario: false,
                adiantamento: [{ tipo_moeda: EnumTipo.moedas.BRL.id, total: 0, status: EnumTipo.adiantamento.naosolicitado }],
                totalinicial: 0,
                pedido: false
            }));
            setStatusAd(EnumTipo.adiantamento.naosolicitado)
        }
        setGerenciamento(event.target.value);
    };

    return (
        <Grid container item xs={12}>
            <Grid item xs={12}>
                <FormControl>
                    <FormLabel id="demo-controlled-radio-buttons-group">Controle de Saldo:</FormLabel>
                    <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={gerenciamento}
                        onChange={handleChange}
                    >
                        <FormControlLabel className="solicitar" value="com" control={<Radio />} label="Solicitar adiantamento" />
                        <FormControlLabel className="semadiantamento" value="sem" control={<Radio />} label="Sem adiantamento" />
                        <FormControlLabel className="saldolivre" value="livre" control={<Radio />} label="Saldo livre" />
                    </RadioGroup>
                </FormControl>
            </Grid>
            {gerenciamento !== "livre" ? (
                <>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Insira o valor"
                            value={custo ? formatarMoeda(String(custo)) : ''}
                            onChange={(event) => handleValorChange(event)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        {getCurrencySymbol(EnumTipo.moedas.BRL.id)}
                                    </InputAdornment>
                                ),
                                inputProps: {
                                    inputMode: 'numeric',
                                    style: { fontSize: '28px', fontWeight: 'bold' }
                                }
                            }}
                            variant="standard"
                        />
                    </Grid>
                </>
            ) : (
                <Grid item xs={12}>
                    <Typography variant="body2" style={{ color: "#414141" }}>
                        Seu saldo será calculado de acordo com suas despesas. Se você não precisa solicitar um adiantamento, suas despesas serão adicionadas ao saldo total que será pago no final da viagem.
                    </Typography>
                </Grid>
            )}
        </Grid>
    );
}