export const ExcelLoadTableDespesas = (data, table, setDataExcel, setColunasExcel) => {
    setColunasExcel([
        "ID",
        "Titulo",
        "Centro de Custo",
        "Nome Completo",
        "Email",
        "Data de criação viagem",
        "Data inicial da viagem",
        "Data final da viagem",
        "Quantidade de Despesas",
        "Política",
        "Total Inicial",
        "Total gasto",
        "Total I.A - Extra",
    ]);

    // Filtrando os itens selecionados e mapeando para o formato necessário
    const datas = data
        .filter((item) => table.selected.includes(item.id))  // Filtra apenas os itens selecionados
        .map((item) => {
            return [
                item.idviagemtenant,
                item.titulo,
                item.centrocusto,
                item.pessoa.nome,
                item.pessoa.email,
                item.datadecriacao,
                item.datainicio,
                item.datafim,
                item.qtd_despesas,
                item.necessita_atencao ? "Fora da política" : "Dentro da política",
                item.totalinicial,
                item.total,
                item.totalextras
            ];
        });

    // Atualizando os dados para exportação no Excel
    setDataExcel(datas);
}