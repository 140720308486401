import { Button, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, InputAdornment, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { EnumTipo, formatarMoeda, getCurrencySymbol } from "../../../../../../enums/enumPrestacaoDeContas";
import Flag from "react-world-flags";
import { Add, Delete } from "@mui/icons-material";
import { useState } from "react";
import { grey } from "../../../../../../theme/palette";

export const ControlBalanceInternacional = ({ model, setModel, statusAd, setStatusAd }) => {
    const [moedaValorCampos, setMoedaValorCampos] = useState([
        { tipo_moeda: EnumTipo.moedas.USD.id, total: 0, status: EnumTipo.adiantamento.solicitado }
    ]);

    const handleMoedaChange = (index, event) => {
        const newMoedaValorCampos = [...moedaValorCampos];
        newMoedaValorCampos[index].tipo_moeda = event.target.value;
        setMoedaValorCampos(newMoedaValorCampos);
        setModel((prevModel) => ({
            ...prevModel,
            tem_multimonetario: true,
            adiantamento: newMoedaValorCampos, // Atualiza o modelo com os valores atuais
            pedido: true
        }));
    };

    const handleValorChange = (index, event) => {
        const newMoedaValorCampos = [...moedaValorCampos];
        const valorString = event.target.value;
        newMoedaValorCampos[index].total = formatarMoeda(valorString);
        setMoedaValorCampos(newMoedaValorCampos);
        setModel((prevModel) => ({
            ...prevModel,
            tem_multimonetario: true,
            adiantamento: newMoedaValorCampos,
            pedido: true // Atualiza o modelo com os valores atuais
        }));
    };

    const handleStatusChange = (index, event) => {
        const newMoedaValorCampos = [...moedaValorCampos];
        newMoedaValorCampos[index].status = event.target.checked ? EnumTipo.adiantamento.solicitado : EnumTipo.adiantamento.naosolicitado;
        setMoedaValorCampos(newMoedaValorCampos);
        setModel((prevModel) => ({
            ...prevModel,
            adiantamento: newMoedaValorCampos // Atualiza o modelo com os valores atuais
        }));
    };

    const adicionarMaisUmCampo = () => {
        setMoedaValorCampos([...moedaValorCampos, { tipo_moeda: EnumTipo.moedas.USD.id, total: '0.00', status: EnumTipo.adiantamento.solicitado }]);
    };

    const removerCampo = (index) => {
        const newMoedaValorCampos = moedaValorCampos.filter((_, i) => i !== index);
        setMoedaValorCampos(newMoedaValorCampos);
        setModel((prevModel) => ({
            ...prevModel,
            adiantamento: newMoedaValorCampos // Atualiza o modelo com os valores atuais
        }));
    };

    return (
        <Grid container item xs={12}>
            <FormControl fullWidth>
                <FormLabel id="demo-controlled-radio-buttons-group" style={{ marginTop: "5px", marginBottom: "0px" }}>Operação:</FormLabel>
                {moedaValorCampos.map((campo, index) => (
                    <Grid container item xs={12} key={index} style={{ marginBottom: '5px' }}>
                        <Grid item xs={12} container spacing={2} style={{ marginBottom: '0px', marginTop: "0px" }}>
                            <Grid item xs={12}>
                                <FormControl fullWidth variant="outlined" style={{ minWidth: 120 }}>
                                    <InputLabel id={`select-moeda-label-${index}`}>Moeda</InputLabel>
                                    <Select
                                        labelId={`select-moeda-label-${index}`}
                                        id={`select-moeda-${index}`}
                                        value={campo.tipo_moeda}
                                        onChange={(event) => handleMoedaChange(index, event)}
                                        label="Moeda"
                                    >
                                        {Object.keys(EnumTipo.moedas).map((key) => {
                                            const item = EnumTipo.moedas[key];
                                            return (
                                                <MenuItem key={item.id} value={item.id}>
                                                    <Flag code={item.flagCode} width="20" style={{ marginRight: '10px' }} /> {item.nome}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Valor"
                                    value={campo.total ? formatarMoeda(String(campo.total)) : ''}
                                    onChange={(event) => handleValorChange(index, event)}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {getCurrencySymbol(campo.tipo_moeda)}
                                            </InputAdornment>
                                        ),
                                        inputProps: {
                                            inputMode: 'numeric',
                                            style: { fontSize: '18px', fontWeight: 'bold' }
                                        }
                                    }}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} style={{
                                display: "flex",
                                justifyContent: "right",
                                textAlign: "right",
                                flexDirection: "column",
                                alignItems: "flex-end",
                            }}>
                                <FormGroup>
                                    <FormControlLabel sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                        label="Solicitar adiantamento"
                                        labelPlacement="start"
                                        control={
                                            <Checkbox
                                                size="large"
                                                checked={campo.status === EnumTipo.adiantamento.solicitado}
                                                onChange={(event) => handleStatusChange(index, event)}
                                            />
                                        } />
                                </FormGroup>
                            </Grid>
                        </Grid>
                        {index !== 0 && (
                            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Button startIcon={<Delete />} color="error" size="large" fullWidth onClick={() => removerCampo(index)} variant="outlined">Remover</Button>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Divider variant="ul" style={{ width: "100%", marginTop: "15px", marginBottom: "15px" }}></Divider>
                        </Grid>
                    </Grid>
                ))}
                <Grid item xs={12} style={{ textAlign: "right" }}>
                    <Button startIcon={<Add />} size="large" onClick={adicionarMaisUmCampo} variant="contained" style={{ color: "#FFFFFF", backgroundColor: grey[800] }}>Nova moeda</Button>
                </Grid>
            </FormControl>
        </Grid>
    )
}