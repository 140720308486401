import {
    useJsApiLoader
} from "@react-google-maps/api";
import html2canvas from "html2canvas";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useCustomSnackbar } from "../../../../../../../components/snackbar2/NewSnackBar";
import { EnumTipo } from "../../../../../../../enums/enumPrestacaoDeContas";
import prestacaoDeContasRepositorio from "../../../../../../../repositorios/prestacaodecontas";
import { InfoComponent } from './info';
import { WorkingComponent } from './working';

const apiKey = "AIzaSyDwG3G11O_o14Bax96T3qNQ7b6Pn-n5ldw"; // !!!!!!!!!!!!!!!! ESCONDER ISSO EM ALGUM LUGAR !!!!!!!!!!!!!!
const center = { lat: -33.8667, lng: 151.1955 };
const libraries = ["places", "drawing", "geometry"];

export const GoogleMapsMComponent = ({ idviagem, setType, handleClickCreateHodometro }) => {
    //Para a API
    const [polylines, setPolylines] = useState([]);
    const [snappedCoordinates, setSnappedCoordinates] = useState([]);
    const [currentLocation, setCurrentLocation] = useState(null);
    const [message, setMessage] = useState("");
    const [log, setLog] = useState(false);
    const [typeLog, setTypeLog] = useState("");
    const [path, setPath] = useState([]);
    //Para logica
    const [start, setStart] = useState(false);
    const [finalized, setFinalized] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalDistance, setTotalDistance] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [timerActive, setTimerActive] = useState(false);
    const [timerId, setTimerId] = useState(null);
    const [tipoHodoOk, setTipoHodoOk] = useState(false);
    const [tipoveiculo, setTipoveiculo] = useState(1);
    const [tipocombustivel, setTipocombustivel] = useState(1);
    const [tipoporte, setTipoporte] = useState(2)
    const [descricao, setDescricao] = useState("");
    const totalGanho = 0
    const [produto, setProduto] = useState({});
    const [openCancelarTrajeto, setOpenCancelarTrajeto] = useState(false);

    const { enqueueSnackbar } = useCustomSnackbar();
    //Para api 
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: apiKey,
        libraries,
    });

    const mapRef = useRef(null);
    const onLoad = useCallback((map) => (mapRef.current = map), []);

    const startTimer = useCallback(() => {
        if (!timerActive) {
            const id = setInterval(() => {
                setMinutes((prevMinutes) => prevMinutes + 1);
            }, 60000); // 60000 ms = 1 minuto
            setTimerId(id);
            setTimerActive(true);
        }
    }, [timerActive, setMinutes, setTimerId, setTimerActive]);

    // Função para parar o temporizador
    const stopTimer = () => {
        if (timerActive && timerId) {
            clearInterval(timerId);
            setTimerActive(false);
            setTimerId(null);
        }
    };

    useEffect(() => {
        return () => {
            if (timerId) {
                clearInterval(timerId);
            }
        };
    }, [timerId]);

    const calculateDistance = (points) => {
        let totalDistance = 0;

        if (points.length > 1) {
            const path = points.map(
                (point) => new window.google.maps.LatLng(point.lat, point.lng)
            );
            totalDistance = window.google.maps.geometry.spherical.computeLength(path);
        }

        return totalDistance / 1000; // Converte para quilômetros.
    };

    const onPolylineComplete = (polyline) => {
        const value = path.map((latlng) => `${latlng.lat},${latlng.lng}`);
        runSnapToRoad(value);
        setPolylines([...polylines, polyline]);
        setLoading(false);
    };

    const runSnapToRoad = (pathValues) => {
        const path = pathValues.join("|");
        setLoading(true);
        fetch(
            `https://roads.googleapis.com/v1/snapToRoads?interpolate=true&key=${apiKey}&path=${path}`
        )
            .then((response) => response.json())
            .then((data) => {
                const newCoordinates = data.snappedPoints.map((point) => ({
                    lat: point.location.latitude,
                    lng: point.location.longitude,
                }));
                setSnappedCoordinates(newCoordinates);

                // Calcula e atualiza a distância total.
                const distance = calculateDistance(newCoordinates);
                setTotalDistance(distance);
            });
    };

    const handleClickFinalized = () => {
        setFinalized(true);
        setStart(false);
        onPolylineComplete();
        stopTimer();
    };

    const captureMap = async () => {
        if (mapRef.current) {
            const map = mapRef.current.getDiv()
            const canvas = await html2canvas(map, { useCORS: true, scale: 1 })
            const image2 = canvas.toDataURL('image/png')
            return image2
        }
    };

    const getLocation = useCallback(() => {
        if (navigator.geolocation) {
            const watchId = navigator.geolocation.watchPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    const newLocation = { lat: latitude, lng: longitude };

                    if (!start) {
                        setCurrentLocation(newLocation);
                    }
                },
                (error) => {
                    console.error("Error Code = " + error.code + " - " + error.message);
                },
                { enableHighAccuracy: true }
            );

            // Retorna a função para limpar o watchPosition
            return () => navigator.geolocation.clearWatch(watchId);
        }
    }, [start, setCurrentLocation]); // Dependências

    function calculateDistance2(lat1, lon1, lat2, lon2) {
        const R = 6371e3; // metros
        const φ1 = (lat1 * Math.PI) / 180;
        const φ2 = (lat2 * Math.PI) / 180;
        const Δφ = ((lat2 - lat1) * Math.PI) / 180;
        const Δλ = ((lon2 - lon1) * Math.PI) / 180;

        const a =
            Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
            Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        return R * c; // Distância em metros
    }

    const clearPolylines = () => {
        polylines.forEach((polyline) => polyline.setMap(null));
        setOpenCancelarTrajeto(false)
        setPolylines([]);
        setSnappedCoordinates([]);
        setStart(false);
        setLoading(false)
        setTotalDistance(0);
        setMinutes(0)
        setType(0)
    };

    useEffect(() => {
        let watchId = null;
        let wakeLock = null;

        async function requestWakeLock() {
            if ('wakeLock' in navigator) {
                try {
                    wakeLock = await navigator.wakeLock.request('screen');
                } catch (err) {
                    console.error(`${err.name}, ${err.message}`);
                }
            }
        }

        if (start) {
            startTimer();
            setLoading(true);
            requestWakeLock(); // tenta manter a tela ligada

            if (navigator.geolocation) {
                let lastPosition = null;

                watchId = navigator.geolocation.watchPosition(
                    (position) => {
                        const { latitude, longitude } = position.coords;
                        setLoading(true);

                        if (lastPosition !== null) {
                            const distance = calculateDistance2(
                                latitude,
                                longitude,
                                lastPosition.lat,
                                lastPosition.lng
                            );

                            if (distance > 50) {
                                enqueueSnackbar('Atualizando localização...', { variant: 'success' });
                                setCurrentLocation({ lat: latitude, lng: longitude });
                                lastPosition = { lat: latitude, lng: longitude };
                                setPath((prevPath) => [...prevPath, lastPosition]);
                            } else if (distance > 1000) {
                                enqueueSnackbar('Movimento dentro de 1 km, não atualiza.', { variant: 'success' });
                            } else {
                                enqueueSnackbar('Movimento dentro de 50 metros, não atualiza.', { variant: 'success' });
                            }
                        } else {
                            enqueueSnackbar('Definindo localização inicial...', { variant: 'success' });
                            setCurrentLocation({ lat: latitude, lng: longitude });
                            lastPosition = { lat: latitude, lng: longitude };
                            setPath((prevPath) => [...prevPath, lastPosition]);
                        }
                    },
                    (error) => {
                        console.error('Error Code = ' + error.code + ' - ' + error.message);
                    },
                    {
                        enableHighAccuracy: true,
                        timeout: 20000,
                        maximumAge: 1000,
                    }
                );
            }
        } else if (watchId !== null) {
            navigator.geolocation.clearWatch(watchId);
            enqueueSnackbar('Monitoramento de posição finalizado.', { variant: 'success' });
        }

        return () => {
            // Limpeza
            if (watchId !== null) {
                navigator.geolocation.clearWatch(watchId);
            }

            if (wakeLock) {
                wakeLock.release().then(() => {
                });
            }
        };
    }, [start, startTimer]);


    const handleClickCreate = async () => {
        try {
            let image = await captureMap()
            handleClickCreateHodometro(
                {
                    idviagem: idviagem,
                    datadecompra: new Date(),
                    descricao: descricao,
                    aprovado: false,
                    controladoria: false,
                    total: 0,
                    mensagem: "",
                    comprovante: null,
                    QrCode: null,
                    tipo_moeda: EnumTipo.moedas.BRL.id,
                    produtos: {
                        iddespesa_tipo: produto?.iddespesa_tipo,
                        iddespesa_subtipo: produto?.iddespesa_subtipo
                    },
                    hodometro: {
                        kminicial: 0,
                        kmfinal: totalDistance,
                        kmtotal: 0,
                        duracao: minutes,
                        valor: 0,
                        veiculo: {
                            categoria: tipoveiculo,
                            combustivel: tipocombustivel,
                            porte: tipoporte
                        },
                        comprovanteinicial: image,
                        comprovantefinal: "",
                        is_manual: false
                    },
                }
            )
            setType(0)
        } catch {

        } finally {

        }
    }

    const getAlltipos = useCallback(async () => {
        setLoading(true);
        try {
            const response = await prestacaoDeContasRepositorio.getAllTiposDeDespesas();

            response.forEach(item => {
                if (item.name === "Hodometro") {
                    item.produtos.forEach(element => {
                        if (element.name === "GPS") {
                            setProduto({
                                iddespesa_tipo: item.iddespesa_tipo,
                                iddespesa_subtipo: element.iddespesa_subtipo
                            });
                        }
                    });
                }
            });
        } catch {
            enqueueSnackbar('Ocorreu um erro ao buscar categorias de despesas.', { variant: 'error' });
        } finally {
            setLoading(false);
        }
    }, [setLoading, setProduto, enqueueSnackbar]);

    useEffect(() => {
        getAlltipos()
        getLocation()
    }, [getLocation, getAlltipos])

    const handleClickCriar = () => {
        if (descricao === "") {
            return
        }
        setTipoHodoOk(true)
    }

    return (
        <>
            {
                !tipoHodoOk ? (
                    <>
                        <InfoComponent
                            setType={setType}
                            handleClickCriar={handleClickCriar}
                            setDescricao={setDescricao}
                            tipoveiculo={tipoveiculo}
                            setTipoveiculo={setTipoveiculo}
                            tipocombustivel={tipocombustivel}
                            setTipocombustivel={setTipocombustivel}
                            tipoporte={tipoporte}
                            setTipoporte={setTipoporte}
                        />
                    </>
                ) : (
                    <>
                        <WorkingComponent
                            start={start}
                            finalized={finalized}
                            mapRef={mapRef}
                            currentLocation={currentLocation}
                            center={center}
                            totalDistance={totalDistance}
                            onLoad={onLoad}
                            path={path}
                            snappedCoordinates={snappedCoordinates}
                            loading={loading}
                            minutes={minutes}
                            totalGanho={totalGanho}
                            handleClickFinalized={handleClickFinalized}
                            setStart={setStart}
                            openCancelarTrajeto={openCancelarTrajeto}
                            setOpenCancelarTrajeto={setOpenCancelarTrajeto}
                            clearPolylines={clearPolylines}
                            setTipoHodoOk={setTipoHodoOk}
                            handleClickCreate={handleClickCreate}
                        />
                    </>
                )}
        </>
    );
}