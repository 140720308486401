import { AdminPanelSettings, AssignmentReturn, CurrencyExchange, DirectionsCar, ExpandMoreRounded, HistoryToggleOff, MarkEmailRead } from "@mui/icons-material"
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Button, Checkbox, Chip, Grid, TextField, Tooltip, Typography } from "@mui/material"
import { useState } from "react"
import { CreationDate, formatarData } from "../../../views/prestacaodecontas/Desktop/sections"
import DialogComponent from "../../../views/prestacaodecontas/Desktop/sections/dialogs-desktop"
import { ViewHodometerExpansesComponent } from "../viewhodometer_expanses"
import { ViewQrCodeProducts } from "../viewqrcode"
import { GastoComponent } from "./Gasto"
import StyledChip from "../chip"
import DespesasHistory from "./Timeline"

export const CardDespesaAprove = ({
    view,
    forView = false,
    despesa,
    indexDespesa,
    grupo,
    handleOpenEdit,
    toggleSelecaoDespesa,
    handleClickReturn,
    handleClickOpenInvalid
}) => {
    const [openQrCodeView, setOpenQrCodeView] = useState(false)
    const [openInfoHodometro, setOpenInfoHodometro] = useState(false)

    const [expanded, setExpanded] = useState(false);

    return (
        <div>
            <Grid
                item xs={12}
                style={{
                    transition: 'background-color 0.5s ease',
                    cursor: 'default', // Cursor padrão quando não está em hover
                    backgroundColor: "#FFFFFF",
                    textAlign: 'center',
                    borderRadius: !despesa.has_historico ? "10px":"10px 10px 0px 0px",
                    boxShadow: "0px 0px 8px 1px rgba(0,0,0,0.13)",
                    zIndex: "1000"
                }}
                container
            >
                {despesa.hodometro ? (
                    <>
                        {view !== "list" ? (
                            <Grid container item xs={12} style={{ padding: "20px" }}>
                                <Grid container item xs={5}>
                                    <Grid container item xs={12} style={{ display: "flex", flexDirection: "column", textAlign: "justify" }}>
                                        <Typography style={{ fontWeight: "600" }}>Hodometro</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={7} style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                    <Grid style={{ marginLeft: "auto" }}>
                                        <CreationDate data={despesa.datadecompra} />
                                    </Grid>
                                    {despesa.valor_auditado !== 0 ? (
                                        <>
                                            <Chip label={"Auditado - " + (new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(despesa.totalauditado))} color="warning" style={{ marginTop: "-14px", marginLeft: "5px", color: "white" }} icon={<CurrencyExchange />}>
                                            </Chip>
                                        </>) : (null)}
                                </Grid>
                                <Grid item xs={12} style={{ textAlign: "left" }}>
                                    <Typography style={{ fontWeight: "500", color: "#595959" }}>{despesa.descricao}</Typography>
                                </Grid>
                                <Grid item xs={12} style={{ display: "flex", justifyContent: "right" }}>
                                    <Button
                                        startIcon={<DirectionsCar />}
                                        color="secondary"
                                        onClick={() => setOpenInfoHodometro(true)}
                                    >
                                        Ver Informações
                                    </Button>
                                </Grid>
                                <Grid item xs={12} style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignContent: "flex-end",
                                    alignItems: "center",
                                    flexDirection: "row",
                                    marginTop: "auto"
                                }}>
                                    {despesa.mensagem !== "" ? (
                                        <>
                                            {despesa.controladoria ? (
                                                <Tooltip title="A despesa foi para controladoria">
                                                    <Button color="warning" style={{ marginTop: "-18px", marginLeft: "5px" }} onClick={() => handleClickReturn(despesa)} startIcon={<AdminPanelSettings />}>
                                                        Notificado
                                                    </Button>
                                                </Tooltip>
                                            ) : (
                                                <Tooltip title="O viajante já foi notificado sobre a despesa">
                                                    <Button color="secondary" style={{ marginTop: "-18px", marginLeft: "5px" }} onClick={() => handleClickReturn(despesa)} startIcon={<MarkEmailRead />}>
                                                        Viajante notificado
                                                    </Button>
                                                </Tooltip>
                                            )}
                                        </>
                                    ) : (
                                        <Tooltip title="Retornar despesa Controladoria/Colaborador">
                                            <Button color="secondary" style={{ marginTop: "auto", marginLeft: "5px" }} onClick={() => handleClickReturn(despesa)} startIcon={<AssignmentReturn />}>
                                                retornar
                                            </Button>
                                        </Tooltip>
                                    )}

                                </Grid>
                                <Grid item xs={12} style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginTop: "auto"
                                }}>
                                    <Typography style={{ fontWeight: "600", color: "rgba(0, 0, 0, 0.87)" }}>{despesa.hodometro.kmtotal} Km</Typography>
                                    {despesa.mensagem === "" ? (
                                        <Box style={{
                                            display: "flex",
                                            alignContent: "flex-end",
                                            alignItems: "center",
                                            flexDirection: "row",
                                            marginTop: "auto"
                                        }}>
                                            <Typography style={{ fontWeight: "600", fontSize: "14px" }} color="primary">Aprovar</Typography>
                                            <Checkbox
                                                defaultChecked
                                                color="primary"
                                                checked={despesa.selecionada}
                                                onChange={() => toggleSelecaoDespesa(grupo.titulo, indexDespesa)}
                                            />
                                        </Box>
                                    ) : (null)}
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid container item xs={12} style={{ padding: "20px" }}>
                                <Grid container item xs={6}>
                                    <Grid container item xs={12} style={{ display: "flex", flexDirection: "column", textAlign: "justify" }}>
                                        <Typography style={{ fontWeight: "600" }}>Hodometro</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={6} style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                    <Grid style={{ marginLeft: "auto" }}>
                                        <CreationDate data={despesa.datadecompra} />
                                    </Grid>
                                    {despesa.valor_auditado !== 0 && (
                                        <Chip
                                            label={"Auditado - " + (new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(despesa.valor_auditado))}
                                            color="warning"
                                            style={{
                                                marginLeft: "5px",
                                                color: "white",
                                                fontWeight: "700",
                                                borderRadius: "10px",
                                                height: "30px",
                                            }}
                                        >
                                        </Chip>
                                    )}
                                </Grid>
                                <Grid item xs={8} style={{ textAlign: "left" }}>
                                    <Typography style={{ fontWeight: "500", color: "#595959" }}>{despesa.descricao}</Typography>
                                </Grid>
                                <Grid item xs={4} style={{ display: "flex", justifyContent: "right" }}>
                                    <Button
                                        startIcon={<DirectionsCar />}
                                        color="secondary"
                                        onClick={() => setOpenInfoHodometro(true)}
                                    >
                                        Ver Informações
                                    </Button>
                                </Grid>
                                <Grid item xs={8} style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignContent: "flex-start",
                                    alignItems: "center",
                                    flexDirection: "row",
                                    marginTop: "auto"
                                }}>
                                    {despesa.mensagem !== "" ? (
                                        <>
                                            {despesa.controladoria ? (
                                                <Button color="warning" style={{ marginTop: "-18px", marginLeft: "5px" }} onClick={() => handleClickReturn(despesa)} startIcon={<AdminPanelSettings />}>
                                                    Notificado
                                                </Button>
                                            ) : (
                                                <Tooltip title="O viajante já foi notificado sobre a despesa">
                                                    <Button color="secondary" style={{ marginTop: "-18px", marginLeft: "5px" }} onClick={() => handleClickReturn(despesa)} startIcon={<MarkEmailRead />}>
                                                        Viajante notificado
                                                    </Button>
                                                </Tooltip>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {!forView && (
                                                <Button color="secondary" style={{ marginTop: "auto", marginLeft: "5px" }} onClick={() => handleClickReturn(despesa)} startIcon={<AssignmentReturn />}>
                                                    retornar
                                                </Button>
                                            )}
                                        </>
                                    )}
                                    {forView && (
                                        <>
                                            {despesa.is_invalido ? (
                                                <Tooltip title="Despesa marcada como inválida">
                                                    <StyledChip label="Inválido" color="error" />
                                                </Tooltip>
                                            ) : despesa.aprovado ? (
                                                despesa.data_aprovado ? (
                                                    <Tooltip title={`Aprovado em ${formatarData(despesa.data_aprovado, "d MMM 'de' yyyy")} por ${despesa.usuario_aprovador}`}>
                                                        <StyledChip label="Aprovado" color="primary" />
                                                    </Tooltip>
                                                ) : (
                                                    <StyledChip label="Aprovado" color="primary" />
                                                )
                                            ) : (
                                                <StyledChip label="Pendente" color="secondary" />
                                            )}
                                        </>
                                    )}
                                </Grid>
                                <Grid item xs={4} style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignContent: "flex-end",
                                    alignItems: "center",
                                    flexDirection: "row",
                                    marginTop: "auto"
                                }}>
                                    {despesa.mensagem === "" && !forView ? (
                                        <>
                                            <Typography style={{ fontWeight: "600", fontSize: "14px" }} color="primary">Aprovar</Typography>
                                            <Checkbox
                                                defaultChecked
                                                color="primary"
                                                checked={despesa.selecionada}
                                                onChange={() => toggleSelecaoDespesa(grupo.titulo, indexDespesa)}
                                            />
                                        </>
                                    ) : (null)}
                                    <Typography style={{ fontWeight: "600", color: "rgba(0, 0, 0, 0.87)" }}>{despesa.hodometro.kmtotal} Km</Typography>
                                </Grid>
                            </Grid>
                        )}
                    </>
                ) : (
                    <>
                        <GastoComponent
                            indexDespesa={indexDespesa}
                            view={view}
                            forView={forView}
                            despesa={despesa}
                            grupo={grupo}
                            handleClickReturn={handleClickReturn}
                            toggleSelecaoDespesa={toggleSelecaoDespesa}
                            handleOpenEdit={handleOpenEdit}
                            setOpenQrCodeView={setOpenQrCodeView}
                            handleClickOpenInvalid={handleClickOpenInvalid}
                        />
                    </>
                )}
            </Grid>
            {despesa.has_historico && (
                <Grid item xs={12}>
                    <Accordion
                        elevation={0}
                        expanded={expanded}
                        onChange={(_, isExpanded) => setExpanded(isExpanded)}
                        style={{
                            zIndex: 999,
                            padding: 0,
                            boxShadow: "0px 0px 2px 1px rgba(0,0,0,0.13)",
                            borderRadius: "0px 0px 10px 10px",
                        }}
                    >
                        <AccordionSummary expandIcon={<ExpandMoreRounded />}>
                            <HistoryToggleOff style={{ color: "#313131", marginRight: 20 }} />
                            <Typography>Histórico de alteração</Typography>
                        </AccordionSummary>

                        <AccordionDetails style={{ display: "flex", padding: "0px", flexDirection: "column", width: "100%" }}>
                            {expanded && <DespesasHistory despesa={despesa} />}
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            )}
            {(despesa.limite > 0 && despesa.total > despesa.limite) && (
                <Alert severity="info" style={{ marginTop: "10px", fontWeight: "500", borderRadius: "10px" }}>
                    Essa despesa passou do limite permitido no grupo {despesa.despesa_desc} de
                    <strong> {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(despesa.limite)} </strong>
                    ela gastou
                    <strong> {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(despesa.total)}. </strong>
                </Alert>
            )
            }
        </div>
    )
}