import { AppBar, Button, ButtonGroup, Dialog, DialogContent, Grid, IconButton, Slide, Toolbar, Typography } from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";
import React, { useCallback, useEffect, useState } from "react";
import api01 from "../../../../images/api/01.svg";
import api02 from "../../../../images/api/02.svg";
import { CreditCardPayment } from "./CreditCardPayment";
import { InvoicedPayment } from "./InvoicedPayment";
import { RegisteredPaymentMethod } from "./RegisteredPaymentMethod";
import { useStyles } from "./styles";
import reservaRepositorio from "../../../../repositorios/reserva";
import aereoRepositorio from "../../../../repositorios/aereo";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const BookingIssue = (props) => {
    const open = props.open;
    const responsive = props.responsive;
    const [booking, setBooking] = useState(props.bookingSelected);
    const classes = useStyles();
    const [tipoPagamento, setTipoPagamento] = useState(3);

    const hancleClickChangePayment = (tipo) => { setTipoPagamento(tipo); };

    const getLogoAPI = () => {
        switch (booking.idapi) {
            case 1:
                return (
                    <object
                        data={api01}
                        width={80}
                        height={80}
                        type="image/svg+xml"
                        aria-label="API 01 Logo"
                    >
                        Seu navegador não suporta imagens SVG. Aqui está uma imagem alternativa da API 01.
                    </object>
                );
            case 2:
                return (
                    <object
                        data={api02}
                        width={80}
                        height={80}
                        type="image/svg+xml"
                        aria-label="API 02 Logo"
                    >
                        Seu navegador não suporta imagens SVG. Aqui está uma imagem alternativa da API 02.
                    </object>
                );
            default:
                return null;
        }
    };


    const handleCloseBookingIssue = () => { props.handleClickCloseBookingIssue() }

    const preLoad = useCallback(async () => {
        // Verificar se booking, reservaRepositorio e aereoRepositorio estão definidos
        if (!booking?.idreserva || !reservaRepositorio || !aereoRepositorio) return;

        try {
            let temp_response = await reservaRepositorio.get(booking.idreserva);
            if (!temp_response?.idaereo) return;  // Verificação adicional para idaereo

            let response = await aereoRepositorio.get(temp_response.idaereo);
            if (!response?.data) return;  // Verificação para garantir que a resposta é válida

            // Atualizar o estado com os novos dados
            setBooking({
                ...booking,
                companhiaaerea: response.data.companhiaaerea,
                total: response.data.total
            });
        } catch (error) {
            console.error("Erro ao carregar dados:", error);
        }
    }, [booking, setBooking]);  // Adiciona as dependências necessárias

    useEffect(() => {
        preLoad();
    }, []);  // O useEffect agora depende de preLoad

    return (
        <>
            <Dialog open={open} onClose={() => props.handleClickCloseBookingIssue()} fullWidth maxWidth="md" fullScreen={responsive} TransitionComponent={Transition}>
                <AppBar position="relative">
                    <Toolbar className={classes.appbar}>
                        <Typography>Método de Emissão</Typography>
                        <IconButton onClick={() => props.handleClickCloseBookingIssue()} ><CloseOutlined className={classes.iconButton} /></IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12} className={classes.gridGroup}>
                            <ButtonGroup orientation={responsive ? "vertical" : "horizontal"} style={{ width: "100%" }}>
                                <Button variant="outlined" onClick={() => hancleClickChangePayment(1)} className={tipoPagamento === 1 ? classes.buttonGroupSelected : classes.buttonGroup}>Faturado</Button>
                                <Button variant="outlined" onClick={() => hancleClickChangePayment(2)} className={tipoPagamento === 2 ? classes.buttonGroupSelected : classes.buttonGroup}>Cartão de Crédito</Button>
                                <Button variant="outlined" onClick={() => hancleClickChangePayment(3)} className={tipoPagamento === 3 ? classes.buttonGroupSelected : classes.buttonGroup}>Método de pagamento</Button>
                            </ButtonGroup>
                            {getLogoAPI()}
                        </Grid>
                        <Grid item xs={12} style={{ margin: responsive ? '1rem' : '0' }}>
                            {tipoPagamento === 1 && <InvoicedPayment {...{
                                booking,
                                responsive,
                                isLoading: props.isLoading,
                                setIsLoading: props.setIsLoading,
                                setSnackMessage: props.setSnackMessage,
                                setSnackState: props.setSnackState,
                                setSnackVariant: props.setSnackVariant,
                                handleCloseBookingIssue
                            }} />}
                            {tipoPagamento === 2 && <CreditCardPayment {...{
                                booking,
                                responsive,
                                isLoading: props.isLoading,
                                setIsLoading: props.setIsLoading,
                                setSnackMessage: props.setSnackMessage,
                                setSnackState: props.setSnackState,
                                setSnackVariant: props.setSnackVariant,
                                handleCloseBookingIssue
                            }} />}
                            {tipoPagamento === 3 && <RegisteredPaymentMethod
                                booking={booking}
                                responsive={responsive}
                                isLoading={props.isLoading}
                                setIsLoading={props.setIsLoading}
                                setSnackMessage={props.setSnackMessage}
                                setSnackState={props.setSnackState}
                                setSnackVariant={props.setSnackVariant}
                                handleCloseBookingIssue={handleCloseBookingIssue}
                            />}
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    )
}
