import Shepherd from "shepherd.js";

export const deskHelpNovaDespesa = (setOpen, setOpenTutorial) => {
    const tour = new Shepherd.Tour({
        defaultStepOptions: {
            cancelIcon: {
                enabled: true,
            },
            classes: 'shepherd-theme-default',
            scrollTo: { behavior: 'smooth', block: 'center' },
        },
        useModalOverlay: true,
    });

    tour.on('complete', () => setOpenTutorial(false));
    tour.on('cancel', () => setOpenTutorial(false));

    // Passo 1
    tour.addStep({
        id: 'custom-step',
        text: `
        <div class="custom-step-text" style="text-align: center;">
            <h4 class="custom-step-text-typography1">Olá! Eu sou o guia do sistema e estou aqui para ajudar você.</h4>
            <p class="custom-step-text-body1">Essa é uma experiência simples e ágil. Você poderá criar sua despesa e adicionar seus gastos e Km rodados..</p>
            <p class="custom-step-text-body1" style="margin-top: 15px">Vamos começar?</p>
        </div>`,
        attachTo: {
            element: '.my-element',
            on: 'right'
        },
        classes: 'custom-tooltip-class',
        buttons: [
            {
                text: 'Iniciar Tutorial',
                action: tour.next,
                classes: 'custom-next-button'
            }
        ],
        cancelIcon: {
            enabled: false
        },
        scrollTo: { behavior: 'smooth', block: 'center' },
        highlightClass: 'highlighted-element'
    });

    // Passo 2
    tour.addStep({
        id: 'second-step',
        text: `
            <div class="custom-step-text" style="text-align: left;">
                <h4 class="custom-step-text-typography1">Vamos criar a sua despesa</h4>
                <p class="custom-step-text-body1">Clique em "Abrir despesa" para iniciar a criação de uma nova despesa.</p>
            </div>
        `,
        attachTo: {
            element: '.element1',
            on: 'bottom'
        },
        classes: 'custom-tooltip-class',
        canClickTarget: false,
        buttons: [
            {
                text: 'Voltar',
                action: tour.back,
                classes: 'custom-back-button'
            },
            {
                text: 'Próximo',
                action: () => {
                    tour.next();
                    setOpen(true);
                },
                classes: 'custom-next-button'
            }
        ],
    });

    // Passo 3
    tour.addStep({
        id: 'third-step',
        text: `
        <div class="custom-step-text" style="text-align: left;">
            <h4 class="custom-step-text-typography1">Insira um título</h4>
            <p class="custom-step-text-body1">Digite um título para a sua despesa, como por exemplo: "Visita ao cliente".</p>
        </div>
        `,
        attachTo: {
            element: '.element2',
            on: 'bottom'
        },
        classes: 'custom-tooltip-class',
        canClickTarget: false,
        buttons: [
            {
                text: 'Voltar',
                action: tour.back,
                classes: 'custom-back-button'
            },
            {
                text: 'Próximo',
                action: tour.next,
                classes: 'custom-next-button'
            }
        ],
    });

    // Passo 4
    tour.addStep({
        id: 'fourth-step',
        text: `
        <div class="custom-step-text" style="text-align: left;">
            <h4 class="custom-step-text-typography1">Selecione seu centro de custo</h4>
            <p class="custom-step-text-body1">Escolha o centro de custo para onde a despesa será destinada.</p>
        </div>
        `,
        attachTo: {
            element: '.element3',
            on: 'bottom'
        },
        classes: 'custom-tooltip-class',
        canClickTarget: false,
        buttons: [
            {
                text: 'Voltar',
                action: tour.back,
                classes: 'custom-back-button'
            },
            {
                text: 'Próximo',
                action: tour.next,
                classes: 'custom-next-button'
            }
        ],
    });

    // Passo 5
    tour.addStep({
        id: 'fifth-step',
        text: `
        <div class="custom-step-text" style="text-align: left;">
            <h4 class="custom-step-text-typography1">Defina as datas da despesa</h4>
            <p class="custom-step-text-body1">Escolha a data de início e a data de término previstas para a sua despesa.</p>
        </div>
        `,
        attachTo: {
            element: '.element4',
            on: 'bottom'
        },
        classes: 'custom-tooltip-class',
        canClickTarget: false,
        buttons: [
            {
                text: 'Voltar',
                action: tour.back,
                classes: 'custom-back-button'
            },
            {
                text: 'Próximo',
                action: tour.next,
                classes: 'custom-next-button'
            }
        ],
    });

    // Passo 6
    tour.addStep({
        id: 'sixth-step',
        text: `
        <div class="custom-step-text" style="text-align: left;">
            <h4 class="custom-step-text-typography1">Adicione o valor inicial</h4>
            <p class="custom-step-text-body1">Insira o valor inicial necessário. Se já possui o valor, deixe o campo de adiantamento desmarcado. Caso precise, selecione "Pedir adiantamento" e insira o valor necessário.</p>
        </div>
        `,
        attachTo: {
            element: '.element5',
            on: 'bottom'
        },
        classes: 'custom-tooltip-class',
        canClickTarget: false,
        buttons: [
            {
                text: 'Voltar',
                action: tour.back,
                classes: 'custom-back-button'
            },
            {
                text: 'Próximo',
                action: tour.next,
                classes: 'custom-next-button'
            }
        ],
    });

    // Passo 7
    tour.addStep({
        id: 'seventh-step',
        text: `
        <div class="custom-step-text" style="text-align: left;">
            <h4 class="custom-step-text-typography1">Finalize a criação da sua despesa</h4>
            <p class="custom-step-text-body1">Clique para criar sua despesa. Lembre-se que você pode excluir essa despesa posteriormente, se necessário.</p>
        </div>
        `,
        attachTo: {
            element: '.element6',
            on: 'bottom'
        },
        classes: 'custom-tooltip-class',
        canClickTarget: false,
        buttons: [
            {
                text: 'Voltar',
                action: tour.back,
                classes: 'custom-back-button'
            },
            {
                text: 'Próximo',
                action: tour.next,
                classes: 'custom-next-button'
            }
        ],
    });

    // Iniciar o tour
    tour.start();
};