import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import {
    Button,
    Grid
} from "@mui/material";
import {
    GoogleMap,
    Marker,
    Polyline
} from "@react-google-maps/api";
import React, { useEffect, useRef } from "react";
import { CardWorking } from './card-working';
import { primary } from '../../../../../../../../theme/palette';

export const WorkingComponent = ({
    start,
    finalized,
    currentLocation,
    center,
    totalDistance,
    onLoad,
    path,
    snappedCoordinates,
    loading,
    minutes,
    totalGanho,
    handleClickFinalized,
    setStart,
    openCancelarTrajeto,
    setOpenCancelarTrajeto,
    setTipoHodoOk,
    clearPolylines,
    handleClickCreate
}) => {
    const mapRef = useRef(null);

    const handleMapLoad = (mapInstance) => {
        mapRef.current = mapInstance;
        if (onLoad) onLoad(mapInstance);
    };

    useEffect(() => {
        if (!mapRef.current) return;

        // EXEMPLO: Sempre que path ou snappedCoordinates mudarem, 
        // enviamos para o SW salvar em background
        const allPoints = [...path, ...snappedCoordinates];
        if (allPoints.length < 1) return;

        // Verifica se o SW está ativo
        if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
          navigator.serviceWorker.controller.postMessage({
            type: 'STORE_COORDS',
            coords: allPoints
          });
        }
    }, [path, snappedCoordinates]);

    // FitBounds automático
    useEffect(() => {
        if (!mapRef.current) return;

        const map = mapRef.current;
        const allPoints = [...path, ...snappedCoordinates];
        if (allPoints.length < 2) return;

        const bounds = new window.google.maps.LatLngBounds();
        allPoints.forEach(coord => {
            bounds.extend({ lat: coord.lat, lng: coord.lng });
        });

        map.fitBounds(bounds);
    }, [path, snappedCoordinates]);

    return (
        <Grid container style={{ width: '100%', height: '100%', marginTop: "-20px" }}>
            {!start && !finalized ? (
                <Grid item xs={12} style={{ zIndex: 1, justifyContent: "left", marginLeft: "20px", height: "20px" }}>
                    <Button
                        style={{ marginTop: "30px" }}
                        variant="contained"
                        color="info"
                        onClick={() => setTipoHodoOk(false)}
                    >
                        Voltar
                    </Button>
                </Grid>
            ) : null}

            <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                <GoogleMap
                    onLoad={handleMapLoad}
                    center={currentLocation || center}
                    zoom={17}
                    mapContainerStyle={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }}
                    options={{
                        streetViewControl: false,
                        mapTypeControl: false,
                        zoomControl: false,
                        fullscreenControl: false,
                    }}
                >
                    {path.length > 1 && (
                        <Polyline
                            path={path}
                            options={{
                                strokeColor: primary.main,
                                strokeOpacity: 10,
                                strokeWeight: 8,
                            }}
                        />
                    )}
                    {snappedCoordinates.length > 0 && (
                        <Polyline
                            path={snappedCoordinates}
                            options={{
                                strokeColor: primary.darker,
                                strokeWeight: 10,
                                strokeOpacity: 2,
                            }}
                        />
                    )}
                    {currentLocation && (
                        <Marker
                            position={currentLocation}
                            icon={{
                                path: window.google.maps?.SymbolPath.BACKWARD_CLOSED_ARROW,
                                scale: 7,
                                fillColor: primary.main,
                                fillOpacity: 1,
                                strokeWeight: 2,
                                strokeColor: "white",
                            }}
                        />
                    )}
                </GoogleMap>
            </div>

            <CardWorking
                start={start}
                loading={loading}
                finalized={finalized}
                totalDistance={totalDistance}
                minutes={minutes}
                totalGanho={totalGanho}
                handleClickFinalized={handleClickFinalized}
                setStart={setStart}
                openCancelarTrajeto={openCancelarTrajeto}
                setOpenCancelarTrajeto={setOpenCancelarTrajeto}
                clearPolylines={clearPolylines}
                handleClickCreate={handleClickCreate}
            />
        </Grid>
    );
};
