import { Grid, Tooltip, Typography } from "@mui/material"
import { CreationDate, CriadoPor, IniciadoEm } from "../../../views/prestacaodecontas/Desktop/sections"
import { formatarParaNomeStatusAdiantamentoList, formatarParaNomeStatusAdiantamentoListI, formatarParaNomeStatusViagem } from "../../../enums/enumPrestacaoDeContas"

export const CardMyViagem_desk = ({ despesa, onClick }) => {
    return (
        <Grid
            item xs={12}
            style={{
                transition: 'background-color 0.5s ease', // Suaviza a transição do efeito de escurecimento
                cursor: 'pointer', // Cursor padrão quando não está em hover
                backgroundColor: "#FFFFFF",
                padding: 20,
                marginTop: 10,
                textAlign: 'center',
                borderRadius: "10px",
                boxShadow: "0px 0px 8px 1px rgba(0,0,0,0.13)",
                '&:hover': {
                    cursor: 'pointer', // Muda o cursor para pointer quando em hover
                    backgroundColor: "#F6F6F6",
                    boxShadow: "0px 0px 3px 1px rgba(0,0,0,0.13)",
                },
            }}
            onClick={() => onClick(despesa.id)}
            container
        >
            <Grid container item xs={6}>
                <Grid container item xs={12} style={{ display: "flex", flexDirection: "column", textAlign: "justify" }}>
                    <Typography style={{ fontWeight: "600" }}>{despesa.titulo}</Typography>
                    <Typography style={{ color: "#637381" }}>{despesa?.qtd_despesas > 0 ? "Contém " + despesa.qtd_despesas + " despesa(s)" : "Não há registros nesta despesa."}</Typography>
                </Grid>
                <Grid container item xs={12} marginTop={5}>
                    {formatarParaNomeStatusViagem(despesa.status)}
                </Grid>
                <Grid container item xs={12}>
                    <IniciadoEm datainicio={despesa.datainicio} />
                    <CriadoPor />
                </Grid>
            </Grid>
            <Grid item xs={6} style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", padding: "0px 20px 0px 0px" }}>
                <Grid item xs={12} style={{ marginLeft: "auto" }}>
                    <CreationDate data={despesa.datadecriacao} />
                </Grid>

                <Grid item xs={12} style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignContent: "flex-end",
                    alignItems: "flex-end",
                    flexDirection: "column"
                }}>
                    {despesa.total !== 0 || despesa.total === null ? (
                        <>
                            {!despesa.tem_multimonetario && (
                                <>
                                    <Typography>Total</Typography>
                                    <Typography style={{ fontWeight: "600", color: "rgba(0, 0, 0, 0.87)" }}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(despesa.total)}</Typography>
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            {(despesa.totalinicial !== 0 || despesa.totalinicial === null) &&
                                (
                                    <>
                                        <Typography>Total inicial</Typography>
                                        <Typography style={{ fontWeight: "600", color: "rgba(0, 0, 0, 0.87)" }}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(despesa.totalinicial)}</Typography>
                                    </>
                                )}
                        </>
                    )}
                    {despesa.tem_multimonetario ? (
                        <>
                            {formatarParaNomeStatusAdiantamentoListI(despesa.adiantamento)}
                        </>
                    ) : (
                        <>
                            <Tooltip title={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(despesa.totalinicial)}>
                                {formatarParaNomeStatusAdiantamentoList(despesa.adiantamento, despesa.totalinicial)}
                            </Tooltip>
                        </>
                    )}

                </Grid>
            </Grid>
        </Grid>
    )
}