import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Slide } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme, ismap, fullScreen }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(0),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(0),
    },
    '& .MuiPaper-root': { // Ajustado para selecionar corretamente o papel do diálogo
        padding: '0px',
        borderRadius: "0px", // Adicionado aqui
    }
}));
 
export const DialogGoogleMaps = ({ body, width, open, handleClose }) => {
    return (
        <>
            <BootstrapDialog
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description" 
                // TransitionComponent={Transition}
                fullScreen={true}
                maxWidth={width}
                open={open}
                keepMounted
                TransitionComponent={Transition}
            >
                <DialogContent>
                    {body}
                </DialogContent>
            </BootstrapDialog>
        </>
    )
}