import { Button, ButtonGroup, Grid, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { CardDespesaAprove } from "../../../../../components/autalii_expanses/card_viewdespesa";
import { ViewHodometerExpansesComponent } from "../../../../../components/autalii_expanses/viewhodometer_expanses";
import { ViewQrCodeProducts } from "../../../../../components/autalii_expanses/viewqrcode";
import { DeskCardPersonExpanses } from "../../../../../components/card_person_expanses";
import { useCustomSnackbar } from "../../../../../components/snackbar2/NewSnackBar";
import ULoaderVoo from "../../../../../components/uloadervoo";
import { formatarParaNomeStatusAdiantamentoList } from "../../../../../enums/enumPrestacaoDeContas";
import prestacaoDeContasRepositorio from "../../../../../repositorios/prestacaodecontas";
import { CreationDate, IniciadoEm } from "../../sections";
import DialogComponent from "../../sections/dialogs-desktop";
import { useStyles } from "./style";

export const DetalhesViagemComponent = ({ viagem }) => {
    const classes = useStyles();
    const [despesa, setDespesa] = useState([])
    const [loading, setLoading] = useState(false)
    const [despesas, setDespesas] = useState([])
    const [extras, setExtras] = useState([])
    const [openInfoHodometro, setOpenInfoHodometro] = useState(false);
    const [currentTab, setCurrentTab] = useState(1);
    const [count, setCount] = useState(0)
    const [openQrCodeView, setOpenQrCodeView] = useState(false)

    const { enqueueSnackbar } = useCustomSnackbar();

    useEffect(() => {
        let c = 0;
        extras?.forEach(extra => {
            if (!extra.add) {
                c += 1;
            }
        })
        setCount(c)
    }, [extras])

    const getDespesas = useCallback(async () => {
        setLoading(true);
        try {
            const response = await prestacaoDeContasRepositorio.getDespesas(viagem.id);
            setDespesas(response.despesas);
            setExtras(response.extras);
        } catch (error) {
            enqueueSnackbar('Ocorreu um erro ao buscar despesas.', { variant: 'error' });
        } finally {
            setLoading(false);
        }
    }, [setLoading, viagem.id, setDespesas, setExtras, enqueueSnackbar]);

    const handleClickOpenQrCode = (despesa) => {
        setDespesa(despesa)
        setOpenQrCodeView(true)
    }

    const handleClickOpenHodometro = (despesa) => {
        setDespesa(despesa)
        setOpenInfoHodometro(true)
    }

    const handleChangeTab = useCallback((newValue) => {
        setCurrentTab(newValue);
    }, []);

    useEffect(() => {
        getDespesas()
    }, [getDespesas])

    return (
        <>
            <ULoaderVoo isLoading={loading} />
            {despesa !== null && (
                <>
                    <DialogComponent
                        title={
                            <>
                                Informações do Hodômetro
                            </>
                        }
                        open={openInfoHodometro}
                        handleClose={() => setOpenInfoHodometro(false)}
                        body={
                            <ViewHodometerExpansesComponent despesa={despesa} />
                        }
                        action={
                            <></>
                        }
                        width={"sm"}
                    />
                    <DialogComponent
                        title={
                            <>
                                Informações do QrCode
                            </>
                        }
                        open={openQrCodeView}
                        handleClose={() => setOpenQrCodeView(false)}
                        body={
                            <ViewQrCodeProducts QrCode={despesa?.qrCode} />
                        }
                        action={
                            <></>
                        }
                        width={"xs"}
                    />
                </>
            )}
            <Grid container item xs={12}>
                <Grid item xs={12} container style={{ marginTop: "0px" }}>
                    <Grid item xs={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                        <IniciadoEm datainicio={viagem.datainicio} />
                    </Grid>
                    <Grid item xs={6} style={{ display: "flex", justifyContent: "flex-end", }}>
                        <Grid style={{ marginLeft: "auto" }}>
                            <CreationDate data={viagem.datadecriacao} />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: "center", justifyContent: "center", display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <DeskCardPersonExpanses viagem={viagem} despesas={despesas} />
                    </Grid>
                </Grid>
                {viagem !== null && extras.length !== 0 ? (
                    <Grid item xs={12} style={{ justifyContent: "center", alignContent: "center", textAlign: "center", marginBottom: "0px" }}>
                        <ButtonGroup variant="outlined" color="inherit" style={{ justifyContent: "center", borderColor: "#D6D6D6" }}>
                            {[{ value: 1, label: "Despesas" }, { value: 2, label: "Conciliação" }].map((tab) => (
                                <Button
                                    key={tab.value}
                                    onClick={() => handleChangeTab(tab.value)}
                                    style={{
                                        textTransform: "none", // Mantém a capitalização original do texto
                                        fontWeight: currentTab === tab.value ? 600 : 400, // Destaca o botão ativo
                                        borderColor: "#D6D6D6"
                                    }}
                                >
                                    <Typography variant="h6" style={{ fontSize: "15px", fontWeight: currentTab === tab.value ? 600 : 400 }}>
                                        {tab.label}
                                    </Typography>
                                </Button>
                            ))}
                        </ButtonGroup>
                    </Grid>
                ) : (null)}

                <Grid item xs={12} style={{ justifyContent: "center", padding: "20px", display: "flex", marginBottom: "0px" }}>
                    {formatarParaNomeStatusAdiantamentoList(viagem.adiantamento)}
                </Grid>

                {currentTab === 1 && (
                    <Grid container item xs={12} style={{ gap: "20px"  }}>
                        {despesas?.map((item, index) => (
                            <CardDespesaAprove view={"list"} forView={true} despesa={item} />
                        ))}
                    </Grid>
                )}

                {currentTab === 2 && (
                    <>
                   
                        {extras?.map((value) => (
                            <>
                                <Grid
                                    item xs={12}
                                    style={{
                                        padding: "20px", 
                                        transition: 'background-color 0.5s ease', // Suaviza a transição do efeito de escurecimento
                                        marginTop: "20px",
                                        cursor: 'default', // Cursor padrão quando não está em hover
                                        backgroundColor: "#FFFFFF",
                                        textAlign: 'center',
                                        borderRadius: "10px",
                                        boxShadow: "0px 0px 8px 1px rgba(0,0,0,0.13)",
                                    }}
                                    container
                                >
                                    <Grid container item xs={12}>
                                        <Grid container item xs={12} style={{ display: "flex", flexDirection: "column", textAlign: "justify", justifyContent: "center" }}>
                                            <Typography style={{ fontWeight: "600" }}>{value.despesa_desc}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} style={{ textAlign: "left", marginTop: "8px" }}>
                                        <Typography style={{ fontWeight: "500", color: "#595959" }}>{value.descricao}</Typography>
                                    </Grid>
                                    <Grid item xs={9} style={{ display: "flex", flexDirection: "column", textAlign: "left", justifyContent: "left" }}>
                                        <CreationDate data={value.datacriacao} />
                                    </Grid>
                                    <Grid item xs={3} style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        alignContent: "flex-end",
                                        alignItems: "flex-end",
                                        flexDirection: "row"
                                    }}>
                                        <Typography style={{ fontWeight: "600", color: "rgba(0, 0, 0, 0.87)" }}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value.total)}</Typography>
                                    </Grid>
                                </Grid>
                            </>
                        ))}
                    </>
                )}
                {/* <Grid container item xs={12} style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button variant="contained" color="secondary" style={{ marginRight: "10px" }}>Enviar para</Button>
                    <Button variant="contained" color="primary">Aprovar todas</Button>
                </Grid> */}
            </Grid >
        </>
    )
}