import { MoreVertOutlined, QrCode2Outlined } from "@mui/icons-material"
import { Chip, Grid, IconButton, MenuItem, Typography } from "@mui/material"
import { useState } from "react"
import { EnumTipo } from "../../../../enums/enumPrestacaoDeContas"
import { CreationDate } from "../../../../views/prestacaodecontas/Desktop/sections"
import { StyledMenu } from "../../../MenuStyled/style"

export const Hodometer = ({
    Gasto, setGasto, Viagemstatus, handleClickEdit, handleClickDelete, handleClickDespesa, onQrCode
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickDespesaIn = (event) => {
        setAnchorEl(event.currentTarget);
        setGasto(Gasto)
    }

    const handleClickEditIn = () => {
        handleClickEdit()
        handleClose()
    }

    const handleClickDelIn = () => {
        handleClickDelete()
        handleClose()
    }
    return (
        <>
            <Grid container item xs={10}>
                <Grid container item xs={12} style={{ display: "flex", flexDirection: "column", textAlign: "justify", justifyContent: "center" }}>
                    <Typography style={{ fontWeight: "600" }}>Hodômetro</Typography>
                </Grid>
            </Grid>
            <Grid item xs={2} style={{ display: "flex", justifyContent: "right" }}>
                <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                        'aria-labelledby': 'demo-customized-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem onClick={handleClickEditIn} disableRipple>
                        Editar
                    </MenuItem>
                    <MenuItem onClick={handleClickDelIn} disableRipple>
                        Excluir
                    </MenuItem>
                </StyledMenu>
                {Gasto.qrCode !== null && (
                    <IconButton
                        onClick={onQrCode}
                    >
                        <QrCode2Outlined />
                    </IconButton>
                )}
                {Viagemstatus === EnumTipo.viagemstatus.aguardandoinicio ||
                    Viagemstatus === 1 && (
                        <IconButton
                            id="demo-customized-button"
                            aria-controls={open ? 'demo-customized-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            variant="contained"
                            disableElevation
                            onClick={(event) => handleClickDespesaIn(event)} // Passando o item atual para a função
                        >
                            <MoreVertOutlined />
                        </IconButton>
                    )}
            </Grid>
            <Grid item xs={12} style={{ textAlign: "left" }}>
                <Typography style={{ fontWeight: "500", color: "#595959" }}>{Gasto.descricao}</Typography>
            </Grid>
            <Grid item xs={Viagemstatus === 8 ? 7 : 12} style={{
                display: "flex",
                justifyContent: "flex-start",
                alignContent: "flex-start",
                alignItems: "flex-start",
                flexDirection: "row"
            }}>
                <Typography variant="body2">
                    {
                        Viagemstatus === EnumTipo.viagemstatus.viagemfinalizada ||
                            Viagemstatus === EnumTipo.viagemstatus.pendenteacorrecao ||
                            Viagemstatus === EnumTipo.viagemstatus.contablidade ||
                            Viagemstatus === EnumTipo.viagemstatus.correcaorealizada ||
                            Viagemstatus === EnumTipo.viagemstatus.financeiro ?
                            (
                                <Grid style={{ marginRight: "10px" }}>
                                    {Gasto.aprovado ? (
                                        <Chip label="Aprovado" color="primary" />
                                    ) : (
                                        null
                                    )}
                                </Grid>
                            ) : (null)
                    }
                </Typography>
                {Gasto?.tipo === 7 ? (<Typography style={{ fontWeight: "600", fontSize: "small", color: "warning.main" }}>Pendente</Typography>) : (null)}
            </Grid>
            <Grid item xs={12} >
                <Typography variant="body2" style={{ textAlign: "left" }}>
                    {Gasto.despesa_desc}
                </Typography>
            </Grid>
            <Grid item xs={9} style={{ display: "flex", flexDirection: "column", textAlign: "left", justifyContent: "left" }}>
                <CreationDate data={Gasto?.datacriacao} />
            </Grid>
            <Grid item xs={3} style={{
                display: "flex",
                justifyContent: "flex-end",
                alignContent: "flex-end",
                alignItems: "flex-end",
                flexDirection: "row"
            }}>
                <Typography style={{ fontWeight: "600", color: "rgba(0, 0, 0, 0.87)" }}>{Gasto.hodometro.kmtotal}km</Typography>
            </Grid>
        </>
    )
}