import React, { useState } from 'react';
import { Grid, Typography, Modal, Box } from '@material-ui/core';
import { IconsSource } from '../../images/Icons';
import ReactImageMagnify from 'react-image-magnify';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

export const ImageComponent = ({ src, width, height, isViewer = true }) => {
  const [open, setOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [zoom, setZoom] = useState(false);
  const [mousePos, setMousePos] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = ((e.pageX - left) / width) * 100;
    const y = ((e.pageY - top) / height) * 100;
    setMousePos({ x, y });
  };

  const viewerWidth = 250;
  const viewerHeight = 250;
  const containerWidth = document.getElementById('containerId') ? document.getElementById('containerId').offsetWidth : 250; // Substitua 'containerId' pelo ID do seu contêiner
  const containerHeight = document.getElementById('containerId') ? document.getElementById('containerId').offsetHeight : 250;

  let left = mousePos.x - (viewerWidth / 2);
  let top = mousePos.y - (viewerHeight / 2);



  const handleMouseOver = () => setIsHovered(true);
  const handleMouseOut = () => setIsHovered(false);

  const handleOpen = () => {
    if (isViewer) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // bgcolor: 'background.paper',
    display: "ruby",
    overflow: 'hidden',
    // imgZoom: {
    //   transition: 'transform 0.5s ease', // Suaviza a transição do zoom
    //   '&:hover': {
    //     transform: 'scale(1.2)', // Aumenta o tamanho da imagem em 20% quando o mouse está sobre ela
    //   },
    // },
  };

  const containerStyle = {
    width: '60%',
    height: '60%',
    overflow: 'hidden',
    position: 'relative',
  };

  const viewerStyle = {
    position: 'absolute',
    border: '2px solid #000',
    width: "250px",
    height: "250px",
    display: zoom ? 'block' : 'none',
    overflow: 'hidden',
    pointerEvents: 'none',
    backgroundImage: `url(${src})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: `${mousePos.x}% ${mousePos.y - (containerHeight / 5)}%`,
    backgroundSize: '200%',
    transform: `translate(-50%, -50%)`, // Centralizar o viewer sobre o cursor
    left: `${mousePos.x}%`,
    top: `${mousePos.y - (containerHeight / 5)}%`,
  };

  return (
    <React.Fragment>
      <Grid
        container
        item
        xs={12}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        onClick={handleOpen}
      >
        <div style={{
          width: width != null ? width : "130px",
          maxHeight: "-webkit-fill-available",
          borderRadius: "15px",
          cursor: "pointer",
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
          <img
            src={src != null && src != "" ? src : IconsSource.recibo}
            style={{
              width: width != null ? width : "130px",
              height: height != null ? height : "160px",
              maxHeight: "-webkit-fill-available",
              borderRadius: "15px",
              cursor: "pointer",
            }}
            alt={IconsSource.recibo}
            loading="lazy"
          />
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              borderRadius: "15px",
              backgroundColor: isHovered ? "rgba(0, 0, 0, 0.5)" : "transparent",
              transition: "background-color 0.5s ease",
            }}
          ></div>
          <Typography
            style={{
              position: "absolute",
              color: "white",
              padding: "5px 10px",
              borderRadius: "10px",
              display: isHovered ? "flex" : "none"
            }}
          >
            ver imagem
          </Typography>
        </div>
      </Grid>
      <Modal open={open} onClose={handleClose} fullScreen>
        <Box
          sx={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
            backgroundColor: 'black',
            position: 'relative'
          }}
        >
          {/* Botão para fechar */}
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: 16,
              right: 16,
              color: 'white',
              zIndex: 1
            }}
          >
            <Close fontSize="large" />
          </IconButton>

          <ReactImageMagnify
            {...{
              smallImage: {
                alt: 'Imagem pequena',
                isFluidWidth: true,
                src: src,
                style: { maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }
              },
              largeImage: {
                src: src,
                width: 2400,
                height: 1600
              },
              enlargedImageContainerDimensions: {
                width: '100%',
                height: '100%'
              },
              enlargedImagePosition: 'over'
            }}
          />
        </Box>
      </Modal>

    </React.Fragment>
  );
}
