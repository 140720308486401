import React from "react";
import Dashboard from "../dashboard";
import { BookingList } from "./BookingList";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { useState } from "react";
import ULoaderVoo from "../../components/uloadervoo";
import { BookingTicket } from "./BookingTicket";
import { RequestTicketBooking } from "./RequestTicket";
import { HotelTicket } from "./HotelTicket";
import IntegrationNotistack from "../../components/snackbar2/NewSnackBar";

const ListaReservas_Main = () => {
    const theme = useTheme()
    const responsive = useMediaQuery(theme.breakpoints.down("sm"));
    const [isLoading, setIsLoading] = useState(false);
    const [bookingSelected, setBookingSelected] = useState(0)
    const [isUpdateTicket, setIsUpdateTicket] = useState(false)
    const [openDialogBookingTicket, setOpenDialogBookingTicket] = useState(false)
    const [openDialogBookingRequestTicket, setOpenDialogBookingRequestTicket] = useState(false);
    const [openDialogBookingHotelTicket, setOpenDialogBookingHotelTicket] = useState(false);
    const [openHistory, setOpenHistory] = useState(false);

    const handleClickOpenHistory = () => { setOpenHistory(true) };
    const handleClickCloseHistory = () => { setOpenHistory(false) };

    const handleClickOpenDialogBookingTicket = () => { setOpenDialogBookingTicket(true) }

    const handleClickOpenDialogBookingRequestTicket = () => { setOpenDialogBookingRequestTicket(true) }

    const handleClickOpenDialogHotelTicket = () => { setOpenDialogBookingHotelTicket(true) }

    return (
        <IntegrationNotistack>
            <ULoaderVoo isLoading={isLoading} />
            <BookingList {...{
                isUpdateTicket,
                setIsUpdateTicket,
                responsive,
                setBookingSelected,
                setIsLoading,
                handleClickOpenDialogBookingTicket,
                handleClickOpenDialogBookingRequestTicket,
                handleClickOpenDialogHotelTicket,
                bookingSelected,
                openDialogBookingRequestTicket,
                handleClickCloseHistory,
                handleClickOpenHistory,
                openHistory,
                setOpenHistory
            }} />
            {openDialogBookingTicket && (
                <BookingTicket {...{
                    bookingSelected,
                    responsive,
                    setIsLoading,
                    openDialogBookingTicket,
                    setOpenDialogBookingTicket,
                    handleClickOpenHistory
                }} />
            )}
            {openDialogBookingRequestTicket && (
                <RequestTicketBooking {...{
                    setIsUpdateTicket,
                    bookingSelected,
                    setBookingSelected,
                    responsive,
                    setIsLoading,
                    openDialogBookingRequestTicket,
                    setOpenDialogBookingRequestTicket,
                }} />
            )}
            {openDialogBookingHotelTicket && (
                <HotelTicket {...{
                    openDialogBookingHotelTicket,
                    responsive,
                    setOpenDialogBookingHotelTicket,
                    bookingSelected,
                    setIsLoading,
                    handleClickOpenHistory
                }} />
            )}
        </IntegrationNotistack>
    )
}

export const ListaReservas_Main_Dash = (props) => {
    return <Dashboard {...props} component={ListaReservas_Main} />;
};
export default ListaReservas_Main_Dash;
