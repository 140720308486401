import { alpha } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import { typography } from "./typography";

export const grey = {
  0: "#FFFFFF",
  100: "#F9FAFB",
  200: "#F4F6F8",
  300: "#DFE3E8",
  400: "#C4CDD5",
  500: "#919EAB",
  600: "#637381",
  700: "#454F5B",
  800: "#212B36",
  900: "#161C24",
};

export const themepalette = createTheme({
  typography,
  palette: {
    default: {
      lighter: "#ef9a9a",
      light: "#e57373",
      main: "#ef5350",
      dark: "#f44336",
      darker: "#e53935",
      contrastText: "#FFFFFF",
    },
    primary: {
      lighter: "#6acb7f",
      light: "#46b261",
      main: "#31A552",
      dark: "#1c772f",
      darker: "#0d5a18",
      contrastText: "#FFFFFF",
      blackcolor: "#161C24"
    },
    secondary: {
      lighter: "#577fce",
      light: "#2e62b6",
      main: "#0954AA",
      dark: "#06317d",
      darker: "#011a60",
      contrastText: "#FFFFFF",
    },
    success: {
      lighter: "#66bb6a",
      light: "#4caf50",
      main: "#43a047",
      dark: "#388e3c",
      darker: "#2e7d32",
      contrastText: grey[0],
    },
    info: {
      lighter: "#736bd8",
      light: "#4d4ec5",
      main: "#3540BB",
      dark: "#1f248d",
      darker: "#0f126f",
      contrastText: grey[0],
    },
    warning: {
      lighter: "#FFF5CC",
      light: "#FFD666",
      main: "#FFAD07",
      dark: "#B76E00",
      darker: "#7A4100",
      contrastText: grey[800],
    },

    // Você pode definir outras cores do tema aqui...
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none", // Mantém a capitalização original do texto
          fontWeight: 600,
          borderColor: "#D6D6D6", // Define a cor da borda para todos os botões
          marginTop: "10px",
          // Não é possível adicionar o fontWeight condicional aqui, pois ele depende de uma condição específica do componente
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          // Estilos para o estado normal...
          borderRadius: "8px",
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: grey[800], // Altera a cor da borda quando focado
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          // Estilos para o Label...
          "&.Mui-focused": {
            color: grey[800], // Altera a cor do label quando focado
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          "&:after": {
            borderBottomColor: grey[800], // Altera a cor da linha de foco para inputs com variante "standard"
          },
        },
      },
    },
    MuiLocalizationProvider: {
      selectDateRange: "Selecione o intervalo de datas", // Altere para o texto desejado ou uma string vazia
    },
  },
  // Outras customizações do tema...
});

export const primary = {
  lighter: "#6acb7f",
  light: "#46b261",
  main: "#31A552",
  dark: "#1c772f",
  darker: "#0d5a18",
  contrastText: "#FFFFFF",
};

export const secondary = {
  lighter: "#577fce",
  light: "#2e62b6",
  main: "#0954AA",
  dark: "#06317d",
  darker: "#011a60",
  contrastText: "#FFFFFF",
};

export const info = {
  lighter: "#736bd8",
  light: "#4d4ec5",
  main: "#3540BB",
  dark: "#1f248d",
  darker: "#0f126f",
  contrastText: "#FFFFFF",
};

export const success = {
  lighter: "#D3FCD2",
  light: "#77ED8B",
  main: "#22C55E",
  dark: "#118D57",
  darker: "#065E49",
  contrastText: "#ffffff",
};

export const warning = {
  lighter: "#FFF5CC",
  light: "#FFD666",
  main: "#FFAB00",
  dark: "#B76E00",
  darker: "#7A4100",
  contrastText: grey[800],
};

export const error = {
  lighter: "#FFE9D5",
  light: "#FFAC82",
  main: "#FF5630",
  dark: "#B71D18",
  darker: "#7A0916",
  contrastText: "#FFFFFF",
};

export const common = {
  black: "#000000",
  white: "#FFFFFF",
};

export const action = {
  hover: alpha(grey[500], 0.08),
  selected: alpha(grey[500], 0.16),
  disabled: alpha(grey[500], 0.8),
  disabledBackground: alpha(grey[500], 0.24),
  focus: alpha(grey[500], 0.24),
  hoverOpacity: 0.08,
  disabledOpacity: 0.48,
};

const base = {
  primary,
  secondary,
  info,
  success,
  warning,
  error,
  grey,
  common,
  divider: alpha(grey[500], 0.2),
  action,
};

// ----------------------------------------------------------------------

export function palette(mode) {
  const light = {
    ...base,
    mode: "light",
    text: {
      primary: grey[800],
      secondary: grey[600],
      disabled: grey[500],
    },
    background: {
      paper: "#FFFFFF",
      default: "#FFFFFF",
      neutral: grey[200],
    },
    action: {
      ...base.action,
      active: grey[600],
    },
  };

  const dark = {
    ...base,
    mode: "dark",
    text: {
      primary: "#FFFFFF",
      secondary: grey[500],
      disabled: grey[600],
    },
    background: {
      paper: grey[800],
      default: grey[900],
      neutral: alpha(grey[500], 0.12),
    },
    action: {
      ...base.action,
      active: grey[500],
    },
  };

  return mode === "light" ? light : dark;
}


export function stringToColor(string = "Não reconhecido") {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#ff'; // Inicia a cor com #ff

  for (i = 0; i < 2; i += 1) { // Apenas dois componentes de cor restantes
    let value = (hash >> (i * 8)) & 0xff;
    // Ajustar o valor para garantir uma cor mais escura
    value = Math.floor(value * 0.5); // Reduzir o valor pela metade para garantir que seja mais escuro
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function stringAvatar(name = "Não reconhecido") {
  return {
    sx: {
      bgcolor: stringToColor(name),
      color: '#fff', // Garantir que o texto seja visível
      mr: 2,
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}


