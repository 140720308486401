import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { Padding } from '@mui/icons-material';

export const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#FFFFFF',
        color: '#1C252E',
        maxWidth: "340px",
        fontSize: theme.typography.pxToRem(12),
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        padding: "10px",
        borderRadius: "8px"
    },
}));
