import React, { useCallback, useEffect, useState } from "react";
import { CircularProgress, Grid } from "@mui/material";
import { useCustomSnackbar } from "../../../../../components/snackbar2/NewSnackBar";
import { hasApprovedAdiantamento } from "../../../../../enums/enumPrestacaoDeContas";
import DialogComponent from "../../sections/dialogs-desktop";
import { AdicionarDespesaComponent } from "./AdicionarDespesa";
import { EditarDespesaComponent } from "./EditarDespesa";
import { HodometroComponent } from "./Hodometro";
import { ViewHodometerDComponent } from "./HodometroInfo";
import 'shepherd.js/dist/css/shepherd.css';
import { CardSaldo_desk } from "../../../../../components/autalii_expanses/card_saldo/index_desk";
import { useCustomLoading } from "../../../../../components/uloadervoo/uloader";
import { Scripts } from "../../../Scripts";
import { DespesaBar } from "./DespesaBar";
import { DespesaList } from "./DespesaList";

export const DespesaComponent = ({
  IdDespesa,
  openViagem = false,
  setOpenViagem,
  handleClickExcluirViagem
}) => {
  const [type, setType] = useState(0);
  const [viagem, setViagem] = useState(null);
  const [gastos, setGastos] = useState([]);
  const [gasto, setGasto] = useState(null);
  const [extras, setExtras] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false)
  const [view, setView] = useState('list')

  const { enqueueSnackbar } = useCustomSnackbar();
  const { setIsLoading } = useCustomLoading();

  // Busca os dados da viagem e dos gastos ao receber um novo IdDespesa
  const fetchData = useCallback(() => {
    if (IdDespesa) {
      setViagem(null);
      Scripts.getGastos(setIsLoading, enqueueSnackbar, IdDespesa, setGastos, setExtras);
      Scripts.getViagemForID(setIsLoading, enqueueSnackbar, IdDespesa, setViagem);
    }
  }, [IdDespesa, setIsLoading, enqueueSnackbar]);

  useEffect(() => {
    fetchData();
  }, [IdDespesa, fetchData]);

  const handleClickFinalizarViagem = useCallback(async () => {
    await Scripts.handleFinalizarViagem(setIsLoading, enqueueSnackbar, viagem.id);
    await Scripts.getViagemForID(setIsLoading, enqueueSnackbar, IdDespesa, setViagem);
  }, [viagem, IdDespesa, setIsLoading, enqueueSnackbar]);

  const handleClickCreateGasto = useCallback(async (despesa) => {
    await Scripts.handleClickCriarDespesa(setIsLoading, enqueueSnackbar, despesa);
    await Scripts.getViagemForID(setIsLoading, enqueueSnackbar, IdDespesa, setViagem);
    await Scripts.getGastos(setIsLoading, enqueueSnackbar, IdDespesa, setGastos, setExtras);
  }, [IdDespesa, setIsLoading, enqueueSnackbar]);

  const handleClickEditGasto = useCallback(async (despesa) => {
    await Scripts.handleClickEditDespesa(setIsLoading, enqueueSnackbar, despesa);
    await Scripts.getViagemForID(setIsLoading, enqueueSnackbar, IdDespesa, setViagem);
    await Scripts.getGastos(setIsLoading, enqueueSnackbar, IdDespesa, setGastos, setExtras);
  }, [IdDespesa, setIsLoading, enqueueSnackbar]);

  const handleClickDeleteGasto = useCallback(async () => {
    if (gasto && gasto.iddespesa) {
      await Scripts.handleDeleteDespesa(setIsLoading, enqueueSnackbar, gasto.iddespesa);
      await Scripts.getViagemForID(setIsLoading, enqueueSnackbar, IdDespesa, setViagem);
      await Scripts.getGastos(setIsLoading, enqueueSnackbar, IdDespesa, setGastos, setExtras);
    }
  }, [gasto, IdDespesa, setIsLoading, enqueueSnackbar]);

  const handleClickEditViagem = useCallback(async (titulo = "", startDate = null, endDate = null) => {
    await Scripts.handleClickEditViagem(setIsLoading, enqueueSnackbar, viagem, titulo, startDate, endDate);
    await Scripts.getViagemForID(setIsLoading, enqueueSnackbar, IdDespesa, setViagem);
  }, [viagem, IdDespesa, setIsLoading, enqueueSnackbar]);

  return (
    <Grid>
      <DialogComponent
        title={
          <div style={{ padding: "10px" }}>
            {(viagem && type === 0) && (
              <DespesaBar
                viagem={viagem}
                handleClickEditViagem={handleClickEditViagem}
                handleClickExcluirViagem={handleClickExcluirViagem}
                temDespesa={gastos.length > 0}
              />
            )}
          </div>
        }
        open={openViagem}
        handleClose={() => setOpenViagem(false)}
        ismap={true}
        body={
          <div style={{ minWidth: "500px" }}>
            {!viagem ? (
              <Grid style={{ width: "500px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <CircularProgress color="success" style={{ zIndex: 1200 }} />
              </Grid>
            ) : (
              <>
                {type === 0 && viagem && (
                  <Grid style={{ width: "auto", marginBottom: "30px" }}>
                    <DespesaList
                      view={view}
                      setView={setView}
                      isExpanded={isExpanded}
                      setIsExpanded={setIsExpanded}
                      viagem={viagem}
                      gastos={gastos}
                      extras={extras}
                      gasto={gasto}
                      setType={setType}
                      setViagem={setViagem}
                      setExtras={setExtras}
                      setGastos={setGastos}
                      setLoading={setIsLoading}
                      setGasto={setGasto}
                      handleClickFinalizarViagem={handleClickFinalizarViagem}
                      hasApprovedAdiantamento={hasApprovedAdiantamento}
                      handleClickDeleteGasto={handleClickDeleteGasto}
                      handleClickEditGasto={handleClickEditGasto}
                    />
                  </Grid>
                )}
                {type === 1 && viagem && (
                  <div style={{ padding: "20px", marginTop: "-40px" }}>
                    <AdicionarDespesaComponent
                      viagem={viagem}
                      handleCreate={handleClickCreateGasto}
                      setType={setType}
                    />
                  </div>
                )}
                {type === 3 && viagem && (
                  <div style={{ padding: "20px", marginTop: "-40px" }}>
                    <EditarDespesaComponent
                      viagem={viagem}
                      despesa={gasto}
                      handleEdit={handleClickEditGasto}
                      setType={setType}
                    />
                  </div>
                )}
                {type === 2 && viagem && (
                  <div style={{ padding: "20px", marginTop: "-20px" }}>
                    <HodometroComponent
                      idviagem={viagem.id}
                      setType={setType}
                      handleClickCreateHodometro={handleClickCreateGasto}
                    />
                  </div>
                )}
                {type === 4 && viagem && (
                  <div style={{ padding: "20px", marginTop: "-10px" }}>
                    <ViewHodometerDComponent
                      viagem={viagem}
                      hodometro={gasto}
                      setType={setType}
                      editHodometro={handleClickEditGasto}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        }
        action={(viagem && type === 0) && <CardSaldo_desk viagem={viagem} />}
        width={isExpanded ? "lg" : "sm"}
      />
    </Grid>
  );
};
