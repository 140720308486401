import GoogleIcon from '@mui/icons-material/Google';
import {
    Box,
    Button,
    Container,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import React from "react";
import { EnumTipoVeiculo } from '../../../../../../../../enums/enumVeiculos';

export const InfoComponent = ({
    setType,
    handleClickCriar,
    setDescricao,
    tipoveiculo,
    setTipoveiculo,
    tipocombustivel,
    setTipocombustivel,
    tipoporte,
    setTipoporte
}) => {
    return (
        <>
            <Container maxWidth="sm" style={{ height: "100%", display: "flex", alignItems: "center" }}>
                <Grid container item xs={12} style={{ padding: "10px" }}>
                    <Grid item xs={12} style={{ textAlign: "center", marginBottom: "10px" }}>
                        <Typography style={{ fontWeight: "600", fontSize: "18px" }}>Informações do seu trajeto</Typography>
                    </Grid>
                    <TextField
                        style={{ marginBottom: "10px" }}
                        id="filled-multiline-flexible"
                        variant="outlined"
                        fullWidth
                        required
                        onChange={(e) => setDescricao(e.target.value)}
                        multiline
                        maxRows={4}
                        label="Justificativa/Descrição"
                        placeholder="Viagem para..."
                    />
                    <Grid item xs={12} style={{ marginBottom: "10px" }}>
                        <FormControl fullWidth>
                            <InputLabel id="veiculo-select-label">Veículo utilizado</InputLabel>
                            <Select
                                labelId="veiculo-select-label"
                                id="veiculo-select"
                                value={tipoveiculo}
                                onChange={(e) => setTipoveiculo(e.target.value)}
                                label="Veículo utilizado"
                            >
                                {Object.entries(EnumTipoVeiculo.categoria).map(([key, value]) => (
                                    <MenuItem key={value} value={value}>
                                        {key.charAt(0).toUpperCase() + key.slice(1)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} style={{ marginBottom: "10px" }}>
                        <FormControl fullWidth>
                            <InputLabel id="combustivel-select-label">Tipo de Combustível</InputLabel>
                            <Select
                                labelId="combustivel-select-label"
                                id="combustivel-select"
                                value={tipocombustivel}
                                onChange={(e) => setTipocombustivel(e.target.value)}
                                label="Tipo de Combustível"
                            >
                                {Object.entries(EnumTipoVeiculo.combustivel).map(([key, value]) => (
                                    <MenuItem key={value} value={value}>
                                        {key.charAt(0).toUpperCase() + key.slice(1)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} style={{ marginBottom: "10px" }}>
                        <FormControl fullWidth>
                            <InputLabel id="porte-select-label">Porte do veículo</InputLabel>
                            <Select
                                labelId="porte-select-label"
                                id="porte-select"
                                value={tipoporte}
                                onChange={(e) => setTipoporte(e.target.value)}
                                label="Porte do veículo"
                            >
                                {Object.entries(EnumTipoVeiculo.porte).map(([key, value]) => (
                                    <MenuItem key={value} value={value}>
                                        {key.charAt(0).toUpperCase() + key.slice(1)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', textAlign: "center", marginTop: "15px", justifyContent: "center" }}>
                        <GoogleIcon />
                        <span style={{ marginLeft: 1, fontFamily: "sans-serif", fontWeight: "500" }}>oogle maps oficial</span>
                    </Grid>
                    <Box sx={{ flexGrow: 1 }} />
                    <Grid container item xs={12} spacing={1} style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "10px", position: "fixed", bottom: 0, left: 0, right: 0, backgroundColor: "white", padding: "10px" }}>
                        <Grid item xs={6}>
                            <Button
                                size="large"
                                variant="contained"
                                color="info"
                                fullWidth
                                onClick={() => setType(0)}
                            >
                                Cancelar
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                size="large"
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={handleClickCriar}
                            >
                                Criar
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}