import { DeleteOutlineOutlined, EditRounded, MoreVertOutlined, QrCode2Outlined } from "@mui/icons-material"
import { Button, Chip, Divider, Grid, IconButton, MenuItem, Typography } from "@mui/material"
import { useState } from "react"
import { EnumTipo, formatarValorComMoeda } from "../../../../enums/enumPrestacaoDeContas"
import { CreationDate } from "../../../../views/prestacaodecontas/Desktop/sections"
import { StyledMenu } from "../../../MenuStyled/style"
import { ImageComponent } from "../../../ImageComponent"

export const WarningD = ({
    Gasto, setGasto, Viagemstatus, handleClickEdit, handleClickDelete, handleClickDespesa, onQrCode
}) => {
    return (
        <Grid container item xs={12} style={{ padding: "0px" }}>
            <Grid container item xs={12}>
                <Grid container item xs={10} style={{ padding: "20px" }}>
                    <Grid container item xs={7} style={{ display: "flex", flexDirection: "column", textAlign: "justify" }}>
                        <Typography style={{ fontWeight: "600" }}>{Gasto.hodometro ? ("Hodometro") : ("Gasto")}</Typography>
                    </Grid>
                    <Grid item xs={5} style={{ display: "flex", justifyContent: "right", textAlign: "right" }}>
                        <CreationDate data={Gasto.datacriacao} />
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: "left" }}>
                        <Typography style={{ fontWeight: "500", color: "#595959" }}>{Gasto.descricao}</Typography>
                    </Grid>
                    <Grid item xs={6} style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignContent: "flex-start",
                        alignItems: "flex-start",
                        flexDirection: "row",
                        marginTop: "auto"
                    }}>
                        <Typography style={{ fontSize: "small" }}>
                            {Gasto.despesa_desc}
                        </Typography>
                    </Grid> 
                    <Grid item xs={3}>
                        {(Viagemstatus === EnumTipo.viagemstatus.aguardandoinicio ||
                            Viagemstatus === EnumTipo.viagemstatus.emviagem ||
                            Viagemstatus === EnumTipo.viagemstatus.pendenteacorrecao) && (
                                <>
                                    <IconButton onClick={() => { setGasto(Gasto); handleClickDelete() }}>
                                        <DeleteOutlineOutlined />
                                    </IconButton>
                                    <IconButton onClick={handleClickEdit}>
                                        <EditRounded />
                                    </IconButton>
                                </>
                            )
                        }
                    </Grid>
                    <Grid item xs={3} style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignContent: "flex-end",
                        alignItems: "flex-end",
                        flexDirection: "row",
                    }}>
                        <Grid style={{ marginRight: "10px" }}>
                            {Gasto.aprovado ? (
                                <Chip label="Aprovado" color="primary" />
                            ) : (
                                <>
                                    {Viagemstatus === EnumTipo.viagemstatus.controladoria && !Gasto.controladoria ? (
                                        <Chip label="Pendente" color="secondary" />
                                    ) : (
                                        null
                                    )}
                                </>
                            )}
                        </Grid>
                        <Typography style={{ fontWeight: "600", color: "rgba(0, 0, 0, 0.87)" }}>
                            {formatarValorComMoeda(Gasto.tipo_moeda, Gasto.total)}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container item xs={2} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <ImageComponent width={"100%"} height={148} src={Gasto.comprovante} alt={"Imagem não encontrada"} />
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ padding: "10px" }}>
                <Divider />
            </Grid>
            <Grid item xs={12} style={{ textAlign: "left", padding: "20px", marginTop: "-20px", display: "flex", justifyContent: "space-between" }}>
                <Typography variant="body2" sx={{ mt: 1 }}><strong>Mensagem:</strong> {Gasto.mensagem}</Typography>
                <Typography variant="body1">
                    <Chip label={Gasto.controladoria ? "Controladoria" : "Atenção"} color="warning" style={{ color: "white" }} />
                </Typography>
            </Grid>
        </Grid>
    )
}