import { styled, Tabs } from "@mui/material";

export const CustomTabs = styled(Tabs)({
    '& .MuiTabs-indicator': {
        backgroundColor: "#212B36", // Cor do indicador personalizada
    },
    '& .MuiTab-root.Mui-selected': {
        color: "#212B36", // Cor do indicador personalizada
    },
    '& .MuiTab-root': {
        minHeight: "0px !important"
    }
});