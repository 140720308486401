import { Box, Button, Chip, Grid, InputAdornment, TextField, Typography } from "@mui/material"
import DialogComponent from "../../../../views/prestacaodecontas/Desktop/sections/dialogs-desktop"
import { ImageComponent } from "../../../ImageComponent"
import { getCurrencySymbol } from "../../../../enums/enumPrestacaoDeContas"
import { CreationDateConvert } from "../../../../views/prestacaodecontas/Desktop/sections"
import { HtmlTooltip } from "../../../tooltip"
import { HelpRounded } from "@mui/icons-material"

export const DialogEditDespesaAudit = ({
    despesa,
    openAuditar,
    setOpenAuditar,
    totalGasto,
    totalAuditar,
    handleValorChange,
    handleValorChangeAuditar,
    handleClickAuditar
}) => {
    return (
        <DialogComponent
            title={
                <>
                    {despesa && (
                        <>
                            <Typography variant="body1" style={{ color: "#181818" }}>Despesa #{despesa?.iddespesa}</Typography>
                            <Typography variant="body2" style={{ color: "#181818", color: "#7e7e7e" }}>{CreationDateConvert(despesa?.datadecompra)}</Typography>
                        </>
                    )}
                </>
            }
            open={openAuditar}
            handleClose={() => setOpenAuditar(false)}
            body={
                <>
                    <Grid container item xs={12} style={{ padding: "5px", gap: "15px" }}>
                        <ImageComponent width={220} height={248} src={despesa?.comprovante} alt={"Imagem não encontrada"} />
                        <TextField
                            fullWidth
                            className="totalgasto"
                            id="outlined-number"
                            label="Total gasto"
                            value={totalGasto}
                            onChange={handleValorChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        {getCurrencySymbol(despesa?.tipo_moeda || 1)}
                                    </InputAdornment>
                                ),
                                inputProps: {
                                    inputMode: 'numeric', // Orienta o uso de teclado numérico
                                    style: { fontSize: '18px' }
                                }
                            }}
                        />
                        <TextField
                            fullWidth
                            className="totalgasto"
                            id="outlined-number"
                            label="Total a auditar"
                            value={totalAuditar}
                            color="warning"
                            onChange={handleValorChangeAuditar}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment
                                        position="start"
                                    >
                                        <div style={{ color: "#FFAD07" }}>
                                            {getCurrencySymbol(despesa?.tipo_moeda || 1)}
                                        </div>
                                    </InputAdornment>
                                ),
                                inputProps: {
                                    inputMode: 'numeric', // Orienta o uso de teclado numérico
                                    style: { fontSize: '18px', color: "#FFAD07" },
                                }
                            }}
                        />
                    </Grid>
                </>
            }
            action={
                <>
                    <Grid item xs={12} style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-end",
                        padding: "20px",
                        marginTop: "-40px",
                        marginBottom: "-10px"
                    }}>
                        <Box item>
                            <HtmlTooltip
                                title={
                                    <div
                                        style={{
                                            padding: '8px',
                                            backgroundColor: '#ffffff',
                                            borderRadius: '8px',
                                            color: '#333',
                                            fontSize: '14px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                            fontFamily: "'Roboto Google', sans-serif",
                                        }}
                                    >
                                        <Typography variant="body">
                                            Atenção:
                                        </Typography>
                                        <Typography variant="body" style={{ marginTop: "10px", fontWeight: "400" }}>
                                            <strong style={{ color: "#868686" }}>Total Gasto: </strong>
                                            Representa o valor original do gasto informado pelo usuário. Ao editar este campo, você altera o valor registrado na gasto.
                                        </Typography>
                                        <Typography variant="body" style={{ marginTop: "0px", fontWeight: "400" }}>
                                            <strong style={{ color: "#868686" }}>Total a Auditar: </strong>
                                            Este campo destina-se a inserir o valor revisado pelo auditor. Use-o para corrigir possíveis inconsistências no valor original, mantendo o registro original intacto.
                                        </Typography>

                                    </div>
                                }
                            >
                                <Chip
                                    icon={<HelpRounded />}
                                    label="Ajuda"
                                    clickable
                                    color="primary"
                                />
                            </HtmlTooltip>
                        </Box>
                        <Box item>
                            <Button variant="text" color="primary" autoFocus onClick={() => handleClickAuditar()}>
                                Confirmar
                            </Button>
                        </Box>
                    </Grid>
                </>
            }
            width={"xs"}
        />
    )
}